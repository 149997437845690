import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as hcExerciseActions from '../../../actions/honestConversations.action'
import { UserObj } from '../../../objects/user'
import { GoalActionObj } from '../../../objects/goals'
import { ContactsInterface } from '../../../objects/contact'
import { getTrackerData, sortHouseholdCards } from '../../../helpers/priorities'
import {
  CardObj,
  HonestConversationsExerciseObj,
  HouseholdCardObj
} from '../../../objects/honestConversation'
import Button from 'react-bootstrap/Button'
import { history, AppDispatch } from '../../../store'
import InputForms from './InputForms'
import ScoreGoals from './ScoreGoals'
import ScoreTracker from './ScoreTracker'

export interface ScoreFormProps {
  goalActions: GoalActionObj[]
  hcExerciseId: string
  user: UserObj
  isPrimary: boolean
  dispatch: AppDispatch
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  previousActiveScoringSessionId: string
}

interface ScoreStateObj {
  householdCardId: string
  card: CardObj
  score: number
  comment: string
}

export interface ScoreFormState {
  scores: ScoreStateObj[]
  actionList: GoalActionObj[]
  currentCardIndex: number
  maxCardIndex: number
  recordsUpdated: boolean
}

export class ScoreForm extends Component<ScoreFormProps, ScoreFormState> {
  constructor(props: ScoreFormProps) {
    super(props)
    this.state = {
      actionList: null,
      scores: [],
      currentCardIndex: 0,
      maxCardIndex: 0,
      recordsUpdated: false
    }
  }

  public async componentDidMount() {
    const { honestConversation } = this.props
    if (honestConversation && honestConversation.householdCards) {
      const sortedHouseholdCards = sortHouseholdCards(
        honestConversation.householdCards
      )
      this.mapExistingHouseholdCards(sortedHouseholdCards)
    }
  }

  public mapExistingHouseholdCards = (householdCards: HouseholdCardObj[]) => {
    const newScores: ScoreStateObj[] = []
    householdCards.forEach((card: HouseholdCardObj) => {
      newScores.push({
        householdCardId: card.id,
        card: card.card,
        score: null,
        comment: null
      })
    })
    this.setState({ scores: newScores })
  }

  public updateActiveScoreSessionId = async (scoringSessionId?: string) => {
    const { hcExerciseId, honestConversation, dispatch, isPrimary } = this.props
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    if (isPrimary) {
      updatedProperty.activeScoringSessionPrimary = scoringSessionId
        ? scoringSessionId
        : honestConversation.activeScoringSessionPrimary
    } else {
      updatedProperty.activeScoringSessionSecondary = scoringSessionId
        ? scoringSessionId
        : honestConversation.activeScoringSessionSecondary
    }
    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        hcExerciseId,
        updatedProperty
      )
    )
  }

  public createScoresAndUpdateScoringSession = async () => {
    const {
      hcExerciseId,
      dispatch,
      honestConversation,
      user,
      isPrimary
    } = this.props
    const scoreSessionId = isPrimary
      ? honestConversation.activeScoringSessionPrimary
      : honestConversation.activeScoringSessionSecondary
    const scoreCardArray: any = []
    this.state.scores.forEach((card: any) => {
      scoreCardArray.push({
        householdCardId: card.householdCardId,
        score: card.score,
        comment: card.comment
      })
    })
    const cardScoreIds =
      honestConversation?.clientScoringSessions?.[user.id]?.[scoreSessionId] &&
      Object.keys(
        honestConversation.clientScoringSessions[user.id][scoreSessionId]
          .cardScores
      )
    await dispatch(
      hcExerciseActions.createCardScoreAndUpdateScoringSession(
        user.id,
        hcExerciseId,
        scoreSessionId,
        scoreCardArray,
        cardScoreIds
      )
    )
  }

  public cancelRecords = async () => {
    const {
      previousActiveScoringSessionId,
      dispatch,
      hcExerciseId,
      isPrimary,
      honestConversation
    } = this.props
    if (!previousActiveScoringSessionId) {
      history.push('/honest-conversations')
    }
    try {
      const scoringSessionId = isPrimary
        ? honestConversation.activeScoringSessionPrimary
        : honestConversation.activeScoringSessionSecondary
      dispatch(
        hcExerciseActions.deleteScoringSession(hcExerciseId, scoringSessionId)
      )
      await this.updateActiveScoreSessionId(previousActiveScoringSessionId)
      await dispatch(hcExerciseActions.getHonestConversation())
      history.push('/honest-conversations')
    } catch (error) {
      history.push('/honest-conversations')
    }
  }

  public goBack = async () => {
    try {
      await this.createScoresAndUpdateScoringSession()
      history.push('/honest-conversations')
    } catch (error) {
      history.push('/honest-conversations')
    }
  }

  public clickNext = async () => {
    window.scrollTo(0, 0)
    const { currentCardIndex, maxCardIndex } = this.state
    this.setState({
      currentCardIndex: currentCardIndex === 4 ? 4 : currentCardIndex + 1,
      maxCardIndex:
        currentCardIndex === 4
          ? currentCardIndex
          : Math.max(currentCardIndex + 1, maxCardIndex),
      recordsUpdated: true
    })
    await this.createScoresAndUpdateScoringSession()
  }

  public clickPrev = async () => {
    window.scrollTo(0, 0)
    const { currentCardIndex, maxCardIndex } = this.state
    this.setState({
      currentCardIndex: currentCardIndex === 0 ? 0 : currentCardIndex - 1,
      maxCardIndex: Math.max(currentCardIndex + 1, maxCardIndex),
      recordsUpdated: true
    })
    await this.createScoresAndUpdateScoringSession()
  }

  public onScoreChange = (score: number, index: any) => {
    const newScores = [...this.state.scores]
    newScores[index].score = score
    this.setState({ scores: newScores, recordsUpdated: true }, () => {
      this.createScoresAndUpdateScoringSession()
    })
  }

  public onCommentChange = (comment: string, index: number) => {
    const newScores = [...this.state.scores]
    newScores[index].comment = comment
    this.setState({ scores: newScores, recordsUpdated: true })
  }

  public render() {
    const {
      scores,
      currentCardIndex,
      maxCardIndex,
      recordsUpdated
    } = this.state
    const hasHouseHoldCards = scores.every(
      (score: ScoreStateObj) => score.householdCardId
    )
    const trackerData =
      scores && getTrackerData(scores, currentCardIndex, maxCardIndex)
    const newActions = this.props.goalActions ? [...this.props.goalActions] : []
    return hasHouseHoldCards && scores ? (
      <div>
        <div
          id='hc-update-scores'
          className='bg-white position-relative p-3 p-md-x2 honestConversations-score mt-n4'>
          <ScoreTracker
            trackerData={trackerData}
            scores={scores}
            currentCardIndex={currentCardIndex}
          />
          <div>
            {scores.map((score: ScoreStateObj, key: number) => {
              return key === currentCardIndex ? (
                <div key={key}>
                  <InputForms
                    card={score}
                    onScoreChange={this.onScoreChange}
                    onCommentChange={this.onCommentChange}
                    cardIndex={key}
                    createScoresAndUpdateScoringSession={
                      this.createScoresAndUpdateScoringSession
                    }
                  />
                  <ScoreGoals card={score} goalActions={newActions} />
                </div>
              ) : null
            })}
          </div>
          <div className='mt-x2 mb-x2'>
            {currentCardIndex === 0 ? null : (
              <Button
                variant='outline-primary'
                size='lg'
                className='font-color--dark match-btn-width'
                onClick={this.clickPrev}>
                Previous
              </Button>
            )}
            <Button
              className='honestConversations-score__button--next match-btn-width mt-2 mt-md-0'
              size='lg'
              onClick={currentCardIndex === 4 ? this.goBack : this.clickNext}>
              {currentCardIndex === 4 ? 'Save' : 'Next'}
            </Button>
          </div>
        </div>
        <Button
          className='honestConversations-score__close'
          variant='light'
          onClick={recordsUpdated ? this.goBack : this.cancelRecords}>
          <i className='bi bi-x-lg fs-5' />
        </Button>
      </div>
    ) : null
  }
}

export default connect()(ScoreForm)
