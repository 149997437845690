import React from 'react'
import DonutChart from '../../components/DonutChart'
import {
  currentDonutData,
  targetDonutData,
  calculateAssetAllocation
} from '../../helpers/investments'
import { AllocationObj } from '../../objects/allocation'

interface AssetAllocationProps {
  allocation: AllocationObj
  accounts: any[]
  noTargets: boolean
}

// label formatter for donut chart label
const labelFormatter = (value: string | number) => `${value}%`

const LegendItem = ({ color, name, percent }) => (
  <div className='inv-allocations__legend-item'>
    <span
      className='inv-allocations__legend-item-dot'
      style={{
        backgroundColor: color
      }}
    />
    <span>{name}</span>
    <span>{percent}</span>
  </div>
)

const Legend = ({ data }) => (
  <div className='d-flex flex-row justify-content-center flex-wrap'>
    {data.map(({ name, value, fill }) => {
      return value ? (
        <LegendItem
          key={name}
          name={name}
          color={fill}
          percent={labelFormatter(value)}
        />
      ) : null
    })}
  </div>
)

const AssetAllocation = ({
  allocation,
  accounts,
  noTargets
}: AssetAllocationProps) => {
  const getAllocations = () => {
    const current = accounts.length
      ? currentDonutData(calculateAssetAllocation(accounts))
      : []
    const target = noTargets ? [] : targetDonutData(allocation)
    return { current, target }
  }

  const { current, target } = getAllocations()

  return (
    <div className='inv-allocations'>
      <div className='inv-allocations__charts'>
        <div className='inv-allocations__chart'>
          <DonutChart
            data={current}
            formatLabelValue={labelFormatter}
            defaultLabel={{ top: 'Current', bottom: 'Allocation' }}
          />
          <div className='inv-allocations__chart-label'>Current Allocation</div>
        </div>
        <div className='inv-allocations__chart'>
          <DonutChart
            data={target}
            formatLabelValue={labelFormatter}
            defaultLabel={{ top: 'Target', bottom: 'Allocation' }}
          />
          <div className='inv-allocations__chart-label'>Target Allocation</div>
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <div className='inv-allocations__legend'>
          <Legend data={current} />
        </div>
      </div>
    </div>
  )
}

export default AssetAllocation
