import React, { useState } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { dollarFormat } from '../../../helpers'
import { ClientAccountObj } from '../../../objects/clientAccount'
import AssetAllocationTable from './AssetAllocationTable'
import {
  getHoldingAllocationData,
  getHoldingDonutChartWidth,
  investmentAccountDateMap
} from '../../../helpers/investments'
import DonutChart from '../../../components/DonutChart'
import { useWindowSize } from '../../../hooks'

interface HoldingHeroProps {
  account: ClientAccountObj
}

const HoldingHero = (props: HoldingHeroProps) => {
  const { account } = props
  const holdingsTotal = dollarFormat(account ? account.totalValueManual : 0, 0)
  const investmentsTotalStyle =
    holdingsTotal.length > 15
      ? `holding-hero__inv__sum holding-hero__inv__sum-long`
      : 'holding-hero__inv__sum h1 p-0 m-0'
  const { width } = useWindowSize()
  const donutHeight = getHoldingDonutChartWidth(width)
  const allocationData = getHoldingAllocationData(account)

  const [active, setActive] = useState(-1)
  return (
    <div>
      <div className='holding-hero__main-container' />
      <div className='position-relative' id='inv_hero'>
        <div className='holding-hero__container'>
          <div className='holding-hero__title'>
            <div className='holding-hero__title-lcontainer'>
              <ReactRouterLink
                to='/investments'
                className='holding-hero__title-link text-secondary fw-bold'>
                <i className='bi bi-chevron-left  holding-hero__title-link_icon me-1' />{' '}
                Investments
              </ReactRouterLink>
            </div>
            <div className='holding-hero__title-name'>
              {account?.accountNickname}
            </div>
          </div>
          <div className='holding-hero__inv'>
            <div
              className='py-1 holding-hero__inv-col holding-hero__inv-col--ordered'
              style={{ flex: 2 }}>
              <div className='holding-hero__inv-balance'>
                <div className={investmentsTotalStyle}>{holdingsTotal}</div>
                <div className='holding-hero__inv__label holding-hero__inv__label-light mt-2'>
                  Total Balance{' '}
                  {account.balanceDate
                    ? `as of ${investmentAccountDateMap(account.balanceDate)}`
                    : null}
                </div>
              </div>
              <div
                className='holding-hero__inv-donut holding-hero__inv-col--hide'
                style={{ height: donutHeight }}>
                <DonutChart
                  data={allocationData}
                  activeIndex={active}
                  onActiveSector={setActive}
                  noLabelOnHover={true}
                />
              </div>
            </div>
            <div className='py-1 holding-hero__inv-col ' style={{ flex: 3 }}>
              <AssetAllocationTable
                allocationData={allocationData}
                activeIndex={active}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HoldingHero
