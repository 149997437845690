import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import {
  ClientAccountObj,
  ClientAccountPositionsObj
} from '../../../objects/clientAccount'
import HoldingHero from './HoldingHero'
import { getInvestmentAccounts } from '../../../actions/clientAccounts.action'
import HoldingsTable from './HoldingsTable'

type PositionsActions = typeof mapDispatchToProps
type PositionsStore = ReturnType<typeof mapStateToProps>
export type PositionsProps = PositionsActions & PositionsStore

export const Positions = (props: PositionsProps) => {
  const { positions, account, getInvestmentAccounts } = props

  useEffect(() => {
    if (!positions || !positions.length) {
      getInvestmentAccounts()
    }
  }, [positions])

  if (!account) {
    return null
  }

  return (
    <div>
      <HoldingHero account={account} />
      <HoldingsTable positions={positions} />
    </div>
  )
}

const mapDispatchToProps = {
  getInvestmentAccounts
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const clientAccounts = store.clientAccounts
  const accountId = match.params.accountId
  let account: ClientAccountObj
  if (clientAccounts && accountId) {
    account = clientAccounts.investments.find((acc) => acc.id === accountId)
  }
  return {
    /**
     * when dump store log into console, I found that .account.postions are array of ClientAccountPositionsObj, as oppose to
     * ClientAccountPositionsInterface which is "key" - accountId, and "value" ClientAccountPositionsObj.
     * FUTURE FIX: address issue for type ClientAccountObj, address issues with code that was build on wrong assumptions, easy fix once v3 folder is removed
     */
    positions: (account?.positions as unknown) as ClientAccountPositionsObj[],
    account: account ? account : null
  }
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Positions)
)
