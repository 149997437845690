import Api from '../helpers/api'
import { FeeScheduleApiResponseObj } from '../objects/investments'

export const FETCH_INVESTMENT_PREFERENCES = 'FETCH_INVESTMENT_PREFERENCES'
export const FETCH_INVESTMENT_PREFERENCES_PENDING =
  'FETCH_INVESTMENT_PREFERENCES_PENDING'
export const FETCH_INVESTMENT_PREFERENCES_FULFILLED =
  'FETCH_INVESTMENT_PREFERENCES_FULFILLED'
export const FETCH_INVESTMENT_PREFERENCES_REJECTED =
  'FETCH_INVESTMENT_PREFERENCES_REJECTED'

export const FETCH_PSS_SUMMARY = 'FETCH_PSS_SUMMARY'
export const FETCH_PSS_SUMMARY_PENDING = 'FETCH_PSS_SUMMARY_PENDING'
export const FETCH_PSS_SUMMARY_FULFILLED = 'FETCH_PSS_SUMMARY_FULFILLED'
export const FETCH_PSS_SUMMARY_REJECTED = 'FETCH_PSS_SUMMARY_REJECTED'

export const FETCH_FEE_SCHEDULE = 'FETCH_FEE_SCHEDULE'

export const getInvestmentPreferences = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_INVESTMENT_PREFERENCES,
    payload: apiInstance.get('/invResponses').then((response) => {
      return response.data
    })
  }
}

export const getPssSummary = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_PSS_SUMMARY,
    payload: apiInstance.get('/pssSummary').then((response) => {
      return response.data
    })
  }
}

export const getFeeSchedule = () => {
  const fields: Array<keyof FeeScheduleApiResponseObj> = [
    'id',
    'name',
    'asOfDate',
    'classification',
    'manager',
    'managerFee',
    'operationalFee',
    'footerAttributes'
  ]
  const URI = `/strategies?fields=` + fields.toString()

  const apiInstance = Api.getInstance()
  return {
    type: FETCH_FEE_SCHEDULE,
    payload: apiInstance
      .get(URI)
      .then((response: { data?: FeeScheduleApiResponseObj[] }) => response)
  }
}
