import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import classnames from 'classnames'
import { TaskSort } from '../../objects/task'
import { history } from '../../store'

export interface TasksHeaderProps {
  sortBy?: string
  onSort?: Function
  onFilter: () => void
  filtered: boolean
  completed?: boolean
}

const sortOptions = [
  { value: TaskSort.DueDate, label: 'Sort by due date' },
  { value: TaskSort.Assignee, label: 'Sort by assignee' }
]

export const TasksHeader = (props: TasksHeaderProps): JSX.Element => {
  const { onSort, onFilter, filtered, completed } = props
  const heading = completed ? 'History' : 'Tasks'
  const [selectedLabel, setSelectedLabel] = useState(sortOptions[0].label)

  const navigateToCompletedTasks = () => {
    history.push('/tasks/completed')
  }

  const handleSort = (_name: string, value: string) => {
    onSort(value)
  }

  const handleSelect = (eventKey) => {
    const selectedOption = sortOptions.find(
      (option) => option.value === eventKey
    )
    setSelectedLabel(selectedOption?.label)
    handleSort('tasks-pick-list', eventKey)
  }

  return (
    <div className='d-flex flex-wrap justify-content-normal align-items-center tasks-header'>
      <h2 className='mb-0'>{heading}</h2>
      <div className='ms-auto me-1 me-sm-3 tasks-header__button'>
        {!completed && (
          <Button
            className='px-0'
            variant=''
            onClick={navigateToCompletedTasks}>
            Show History
          </Button>
        )}
      </div>
      <div
        className={classnames('px-0 tasks-header__button', {
          'pe-sm-4': !completed,
          'ms-auto': completed
        })}>
        <Button variant='' onClick={onFilter}>{`Show ${
          filtered ? 'all' : 'my'
        } tasks`}</Button>
      </div>

      {!completed && (
        <Dropdown onSelect={handleSelect}>
          <Dropdown.Toggle
            variant=''
            id='dropdown-basic'
            className='d-flex justify-content-between align-items-center text-start tasks-header__dropdown-toggle'>
            {selectedLabel}
            <i className='bi bi-chevron-down'></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortOptions.map((option, index) => (
              <Dropdown.Item
                eventKey={option.value}
                key={option.value + index}
                className='tasks-header__dropdown-item'>
                {option.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}
