import React from 'react'
import { FeeScheduleObj } from '../../objects/investments'

export interface FeeSummaryTableProps {
  feeData: FeeScheduleObj[]
}

const FeeSummaryTable = ({ feeData }: FeeSummaryTableProps) => {
  const mapFeeData = () => {
    return feeData.map((fee) => {
      const managerFee = fee.managerFee
        ? (fee.managerFee * 100).toFixed(2)
        : '-'
      const operationsFee = fee.operationsFee
        ? (fee.operationsFee * 100).toFixed(2)
        : '-'
      return (
        <div key={fee.id} className='feesum-table__item-w'>
          <div className='feesum-table__col-name'>{fee.name || '-'}</div>
          <div className='feesum-table__col-manager'>
            {fee.manager}
            {Boolean(fee.footnoteNumber) && <sup> {fee.footnoteNumber} </sup>}
          </div>
          <div className='feesum-table__col-info'>{managerFee}</div>
          <div className='feesum-table__col-info'>{operationsFee}</div>
          <div className='feesum-table__col-info'>
            {fee.classification || '-'}
          </div>
        </div>
      )
    })
  }

  return (
    <div className='feesum-table__w'>
      <div className='feesum-table__header'>
        <div className='feesum-table__col-name'>Strategy Name</div>
        <div className='feesum-table__col-manager'>Portfolio Manager</div>
        <div className='feesum-table__col-info feesum-table__col-info--bold'>
          Manager Fee (BPS)
        </div>
        <div className='feesum-table__col-info feesum-table__col-info--bold'>
          Operations Fee (BPS)
        </div>
        <div className='feesum-table__col-info feesum-table__col-info--bold'>
          Classification
        </div>
      </div>
      <div className='feesum-table__content-w'>{mapFeeData()}</div>
    </div>
  )
}

export default FeeSummaryTable
