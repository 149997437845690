import * as actions from '../actions/marketplaceOffers.actions'
import {
  marketplaceOfferRequestInterface,
  marketplaceOfferObj
} from '../objects/marketplaceOffers'

const initState: marketplaceOfferRequestInterface = {}

const mapMarketplaceOffers = (
  offers: marketplaceOfferObj[]
): marketplaceOfferRequestInterface => {
  const offersList: marketplaceOfferRequestInterface = {}
  offers.forEach((offer) => {
    offersList[offer.id] = offer
  })
  return offersList
}

const formatProtocol = (offers) => {
  for (const property in offers) {
    offers[property].gcOfferUrl =
      offers[property].gcOfferUrl == 'Gsselect.com'
        ? `http://${offers[property].gcOfferUrl}`
        : `https://${offers[property].gcOfferUrl}`
  }
  return offers
}
const MarketplaceOffers = (
  state = initState,
  action: any
): marketplaceOfferRequestInterface => {
  switch (action.type) {
    case `${actions.GET_MARKETPLACE_OFFERS}_FULFILLED`:
      const offers = formatProtocol(mapMarketplaceOffers(action.payload.data))
      return { ...state, ...offers }
    default:
      return state
  }
}
export default MarketplaceOffers
