import React from 'react'
import NewDocumentNotification from './NewDocumentNotification'

export const MessageNotification: React.FC = () => {
  return (
    <div className='header__notification-message'>
      New message from your advisor.
    </div>
  )
}

export const NewVaultFileView: React.FC<{
  batchSize: number
  content: any
  firstName: string
  lastName: string
}> = ({ batchSize, content, firstName, lastName }) => {
  return (
    <NewDocumentNotification
      batchSize={batchSize}
      content={content}
      firstName={firstName}
      lastName={lastName}
    />
  )
}

export const NewTaskAssignedView: React.FC<{
  batchSize: number
  name: string
}> = ({ batchSize, name }) => {
  return (
    <div className='header__notification-message'>
      {batchSize > 1 ? (
        <>
          You have been assigned{' '}
          <span className='header__notification-name'>{batchSize} Tasks.</span>
        </>
      ) : (
        <>
          You have been assigned the task{' '}
          <span className='header__notification-name'>{name}.</span>
        </>
      )}
    </div>
  )
}

export const GenericNotificationView: React.FC<{}> = (props) => {
  return (
    <div className='header__notification-message'>
      New notification from your advisor.
    </div>
  )
}
