import Api from '../helpers/api'
import { AxiosResponse } from 'axios'

export const FETCH_FOLDERS = 'FETCH_FOLDERS'
export const FETCH_FOLDERS_PENDING = 'FETCH_FOLDERS_PENDING'
export const FETCH_FOLDERS_REJECTED = 'FETCH_FOLDERS_REJECTED'
export const FETCH_FOLDERS_FULFILLED = 'FETCH_FOLDERS_FULFILLED'
export const FETCH_FOLDER = 'FETCH_FOLDER'
export const FETCH_FOLDER_PENDING = 'FETCH_FOLDER_PENDING'
export const FETCH_FOLDER_REJECTED = 'FETCH_FOLDER_REJECTED'
export const FETCH_FOLDER_FULFILLED = 'FETCH_FOLDER_FULFILLED'
export const CREATE_FOLDER = 'CREATE_FOLDER'
export const CREATE_FOLDER_PENDING = 'CREATE_FOLDER_PENDING'
export const CREATE_FOLDER_REJECTED = 'CREATE_FOLDER_REJECTED'
export const CREATE_FOLDER_FULFILLED = 'CREATE_FOLDER_FULFILLED'
export const EDIT_FOLDER = 'EDIT_FOLDER'
export const EDIT_FOLDER_PENDING = 'EDIT_FOLDER_PENDING'
export const EDIT_FOLDER_REJECTED = 'EDIT_FOLDER_REJECTED'
export const EDIT_FOLDER_FULFILLED = 'EDIT_FOLDER_FULFILLED'
export const EMPTY_TRASH = 'EMPTY_TRASH'
export const EMPTY_TRASH_PENDING = 'EMPTY_TRASH_PENDING'
export const EMPTY_TRASH_REJECTED = 'EMPTY_TRASH_REJECTED'
export const EMPTY_TRASH_FULFILLED = 'EMPTY_TRASH_FULFILLED'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const DELETE_FOLDER_PENDING = 'DELETE_FOLDER_PENDING'
export const DELETE_FOLDER_REJECTED = 'DELETE_FOLDER_REJECTED'
export const DELETE_FOLDER_FULFILLED = 'DELETE_FOLDER_FULFILLED'
export const FETCH_FILES = 'FETCH_FILES'
export const FETCH_FILES_PENDING = 'FETCH_FILES_PENDING'
export const FETCH_FILES_REJECTED = 'FETCH_FILES_REJECTED'
export const FETCH_FILES_FULFILLED = 'FETCH_FILES_FULFILLED'
export const UPLOAD_FILE = 'UPLOAD_FILE'
export const UPLOAD_FILE_PENDING = 'UPLOAD_FILE_PENDING'
export const UPLOAD_FILE_REJECTED = 'UPLOAD_FILE_REJECTED'
export const UPLOAD_FILE_FULFILLED = 'UPLOAD_FILE_FULFILLED'
export const EDIT_FILE = 'EDIT_FILE'
export const EDIT_FILE_PENDING = 'EDIT_FILE_PENDING'
export const EDIT_FILE_REJECTED = 'EDIT_FILE_REJECTED'
export const EDIT_FILE_FULFILLED = 'EDIT_FILE_FULFILLED'
export const HARD_DELETE_FILE = 'HARD_DELETE_FILE'
export const HARD_DELETE_FILE_PENDING = 'HARD_DELETE_FILE_PENDING'
export const HARD_DELETE_FILE_REJECTED = 'HARD_DELETE_FILE_REJECTED'
export const HARD_DELETE_FILE_FULFILLED = 'HARD_DELETE_FILE_FULFILLED'
export const UPDATE_ARCHIVED_FILE = 'UPDATE_ARCHIVED_FILE'
export const UPDATE_ARCHIVED_FILE_PENDING = 'UPDATE_ARCHIVED_FILE_PENDING'
export const UPDATE_ARCHIVED_FILE_REJECTED = 'UPDATE_ARCHIVED_FILE_REJECTED'
export const UPDATE_ARCHIVED_FILE_FULFILLED = 'UPDATE_ARCHIVED_FILE_FULFILLED'
export const MOVE_FILE = 'MOVE_FILE'
export const MOVE_FILE_PENDING = 'MOVE_FILE_PENDING'
export const MOVE_FILE_REJECTED = 'MOVE_FILE_REJECTED'
export const MOVE_FILE_FULFILLED = 'MOVE_FILE_FULFILLED'
export const UPDATE_VAULT_SEARCH = 'UPDATE_VAULT_SEARCH'
export const ADD_NEW_FOLDER = 'ADD_NEW_FOLDER'
export const CLEAR_NEW_FOLDERS = 'CLEAR_NEW_FOLDERS'

export const getFolders = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_FOLDERS,
    payload: apiInstance.get('/portaldocumentvault').then((response) => {
      return response
    })
  }
}

export const getFolder = (folderId: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_FOLDER,
    payload: apiInstance
      .get(`/portaldocumentvault/${folderId}`)
      .then((response) => {
        return response
      })
  }
}

export const emptyTrash = () => {
  const apiInstance = Api.getInstance()
  return {
    type: EMPTY_TRASH,
    payload: apiInstance
      .delete(`/portaldocumentvault/emptytrash`)
      .then((response) => {
        return response
      })
  }
}

export const getFiles = (
  folderId: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_FILES,
    payload: apiInstance
      .get(`/portaldocumentvault/${folderId}/files`)
      .then((response) => {
        response.data.folderId = folderId
        return response
      })
  }
}

export const deleteFolder = (folderId: string, archived: boolean) => {
  const apiInstance = Api.getInstance()
  return {
    type: DELETE_FOLDER,
    payload: apiInstance
      .put(`/portaldocumentvault/${folderId}`, {
        id: folderId,
        archived
      })
      .then((response) => {
        response.data = {}
        response.data.folderId = folderId
        return response
      })
      .catch((err) => {
        throw err
      })
  }
}

export const updateArchivedFile = (
  folderId: string,
  fileId: string,
  archived: boolean
) => {
  const apiInstance = Api.getInstance()
  return {
    type: UPDATE_ARCHIVED_FILE,
    payload: apiInstance
      .put(`/portaldocumentvault/${folderId}/files/${fileId}`, {
        fileId,
        archived
      })
      .then((response) => {
        response.data = {}
        response.data.fileId = fileId
        response.data.folderId = folderId
        return response
      })
  }
}

export const hardDeleteFile = (folderId: string, fileId: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: HARD_DELETE_FILE,
    payload: apiInstance
      .delete(`/portaldocumentvault/${folderId}/files/${fileId}`)
      .then((response) => {
        return response
      })
  }
}

export const createFolder = (folderName: string) => {
  const apiInstance = Api.getInstance()
  const postRequest = {
    name: folderName
  }
  return {
    type: CREATE_FOLDER,
    payload: apiInstance
      .post('/portaldocumentvault', postRequest)
      .then((response) => {
        return response
      })
  }
}

export const editFolder = (folderId: string, name: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: EDIT_FOLDER,
    payload: apiInstance
      .put(`/portaldocumentvault/${folderId}`, { id: folderId, name })
      .then((response) => {
        return response
      })
  }
}

export const uploadFile = (
  folderId: string,
  fileName: string,
  fileContent: string
): { type: string; payload: Promise<AxiosResponse<any>> } => {
  //Axios config is in bytes, Salesforce API limits to 36MB
  const maxContentLength = 36 * 1024 * 1024
  const apiInstance = Api.getInstance()
  const body = {
    name: fileName,
    content: fileContent
  }
  return {
    type: UPLOAD_FILE,
    payload: apiInstance
      .post(`/portaldocumentvault/${folderId}/files`, body, {
        maxContentLength
      })
      .then((response) => {
        response.data.folderId = folderId
        return response
      })
  }
}

export const editFile = (folderId: string, id: string, name: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: EDIT_FILE,
    payload: apiInstance
      .put(`/portaldocumentvault/${folderId}/files/${id}`, { id, name })
      .then((response) => {
        return { data: response.data, folderId }
      })
  }
}

export const moveFile = (
  oldFolderId: string,
  fileId: string,
  newFolderId: string
) => {
  const apiInstance = Api.getInstance()
  return {
    type: MOVE_FILE,
    payload: apiInstance
      .put(`/portaldocumentvault/${oldFolderId}/files/${fileId}`, {
        id: fileId,
        folder: newFolderId
      })
      .then((response) => {
        return { data: response.data, oldFolderId }
      })
  }
}

export const updateVaultSearch = (value: string) => {
  return {
    type: UPDATE_VAULT_SEARCH,
    payload: { value }
  }
}

export const addNewFolder = (folderId: string) => {
  return {
    type: ADD_NEW_FOLDER,
    payload: folderId
  }
}

export const clearNewFolders = () => {
  return {
    type: CLEAR_NEW_FOLDERS
  }
}
