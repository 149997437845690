import { merge } from 'lodash-es'
import Api from '../helpers/api'
import { Preferences } from '../objects/user'

export const FETCH_USER: string = 'FETCH_USER'
export const FETCH_USER_PENDING: string = 'FETCH_USER_PENDING'
export const FETCH_USER_FULFILLED: string = 'FETCH_USER_FULFILLED'
export const FETCH_USER_REJECTED: string = 'FETCH_USER_REJECTED'
export const REVOKE_USER: string = 'REVOKE_USER'
export const FETCH_INSTITUTION: string = 'FETCH_INSTITUTION'
export const FETCH_INSTITUTION_PENDING: string = 'FETCH_INSTITUTION_PENDING'
export const FETCH_INSTITUTION_FULFILLED: string = 'FETCH_INSTITUTION_FULFILLED'
export const FETCH_INSTITUTION_REJECTED: string = 'FETCH_INSTITUTION_REJECTED'
export const FETCH_TOS_AND_PRIVACY_POLICY: string =
  'FETCH_TOS_AND_PRIVACY_POLICY'
export const FETCH_TOS_AND_PRIVACY_POLICY_PENDING: string =
  'FETCH_TOS_AND_PRIVACY_POLICY_PENDING'
export const FETCH_TOS_AND_PRIVACY_POLICY_FULFILLED: string =
  'FETCH_TOS_AND_PRIVACY_POLICY_FULFILLED'
export const FETCH_TOS_AND_PRIVACY_POLICY_REJECTED: string =
  'FETCH_TOS_AND_PRIVACY_POLICY_REJECTED'
export const ACCEPT_TOS_AND_PRIVACY_POLICY: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_PENDING: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_PENDING'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED'
export const ACCEPT_TOS_AND_PRIVACY_POLICY_REJECTED: string =
  'ACCEPT_TOS_AND_PRIVACY_POLICY_REJECTED'
export const BYPASS_TOS_AND_PRIVACY_POLICY: string =
  'BYPASS_TOS_AND_PRIVACY_POLICY'
export const NEW_PHOTO: string = 'NEW_PHOTO'
export const NEW_PHOTO_PENDING: string = 'NEW_PHOTO_PENDING'
export const NEW_PHOTO_FULFILLED: string = 'NEW_PHOTO_FULFILLED'
export const NEW_PHOTO_REJECTED: string = 'NEW_PHOTO_REJECTED'
export const DELETE_PHOTO: string = 'DELETE_PHOTO'
export const DELETE_PHOTO_PENDING: string = 'DELETE_PHOTO_PENDING'
export const DELETE_PHOTO_FULFILLED: string = 'DELETE_PHOTO_FULFILLED'
export const DELETE_PHOTO_REJECTED: string = 'DELETE_PHOTO_REJECTED'
export const STORE_COMMUNITY_ID: string = 'STORE_COMMUNITY_ID'
export const SET_ADVISOR_IMPERSONATION: string = 'SET_ADVISOR_IMPERSONATION'
export const FETCH_PREFERENCES: string = 'FETCH_PREFERENCES'
export const FETCH_PREFERENCES_PENDING: string = 'FETCH_PREFERENCES_PENDING'
export const FETCH_PREFERENCES_FULFILLED: string = 'FETCH_PREFERENCES_FULFILLED'
export const FETCH_PREFERENCES_REJECTED: string = 'FETCH_PREFERENCES_REJECTED'
export const SET_PREFERENCES: string = 'SET_PREFERENCES'
export const SET_PREFERENCES_FULFILLED: string = 'SET_PREFERENCES_FULFILLED'

export const getUser = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_USER,
    payload: apiInstance.get('/users').then((response) => {
      return response.data
    })
  }
}

export const revokeUser = (): { type: string; payload: boolean } => {
  return {
    type: REVOKE_USER,
    payload: true
  }
}

export const getInstitution = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_INSTITUTION,
    payload: apiInstance.get('/institution').then((response) => {
      return response.data
    })
  }
}

export const getTOSandPrivacyPolicy = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_TOS_AND_PRIVACY_POLICY,
    payload: apiInstance.get('/terms').then((response) => {
      return response.data
    })
  }
}

export const acceptTOSandPrivacyPolicy = (version) => {
  const apiInstance = Api.getInstance()
  return {
    type: ACCEPT_TOS_AND_PRIVACY_POLICY,
    payload: apiInstance
      .post('/terms', { termsOfServiceVersion: version })
      .then((response) => {
        return response.data
      })
  }
}

export const bypassTermsOfService = () => {
  return {
    type: BYPASS_TOS_AND_PRIVACY_POLICY
  }
}

export const addPhoto = (
  fileName: string,
  photo: string,
  contactLevel: string
) => {
  const apiInstance = Api.getInstance()
  const body = {
    fileName,
    photo
  }
  return {
    type: NEW_PHOTO,
    payload: apiInstance.post('/users/photo', body).then((response) => {
      response.data.contactLevel = contactLevel
      return response.data
    })
  }
}

export const deletePhoto = (contactLevel: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: DELETE_PHOTO,
    payload: apiInstance.delete('/users/photo').then((response) => {
      response.data = {}
      response.data.contactLevel = contactLevel
      return response.data
    })
  }
}

export const storeCommunityId = (id: string) => {
  return {
    type: STORE_COMMUNITY_ID,
    payload: id
  }
}

export const setAdvisorImpersonation = () => {
  return {
    type: SET_ADVISOR_IMPERSONATION
  }
}

export const getUserPreferences = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_PREFERENCES,
    payload: apiInstance.get('/users/gcstore').then((response) => {
      return response.data?.store
    })
  }
}

const _setUserPreferences = (id: string, prefs: Preferences) => {
  const apiInstance = Api.getInstance()
  const body = { id, store: prefs }
  return {
    type: SET_PREFERENCES,
    payload: apiInstance.post('/users/gcstore', body).then((response) => {
      return response.data?.store || prefs
    })
  }
}

export const setUserPreferences = (data: Preferences) => (
  dispatch,
  getState
) => {
  const { id, preferences } = getState().user
  const updated = merge({}, preferences, data)
  dispatch(_setUserPreferences(id, updated))
}
