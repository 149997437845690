import { createDeepEqualSelector } from './utilz'

import { GlobalState } from '../reducers'

import { ClientAccountsState } from '../reducers/clientAccounts.reducer'
import { ClientAccountObj } from '../objects/clientAccount'
import { sortNetWorthAccounts } from '../helpers/netWorthSort'

/**
 * Errors
 *
 * Pending
 * Cash & Equivalents : Cash & Equivalents
 * Investments : Tax Deferred Investments, Tax Exempt Investments, Taxable Investment
 * Other : Business Assets, Personal Assets, Real Estate
 * Liabilities : Long-Term Liabilities, Short-Term Liabilities
 */
const accountTypes = [
  'Short-Term Liabilities',
  'Long-Term Liabilities',
  'Cash & Equivalents',
  'Investments',
  'Other'
]

const getClientAccounts = (store: GlobalState) => {
  const { clientAccounts } = store
  return clientAccounts
}

const getCategorizedAccounts = (store: GlobalState, subType: string) => {
  const { clientAccounts } = store
  return { accounts: clientAccounts, subType }
}

export const pendingAccounts = createDeepEqualSelector(
  [getClientAccounts],
  (accounts: ClientAccountsState) => {
    const { assets, liabilities, pending } = accounts
    const allAccounts = assets.concat(liabilities).concat(pending)
    let sum = 0
    const pendingTypeAccounts = allAccounts.reduce((uniq, account) => {
      const isPending = !accountTypes.includes(account.subType)
      if (!isPending || uniq.some((acct) => acct.id === account.id)) {
        return uniq
      }
      sum = sum + Number(account.totalValue)
      return [...uniq, account]
    }, [])
    const invalidPreferences =
      accounts.preferences === null || accounts.preferences === undefined
    const sortedAccounts = invalidPreferences
      ? pendingTypeAccounts
      : renderAccountGroup(
          pendingTypeAccounts,
          accounts.preferences.unclassifiedGroupBy,
          accounts.preferences.unclassifiedSortBy,
          accounts.preferences.unclassifiedSortingOrder
        )
    return { category: 'Pending', accounts: sortedAccounts, sum }
  }
)

export const renderAccountGroup = (
  accountGroup: ClientAccountObj[],
  groupBy: string,
  sortBy: string,
  sortingOrder: string
) => {
  if (accountGroup && accountGroup.length) {
    const mappedAccounts = {}
    accountGroup.forEach(
      (account: ClientAccountObj) => (mappedAccounts[account.id] = account)
    )
    const sortedAccounts = sortNetWorthAccounts(mappedAccounts, {
      groupBy,
      sortBy,
      sortingOrder
    })
    const accounts = []
    Object.keys(sortedAccounts).forEach((key: string) =>
      accounts.push(sortedAccounts[key])
    )
    return accounts
  } else {
    return accountGroup
  }
}

export const categorizedAccounts = createDeepEqualSelector(
  [getCategorizedAccounts],
  ({ accounts, subType }) => {
    const { assets, liabilities, pending } = accounts
    const allAccounts = assets.concat(liabilities).concat(pending)
    let sum = 0
    const subTypeAccounts = allAccounts.reduce((uniq, account) => {
      const isSubType = account.subType === subType
      if (!isSubType || uniq.some((acct) => acct.id === account.id)) {
        return uniq
      }

      sum = sum + Number(account.totalValue)

      return [...uniq, account]
    }, [])
    return { category: subType, accounts: subTypeAccounts, sum }
  }
)

export const networthAccountsSelector = createDeepEqualSelector(
  [getClientAccounts],
  (accounts: ClientAccountsState) => {
    const { assets, errorMessage, investments, liabilities } = accounts

    return { assets, errorMessage, investments, liabilities }
  }
)
