import { Middleware } from '@reduxjs/toolkit'
import { LOCATION_CHANGE } from 'connected-react-router'
import qs from 'query-string'
import { GlobalState } from '../reducers'

// preserve contact query param when impersonating and switching routes
const addContactParam: Middleware<unknown, GlobalState> = (store) => (next) => (
  action
) => {
  if (action.type !== LOCATION_CHANGE) {
    return next(action)
  }

  const query = qs.parse(action.payload.location.search)
  const contact = store.getState().auth.userContactId
  if (contact && !query.contact) {
    // we switched routes and are impersonating, but there is no contact query param
    // add contact to new query string
    const newSearch = qs.stringify({
      ...query,
      contact
    })

    // create new location payload for action
    const newLocation = {
      ...action.payload.location,
      search: `?${newSearch}`
    }

    // copy the action with the new location and dispatch it
    const newAction = {
      ...action,
      payload: {
        ...action.payload,
        location: newLocation
      }
    }
    return next(newAction)
  }
  return next(action)
}

export default addContactParam
