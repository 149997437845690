import dayjs from 'dayjs'

export const ASCENDING = 'Ascending'
export const DESCENDING = 'Descending'

export const compareDates = (a: string, b: string, descending?: boolean) => {
  return !descending ? dayjs(a).diff(dayjs(b)) : dayjs(b).diff(dayjs(a))
}

export const compareNumericField = (
  a: string | number,
  b: string | number,
  descending?: boolean
) => {
  let valueA = a
  let valueB = b

  // sanitize string amounts (e.g., $50,000)
  if (typeof a === 'string') {
    valueA = a.replace(/[^\w\s]/gi, '')
  }

  if (typeof b === 'string') {
    valueB = b.replace(/[^\w\s]/gi, '')
  }

  const comparison = Number(valueA) - Number(valueB)
  return descending ? -comparison : comparison
}

export const compareStringField = (
  a: string,
  b: string,
  descending?: boolean
) => {
  return descending ? b.localeCompare(a) : a.localeCompare(b)
}

export const nullSort = (a: any, b: any, nullFirst?: boolean) => {
  let score: number

  if (!a && !b) {
    return 0
  }

  if (!a) {
    score = 1
  }

  if (!b) {
    score = -1
  }

  if (!score) {
    return score
  }

  return nullFirst ? -score : score
}
