import React from 'react'
import { connect } from 'react-redux'
import * as OfficeTeamActions from '../../actions/officeteam.action'
import { MainTile } from '../../components/Layout'
import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'

interface SiteDisclosureProps {
  dispatch?: AppDispatch
}

class SiteDisclosurePage extends React.Component<SiteDisclosureProps> {
  public componentDidMount() {
    this.props.dispatch(OfficeTeamActions.getOfficeTeam())
  }

  public render() {
    return (
      <MainTile title='Site Disclosure'>
        <div style={{ paddingLeft: 40, paddingRight: 40 }}>
          <p>
            FinLife Partners is a dba of United Capital Financial Advisors, LLC.
          </p>
          <p>
            All names, logos, and slogans identifying United Capital and United
            Capital’s products and services (including, without limitation,
            Honest Conversations®, MoneyMind®, FinLife®, FlexScore®, Financial
            Control Scorecard®, FCS®, One Best Financial Life®, United Capital
            Private Wealth Counseling®, Ideal Life Index™, and FinLife Partners
            Powered by United Capital™, United Capital Financial Life
            Management™, Financial Years of Freedom™) are trademarks and service
            marks or registered trademarks and service marks of United Capital
            or its affiliates in the United States and/or other countries.
          </p>
          <p>
            The information, data, and analyses contained herein (1) include the
            confidential and proprietary information of United Capital, (2) may
            include, or be derived from, account information provided by your
            financial advisor which cannot be verified by United Capital, (3)
            may not be copied or redistributed, (4) do not constitute investment
            advice offered by United Capital, (5) are provided solely for
            informational purposes and therefore are not an offer to buy or sell
            a security, and (6) are not warranted to be correct, complete or
            accurate. Except as otherwise required by law, United Capital shall
            not be responsible for any trading decisions, damages or other
            losses resulting from, or related to, this information, data,
            analyses or opinions or their use.
          </p>
        </div>
      </MainTile>
    )
  }
}

const SiteDisclosure = connect(
  (store: GlobalState, dispatch: AppDispatch): SiteDisclosureProps => {
    return {
      dispatch
    }
  }
)(SiteDisclosurePage)

export default SiteDisclosure
