import Api from '../helpers/api'

export const FETCH_LIFE_ACTIONS = 'FETCH_LIFE_ACTION'
export const FETCH_LIFE_ACTION_BY_ID = 'FETCH_LIFE_ACTION_BY_ID'

export const getLifeAction = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_LIFE_ACTIONS,
    payload: apiInstance.get(`/lifeactions`).then((response) => {
      return response
    })
  }
}

export const getLifeActionById = (lifeActionId: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_LIFE_ACTION_BY_ID,
    payload: apiInstance
      .post(`/lifeactions/${lifeActionId}`)
      .then((response) => {
        return response
      })
  }
}
