import browserInfo from './browserInfo'
import updateUrlParameter from './urlPram'

/**
 * Check if the deeplink is available in the env.
 */
export function hasDeeplinkEnv(): boolean {
  const hasEnv = Boolean(window._env_.REACT_APP_DEEPLINK)
  // Throw and error in console to be aware.
  if (!hasEnv) console.error('Deeplink missing from config')
  return hasEnv
}

/**
 * If the Android banner should be visible on the top.
 * The logic is extracted in a function because it is used to
 * determine the initial state in the store.
 */
export function shouldShowAndroidBanner(): boolean {
  const { os } = browserInfo()
  const osName = os.name.toLowerCase()

  return hasDeeplinkEnv() && osName === 'android'
}

/**
 * If the bottom drawer should be visible.
 */
export function shouldShowDrawer(): boolean {
  const { type } = browserInfo()

  return hasDeeplinkEnv() && type === 'mobile'
}

/**
 * Utillity function to generate the deeplink based on where the user lands.
 * It appends the `link` param to a deeplink that will equal to the page URL.
 * @see {@link https://firebase.google.com/docs/dynamic-links/create-manually}
 */
export default function generateDeeplink() {
  if (!hasDeeplinkEnv()) return ''
  return updateUrlParameter(
    window._env_.REACT_APP_DEEPLINK,
    'link',
    window.location.href
  )
}
