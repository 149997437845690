import React from 'react'
import {
  getFormattedTotal,
  mapAllocationName
} from '../../../helpers/investments'

const allocationNames = {
  ...mapAllocationName,
  unclassified: 'Other Investments'
}

const AssetAllocationTable = (props) => {
  const { allocationData, activeIndex } = props

  const getContentClassName = (id: string) => {
    return activeIndex === id
      ? 'holding-hero__inv__table-content holding-hero__inv__table-hover'
      : 'holding-hero__inv__table-content'
  }

  return (
    <table className='holding-hero__inv__table'>
      <tbody>
        {allocationData.map((allocation) => (
          <tr key={allocation.id}>
            <td
              className='holding-hero__inv__table-cell holding-hero__inv__table-tag fw-bold fs-7'
              style={{ backgroundColor: allocation.fill }}></td>
            <td className='holding-hero__inv__table-cell holding-hero__inv__table-name fw-bold fs-7'>
              <div className={getContentClassName(allocation.id)}>
                {allocationNames[allocation.name]}
              </div>
            </td>
            <td className='holding-hero__inv__table-cell holding-hero__inv__table-percentage fw-bold fs-7'>
              <div
                className={getContentClassName(
                  allocation.id
                )}>{`${allocation.value}%`}</div>
            </td>
            <td className='holding-hero__inv__table-cell holding-hero__inv__table-amount fw-bold fs-7'>
              <div className={getContentClassName(allocation.id)}>
                {getFormattedTotal(allocation.amount)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default AssetAllocationTable
