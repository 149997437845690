import React from 'react'
import { Redirect, RedirectProps } from 'react-router'
import { connect, ConnectedProps } from 'react-redux'

import { GlobalState } from '../../reducers'

export enum DepValues {
  hc = 'hc',
  inv = 'inv'
}

type ConnectProps = ConnectedProps<typeof connector>

/**
 * @param WrappedComponent - class or functional component for optional rendering
 * @param dependancy - string 'hc' or 'inv' signifies which value to read from the store to determine if component should be rendered
 * @param to - optional argument that matches Redirect 'to' prop. If provided HOC will return Redirect component instead of null
 * @returns - WrappedComponent, null or Redirect based on arguments provided
 */

const optionalRender = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  dependancy: keyof typeof DepValues,
  to?: RedirectProps['to']
) => {
  const Hoc = (props: ConnectProps) => {
    const {
      showHonestConversations,
      showInvestments,
      dispatch,
      userId,
      ...componentProps
    } = props

    const dependancyMap = {
      [DepValues.hc]: showHonestConversations,
      [DepValues.inv]: showInvestments
    }

    const showFeature = dependancyMap[dependancy]

    if (typeof showFeature === 'undefined' || !userId) {
      return null
    }

    if (!showFeature) {
      return to ? <Redirect to={to} /> : null
    }

    return <WrappedComponent {...(componentProps as P)} />
  }

  return connector(Hoc)
}

const mapStoreToProps = (store: GlobalState) => ({
  userId: store.user.id,
  showHonestConversations: store.user.showHonestConversations,
  showInvestments: store.user.showInvestments
})

const connector = connect(mapStoreToProps)

export default optionalRender
