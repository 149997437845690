import React, { CSSProperties, FunctionComponent, SVGProps } from 'react'
import { Link } from 'react-router-dom'
import arrowRight from '../../assets/images/icons/arrow_right_blue.png'

interface TileProps {
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  iconPng?: string
  children: React.ReactNode | string
  to?: string
  headerBorder?: boolean
  collapsable?: boolean
  route?: string
  rightHeader?: React.ReactNode | string
  leftHeader?: React.ReactNode | string
  anchorBorder?: boolean
  style?: CSSProperties
  contentStyle?: CSSProperties
  headerStyle?: CSSProperties
  largeHeader?: boolean
}

interface TileState {
  collapsableClosed: boolean
}

export class Tile extends React.Component<TileProps, TileState> {
  constructor(props: TileProps) {
    super(props)
    this.state = {
      collapsableClosed: false
    }
  }

  public leftHeader = () => {
    const { leftHeader, largeHeader } = this.props
    return (
      <div
        className={
          largeHeader
            ? 'legacy-tile__header-left legacy-tile__header--large-text'
            : 'legacy-tile__header-left'
        }>
        {leftHeader}
      </div>
    )
  }

  public rightHeader = () => {
    const { rightHeader, collapsable } = this.props
    const { collapsableClosed } = this.state
    return (
      <div className='legacy-tile__header-right'>
        {rightHeader}
        {collapsable ? (
          <img
            className={
              collapsableClosed
                ? 'legacy-tile__header-arrow-collapse'
                : 'legacy-tile__header-arrow-collapse legacy-tile__header-arrow-collapse--closed'
            }
            src={arrowRight}
            alt='arrow icon'
          />
        ) : null}
      </div>
    )
  }

  public toggleCollapsable = () => {
    this.setState({ collapsableClosed: !this.state.collapsableClosed })
  }

  public classNameDefaults = (customClassNames = '') => {
    const { headerBorder, anchorBorder, collapsable } = this.props
    let defaultClasses = ''
    if (headerBorder && !anchorBorder) {
      defaultClasses = 'legacy-tile__header legacy-tile__header--border'
    } else if (!headerBorder && anchorBorder) {
      defaultClasses = 'legacy-tile__header legacy-tile__header--anchor'
    } else if (headerBorder && anchorBorder) {
      defaultClasses =
        'legacy-tile__header legacy-tile__header--border legacy-tile__header--anchor'
    } else if (collapsable) {
      defaultClasses =
        'legacy-tile__header legacy-tile__header--border legacy-tile__header--collapsable'
    } else {
      defaultClasses = 'legacy-tile__header'
    }
    return `${defaultClasses} ${customClassNames}`
  }

  public header = () => {
    const {
      rightHeader,
      leftHeader,
      headerStyle,
      to,
      iconPng,
      collapsable
    } = this.props
    const { collapsableClosed } = this.state
    const Icon = this.props.icon

    if (Icon || leftHeader || rightHeader) {
      if (to) {
        const linkTag = 'legacy-tile__header--link'
        return (
          <Link
            to={to}
            className={this.classNameDefaults(linkTag)}
            style={headerStyle}>
            {Icon ? <Icon className='legacy-tile__header--icon' /> : null}
            {iconPng ? (
              <img
                src={iconPng}
                alt='header icon'
                className='legacy-tile__header-icon--png'
              />
            ) : null}
            {leftHeader ? this.leftHeader() : null}
            {rightHeader ? this.rightHeader() : null}
          </Link>
        )
      } else {
        return (
          <div
            className={`${this.classNameDefaults()} ${
              collapsableClosed && collapsable
                ? 'legacy-tile__header--closed'
                : ''
            }`}
            style={headerStyle}
            onClick={collapsable ? this.toggleCollapsable : null}>
            {Icon ? <Icon className='legacy-tile__header--icon' /> : null}
            {iconPng ? (
              <img
                src={iconPng}
                alt='header icon'
                className='legacy-tile__header-icon--png'
              />
            ) : null}
            {leftHeader ? this.leftHeader() : null}
            {rightHeader || collapsable ? this.rightHeader() : null}
          </div>
        )
      }
    } else {
      return null
    }
  }

  public render() {
    const { style, contentStyle, children } = this.props
    return (
      <div className='legacy-tile-w' style={style}>
        {this.header()}
        <div className='legacy-tile__content' style={contentStyle}>
          {children}
        </div>
      </div>
    )
  }
}

export default Tile
