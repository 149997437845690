import React from 'react'
import Button from 'react-bootstrap/Button'
import { PlanScoreBar } from '../../components/Plan'
import { FundedScoreObj, PlanningSoftwareType } from '../../objects/plan'
import { UserObj } from '../../objects/user'
import { emoneyPlannings, planningSoftwares } from '../../helpers/dashboard'
import { dateFormat } from '../../helpers'
import { ReactComponent as GDXLogo } from '../../assets/images/logos/planLogos/gdx-360-gray.svg'
import { ReactComponent as MGPLogo } from '../../assets/images/logos/planLogos/mgp-gray.svg'
import { emoneyClickThru } from '../../analytics/googleTagManager'

export interface PlanHeroProps {
  latestScore: FundedScoreObj
  user: UserObj
}

export interface ScoreDescriptionProps {
  onClose: () => void
}

export interface PlanningSoftwareProps {
  software: PlanningSoftwareType
  onClick: () => void
}

export const PlanningSoftware = ({
  software,
  onClick
}: PlanningSoftwareProps) => {
  if (!software || !planningSoftwares.includes(software)) {
    return null
  }
  if (emoneyPlannings.includes(software)) {
    return (
      <Button className='plan-hero__emoney' onClick={onClick}>
        Login to eMoney
      </Button>
    )
  }
  if (software === 'gdx360') {
    return <GDXLogo />
  }
  if (software === 'moneyguidepro') {
    return <MGPLogo />
  }
  return null
}

const PlanHero = ({ latestScore, user }: PlanHeroProps) => {
  const loginToEmoney = () => {
    emoneyClickThru(user.planningSoftware, user.id)
    const eMoneyLoginUrl = window._env_.REACT_APP_LOGIN_TO_EMONEY_URL
    window.open(eMoneyLoginUrl, '_blank')
  }

  return (
    <div className='plan-hero'>
      <div className='plan-hero__background' />
      <div className='plan-hero__container'>
        <div className='plan-hero__header'>
          <h1 className='plan-hero__title'>Financial Plan</h1>
          {latestScore && (
            <div
              className={`plan-hero__software${
                emoneyPlannings.includes(user?.planningSoftware)
                  ? '--clickable'
                  : ''
              }`}>
              <PlanningSoftware
                software={user?.planningSoftware}
                onClick={loginToEmoney}
              />
            </div>
          )}
        </div>
        <div className='plan-hero__date'>
          {latestScore?.scoreDate
            ? `Last updated ${dateFormat(latestScore.scoreDate, true)}`
            : '-'}
        </div>
        <div className={`plan-hero__score${!latestScore ? '--null' : ''}`}>
          <PlanScoreBar
            score={latestScore?.score && Math.round(latestScore.score)}
            minScore={latestScore?.rangeLow}
            maxScore={latestScore?.rangeHigh}
            source={latestScore?.source}
            light={true}
            isNull={!latestScore}
          />
        </div>
      </div>
    </div>
  )
}

export default PlanHero
