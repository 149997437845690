import React, { Fragment } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { typeToTab } from '../../helpers/goals'
import StatusDot from './StatusDot'
import { history } from '../../store'

const GoalItem = (goal, hideLink?: false) => {
  const { name, id, type, note, dueDate, status } = goal
  const goalsLink = `/goals-detail/${id}`

  const mobileDetails = () => {
    if (window.innerWidth <= 640) {
      history.push(goalsLink)
    }
  }

  return (
    <div
      key={`goal-${id}`}
      style={{ marginBottom: '10px' }}
      onClick={mobileDetails}>
      <Card
        className='bg-white rounded-0 border border-light-subtle w-100 p-3 p-md-x2'
        id={id + '__upper'}>
        <Row className='goal-card__upper'>
          <Col className='justify-content-start goal-card__text'>
            <Col className='goal-card__row-item'>
              <span className='goal-card__label'>
                <StatusDot status={status} />
              </span>
              <div>
                <div className='d-flex'>
                  <h4 className='goal-card__name'>{name}</h4>
                </div>
                <Col className='goal-card__row-item'>
                  {typeToTab(type).map((type, i) => (
                    <div
                      key={`goal-${id}-type-${i}`}
                      className='goal-card__type'>
                      {type}
                    </div>
                  ))}
                </Col>
              </div>
            </Col>
          </Col>
          <Col className='justify-content-end goal-card__row-item'>
            <div className='goal-card__date'>
              <h4>{dueDate}</h4>
              <div>{dueDate ? 'Check-In Date' : null}</div>
            </div>
            {!hideLink ? (
              <div className='goal-card__link'>
                <Link to={goalsLink}>See Details</Link>
              </div>
            ) : null}
          </Col>
        </Row>
      </Card>
      {note && (
        <Card
          id={id + '__lower'}
          className='bg-white rounded-0 border border-light-subtle goal-card__lower-main p-3 p-md-x2'>
          <Row className='goal-card__lower'>
            <Col className='goal-card__text'>{note}</Col>
          </Row>
        </Card>
      )}
    </div>
  )
}

export const GoalsTiles = (props) => {
  const { goals, hideLink } = props
  return (
    <Fragment>
      {goals &&
        goals.map((goal) => {
          return goal && GoalItem(goal, hideLink)
        })}
    </Fragment>
  )
}
