import React from 'react'

interface ErrorBoundaryState {
  hasError: boolean
}
interface ErrorBoundaryProps {
  children: any
  routeProps: string
}
class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error: Error): any {
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: any) {
    console.error('An error occurred')
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (prevProps?.routeProps !== this.props?.routeProps) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className='main-container'>
          <div className='main-container-inner'>
            <div className='errors__container errors__container--mx'>
              <div className='errors__heading-text errors__heading-text--lg'>
                500 Error
              </div>
              <div className='errors__body-text errors__body-text--sm'>
                Come back soon, or contact your advisor. We apologize for any
                inconvenience.
              </div>
            </div>
          </div>
        </div>
      )
    }
    return this.props.children
  }
}
export default ErrorBoundary
