import * as React from 'react'
import { isEmpty } from 'lodash-es'
import { connect } from 'react-redux'
import { UserObj } from '../../objects/user'
import { DisclosureFooter } from './DisclosureFooter'
import { GlobalState } from '../../reducers'
import { getHousehold } from '../../actions/household.action'
import { getMarketplaceOffers } from '../../actions/marketplaceOffers.actions'
import { marketplaceOfferRequestInterface } from '../../objects/marketplaceOffers'
import { history, AppDispatch } from '../../store'
import Offer from './Offer'

export interface MarketplaceProps {
  marketplaceOffers: marketplaceOfferRequestInterface
  user: UserObj
  locationPath: string
  dispatch: AppDispatch
}

interface MarketplaceState {
  offersFetched: boolean
}

export class MarketplaceOffers extends React.Component<
  MarketplaceProps,
  MarketplaceState
> {
  constructor(props) {
    super(props)

    this.state = {
      offersFetched: !isEmpty(this.props.marketplaceOffers)
    }
  }

  public async componentDidMount() {
    const { householdId } = this.props.user
    if (householdId) {
      await this.fetchOffers(householdId)
    } else {
      await this.props.dispatch(getHousehold())
    }
  }

  public async componentDidUpdate(prevProps: MarketplaceProps) {
    if (this.state.offersFetched) {
      return
    }

    const { householdId } = this.props.user
    if (householdId !== prevProps.user.householdId) {
      await this.fetchOffers(householdId)
    }
  }

  public async fetchOffers(householdId: string) {
    await this.props.dispatch(getMarketplaceOffers(householdId))
    this.setState({ offersFetched: true })
  }

  public redirect() {
    history.replace('/')
  }

  public renderOffers = () => {
    const { marketplaceOffers } = this.props
    return (
      <div className='marketplace__content'>
        {marketplaceOffers &&
          Object.keys(marketplaceOffers).map((key) => {
            return <Offer offer={marketplaceOffers[key]} key={key} />
          })}
      </div>
    )
  }

  public render() {
    const { marketplaceOffers, locationPath } = this.props
    const { offersFetched } = this.state

    if (!offersFetched) {
      return null
    }

    if (isEmpty(marketplaceOffers)) {
      this.redirect()
      return null
    }

    return (
      <div>
        <h2 className='marketplace__title-resources'>Resources</h2>
        <div className='marketplace__w'>
          <div
            id='marketplace-offers'
            style={{ background: 'unset', padding: 'unset' }}>
            {this.renderOffers()}
          </div>
        </div>
        <DisclosureFooter hasOffers={true} locationPath={locationPath} />
      </div>
    )
  }
}

export default connect(
  (store: GlobalState, dispatch: AppDispatch): MarketplaceProps => {
    return {
      marketplaceOffers: store.marketplaceOffers,
      user: store.user,
      locationPath: store?.router?.location?.pathname,
      dispatch
    }
  }
)(MarketplaceOffers)
