import React, { useEffect, useState } from 'react'
import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'
import { timeFormat } from '../../../helpers'
import ProfilePicture from '../../../components/ProfilePicture'
import { messageMediaType, VideoMessageData } from '../../../objects/messages'
import SpinnerLoader from '../../../assets/images/loader-spinner.gif'
import { useWindowSize } from '../../../hooks'
import { Modal } from 'react-bootstrap'

interface MessageBodyItemProps {
  authorName: string
  message: string
  threadId: string
  messageId: string
  createdDate: string
  photo: string
  isVideoMessage: boolean
  isOldVideoMessage: boolean
  videoMessages: VideoMessageData
  getVideoUrl(): void
  getThumbnailUrl(): void
}

const MessageBodyItem = (props: MessageBodyItemProps) => {
  const [showVideoModal, setShowVideoModal] = useState(false)
  const { getVideoUrl, getThumbnailUrl } = props

  useEffect(() => {
    getThumbnailUrl && getThumbnailUrl()
  }, [getThumbnailUrl])

  const { width } = useWindowSize()

  const toggleVideoModal = (event?: any) => {
    event && event.preventDefault()

    const showModal = !showVideoModal
    setShowVideoModal(showModal)

    if (showModal) {
      //Fetch the video url. Should probably be memoized
      getVideoUrl && getVideoUrl()
    }

    return false
  }

  const showLoader = (type: messageMediaType = 'thumbnail') => {
    return (
      <div className={`message-body__${type}-loader`}>
        <img src={SpinnerLoader} width='41' height='41' alt='loader' />
      </div>
    )
  }

  const videoMessageView = () => {
    const {
      message,
      isOldVideoMessage,
      threadId,
      messageId,
      videoMessages
    } = props

    // Pick the video url and thumbnailUrl from the video Messages reducer state
    const {
      videoUrl = '',
      thumbnailUrl = '',
      showvideoLoader = false,
      showthumbnailLoader = false
    } = videoMessages?.[threadId]?.[messageId] || {}

    // Modal popup logic for the video based on old way or new way
    const videoModalContent = () => (
      <Modal
        show={true}
        onHide={toggleVideoModal}
        fullscreen='md-down'
        className='p-0 p-md-5 message-body__modal'>
        <Modal.Header closeButton className='pb-md-0' />
        <div className='message-body__video-media'>
          {showvideoLoader ? (
            showLoader('video')
          ) : videoUrl ? (
            <video
              width={width > 1200 ? '865' : width > 768 ? '580' : '270'}
              height='470'
              preload='auto'
              controlsList='nodownload'
              controls
              disablePictureInPicture
              src={videoUrl}
            />
          ) : null}
        </div>
      </Modal>
    )

    return (
      <>
        {/* Message body with video thumbnail  */}
        <div className='message-body__video' onClick={toggleVideoModal}>
          <div
            className='message-body__video-thumbnail'
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {!isOldVideoMessage ? (
            <>
              {showthumbnailLoader ? (
                showLoader()
              ) : thumbnailUrl ? (
                <div className='message-body__video-thumbnail-wrapper'>
                  <img
                    className='message-body__video-thumbnail'
                    src={thumbnailUrl}
                    alt='thumbnail'
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>

        {/* Show the video modal when state is true */}
        {showVideoModal && videoModalContent()}
      </>
    )
  }

  const {
    message,
    authorName,
    createdDate,
    photo,
    isVideoMessage,
    isOldVideoMessage
  } = props
  const firstName = authorName?.slice(0, authorName?.indexOf(' '))
  const lastName = authorName?.slice(
    authorName?.lastIndexOf(' ') + 1,
    authorName?.length
  )

  return (
    <div className='message-body-item-w'>
      <ProfilePicture
        firstName={firstName}
        lastName={lastName}
        photo={photo}
        isPrimary={true}
        pictureSize={width > 768 ? 48 : 32}
      />
      <div className='message-body-heading-container'>
        <div className='message-body-heading-w'>
          <div className='message-body-heading__name'>{authorName}</div>
          <div style={{ flex: '1,1,100%' }}>
            <div className='message-body-heading__date'>
              {timeFormat(createdDate)}
            </div>
          </div>
        </div>
        <div className='message-body__message'>
          {/* Show the video message view if the message is a video message */}
          {isVideoMessage || isOldVideoMessage ? videoMessageView() : message}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    videoMessages: store.messages?.videoMessages || {}
  }
}
export default connect(mapStateToProps)(MessageBodyItem)
