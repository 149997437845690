import * as React from 'react'

interface GlobalButtonProps {
  type: string
  color?: string
  text?: string
  width?: number | string
  height?: number | string
  link?: string
  modalAction?(): void
}

export class GlobalButton extends React.Component<GlobalButtonProps, {}> {
  constructor(props: GlobalButtonProps) {
    super(props)
  }

  public render() {
    const { color, width, link, modalAction, type, height } = this.props
    if (type === 'close') {
      return (
        <a onClick={modalAction} href={link} role='button'>
          <span className='legacy-glob-btn__close'>
            <span
              className='legacy-bar-vert'
              style={{ background: color, width, height }}
            />
            <span
              className='legacy-bar-horiz'
              style={{ background: color, width, height }}
            />
          </span>
        </a>
      )
    } else if (type === 'plus-button') {
      return (
        <a onClick={modalAction} href={link} role='button'>
          <span className='legacy-glob-btn__plus'>
            <span className='legacy-bar-vert' />
            <span className='legacy-bar-horiz' />
          </span>
        </a>
      )
    } else if (type === 'main-button') {
      return (
        <a
          className={'legacy-glob-btn__main ' + this.props.color}
          onClick={modalAction}
          href={link}
          role='button'>
          {this.props.text && this.props.text}
        </a>
      )
    } else if (type === 'link-button') {
      return (
        <a className='legacy-glob-btn__link' href={link} role='button'>
          <span className='legacy-left-bar' />
          <span className='legacy-right-bar' />
        </a>
      )
    } else return null
  }
}
