import React, { useCallback, useEffect, useState } from 'react'
import { GradientHero } from '../../components/Hero'
import DashRow from '../../components/Dashboard/DashRow'
import AccountsTile from './AccountsTile'
import PlanTile from './PlanTile'
import PrioritiesTile from './Priorities/PrioritiesTile'
import TasksTile from './Tasks/TasksTile'
import MessagesTile from './MessagesTile'
import TeamTile from './TeamTile'
import { GlobalState } from '../../reducers'
import { connect } from 'react-redux'
import { getContact } from '../../selectors/contacts'
import { syncHousehold } from '../../actions/auth.action'

export type DashboardProps = ReturnType<typeof mapStateToProps>

export const Dashboard = (props: DashboardProps) => {
  const { contact } = props
  const [dispatchState, setSyncDispatchState] = useState(false)

  const syncUserHousehold = useCallback(async () => {
    await syncHousehold()
    setSyncDispatchState(true)
  }, [dispatchState])

  useEffect(() => {
    !dispatchState && syncUserHousehold()
  }, [dispatchState])

  const tiles = [
    <DashRow key='dashboard-accounts-tile'>
      <AccountsTile />
      <PlanTile />
    </DashRow>,
    <DashRow key='dashboard-priorities-tile'>
      <PrioritiesTile />
      <TasksTile />
    </DashRow>,
    <DashRow key='dashboard-messages-tile'>
      <MessagesTile />
    </DashRow>,
    <DashRow key='dashboard-team-tile'>
      <TeamTile />
    </DashRow>
  ]

  return (
    <div className='dashboard-p'>
      <GradientHero className='dashboard-p__hero' />
      <div className='dashboard-p__hero-content'>
        <h1>{contact && `Hello, ${contact.firstName}`}</h1>
      </div>
      <div className='dashboard-p__tiles'>{dispatchState && tiles}</div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    contact: getContact(store)
  }
}
export default connect(mapStateToProps)(Dashboard)
