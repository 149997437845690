import React from 'react'
import classnames from 'classnames'

interface GradientHeroProps {
  className?: string
}

const GradientHero = ({ className }: GradientHeroProps) => {
  const heroClasses = classnames('hero hero-gradient', className)
  return <div className={heroClasses} />
}

export default GradientHero
