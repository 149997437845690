import React from 'react'
import Button from 'react-bootstrap/Button'
import { history } from '../../store'

const goToPriorities = () => {
  history.push('/honest-conversations/personal-priorities')
}

export const IndividualPrioritiesCard = () => {
  return (
    <div className='personal-priorities__card my-5'>
      <div className='d-flex justify-content-between flex-column flex-sm-row'>
        <div className='mb-4 mb-sm-0 pe-2'>
          <h3 className='rfont--h3 fw-bold text-dark'>Personal Priorities</h3>
        </div>

        <Button
          size='lg'
          variant='outline-primary'
          className='font-color--dark fw-bold'
          onClick={goToPriorities}>
          See Individual Priorities
        </Button>
      </div>
    </div>
  )
}
