import React from 'react'

type TTableData = {
  id: string
  cellComponentProps: any
}[]

type TTableColumns = {
  Cell: React.ElementType
  id: string
  style?: any
  className?: string
}[]

const renderRows = (
  data: TTableData,
  columns: TTableColumns,
  rowClassname?: string,
  onRowClick?: any
) => {
  return data?.map((item, _index) => (
    <tr
      key={item.id}
      className={rowClassname}
      onClick={() => {
        onRowClick(item.cellComponentProps)
      }}>
      {columns.map((column) => {
        const CellComponent = column.Cell
        return (
          <td
            key={column.id}
            style={column.style as any}
            className={`${column.className}`}>
            <CellComponent {...item.cellComponentProps} />
          </td>
        )
      })}
    </tr>
  ))
}

export const MessagesCustomTable = ({
  id,
  data,
  columns,
  rowClassname,
  className,
  onRowClick
}: {
  id: string
  data: TTableData
  columns: TTableColumns
  rowClassname?: string
  className?: string
  onRowClick?: any
}) => {
  return (
    <table id={id} className={`${className}`}>
      <tbody>{renderRows(data, columns, rowClassname, onRowClick)}</tbody>
    </table>
  )
}
