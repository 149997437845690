import React from 'react'
import classnames from 'classnames'
import HorizontalBarChart from '../../../components/HorizontalBarChart'
import { getFormattedTotal } from '../../../helpers/investments'

export enum ESortOrder {
  ASC = 1,
  DESC = -1
}

export const AllocationCell = ({
  equityPercent,
  fixedPercent,
  cashPercent,
  altPercent,
  unclassifiedPercent
}) => {
  const itemList = [
    {
      name: 'Equity',
      percent: equityPercent ? equityPercent : 0,
      color: '#3B98E2'
    },
    {
      name: 'Fixed Income',
      percent: fixedPercent ? fixedPercent : 0,
      color: '#61B75E'
    },
    {
      name: 'Cash',
      percent: cashPercent ? cashPercent : 0,
      color: '#E4E6F5'
    },
    {
      name: 'Alternatives',
      percent: altPercent ? altPercent : 0,
      color: '#F4A83E'
    },
    {
      name: 'Other Investments',
      percent: unclassifiedPercent ? unclassifiedPercent : 0,
      color: '#89579D'
    }
  ]
  return (
    <div>
      <HorizontalBarChart itemList={itemList} tooltipOn={true} />
    </div>
  )
}

export const HoldingHeaderCell = (props) => {
  const { label, property, sortOrder, sortCallback } = props

  const getSortingIcon = () => {
    const iconClass = 'holding-table__sort-icon'
    const iconModifierClass = classnames({
      [`${iconClass}--default`]: !sortOrder,
      [`${iconClass}--desc`]: sortOrder && sortOrder === ESortOrder.DESC,
      [`${iconClass}--asc`]: sortOrder && sortOrder === ESortOrder.ASC
    })
    return <div className={iconModifierClass} />
  }

  return (
    <th className={`holding-table__header holding-table__header--${property}`}>
      <span
        className='holding-table__header--sortable'
        onClick={() => sortCallback(property)}>
        {label} {getSortingIcon()}
      </span>
    </th>
  )
}

export const HoldingRow = (props) => {
  const { rowData } = props
  return (
    <tr className='holding-table__row'>
      <td className='holding-table__cell holding-table__cell-first' />
      <td className='holding-table__cell holding-table__cell-name'>
        {rowData.tickerSymbol}
      </td>
      <td className='holding-table__cell holding-table__cell-description'>
        {rowData.securityDescription}
      </td>
      <td className='holding-table__cell holding-table__cell-allocation'>
        <AllocationCell {...rowData} />
      </td>
      <td className='holding-table__cell holding-table__cell-qty'>
        {rowData.quantity}
      </td>
      <td className='holding-table__cell holding-table__cell-price'>
        {getFormattedTotal(rowData.price)}
      </td>
      <td className='holding-table__cell holding-table__cell-value'>
        {getFormattedTotal(rowData.value)}
      </td>
    </tr>
  )
}
