import dayjs from 'dayjs'
import { cloneDeep } from '@apollo/client/utilities'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { ContactsState } from '../reducers/contacts.reducer'
import {
  AllSetting,
  ClientNotification,
  MessageNotfication,
  NewTaskAssignedNotification,
  NotificationContent,
  NewVaultFileNotification,
  DisplayableNotification
} from '../objects/settings/clientAdvisor'
import { ContactsInterface, ProfileImgClients } from '../objects/contact'
import { isComplete } from '../helpers/tasks'
import { GetClientNotificationSettingsQuery } from './queries'
import { ClientNotificationSettingsSubscription } from '../helpers/queries'
import { addSuccessToast, addErrorToast } from '../actions/toasts.action'
import {
  clientSettingsMapping,
  settingsStateValidator
} from '../helpers/clientSettings'
import { SetClientNotifications } from '../gql/client/settings/settings'
import { history } from '../store'

export const notificationDate = (date: string) => {
  const days = dayjs().diff(date, 'days')
  if (days < 1) {
    const hours = dayjs().diff(date, 'hours')
    if (hours < 1) {
      const minutes = dayjs().diff(date, 'minutes')
      if (minutes < 1) {
        return null
      }
      return minutes === 1 ? `1 minute ago` : `${minutes} minutes ago`
    }
    return hours > 1 ? `${hours} hours ago` : `1 hour ago`
  } else if (days < 7) {
    return days > 1 ? `${days} days ago` : `1 day ago`
  } else if (days >= 7 && days < 30) {
    const weeks = dayjs().diff(date, 'weeks')
    return weeks > 1 ? `${weeks} weeks ago` : `1 week ago`
  } else {
    const months = dayjs().diff(date, 'months')
    return months > 1 ? `${months} months ago` : `1 month ago`
  }
}

export const parseMessageContent = (
  content: string | NotificationContent
): NotificationContent => {
  if (typeof content === 'string') {
    let parsed: NotificationContent
    try {
      parsed = JSON.parse(content)
      if (typeof parsed === 'string') {
        parsed = JSON.parse(parsed)
      }
    } catch (e) {
      return null
    }
    return parsed
  }

  return content
}

export const getClientData = (contacts: ContactsInterface) => {
  const types = ['Primary', 'Secondary']
  const clients: ProfileImgClients = {}
  types.forEach((type) => {
    const typeLower: string = type.toLowerCase()
    if (contacts?.[typeLower]) {
      clients[typeLower] = {
        firstName: contacts && contacts[typeLower]?.firstName,
        lastName: contacts && contacts[typeLower]?.lastName,
        photo: contacts && contacts[typeLower]?.photo,
        isPrimary: type === 'Primary'
      }
    }
  })
  return clients
}

export const getNotificationLink = (
  notificationType: string,
  notificationData: any
) => {
  switch (notificationType) {
    case 'MESSAGE': {
      const {
        content: {
          data: { parentId, messageId }
        }
      } = notificationData as MessageNotfication
      const threadId = parentId ?? messageId
      return `/messages/${threadId}`
    }

    case 'NEW_TASK_ASSIGNED': {
      const { completed } = notificationData as ClientNotification
      return completed ? '/tasks/completed' : '/tasks'
    }

    case 'NEW_PDV_FILE': {
      const {
        content: {
          data: { folderId }
        }
      } = (notificationData as NewVaultFileNotification) || {
        content: { data: { folderId: '' } }
      }

      const folder = folderId ? `/${folderId}` : ''
      return `/document-vault${folder}`
    }

    default:
      return ''
  }
}

export const getNotificationContactNames = (
  id: string,
  householdContact: ContactsState
) => {
  const { primary, secondary } = householdContact

  return {
    cFirstName:
      primary?.id === id
        ? primary.firstName
        : secondary?.id === id
        ? secondary.firstName
        : null,
    cLastName:
      primary?.id === id
        ? primary.lastName
        : secondary?.id === id
        ? secondary.lastName
        : null,
    cPhoto:
      primary?.id === id
        ? primary.photo
        : secondary?.id === id
        ? secondary.photo
        : null
  }
}

const getTaskNotification = (notification: ClientNotification) => {
  const content = parseMessageContent(
    notification.notificationByNotification.content
  )
  const data = content.data as NewTaskAssignedNotification
  return {
    created: data.taskCreatedDate && new Date(data.taskCreatedDate).getTime(),
    completedDate: data.taskCompletedDate && new Date(data.taskCompletedDate),
    status: data.taskStatus,
    subject: data.taskSubject
  }
}

export const batchByReadDate = (notifications: any[], groupKey: AllSetting) => {
  if (!notifications) return []

  const collection: any = { [groupKey]: {} }
  notifications.forEach((notification) => {
    const {
      notificationByNotification: { subject, content },
      read,
      readDate
    } = notification

    if (subject === groupKey) {
      const notificationContent = parseMessageContent(content)
      const { data, triggerId } = notificationContent
      const indentifier = 'householdId' in data ? data.householdId : triggerId

      const dateObj = new Date(readDate)
      const key = dateObj.getTime()

      if (!collection[groupKey][indentifier]) {
        collection[groupKey][indentifier] = {}
        collection[groupKey][indentifier].unread = []
      }

      if (!collection[groupKey][indentifier][key]) {
        collection[groupKey][indentifier][key] = []
      }

      if (read) {
        collection[groupKey][indentifier][key].push(notification)
      } else {
        collection[groupKey][indentifier].unread.push(notification)
      }
    }
  })

  return collection
}

const groupNewTaskAssinged = (
  notifications: any[],
  rowGroup: any[],
  batchedNewTasksAssigned: any,
  notificationCopy: any,
  householdId: string
) => {
  const { readDate, read, subject } = notificationCopy

  const groupedPerHousehold =
    batchedNewTasksAssigned.NEW_TASK_ASSIGNED?.[householdId]
  const notificationDateObj = new Date(readDate)

  const lookupKey = read ? notificationDateObj.getTime() : 'unread'
  notificationCopy.batchSize = groupedPerHousehold[lookupKey]?.length

  if (
    notificationCopy.batchSize > 1 &&
    !rowGroup.includes(subject + householdId + lookupKey)
  ) {
    rowGroup.push(subject + householdId + lookupKey)

    const batched = groupedPerHousehold[lookupKey]
    // get status of most recently created task in batch
    notificationCopy.completed = isComplete(
      batched.reduce(
        (p, item: ClientNotification) => {
          const task = getTaskNotification(item)
          if (isNaN(task.created)) return p
          if (task.created > p.created) {
            p = task
          }
          return p
        },
        { created: 0 }
      )
    )

    notificationCopy.batchedIds = batched.map((n: ClientNotification) => n.id)

    notifications.push({ ...notificationCopy })
  }
  if (notificationCopy.batchSize === 1) {
    const task = getTaskNotification(notificationCopy)
    notificationCopy.completed = isComplete(task)
    notifications.push({ ...notificationCopy })
  }

  return { notifications, rowGroup }
}

const groupNewVaultFile = (
  position: number,
  notifications: any[],
  rowGroup: any[],
  batchedNewVaultFile: any,
  notificationCopy: any,
  householdId: string
) => {
  const {
    readDate,
    read,
    notificationByNotification: { subject },
    content: {
      triggerId,
      data: { folderId }
    }
  } = notificationCopy

  const groupByUploader = batchedNewVaultFile['NEW_PDV_FILE'][triggerId]
  const groupLookupKey = read ? new Date(readDate).getTime() : 'unread'
  notificationCopy.batchSize = groupByUploader?.[groupLookupKey]?.reduce(
    (accum, current) => {
      const {
        data: { folderId: currentFolderId }
      } = JSON.parse(current.notificationByNotification.content)
      if (currentFolderId === folderId) {
        return accum.concat([current])
      }
      return accum
    },
    []
  ).length

  const rowGroupKey = `${subject}|${triggerId}|${folderId}|${groupLookupKey}`

  if (notificationCopy.batchSize > 1 && !rowGroup.includes[rowGroupKey]) {
    rowGroup.push(rowGroupKey)
    const batched = groupByUploader[groupLookupKey]
    notificationCopy.batchedIds = batched.map((n: ClientNotification) => n.id)
  }

  return { notifications, rowGroup }
}

export const extendedNotificationsDisplayables = (
  contacts: ContactsState,
  clientNotificationNodes: ClientNotification[]
) => {
  let notifications: any[] = []
  let rowGroup: string[] = []

  const batchedNewTasksAssigned = batchByReadDate(
    clientNotificationNodes,
    'NEW_TASK_ASSIGNED'
  )

  const batchedNewVaultFile = batchByReadDate(
    clientNotificationNodes,
    'NEW_PDV_FILE'
  )

  clientNotificationNodes.forEach((notification: any, position: number) => {
    const notificationCopy = cloneDeep(notification)

    const { notificationByNotification } = notification
    const { subject, content } = notificationByNotification

    const contentObj = JSON.parse(content)
    notificationCopy.householdContacts = contacts

    const {
      data: { householdId }
    } = contentObj

    notificationCopy.content = contentObj

    if (subject === 'NEW_PDV_FILE') {
      const groupedNewVault = groupNewVaultFile(
        position,
        notifications,
        rowGroup,
        batchedNewVaultFile,
        notificationCopy,
        householdId
      )
      notifications = groupedNewVault.notifications
      rowGroup = groupedNewVault.rowGroup
    }

    if (subject === 'NEW_TASK_ASSIGNED') {
      const groupedNewTask = groupNewTaskAssinged(
        notifications,
        rowGroup,
        batchedNewTasksAssigned,
        notificationCopy,
        householdId
      )
      notifications = groupedNewTask.notifications
      rowGroup = groupedNewTask.rowGroup
    }

    !['NEW_ACCOUNT_LINKED', 'COMPLETE_TASK', 'NEW_TASK_ASSIGNED'].includes(
      subject
    ) &&
      notificationCopy &&
      notifications.push(notificationCopy)
  })

  const filterByBatchedIds =
    notifications.length > 0 &&
    notifications.reduce((accum, current) => {
      const batchedFound = accum.find(
        (item) =>
          item?.batchedIds?.join() === current?.batchedIds?.join() &&
          current?.batchSize > 1
      )
      if (!batchedFound) {
        return accum.concat([current])
      }
      return accum
    }, [])
  if (filterByBatchedIds?.length > 0) {
    notifications = filterByBatchedIds
  }

  return notifications
}

export const enabledNotifications = (
  clientEnabledSubjects: string[],
  clientNotifications: ClientNotification[]
) => {
  const subjects = clientEnabledSubjects?.length ? clientEnabledSubjects : []
  const notifications = clientNotifications?.length ? clientNotifications : []
  return notifications.filter((notificationsNode: ClientNotification) => {
    const {
      notificationByNotification: { subject }
    } = notificationsNode
    return subject !== 'SYNC' && subjects.includes(subject)
  })
}

/**
 * Compare our settings cache vs state, then save if we have changes
 */
export const saveNotificationSettings = (
  userId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>,
  desktopSettings: string[],
  emailSettings: string[],
  mobileSettings: string[],
  dispatch
) => {
  if (!userId) {
    dispatch(addErrorToast({ message: 'Unable to update your information' }))
    return
  }
  queryNotificationSettings(userId, apolloClient)
    .then((queryResponse: any) => {
      if (!queryResponse) {
        dispatch(
          addErrorToast({ message: 'Unable to update your information' })
        )
        return
      }
      const settings = clientSettingsMapping(queryResponse)
      const desktopSettingsChanged =
        settings?.desktopSettings &&
        settingsStateValidator(settings.desktopSettings, desktopSettings)
      const emailSettingsChanged =
        settings?.emailSettings &&
        settingsStateValidator(settings.emailSettings, emailSettings)
      const mobileSettingsChanged =
        settings?.mobileSettings &&
        settingsStateValidator(settings.mobileSettings, mobileSettings)
      if (
        desktopSettingsChanged ||
        emailSettingsChanged ||
        mobileSettingsChanged
      ) {
        SetClientNotifications(apolloClient, {
          desktopSettings,
          emailSettings,
          mobileSettings,
          clientId: userId
        })
          .then((response: any) => {
            history.push('/my-profile')
            dispatch(addSuccessToast({ message: 'Information updated' }))
          })
          .catch((err) => {
            dispatch(
              addErrorToast({ message: 'Unable to update your information' })
            )
          })
      }
    })
    .catch((err) => {
      dispatch(addErrorToast({ message: 'Unable to update your information' }))
    })
}

export const queryNotificationSettings = async (
  userId: string,
  apolloClient: ApolloClient<NormalizedCacheObject>
) => {
  if (!userId) {
    return {
      desktopSettings: [],
      emailSettings: [],
      mobileSettings: [],
      disabled: true
    }
  }
  try {
    const queryResponse = await apolloClient.query({
      query: GetClientNotificationSettingsQuery,
      variables: { clientId: userId },
      fetchPolicy: 'no-cache'
    })
    return queryResponse
  } catch (err) {
    return null
  }
}

export const notificationsSubscriber = (subscribeToMore) => {
  subscribeToMore &&
    subscribeToMore({
      document: ClientNotificationSettingsSubscription,
      variables: null,
      onError: (err: any) => {
        //
      },
      updateQuery: (
        prev: any,
        { subscriptionData }: { subscriptionData: any }
      ) => {
        const {
          desktopSubject,
          emailSubject,
          mobileSubject
        } = subscriptionData.data.notification
        const updatedCache = Object.assign({}, prev, {
          allClients: {
            nodes: [
              {
                desktopSubject,
                emailSubject,
                mobileSubject
              },
              ...prev.allClients.nodes
            ]
          }
        })
        return updatedCache
      }
    })
}

export const sameMembers = (arr1, arr2) => {
  const containsAll = (arr1, arr2) =>
    arr2.every((arr2Item) => arr1.includes(arr2Item))
  return containsAll(arr1, arr2) && containsAll(arr2, arr1)
}

// get IDs of unread notifications from a single notification with possible batch
export const unreadFromNotification = (
  notification: DisplayableNotification
): string[] => {
  if (notification.read || notification.readDate) {
    return []
  }
  if (notification.batchedIds?.length) {
    return notification.batchedIds
  }
  return [notification.id]
}

// get IDs of all unread notifications from batched notifications
export const unreadFromAllNotifications = (
  notifications: DisplayableNotification[]
): string[] => {
  return notifications.reduce((p: string[], n) => {
    p = [...p, ...unreadFromNotification(n)]
    return p
  }, [])
}
