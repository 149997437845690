import React from 'react'
import { NavLink } from 'react-router-dom'
import { profileLinks } from '../Profile'
import Nav from 'react-bootstrap/Nav'
import { useWindowSize } from '../../hooks'

const ProfileNavigation = (): JSX.Element => {
  const { width } = useWindowSize()
  const isSmallScr = width < 768
  const navVariant = isSmallScr ? 'underline' : undefined
  const linkClass = isSmallScr ? 'sm' : 'md'

  return (
    <div className='profile-nav'>
      <Nav
        defaultActiveKey={profileLinks[0]?.path}
        className='profile-nav__container'
        variant={navVariant}>
        {profileLinks.map((profileLink) => {
          return (
            <Nav.Link
              key={profileLink.text}
              exact
              as={NavLink}
              to={profileLink.path}
              className={`profile-nav__link profile-nav__link-${linkClass}`}
              activeClassName={`profile-nav__link-${linkClass}--active`}>
              {profileLink.text}
            </Nav.Link>
          )
        })}
      </Nav>
    </div>
  )
}

export default ProfileNavigation
