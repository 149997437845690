import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { PlanScoreBar } from '../../../components/Plan'
import { dateFormat } from '../../../helpers'
import { FundedScoreObj } from '../../../objects/plan'

export interface LineGraphModalProps {
  currentScore: FundedScoreObj
  closeModal(): void
  show: boolean
}

const LineGraphModal = (props: LineGraphModalProps) => {
  const {
    scoreDate,
    score,
    source,
    rangeLow,
    rangeHigh,
    notes
  } = props.currentScore

  const { closeModal, show } = props

  const roundedScore = Math.round(score)

  return (
    <Modal
      onHide={closeModal}
      show={show}
      backdrop='static'
      className='funded-score__modal'
      contentClassName='p-x2'>
      <Modal.Header closeButton className='p-0'>
        <h2 className='funded-score__modal-title'>Score Details</h2>
      </Modal.Header>

      <div className='funded-score__modal-date'>
        {`Score updated on ${dateFormat(scoreDate)}`}
      </div>
      <div className='funded-score__modal-score-bar'>
        <PlanScoreBar
          score={roundedScore}
          minScore={rangeLow}
          maxScore={rangeHigh}
          source={source}
        />
      </div>
      {notes && (
        <div>
          <div className='score-history-tile__notes-title score-history-tile__notes-title--with-border'>
            Advisor's Note
          </div>
          <div className='score-history-tile__notes-subtitle'>{notes}</div>
        </div>
      )}
    </Modal>
  )
}

export default LineGraphModal
