import { useReducer } from 'react'
import { ClientAccountObj } from '../objects/clientAccount'
import { history } from '../store'

export const titleList: Record<ModalActions, Record<string, string>> = {
  show_add_account: {
    header: 'Account Details',
    subheader: 'Enter as much information as you know about your account below.'
  },
  show_edit_account: {
    header: 'Edit Manual Account',
    subheader: 'Update information about your account below'
  },
  show_yodleeEdit_account: {
    header: null
  },
  show_delete_account: {
    header: null
  },
  close_modal: {
    header: null
  }
}

export type ModalActions =
  | 'show_add_account'
  | 'show_edit_account'
  | 'show_delete_account'
  | 'show_yodleeEdit_account'
  | 'close_modal'

export const initModalState = {
  showAddAccount: {
    account: null,
    status: false
  },
  showEditAccount: {
    account: null,
    status: false
  },
  showYodleeEditAccount: {
    account: null,
    status: false
  },
  showDeleteAccount: {
    account: null,
    status: false
  }
}

export const modalReducer = (state, action) => {
  const currentAccount = action.account
  switch (action.type) {
    case 'show_add_account':
      return {
        ...initModalState,
        showAddAccount: { account: currentAccount, status: true }
      }
    case 'show_edit_account':
      return {
        ...initModalState,
        showEditAccount: { account: currentAccount, status: true }
      }
    case 'show_yodleeEdit_account':
      return {
        ...initModalState,
        showYodleeEditAccount: { account: currentAccount, status: true }
      }
    case 'show_delete_account':
      return {
        ...initModalState,
        showDeleteAccount: { account: currentAccount, status: true }
      }
    case 'close_modal':
      return { ...initModalState }
    default:
      throw new Error('No proper action was passed')
  }
}

export const useModalReducer = () => {
  const [modalState, handleModal] = useReducer(modalReducer, initModalState)
  return { modalState, handleModal }
}

export const validErrorCode = (connectedErrorCode: string) => {
  const isErrorAccount =
    connectedErrorCode &&
    connectedErrorCode !== '0' &&
    connectedErrorCode !== '801' &&
    connectedErrorCode !== '802' &&
    connectedErrorCode !== '811'
      ? true
      : false
  return isErrorAccount
}

export const isYodleeAccount = (account: ClientAccountObj) => {
  return account.source === 'Guide Center - Yodlee'
}

export const openAddModal = () => history.push('/net-worth/account/add')

export const closeAddModal = () => history.replace('/net-worth')

export const getClassification = (cls: string) => {
  if (!cls) {
    return ''
  }

  switch (cls) {
    case 'Other - Personal Assets':
      return 'Personal Assets'
    case 'Other - Business Assets':
      return 'Business Assets'
    case 'Business Assets':
      return 'Other - Business Assets'
    case 'Personal Assets':
      return 'Other - Personal Assets'
    default:
      return cls
  }
}
