import React from 'react'
import Form from 'react-bootstrap/Form'
import classnames from 'classnames'
import ScoreComment from './ScoreComment'

const SCORE_MIN = 0
const SCORE_MAX = 10
const SCORE_HIGH = 7
const SCORE_STEP = 1

export const InputForms = (props): JSX.Element => {
  const {
    card,
    onScoreChange,
    onCommentChange,
    cardIndex,
    createScoresAndUpdateScoringSession
  } = props
  const currentScore = card?.score || SCORE_MIN
  // apply modifiers that change colors based on selected score
  const rangeClassName = classnames('honestConversations-score__range', {
    'honestConversations-score__range--low': currentScore < SCORE_HIGH,
    'honestConversations-score__range--high': currentScore >= SCORE_HIGH
  })
  // adjusts low/high progress overlay width
  const calcWidthPercent = (currentScore / SCORE_MAX) * 100
  const calcThumbOffset = (currentScore / SCORE_MAX) * 24
  const rangeProgressWidth = `calc(${calcWidthPercent}% - ${calcThumbOffset}px)`
  return (
    <div>
      <div className={`honestConversations-score__slider pb-x2 pt-x2`}>
        <div className='rfont--body2 mb-3'>Your score</div>
        <div className={rangeClassName}>
          <Form.Label className='honestConversations-score__range-label'>
            {currentScore}
          </Form.Label>
          <div className='position-relative'>
            <Form.Range
              max={SCORE_MAX}
              min={SCORE_MIN}
              step={SCORE_STEP}
              defaultValue={currentScore}
              onChange={(evt) => {
                const value = parseInt(evt.target.value)
                onScoreChange(value, cardIndex)
              }}
            />
            <span
              className='honestConversations-score__range-progress'
              style={{
                width: rangeProgressWidth
              }}
            />
          </div>
          <span className='honestConversations-score__range-min'>
            {SCORE_MIN}
          </span>
          <span className='honestConversations-score__range-max'>
            {SCORE_MAX}
          </span>
        </div>
      </div>
      <div className='honestConversations-score__comment mb-5'>
        <ScoreComment
          onCommentChange={onCommentChange}
          cardIndex={cardIndex}
          value={card.comment}
          createScoresAndUpdateScoringSession={
            createScoresAndUpdateScoringSession
          }
        />
      </div>
    </div>
  )
}

export default InputForms
