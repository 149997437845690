import React from 'react'

interface IScorePropgressProps {
  score: number
}

const ScoreProgress: React.FC<IScorePropgressProps> = ({ score }) => {
  const getProgressColor = (index: number) => {
    if (index < score) {
      return score <= 5
        ? 'dash-score-progress__span-lowscore'
        : 'dash-score-progress__span-highscore'
    }
    return 'dash-score-progress__span-gray'
  }

  return (
    <div className='dash-score-progress'>
      {Array.from({ length: 10 }, (_, index) => (
        <span
          key={index}
          className={`dash-score-progress__span ${getProgressColor(
            index
          )}`}></span>
      ))}
    </div>
  )
}

export default ScoreProgress
