import { AxiosResponse } from 'axios'
import qs from 'query-string'
import Api from '../helpers/api'

export const FETCH_ACCESS_TOKEN: string = 'FETCH_ACCESS_TOKEN'
export const FETCH_ACCESS_TOKEN_FULFILLED: string =
  'FETCH_ACCESS_TOKEN_FULFILLED'
export const FETCH_AUTHENTICATION_TOKENS: string = 'FETCH_AUTHENTICATION_TOKENS'
export const FETCH_AUTHENTICATION_TOKENS_FULFILLED: string =
  'FETCH_AUTHENTICATION_TOKENS_FULFILLED'
export const REVOKE_ACCESS_TOKEN: string = 'REVOKE_ACCESS_TOKEN'
export const REVOKE_ACCESS_TOKEN_FULFILLED: string =
  'REVOKE_ACCESS_TOKEN_FULFILLED'
export const RESET_SESSION_TIMEOUT: string = 'RESET_SESSION_TIMEOUT'
export const AUTH_PASSTHROUGH: string = 'AUTH_PASSTHROUGH'
export const SYNC_USER_HOUSEHOLD: string = 'SYNC_USER_HOUSEHOLD'

const config = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': '*'
  }
}

interface RefreshTokenResponse {
  accessToken: string
}

interface GrantTokenResponse {
  refreshToken: string
  accessToken: string
}

export const getAccessToken = (
  refreshToken: string
): { type: string; payload: Promise<string> } => {
  const data: string = qs.stringify({
    refreshToken
  })
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_ACCESS_TOKEN,
    payload: apiInstance
      .post('/authentication/refreshToken', data, config)
      .then((response: AxiosResponse<RefreshTokenResponse>): string => {
        return response.data.accessToken
      })
  }
}

export const getAuthenticationTokens = (
  grantToken: string
): { type: string; payload: Promise<{ accessToken: string }> } => {
  const data: string = qs.stringify({
    grantToken,
    redirectUri: `${window.location.protocol}//${window.location.host}${window._env_.REACT_APP_OAUTH_REDIRECT_URI}`
  })
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_AUTHENTICATION_TOKENS,
    payload: apiInstance
      .post('/authentication/grantToken', data, config)
      .then((response: AxiosResponse<GrantTokenResponse>): {
        accessToken: string
      } => {
        // we have a successfull auth, go ahead and pass the auth key to the API and create the instance
        Api.createInstance(response.data.accessToken)
        return {
          accessToken: response.data.accessToken
        }
      })
  }
}

// assumes API already has an authed instance
export const revokeAuthenticationToken = (): {
  type: string
  payload: Promise<boolean>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: REVOKE_ACCESS_TOKEN,
    payload: apiInstance
      .get('/authentication/logout')
      .then((response: AxiosResponse) => {
        return true
      })
  }
}

export const resetTimeout = () => {
  return {
    type: RESET_SESSION_TIMEOUT
  }
}

export const authPassthrough = (
  accessToken: string,
  contactId: string
): { type: string; payload: { accessToken: string; contactId: string } } => {
  return {
    type: AUTH_PASSTHROUGH,
    payload: {
      accessToken,
      contactId
    }
  }
}

export const syncHousehold = () => {
  const apiInstance = Api.getInstance()
  return {
    type: SYNC_USER_HOUSEHOLD,
    payload: apiInstance.post('/account/sync').then((response) => response)
  }
}
