/**
 * Utility function that can append and update query params on a given uri.
 * @param {string} uri
 * @param {string} key
 * @param {string} value
 */
export default function updateUrlParameter(
  uri: string,
  key: string,
  value: string
): string {
  const hashPosition = uri.indexOf('#')
  const existingKeyRegEx = new RegExp('([?&])' + key + '=.*?(&|$)', 'i')
  const hash = hashPosition === -1 ? '' : uri.substr(hashPosition)

  // Remove hash before processing the URL.
  uri = hashPosition === -1 ? uri : uri.substr(0, hashPosition)
  // Remove tailing slash.
  uri = uri.replace(/\/$/, '')

  // Check if there are already query strings.
  const separator = uri.indexOf('?') !== -1 ? '&' : '?'

  if (uri.match(existingKeyRegEx)) {
    uri = uri.replace(existingKeyRegEx, '$1' + key + '=' + value + '$2')
  } else {
    uri = `${uri}${separator}${encodeURIComponent(key)}=${encodeURIComponent(
      value
    )}`
  }
  return `${uri}${hash}`
}
