import { ContactsInterface, ContactObj } from '../contact'

/**
 * ALL DESKTOP SETTINGS
 * ["MESSAGE", "BROKEN_LINK", "GOAL_CHANGE", " NEW_ACCOUNT_LINKED", "SYNC", "NEW_YMM", "NEW_HC", "NEW_PDV_FILE"]
 * settings not in allSettings array will be ignored (like "SYNC")
 */
export type AllSetting =
  | 'SYNC'
  | 'MESSAGE'
  | 'GOAL_CHANGE'
  | 'NEW_ACCOUNT_LINKED'
  | 'NEW_YMM'
  | 'NEW_HC'
  | 'NEW_PDV_FILE'
  | 'NEW_TASK_ASSIGNED'
/**
 * utility properties to gouge which toggles to render for desktop/email settings
 * this can be changed once BE will implement queries to retrive list of
 * all available subjects
 */
export const allSettings: AllSetting[] = [
  'MESSAGE',
  'GOAL_CHANGE',
  'NEW_ACCOUNT_LINKED',
  'NEW_YMM',
  'NEW_HC',
  'NEW_PDV_FILE',
  'NEW_TASK_ASSIGNED'
]
//
export const allEmailSettings: AllSetting[] = ['MESSAGE', 'NEW_TASK_ASSIGNED']

export const captionList: Record<AllSetting, string> = {
  SYNC: '',
  MESSAGE: 'New Message',
  GOAL_CHANGE: 'Financial Plan Change Requests',
  NEW_YMM: 'New MoneyMind<sup>&reg;</sup>',
  NEW_HC: 'New HonestConversations<sup>&reg;</sup>',
  NEW_PDV_FILE: 'New Vault File',
  NEW_ACCOUNT_LINKED: 'New Account Linked',
  NEW_TASK_ASSIGNED: 'New Task'
}

export const captionKeyList: Record<string, string> = {
  '': 'SYNC',
  'New Message': 'MESSAGE',
  'Financial Plan Change Requests': 'GOAL_CHANGE',
  'New MoneyMind<sup>&reg;</sup>': 'NEW_YMM',
  'New HonestConversations<sup>&reg;</sup>': 'NEW_HC',
  'New Vault File': 'NEW_PDV_FILE',
  'New Account Linked': 'NEW_ACCOUNT_LINKED',
  'New Task': 'NEW_TASK_ASSIGNED'
}

export const captionTitleList: Record<AllSetting, string> = {
  SYNC: '',
  MESSAGE: 'receive a new message',
  GOAL_CHANGE: 'have new Financial Plan Change Requests',
  NEW_YMM: 'have New MoneyMind<sup>&reg;</sup>',
  NEW_HC: 'have New HonestConversations<sup>&reg;</sup>',
  NEW_PDV_FILE: 'have a new vault file',
  NEW_ACCOUNT_LINKED: 'have New Account Linked',
  NEW_TASK_ASSIGNED: 'have a new task assigned to you'
}

/**
 * ALL CLIENT SETTINGS
 */
export type ClientNotificationTypes =
  | 'MESSAGE'
  | 'NEW_TASK_ASSIGNED'
  | 'NEW_PDV_FILE'

export const ClientDesktopNotifications: ClientNotificationTypes[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED',
  'NEW_PDV_FILE'
]
export const ClientEmailNotifications: ClientNotificationTypes[] = [
  'MESSAGE',
  'NEW_TASK_ASSIGNED'
]
export const ClientMobileNotifications: ClientNotificationTypes[] = ['MESSAGE']

export const isAdjustable = (
  settingType: string
): settingType is ClientNotificationTypes => {
  return ['MESSAGE', 'NEW_TASK_ASSIGNED', 'NEW_PDV_FILE'].includes(settingType)
}
export type settingTypes = 'EMAIL_' | 'WEB_' | 'MOBILE_'

/**
 * DEPENDANT EMAIL NOTIFICATION SETTINGS
 * if an AllSetting type is applied, the related email type will require its
 * web type to be enabled for it to be available for toggling on/off.
 * {[key - email notification type]: [value - tooltip message]}
 * exp:
 * { MESSAGE: 'In order to receive email notifications, please turn both email and web notifications on.'}
 */
export const dependantWebNotificationSettings: Record<string, string> = {}

export type NotificationTriggerType = 'client' | 'adviser' | 'household'

export type NotficationTypes = MessageNotfication | NewTaskAssignedNotification

export type MessageNotfication = {
  content: {
    data: {
      parentId: string
      messageId: string
      householdId: string
    }
  }
}

export type NewVaultFileNotification = {
  id: string
  batchIds?: string[]
  content: {
    data: {
      id: string
      name: string
      size: number
      household: string
      folderName: string
      fileName: string
      folderId: string
      createdDate: string
      triggerId: string //client id || adviser id
      triggerType: string // adviser || client
    }
  }
}

export type NewTaskAssignedNotification = {
  taskId: string
  householdId: string
  taskSubject: string
  taskCreatedDate?: string
  taskCompletedDate?: string
  taskStatus?: string
}
export interface NotificationContent {
  data: NotficationTypes
  triggerId: string
  triggerType: NotificationTriggerType
}

export interface NotificationByNotification {
  createdDate: string
  subject: AllSetting
  title: string
  content: string | NotificationContent
}

export interface ClientNotification {
  delivered?: boolean
  read: boolean
  id: string
  notificationByNotification: NotificationByNotification
  dateKey?: number
  readDate: string | null
  deliveredDate?: string
  completed?: boolean
}

export interface NotificationContent {
  data: NotficationTypes
  triggerId: string
  triggerType: NotificationTriggerType
}

export interface DisplayableNotification extends ClientNotification {
  household?: any
  householdContacts?: ContactsInterface
  contact?: ContactObj
  batchSize?: number
  batchedIds?: string[]
}
