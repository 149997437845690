import Api from '../helpers/api'
export const FETCH_GOALS = 'FETCH_GOALS'

export const getGoals = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_GOALS,
    payload: apiInstance.get('/goals').then((response) => {
      return response.data
    })
  }
}
