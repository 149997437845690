import React from 'react'
import TaskCheckboxGroup from '../../Tasks/TaskCheckboxGroup'
import { dateFormat } from '../../../helpers'
import { TaskObj } from '../../../objects/task'

export interface TaskItemProps {
  task: TaskObj
}

const DashTaskRow = (props: TaskItemProps): JSX.Element => {
  const { task } = props
  const { subject, dueDate } = task
  return (
    <div className='dash-task-row__w'>
      <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
        <div className='dash-task-row__checkbox'>
          <TaskCheckboxGroup task={task} />
        </div>
        <div className='dash-task-row__text'>
          <div className='dash-task-row__text-name'>{subject || ''}</div>
          <div className='dash-task-row__text-date'>
            {dueDate ? dateFormat(dueDate) : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashTaskRow
