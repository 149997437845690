import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { GlobalState } from '../../../reducers'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getGoalActions } from '../../../actions/goalActions.action'
import { getHonestConversation } from '../../../actions/honestConversations.action'
import { typeToTab } from '../../../helpers/goals'
import StatusDot from '../StatusDot'
import dayjs from 'dayjs'
import { history } from '../../../store'

type GoalsDetailActions = typeof mapDispatchToProps
type GoalsDetailStore = ReturnType<typeof mapStateToProps>
export type GoalsDetailProps = GoalsDetailActions & GoalsDetailStore

const GoalsDetail = (props: GoalsDetailProps) => {
  const { goal, getGoalActions } = props
  useEffect(() => {
    getGoalActions()
    getHonestConversation()
  }, [])

  return (
    <div>
      <div className='goal-detail__container'>
        <Link
          to={''}
          className='goal-detail__title-link'
          onClick={history.goBack}>
          <span>
            <i className='bi bi-chevron-left'></i> Back
          </span>
        </Link>
      </div>
      <div style={{ marginBottom: '10px' }}>
        {goal && (
          <Card
            className='bg-white rounded-0 border-0 w-100 goal-detail__card'
            id={goal.id + '__upper'}>
            <Row className='goal-detail__row-item'>
              <Col className='justify-content-start goal-detail__title'>
                <h3 className='goal-detail__name'>{goal && goal.name}</h3>
                {goal && goal.dueDate && (
                  <p className='goal-detail__date-small'>
                    Check in on{' '}
                    {dayjs(goal?.dueDate, 'M-D-YY')?.format('MMM D, YYYY')}
                  </p>
                )}
                <Col className='goal-detail__row-item'>
                  <div style={{ marginRight: '20px' }}>
                    {goal && (
                      <Col className='goal-detail__row-item'>
                        {typeToTab(goal.type).map((type, i) => {
                          const compositeKey = goal.id + type + i
                          return (
                            <div
                              key={compositeKey}
                              className='goal-detail__type'>
                              {type}
                            </div>
                          )
                        })}
                      </Col>
                    )}
                  </div>
                  <div className='goal-detail__dot'>
                    <StatusDot status={goal.status} />
                  </div>
                  <p style={{ marginLeft: '15px' }}>{goal.status}</p>
                </Col>
              </Col>
              <Col className='d-flex justify-content-end goal-detail__date-large'>
                {goal && goal.dueDate && (
                  <p className='goal-detail__date-large'>
                    Check in on{' '}
                    {dayjs(goal?.dueDate, 'M-D-YY')?.format('MMM D, YYYY')}
                  </p>
                )}
              </Col>
            </Row>
          </Card>
        )}
        {goal && goal.note && (
          <Card
            className='bg-white rounded-0 border-0 w-100'
            id={goal.id + '__upper'}>
            <Row>
              <Col className='goal-detail__text'>{goal.note}</Col>
            </Row>
          </Card>
        )}
      </div>
      {goal && goal.cards.length > 0 && (
        <Row className='goal-detail__row-priorities'>
          <h3 className='goal-detail__priority-title'>Priorities</h3>
          <Row className='goal-detail__priority-box'>
            {goal.cards.map((card, idx) => (
              <div
                key={idx}
                className={
                  'goal-detail__priorities goal-detail__priorities-' + idx
                }>
                {card.title}
              </div>
            ))}
            <div className='goal-detail__dummy-div' />
            <div className='goal-detail__dummy-div' />
            <div className='goal-detail__dummy-div' />
            <div className='goal-detail__dummy-div' />
            <div className='goal-detail__dummy-div' />
          </Row>
        </Row>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getGoalActions
}
const mapStateToProps = (store: GlobalState, { match }: any) => {
  const goalId = match.params.goalId
  const goalActions = store.goalActions.goals
  const goal =
    goalActions && goalActions.find((goalItem) => goalItem.id === goalId)
  return {
    goal
  }
}
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GoalsDetail)
)
