// use cyclical import trick for unit tests
// to mock/spyOn inner functions called by outer functions
// https://stackoverflow.com/a/55193363
import dayjs from 'dayjs'
import * as helpers from './tasks'
import { TaskObj, TaskStatus } from '../objects/task'
import { ContactObj } from '../objects/contact'
import api from '../helpers/api'
import { getTasksV2 } from '../actions/tasks.action'
import store from '../store'

export const isAssigned = (task: TaskObj, contact: ContactObj) => {
  const { crmId, assigneeNames, primaryAssignee, secondaryAssignee } = task
  if (crmId || !contact) {
    return false
  }
  if ([primaryAssignee, secondaryAssignee].includes(contact.id)) {
    return true
  }
  if (!assigneeNames) {
    return true
  }
  const { firstName, lastName } = contact
  if (
    assigneeNames === `${firstName} ${lastName}` ||
    assigneeNames.includes(`${firstName} and`) ||
    assigneeNames.includes(`and ${firstName}`)
  ) {
    return true
  }
  return false
}
const checkNullTask = (task: any) => {
  if ((task.status == null || task.subject == null) && task.crmId != null) {
    return false
  }
  return true
}

export const filterNullTasks = (tasks: TaskObj[]): TaskObj[] => {
  return tasks.filter((task) => checkNullTask(task))
}

export const filterTasks = (tasks: TaskObj[], contact: ContactObj) => {
  return tasks.filter((task) => helpers.isAssigned(task, contact))
}

export const sortByDueDate = (tasks: TaskObj[]) => {
  return tasks.sort((a, b) => {
    if (!a.dueDate) return 1
    if (!b.dueDate) return -1
    if (dayjs(a.dueDate).isAfter(b.dueDate)) {
      return 1
    } else {
      return -1
    }
  })
}

export const sortTasks = (tasks: TaskObj[], sortBy?: string) => {
  switch (sortBy) {
    case 'assignee':
      return tasks.sort((a: TaskObj, b: TaskObj) => {
        if (!a.assigneeNames) return 1
        if (!b.assigneeNames) return -1
        return a.assigneeNames.localeCompare(b.assigneeNames, 'en', {
          ignorePunctuation: true
        })
      })
    default:
      return sortByDueDate(tasks)
  }
}

export const filterAndSort = (
  tasks: TaskObj[],
  sortBy: string,
  filter: boolean,
  contact?: ContactObj
): TaskObj[] => {
  if (!filter) {
    return filterNullTasks(helpers.sortTasks(tasks, sortBy))
  }
  const filtered = helpers.filterTasks(tasks, contact)
  const nullFiltered = filterNullTasks(filtered)
  return helpers.sortTasks(nullFiltered, sortBy)
}
// this function is used inside reducer to determine if status should be on history page for both CRM and native tasks
export const isComplete = (task: TaskObj | any, status?: string) => {
  return (
    (task?.completedDate && dayjs(task?.completedDate).isValid()) ||
    [task?.status, status].includes(TaskStatus.Completed) ||
    [task?.status, status].includes(TaskStatus.Cancelled) ||
    task?.isClosed
  )
}

export const taskRefetch = (remainingRetryAttempt: number = 3) => {
  void api
    .getInstance()
    .get(`/tasks/tasksV2`)
    .then((response: any) => {
      const { crmTasks } = response.data
      const fiveMins = window._env_.REACT_APP_TASKS_V2_REFETCH_MINS
      let isOutOfSync: boolean = false
      // check every task to see if they are all within 5 mins of lastSynced time
      isOutOfSync = crmTasks.length
        ? crmTasks.every((task: any) => {
            const currentDateTimeTest: any = new Date()
            const lastSyncedDate: any = new Date(task.lastSynced)
            return Math.abs(lastSyncedDate - currentDateTimeTest) > fiveMins
          })
        : false
      // retry api call if response has crmTasks that are out of sync
      if (crmTasks.length > 0 && isOutOfSync) {
        if (remainingRetryAttempt < 1) {
          // Base case
          store.dispatch(getTasksV2(response.data))
          return response
        } else {
          // if isOutOfSync is true and there are crmTasks, retry api call 3 times in 10 second intervals
          setTimeout(() => {
            // recursively call until base case is met
            taskRefetch(remainingRetryAttempt - 1)
          }, 10000)
        }
      }
      return store.dispatch(getTasksV2(response.data))
    })
}
// this is used for CRM task status mapping
export const advisorTaskStatus = (task: TaskObj) => {
  const statusStyle = (color: string) => `task__status task__status--${color}`
  const isComplete = [
    'Complete',
    'Completed',
    'N/A',
    'Canceled/No Longer Relevant',
    'SENT',
    'Not Moving Forward'
  ]
  const isWaiting = ['Waiting', 'Waiting on someone else', 'Deferred']
  const { status } = task
  if (isComplete.includes(status)) {
    return {
      statusDisplay: 'Completed',
      statusClassName: statusStyle('green')
    }
  } else if (status === 'In Progress' || status === 'Not Started') {
    return {
      statusDisplay: task.status,
      statusClassName: statusStyle('gray')
    }
  } else if (isWaiting.includes(status)) {
    return {
      statusDisplay: 'Waiting',
      statusClassName: statusStyle('orange')
    }
  } else {
    return {
      statusDisplay: task.status,
      statusClassName: statusStyle('gray')
    }
  }
}
// This is used for CRM task status mapping, used on task V1 reducer
export const taskActionTimePeriod = (task: TaskObj) => {
  const statusStyle = (color: string) => `task__status task__status--${color}`
  const daysTillCheckIn = dayjs(task.dueDate).isValid()
    ? dayjs(task.dueDate).diff(dayjs(), 'days')
    : null
  const isComplete =
    dayjs(task.completedDate).isValid() || task.status === 'Completed'
  if (task.assigneeType === 'user') {
    return advisorTaskStatus(task)
  } else if (isComplete) {
    return {
      statusDisplay: 'Completed',
      statusClassName: statusStyle('green')
    }
  } else if (
    dayjs(task.createdDate).isValid() &&
    dayjs().diff(task.createdDate, 'days') < 1
  ) {
    return {
      statusDisplay: 'New',
      statusClassName: statusStyle('green')
    }
  } else if (daysTillCheckIn >= 0 && daysTillCheckIn < 7) {
    return {
      statusDisplay: 'Due Soon',
      statusClassName: statusStyle('orange')
    }
  } else if (daysTillCheckIn < 0) {
    return {
      statusDisplay: 'Past Due',
      statusClassName: statusStyle('red')
    }
  } else {
    return {
      statusDisplay: 'Not Started',
      statusClassName: statusStyle('gray')
    }
  }
}
