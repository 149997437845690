import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import Collapse from 'react-bootstrap/Collapse'
import Button from 'react-bootstrap/Button'

import Histogram from '../../components/Histogram'

import performIcon from '../../assets/images/icons/iv-performance.png'
import protectionIcon from '../../assets/images/icons/iv-protection.png'
import costIcon from '../../assets/images/icons/iv-cost.png'
import taxesIcon from '../../assets/images/icons/iv-taxes.png'
import thresholdIcon from '../../assets/images/icons/iv-target.png'
import optionalRender from '../../components/OptionalRender'

const preferencesIcons = {
  Performance: performIcon,
  Protection: protectionIcon,
  Cost: costIcon,
  Taxes: taxesIcon
}

export type InvestingPreferencesProps = ReturnType<typeof mapStateToProps>

export const InvestingPreferences = (props: InvestingPreferencesProps) => {
  const { priorities, invresponseId } = props
  const [showPreferences, setShow] = useState(false)
  const [showPreferencesLabel, setShowLabel] = useState('Show details')

  useEffect(() => {
    setShowLabel(showPreferences ? 'Hide details' : 'Show details')
  }, [showPreferences])

  const show = () => setShow(!showPreferences)

  const thresholdIconDisplay = (altText: string) => (
    <img
      src={thresholdIcon}
      alt={altText.toString()}
      role='presentation'
      style={{ display: 'inline-block', position: 'relative', top: '-5px' }}
    />
  )

  const histogramLabel = (labelName: string) => (
    <span className='inv-preferences-display__label'>
      <img src={preferencesIcons[labelName]} alt='' role='presentation' />{' '}
      {labelName}
    </span>
  )

  const priority = priorities?.[invresponseId]
  if (!priority) {
    return null
  }

  const histogramDisplay = (preferenceName, preferenceValue) => {
    const preferenceNumber = (preferenceValue / 14) * 100

    return (
      <Histogram
        className='inv-preferences-display__graph'
        totalCount={100}
        stepCount={preferenceNumber || 0}
        thresholdLimit={preferenceNumber || 0}
        showThresholdLabel={true}
        chartLabel={histogramLabel(preferenceName)}
        titleClassName='text-dark fw-bold'
        infoLabelClassName='fs-7'
        showThresholdLabelOnLeft={true}
        thresholdLimitIcon={thresholdIconDisplay(
          `${preferenceName} ${preferenceNumber || 0}%`
        )}
      />
    )
  }

  return (
    <div className='mb-x2'>
      <div className='bg-white mt-4 text-secondary p-3 p-md-x2'>
        <div className='h4 mb-0'>Investing Preferences</div>
        <div className='inv-preferences-display__toggler'>
          <span className='inv-preferences-display__toggler-text'>
            See your results from taking our investing preferences exercise
          </span>
          <Button
            variant='light'
            className='inv-preferences-display__toggler-button'
            onClick={show}>
            {showPreferencesLabel}{' '}
            <i
              className={`inv-preferences-display__toggler-icon bi ${
                showPreferences ? 'bi-chevron-up' : 'bi-chevron-down'
              }`}
            />
          </Button>
        </div>
      </div>

      <Collapse in={showPreferences} appear>
        <div className='bg-white mb-4 mt-2 py-3 pt-md-x2 pb-md-x2 px-3 px-sm-1'>
          <div className='inv-preferences-display__container'>
            <div className='inv-preferences-display__performance'>
              {histogramDisplay('Performance', priority?.performance)}
            </div>
            <div className='inv-preferences-display__protection'>
              {histogramDisplay('Protection', priority?.protection)}
            </div>
            <div className='inv-preferences-display__cost'>
              {histogramDisplay('Cost', priority?.cost)}
            </div>
            <div className='inv-preferences-display__taxes'>
              {histogramDisplay('Taxes', priority?.taxes)}
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { investments } = store
  const { priorities, invresponseId } = investments

  return {
    priorities,
    invresponseId
  }
}

export default connect(mapStateToProps)(
  optionalRender(InvestingPreferences, 'inv')
)
