import React from 'react'
import { connect } from 'react-redux'
import { find } from 'lodash-es'
import ListGroup from 'react-bootstrap/ListGroup'
import { GlobalState } from '../../../reducers'
import { ContactObj, ContactsInterface } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'

type ContactProps = ReturnType<typeof mapStateToProps>

export const getAddress = (contact: ContactObj) => {
  const { street = '', city = '', state = '', zip = '' } = contact

  const addressLine1 = () => street && <li>{street}</li>
  const stateDisplay = state ? `, ${state}` : ''
  const addressLine2 = () => {
    return (
      <li>
        {city}
        {stateDisplay} {zip}
      </li>
    )
  }

  return (
    <ul className='contact-info__address list-unstyled'>
      {addressLine1()}
      {addressLine2()}
    </ul>
  )
}

export const getPhones = (contact: ContactObj) => {
  const { mobile, home, work } = contact
  if (!mobile && !home && !work) {
    return null
  }

  return (
    <ul className='list-unstyled'>
      {home && <li>h: {home}</li>}
      {mobile && <li>m: {mobile}</li>}
      {work && <li>w: {work}</li>}
    </ul>
  )
}

export const getContactDetails = (
  user: UserObj,
  contacts: ContactsInterface
) => {
  const contact = find(contacts, (c) => c.id === user.id)
  if (!contact) {
    return [{ label: 'Email' }, { label: 'Phone Number' }, { label: 'Address' }]
  }
  const { email } = contact
  const address = getAddress(contact)
  const phones = getPhones(contact)

  return [
    { label: 'Email', data: email },
    { label: 'Phone Number', data: phones },
    { label: 'Address', data: address }
  ]
}

const Contact = (props: ContactProps): JSX.Element => {
  const { user, contacts } = props
  const contactDetails = getContactDetails(user, contacts)

  return (
    <div className='contact-info__tile contact-info'>
      <h3 className='rfont--h3 contact-info__tile-title'>Contact Info</h3>
      <ListGroup variant='flush'>
        {contactDetails.map((details) => (
          <ListGroup.Item key={details.label} className='p-0 py-3'>
            <div className='contact-info__row'>
              <span className='contact-info__row-label'>{details.label}</span>
              <span className='contact-info__row-value'>{details.data}</span>
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user, contacts } = store
  return { user, contacts }
}

export default connect(mapStateToProps)(Contact)
