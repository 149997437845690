import { combineReducers } from '@reduxjs/toolkit'
import { InsuranceObj } from '../objects/insurance'
import { UserObj } from '../objects/user'
import { OfficeTeamObj } from '../objects/officeTeam'
import { YodleeObj } from '../objects/yodlee'
// PUT REDUCERS HERE
import auth, { AuthState } from './auth.reducer'
import clientAccounts, { ClientAccountsState } from './clientAccounts.reducer'
import contacts, { ContactsState } from './contacts.reducer'
import documentVault from './documentVault.reducer'
import toasts, { ToastState } from './toasts.reducer'
import financialGoals, { FinancialGoalsState } from './financialGoals.reducer'
import goalRequests, { GoalRequestObjInterface } from './goalRequests.reducer'
import honestConversations, {
  HonestConversationsState
} from './honestConversations.reducer'
import lifeActions, { LifeActionsState } from './lifeActionsState.reducer'
import insurance from './insurance.reducer'
import investments, { InvestmentsState } from './investments.reducer'
import marketplaceOffers from './marketplaceOffers.reducer'
import messages from './messages.reducer'
import officeTeam from './officeTeam.reducer'
import priorityActions, {
  PriorityActionsState
} from './priorityActions.reducer'
import goalActions, { GoalActionState } from './goalActions.reducer'
import { MessagesState } from '../objects/messages'
import { DocVaultState } from '../objects/documentVault'
import tasks from './tasks.reducer'
import tasksV2 from './tasksV2.reducer'
import user from './user.reducer'
import yodlee from './yodlee.reducer'
import * as authActions from '../actions/auth.action'
import { marketplaceOfferRequestInterface } from '../objects/marketplaceOffers'
import { TasksState } from '../objects/task'
import { TasksStateV2 } from '../objects/taskV2'
import { connectRouter, RouterState } from 'connected-react-router'

const appReducer = (history) =>
  combineReducers<GlobalState>({
    auth,
    router: connectRouter(history),
    clientAccounts,
    contacts,
    financialGoals,
    goalRequests,
    priorityActions,
    goalActions,
    honestConversations,
    lifeActions,
    insurance,
    investments,
    marketplaceOffers,
    tasks,
    tasksV2,
    messages,
    user,
    officeTeam,
    yodlee,
    documentVault,
    toasts
  })

export interface GlobalState {
  auth?: AuthState
  router?: RouterState
  clientAccounts?: ClientAccountsState
  contacts?: ContactsState
  financialGoals?: FinancialGoalsState
  goalRequests?: GoalRequestObjInterface
  priorityActions?: PriorityActionsState
  goalActions?: GoalActionState
  honestConversations?: HonestConversationsState
  investments?: InvestmentsState
  marketplaceOffers?: marketplaceOfferRequestInterface
  tasks?: TasksState
  tasksV2?: TasksStateV2
  messages?: MessagesState
  user?: UserObj
  officeTeam?: OfficeTeamObj
  yodlee: YodleeObj
  documentVault?: DocVaultState
  insurance: InsuranceObj[]
  toasts?: ToastState
  lifeActions?: LifeActionsState
}
export interface LoadingStatus {
  isLoading: boolean
  isLoaded: boolean
  hasFailed: boolean
}

const rootReducer = (history) => (state: GlobalState, action: any) => {
  if (action.type === authActions.REVOKE_ACCESS_TOKEN) {
    state = undefined
  }

  return appReducer(history)(state, action)
}

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
