import React from 'react'
import { isEmpty } from 'lodash-es'
import { HonestConversationsNullProps } from './NullState'
import Priorities from './Priorities'
import { ClientCardsData } from '../../../objects/honestConversation'
import bgUpper from '../../../assets/images/backgroundlogo.png'

const sentLink =
  'Your advisor sent you the link to HonestConversations exercise.'
const progress =
  'When both you and your partner choose personal priorities, you can proceed to making shared priorities.'

export const descriptions = {
  single: {
    progress: `${sentLink} You can proceed to choosing your priorities.`,
    completed:
      'You need to add comments and actions in a meeting with your advisor.'
  },
  couple: {
    progress: `${sentLink} ${progress}`,
    userCompleted: progress,
    completed:
      'Your advisor will contact you about setting your shared priorities.'
  }
}

const getCoupleDescription = (
  rankedTopCards: boolean,
  user: ClientCardsData,
  partner: ClientCardsData
) => {
  const { couple } = descriptions
  const userComplete = !isEmpty(user.rankedTopCards)
  const partnerComplete = !isEmpty(partner.rankedTopCards)

  if (rankedTopCards) {
    return couple.completed
  }
  if (userComplete && !partnerComplete) {
    return couple.userCompleted
  }
  if (userComplete && partnerComplete) {
    return couple.completed
  }
  return couple.progress
}

const getSingleDescription = (rankedTopCards: boolean) => {
  const { single } = descriptions
  if (rankedTopCards) {
    return single.completed
  }
  return single.progress
}

const InProgress = (props: HonestConversationsNullProps) => {
  const { user, partner, isSingleHH, rankedTopCards } = props

  const text = isSingleHH
    ? getSingleDescription(rankedTopCards)
    : getCoupleDescription(rankedTopCards, user, partner)

  return (
    <div className='hc-null-progress'>
      <img
        className='hc-null__bg-image--upper'
        src={bgUpper}
        alt='background'
      />
      <div id='hc-null-title' className='hc-null__main-title'>
        <h1 className='hc-null-progress__title'>HonestConversations®</h1>
        <div className='hc-null-progress__description'>{text}</div>
      </div>
      <Priorities
        title='Your Priorities'
        rankedTopCards={user.rankedTopCards}
        rankedAnyCards={user.rankedAnyCards}
        emailSent={user.emailSent}
        redirect={user.redirectPath}
        accessToken={user.accessToken}
        currentUser
      />
      {!isSingleHH && (
        <Priorities
          title={`${partner.firstName}'s Priorities`}
          rankedTopCards={partner.rankedTopCards}
          emailSent={partner.emailSent}
          rankedAnyCards={partner.rankedAnyCards}
        />
      )}
    </div>
  )
}

export default InProgress
