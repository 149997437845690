import React, { useState, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'

import { GlobalState } from '../../reducers'

import * as actions from '../../actions/documentVault.action'
import {
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
} from '../../actions/toasts.action'

import { history } from '../../store'

import { docVaultSelector } from '../../selectors/docvault'

import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import { getDocVaultTableRow } from './VaultTableRows'

import CreateFolder from './CreateFolder'
import NullDocVault from './NullView'
import DeleteModal from './DeleteModal'
import EditModal from './EditModal'
import MoveModal from './MoveModal'

import {
  downloadFile,
  navigatorPlatformTypeEquals,
  useModalReducer,
  ModalActions
} from '../../helpers/docvault'

import blackDiamondBrandmark from '../../assets/images/logos/partnerLogos/black-diamond-white.png'
import { useWindowSize } from '../../hooks'

export type DocumentVaultProps = ConnectedProps<typeof connector>

const DocumentVault = (props: DocumentVaultProps): JSX.Element => {
  const { modalState, handleModal } = useModalReducer()
  const [currentId, setCurrentId] = useState('')
  const { width } = useWindowSize()

  const {
    docVaultItems,
    showBlackDiamondVault,
    match,
    search,
    getFolders,
    getFolder,
    updateVaultSearch,
    editFolder,
    editFile,
    updateArchivedFile,
    moveFile,
    deleteFolder,
    clearNewFolders,
    addSuccessToast,
    addErrorToast,
    networkErrorOrMsg
  } = props

  useEffect(() => {
    getFolders()
    return () => {
      updateVaultSearch('')
    }
  }, [])

  useEffect(() => {
    return () => {
      clearNewFolders()
    }
  }, [])

  const toggleModal = (
    id: string = '',
    action?: 'edit' | 'delete' | 'move',
    type?: 'file' | 'folder' | 'error'
  ) => {
    setCurrentId(id)
    if (action && type) {
      const modalAction = `show_${action}_${type}` as ModalActions
      handleModal(modalAction)
    } else {
      handleModal('close_modal')
    }
  }

  const deleteFolderFn = async (id: string) => {
    toggleModal()
    try {
      await deleteFolder(id, true)
      getFolders()
      addSuccessToast({ message: 'Folder deleted.' })
    } catch {
      addErrorToast({ message: 'Unable to delete folder' })
    }
  }

  const editFolderFn = async (id: string, name: string) => {
    toggleModal()
    try {
      await editFolder(id, name)
      addSuccessToast({ message: 'Folder renamed.' })
    } catch {
      addErrorToast({
        message: 'Unable to rename folder'
      })
    }
  }

  const deleteFileFn = async (id: string) => {
    if (id) {
      const folderId = getFolderId(id)
      try {
        await updateArchivedFile(folderId, id, true)
        addSuccessToast({ message: 'File deleted.' })
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to delete file')
        getFolder(folderId)
      }
    }
    toggleModal()
  }

  const editFileFn = async (id: string, name: string) => {
    const folderId = getFolderId(id)
    try {
      await editFile(folderId, id, name.replace(/ /g, '_'))
      addSuccessToast({
        message: 'File renamed.'
      })
    } catch (error) {
      networkErrorOrMsg(error, 'Unable to rename file')
      getFolder(folderId)
    }
    toggleModal()
  }

  const moveFn = async (id: string, newFolderId: string) => {
    if (newFolderId) {
      const oldFolderId = getFolderId(id)
      try {
        await moveFile(oldFolderId, id, newFolderId)
        addSuccessToast({ message: 'File moved.' })
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to move file')
        getFolder(oldFolderId)
      }
    }
    toggleModal()
  }

  const generateTableData = () => {
    const { newFolders } = props
    return docVaultItems
      .map((item) => ({
        ...item,
        content: {
          ...item.content,
          newFolders,
          toggleModal
        }
      }))
      .filter((item) => item.content.archived === false)
  }

  const getCurrentName = () => {
    if (!currentId) return ''
    return docVaultItems.find((el) => el.id === currentId)?.content?.name
  }

  const getFolderId = (fileId: string) => {
    if (!fileId) return ''
    const currentFile = docVaultItems.find((el) => el.id === fileId)
    if (currentFile && 'folderId' in currentFile.content) {
      return currentFile.content.folderId
    }
    return ''
  }

  const handleDownloadFile = (
    folderId: string,
    fileId: string,
    fileName: string
  ) => {
    if (
      navigatorPlatformTypeEquals('iPhone') ||
      navigatorPlatformTypeEquals('iPad')
    ) {
      addErrorToast({
        message: `You can't download vault files on your tablet through the browser version of GuideCenter.`
      })
      return
    }
    try {
      downloadFile(folderId, fileId, fileName)
    } catch {}
  }

  const handleRowClick = (row) => {
    const { type, id, content } = row
    if (type === 'folder') history.push(`${match.url}/${row.id}`)
    if (type === 'file')
      handleDownloadFile(content?.folderId, id, content?.name)
  }

  const handleClickTrashPage = () => {
    history.push(`${match.url}/trash`)
  }

  const navigateToBlkD = () => {
    window.open(
      'https://login.bdreporting.com/',
      '_blank',
      'noopener,noreferrer'
    )
  }

  const tableData = generateTableData()
  const currentName = getCurrentName()
  const currentFolderId = getFolderId(currentId)
  const docVaultTableRow = getDocVaultTableRow(width)

  const blackDiamondBtnLabel = (
    <>
      <img
        className='btn__img vault__btn--blackdiamond'
        src={blackDiamondBrandmark}
        alt='black-diamond-brandmark'
      />
      <span>Black Diamond</span>
    </>
  )

  return (
    <div>
      <div className='d-flex flex-wrap justify-content-start align-items-center px-4 px-sm-0 mb-4'>
        <h2 className='rfont--h2 fw-bold text-dark text-break flex-basis--12 flex-basis-lg--3 text-wrap mb-3 mb-lg-0'>
          My Documents
        </h2>

        <div className='vault-search__container flex-basis--12 flex-basis-sm--6 flex-basis-lg--4'>
          <Form.Control
            size='lg'
            className='vault-search__input mb-3 mb-sm-0'
            defaultValue={search}
            onChange={(evt) => {
              updateVaultSearch(evt.target.value)
            }}
            name='vault-search'
            type='text'
            placeholder='Search Folders, Files...'
          />
        </div>

        <div className='flex-basis--12 flex-basis-sm--6 flex-basis-lg--5 d-flex flex-column justify-content-center flex-sm-row justify-content-sm-end'>
          {showBlackDiamondVault && (
            <Button
              variant='primary'
              size='lg'
              onClick={navigateToBlkD}
              className='vault__btn match-btn-width mb-3 mb-sm-0 ms-0 ms-sm-2'>
              {blackDiamondBtnLabel}
            </Button>
          )}
          <CreateFolder />
        </div>
      </div>
      <div id='doc-vault-table-container' className='bg-white p-2 p-sm-x2 mb-9'>
        {tableData.length ? (
          <table id='file-vault-table' className='vault__table'>
            <tbody>
              {tableData.map((rowData) => {
                return (
                  <tr
                    key={rowData.id}
                    role='button'
                    className='vault__table-row vault__table-row--button'>
                    {docVaultTableRow.map((cell) => (
                      <td
                        key={cell.Cell.name + rowData.id}
                        className='vault__table-cell'
                        style={cell.style}
                        onClick={() => {
                          !cell.overflowable && handleRowClick(rowData)
                        }}>
                        {<cell.Cell {...rowData} />}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <NullDocVault
            headerText='No results'
            supportText='There are no files or folders matching the search term.'
          />
        )}
      </div>

      <div
        id='doc-vault-trash-table-container-index'
        className='trash__card p-3 p-sm-x2 pb-6 pb-sm-x2'>
        <div className='px-5 px-sm-0 d-flex flex-column flex-sm-row justify-content-between'>
          <div className='mb-4'>
            <h2 className='rfont--h2 text-dark fw-bold mb-3 mb-lg-0'>Trash</h2>
            <span className='fs-7'>Review and restore deleted files.</span>
          </div>
          <div>
            <Button
              type='button'
              variant='outline-primary'
              onClick={handleClickTrashPage}
              className='trash__btn fw-bold fs-6'>
              View Deleted Items
            </Button>
          </div>
        </div>
      </div>

      <DeleteModal
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
        showModal={modalState.showDeleteFolder}
        type='folder'
        id={currentId}
        deleteModal={deleteFolderFn}
        closeModal={toggleModal}
      />

      <EditModal
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
        showModal={modalState.showEditFolder}
        type='folder'
        id={currentId}
        name={currentName}
        editModal={editFolderFn}
        closeModal={toggleModal}
      />

      <EditModal
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
        showModal={modalState.showEditFile}
        type='file'
        id={currentId}
        name={currentName}
        editModal={editFileFn}
        closeModal={toggleModal}
      />

      <DeleteModal
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
        showModal={modalState.showDeleteFile}
        type='file'
        id={currentId}
        deleteModal={deleteFileFn}
        closeModal={toggleModal}
      />

      <MoveModal
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
        id={currentId}
        showModal={modalState.showMoveFile}
        oldFolderId={currentFolderId}
        moveModal={moveFn}
        closeModal={toggleModal}
      />
    </div>
  )
}

const mapStateToProps = (
  store: GlobalState,
  { match }: RouteComponentProps
) => {
  return {
    newFolders: store.documentVault.newFolders,
    search: store.documentVault.search,
    docVaultItems: docVaultSelector(store),
    match,
    showBlackDiamondVault: store.user.showBlackDiamondVault
  }
}

const mapDispatchToProps = {
  updateVaultSearch: actions.updateVaultSearch,
  editFolder: actions.editFolder,
  getFolders: actions.getFolders,
  getFolder: actions.getFolder,
  deleteFolder: actions.deleteFolder,
  editFile: actions.editFile,
  updateArchivedFile: actions.updateArchivedFile,
  moveFile: actions.moveFile,
  clearNewFolders: actions.clearNewFolders,
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default withRouter(connector(DocumentVault))
