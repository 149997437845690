import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
// get actions
import { getInvestmentAccounts } from '../../actions/clientAccounts.action'
import { getHousehold } from '../../actions/household.action'
import {
  getPssSummary,
  getInvestmentPreferences
} from '../../actions/investments.action'
import { getOfficeTeam } from '../../actions/officeteam.action'
// get reducer
import { GlobalState } from '../../reducers'
import { getInvestments } from '../../selectors/investments'
//
import { allowedPartners } from '../../objects/partners'
// V3 PAGE
import InvestingPreferences from './InvestingPreferences'
// CONTAINER PAGE
import InvestmentHero from './InvestmentHero'
import PartnerLoginTile from './PartnerLoginTile'
import AccountsTable from './AccountsTable'

type InvestmentsActions = typeof mapDispatchToProps
type InvestmentsStore = ReturnType<typeof mapStateToProps>
export type InvestmentsProps = InvestmentsActions & InvestmentsStore

export const Investments = (props: InvestmentsProps) => {
  const {
    accounts,
    noAccounts,
    getInvestmentAccounts,
    getInvestmentPreferences,
    getHousehold,
    getPssSummary,
    getOfficeTeam,
    locationPath,
    partners
  } = props

  useEffect(() => {
    getInvestmentAccounts()
    getInvestmentPreferences()
    getHousehold()
    getPssSummary()
    getOfficeTeam()
  }, [])

  const filteredPartners = allowedPartners.reduce(
    (displayablePartners, partner) => {
      if (partners?.includes(partner)) {
        displayablePartners.push(partners)
      }
      return displayablePartners
    },
    []
  )

  return (
    <div>
      <InvestmentHero />
      <div
        className={`investment-accounts${noAccounts ? '--no-accounts' : ''}`}>
        <AccountsTable accounts={accounts} noAccounts={noAccounts} />

        {filteredPartners?.length > 0 && <PartnerLoginTile />}

        <InvestingPreferences />

        <div className='investment-footer'>
          <p className='disclosures__text--footer'>
            The information contained in this material is for general
            informational purposes only and does not constitute an offer,
            solicitation, invitation or recommendation to buy or sell any
            financial instruments or to provide any investment advice or service
            of any kind.
          </p>
          <Link
            to={{
              pathname: '/disclosures',
              state: {
                section: 'investment',
                prevPath: locationPath
              }
            }}
            className='investment-footer__link'>
            Click for more important information regarding this table
          </Link>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  getInvestmentAccounts,
  getInvestmentPreferences,
  getHousehold,
  getPssSummary,
  getOfficeTeam
}

const mapStateToProps = (store: GlobalState) => {
  const { accounts, noAccounts } = getInvestments(store)
  return {
    partners: store.investments.partners,
    accounts,
    locationPath: store?.router?.location?.pathname,
    noAccounts
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Investments)
