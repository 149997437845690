import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DashTileNull from '../../components/Dashboard/DashTileNull'
import DashTile, { DashTileLink } from '../../components/Dashboard/DashTile'
import { GlobalState } from '../../reducers'
import * as householdActions from '../../actions/household.action'
import * as UserActions from '../../actions/user.action'
import MoneyGuideProIcon from '../../assets/images/logos/planLogos/mgp-logo.png'
import { ReactComponent as EMoneyIcon } from '../../assets/images/logos/planLogos/emoney-logo-coolgray.svg'
import { ReactComponent as GDX360Icon } from '../../assets/images/logos/planLogos/gdx-360-logo.svg'
import { PlanScoreBar } from '../../components/Plan'
import { planningSoftwares } from '../../helpers/dashboard'

type DashPlanActions = typeof mapDispatchToProps
type DashPlanStore = ReturnType<typeof mapStateToProps>
export type DashPlanProps = DashPlanActions & DashPlanStore

const links: DashTileLink[] = [
  { label: 'Review Financial Plan', route: '/financial-plan' }
]

const getLogo = (fundedScores) => {
  const latestScore = fundedScores[0]
  const planningSoftware = latestScore?.source
  switch (planningSoftware) {
    case 'emoney':
    case 'emoney_enterprise':
      return <EMoneyIcon />
    case 'moneyguidepro':
      return <img src={MoneyGuideProIcon} alt='moneyguidepro' width={100} />
    case 'gdx360':
      return <GDX360Icon />
    default:
      return null
  }
}

export const PlanNullState = () => (
  <DashTileNull
    className='dashboard-plan-tile__null'
    title='No Plan Score'
    description='Contact your advisor to discuss and have a financial plan created for you.'
  />
)

export const PlanDisplay = (props) => {
  const { fundedScores } = props
  const latestScore = fundedScores[0]
  return (
    <div className='my-4'>
      {planningSoftwares.includes(latestScore.source) ? (
        <PlanScoreBar
          score={latestScore?.score && Math.round(latestScore.score)}
          minScore={latestScore?.rangeLow}
          maxScore={latestScore?.rangeHigh}
          source={latestScore?.source}
          light={false}
          isNull={!latestScore}
        />
      ) : null}
    </div>
  )
}

export const PlanTile = (props) => {
  const { fundedScores, getHousehold, getInstitution } = props
  useEffect(() => {
    getHousehold()
    getInstitution()
  }, [])

  const noPlan = !fundedScores || fundedScores.length === 0
  return (
    <DashTile
      title='Financial Plan'
      links={links}
      nullComponent={<PlanNullState />}
      showNull={noPlan}
      imageRight={noPlan ? null : getLogo(fundedScores)}>
      {fundedScores && <PlanDisplay fundedScores={fundedScores} />}
    </DashTile>
  )
}

const mapDispatchToProps = {
  getHousehold: householdActions.getHousehold,
  getInstitution: UserActions.getInstitution
}
const mapStateToProps = (store: GlobalState) => {
  return {
    fundedScores: store.financialGoals.fundedScores
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PlanTile)
