import React from 'react'
import { OfficeTeamObj } from '../../objects/officeTeam'
import DashTile from '../../components/Dashboard/DashTile'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { formatPhoneNumber } from '../../helpers'
import { useWindowSize } from '../../hooks'
import SlickCarousel from '../../components/SlickCarousel'
import ProfilePicture from '../../components/ProfilePicture'

export interface TeamProps {
  team: OfficeTeamObj
}

export const OfficeTeamDisplay = (props: TeamProps) => {
  const { team } = props
  const { width } = useWindowSize()
  let maxPossibleSlidesToShow = 1
  if (width > 640) {
    maxPossibleSlidesToShow = 2
    if (width > 768) {
      maxPossibleSlidesToShow = 3
    }
  }
  // Want to show a maximum of 3 slides at a time
  const slidesToShow = Math.min(team.members.length, maxPossibleSlidesToShow)

  const renderSlides = () =>
    team.members
      .filter((mem) => mem.user)
      .sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
      .map((member, index) => {
        const phoneNumber =
          formatPhoneNumber(member.user.phone?.phone) ||
          formatPhoneNumber(member.user.phone?.mobile) ||
          ''
        const advisor = member.user
        return (
          <div key={index} className='dashboard-team__row'>
            <div className='dashboard-team__avatar'>
              <ProfilePicture
                photo={advisor.photoUrl}
                firstName={`${advisor.firstName}`}
                lastName={`${advisor.lastName}`}
                isCarousel={true}
                isPrimary={false}
                containerStyle={{ width: '64px', height: '64px' }}
              />
              <div className='dashboard-team__info-w'>
                <span className='dashboard-team__name'>
                  {advisor.firstName} {advisor.lastName}
                </span>
                <span className='dashboard-team__phone'>
                  {phoneNumber && <div>{phoneNumber}</div>}
                </span>
                <div className='dashboard-team__email'>
                  {advisor.email && (
                    <a href={`mailto:${advisor.email}`} role='button'>
                      Send email
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      })

  return (
    <SlickCarousel
      autoplay={false}
      autoplaySpeed={8000}
      slides={renderSlides}
      slidesToScroll={maxPossibleSlidesToShow}
      slidesToShow={slidesToShow}
      sliderClassName='mb-x2 w-100'
    />
  )
}

export const TeamTile = ({ team }: TeamProps) => {
  return team?.members?.length ? (
    <DashTile className='dashboard-team' title='Office Team'>
      <OfficeTeamDisplay team={team} />
    </DashTile>
  ) : null
}

const mapStateToProps = (store: GlobalState) => {
  return {
    team: store.officeTeam
  }
}

export default connect(mapStateToProps)(TeamTile)
