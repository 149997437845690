import { reduce } from 'lodash-es'
import dayjs from 'dayjs'
import { createDeepEqualSelector } from './utilz'
import { GlobalState } from '../reducers'
import { FinancialGoalsByType, FinancialGoalsSort } from '../objects/plan'
import * as SortHelper from '../helpers/sort'
import {
  sortTypes,
  sortGoals,
  sortTypeToGoalType,
  defaultByType
} from '../helpers/plan'

const defaultSortValue = 'Name'

const getGoals = (store: GlobalState) => store.financialGoals.goalsById

const getFundedScores = (store: GlobalState) =>
  store.financialGoals.fundedScores

const getSortPreferences = (store: GlobalState) => {
  const prefs = store.financialGoals.preferences || {}

  const sortPrefs: FinancialGoalsSort = sortTypes.reduce((p, sortType) => {
    const key = sortTypeToGoalType[sortType]
    const sortValue = prefs[`goals${sortType}SortBy`] || defaultSortValue
    const descending =
      prefs[`goals${sortType}SortingOrder`] === SortHelper.DESCENDING

    p[key] = {
      sortValue,
      descending
    }

    return p
  }, {})

  return sortPrefs
}

export const getPlanInputs = createDeepEqualSelector(
  getGoals,
  getSortPreferences,
  (goalsById, preferences) => {
    const defaultGoalsByType = defaultByType()

    const goalsByType: FinancialGoalsByType = reduce(
      goalsById,
      (p, goal) => {
        if (!p[goal.type]) {
          return p
        }
        p[goal.type] = [...p[goal.type], goal]
        return p
      },
      defaultGoalsByType
    )

    return {
      goalsById,
      goalsByType: sortGoals(goalsByType, preferences)
    }
  }
)

export const getPlanningSoftware = createDeepEqualSelector(
  getFundedScores,
  (fundedScores) => {
    const latestFundedScore =
      fundedScores && fundedScores[0] ? fundedScores[0] : null
    return latestFundedScore?.source ? latestFundedScore.source : null
  }
)

export const filteredFundedScores = createDeepEqualSelector(
  getFundedScores,
  (fundedScores) => {
    return fundedScores
      ?.filter((score, i) => i === 0 || score.inGuidebook === true)
      .sort((scoreA, scoreB) =>
        dayjs(scoreA.scoreDate).isBefore(scoreB.scoreDate) ? 1 : 0
      )
      .slice(0, 4)
      .reverse()
  }
)

export const annotateYRange = createDeepEqualSelector(
  getFundedScores,
  (fundedScores) => {
    const latestFundedScore =
      fundedScores && fundedScores[0] ? fundedScores[0] : null
    const planningSoftware = latestFundedScore?.source
      ? latestFundedScore.source
      : null
    const lowRange = () => {
      if (!latestFundedScore) return 75
      return planningSoftware === 'gdx360' || !latestFundedScore.rangeLow
        ? 75
        : latestFundedScore.rangeLow
    }
    const highRange = () => {
      if (!latestFundedScore) return 90
      return planningSoftware === 'gdx360' || !latestFundedScore.rangeLow
        ? 90
        : latestFundedScore.rangeLow
    }
    return { rangeLow: lowRange(), rangeHigh: highRange() }
  }
)
export const hasActiveGoals = (store) => {
  const goals = getPlanInputs(store).goalsByType
  return Boolean(
    Object.values(goals).reduce((a, e) => [...a, ...e], [])?.length
  )
}
