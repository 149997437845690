import gql from 'graphql-tag'

export const MessageBodyQuery = gql`
  query Messages($householdFinId: UUID!, $threadId: UUID!) {
    allMessages(
      condition: { household: $householdFinId }
      orderBy: [CREATED_DATE_ASC]
      filter: {
        or: [{ parent: { equalTo: $threadId } }, { id: { equalTo: $threadId } }]
      }
    ) {
      nodes {
        id
        message
        adviserByAdviserSender {
          adviser
          name
        }
        clientByClientSender {
          client
          name
        }
        createdDate
        videoId
        thumbnailId
      }
    }
  }
`

export const CreateMessageReplyQuery = gql`
  mutation CreateMessageReply(
    $value: String!
    $userId: UUID!
    $householdFinId: UUID!
    $threadId: UUID!
  ) {
    createMessage(
      input: {
        message: {
          message: $value
          clientSender: $userId
          household: $householdFinId
          parent: $threadId
        }
      }
    ) {
      message {
        subject
        message
        clientSender
      }
    }
  }
`

export const CreateMessageThreadQuery = gql`
  mutation CreateMessageThread(
    $message: String!
    $userId: UUID!
    $householdFinId: UUID!
    $subject: String!
  ) {
    createMessage(
      input: {
        message: {
          message: $message
          clientSender: $userId
          household: $householdFinId
          subject: $subject
        }
      }
    ) {
      message {
        id
        subject
        message
        clientSender
      }
    }
  }
`

export const GetMessageThreadQuery = gql`
  query MessageThreads($householdFinId: UUID!) {
    allMessages(condition: { household: $householdFinId, parent: null }) {
      nodes {
        id
        subject
        messagesByParent(orderBy: [CREATED_DATE_ASC], first: 1) {
          nodes {
            createdDate
          }
        }
        createdDate
        household
      }
    }
  }
`

export const LatestMessageThreadQuery = gql`
  query LatestMessageThread($householdId: UUID!) {
    allMessages(condition: { household: $householdId, parent: null }) {
      nodes {
        id
        adviserByAdviserSender {
          adviser
          name
        }
        clientByClientSender {
          client
          name
        }
        subject
        message
        messagesByParent(orderBy: [CREATED_DATE_DESC], first: 1) {
          nodes {
            id
            adviserByAdviserSender {
              adviser
              name
            }
            clientByClientSender {
              client
              name
            }
            createdDate
            subject
            message
          }
        }
        createdDate
      }
    }
  }
`

export const GetAdvisorNotificationSettingsQuery = gql`
  query getAdviserNotificationSettings($userId: String!) {
    allAdvisers(condition: { adviser: $userId }) {
      nodes {
        subject
      }
    }
  }
`

export const SetAdvisorNotificationSettingsQuery = gql`
  mutation setAdviserNotificationSettings(
    $settings: [NotificationSubjects]
    $userId: String!
  ) {
    updateAdviserByAdviser(
      input: { adviser: $userId, adviserPatch: { subject: $settings } }
    ) {
      adviser {
        subject
      }
    }
  }
`

export const GetClientNotificationSettingsQuery = gql`
  query getClientNotificationSettings($clientId: UUID!) {
    allClients(condition: { client: $clientId }) {
      nodes {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`
export const ClientNotificationSettingsSubscription = gql`
  subscription {
    notification {
      desktopSubject
      emailSubject
      mobileSubject
    }
  }
`

export const SetClientNotificationSettingsQuery = gql`
  mutation setClientNotificationSettings(
    $desktopSettings: [NotificationSubjects]
    $emailSettings: [NotificationSubjects]
    $mobileSettings: [NotificationSubjects]
    $clientId: UUID!
  ) {
    updateClientByClient(
      input: {
        client: $clientId
        clientPatch: {
          desktopSubject: $desktopSettings
          emailSubject: $emailSettings
          mobileSubject: $mobileSettings
        }
      }
    ) {
      client {
        desktopSubject
        emailSubject
        mobileSubject
      }
    }
  }
`

export const GetClientNotifications = gql`
  query getClientNotifications($userId: UUID!) {
    allClientToNotifications(
      condition: { client: $userId }
      orderBy: [CREATED_DATE_DESC]
    ) {
      nodes {
        notificationByNotification {
          subject
          content
          createdDate
          title
        }
        read
        readDate
        delivered
        deliveredDate
        id
      }
    }
  }
`

export const ClientNotificationSubscription = gql`
  subscription {
    notification {
      id
      subject
      content
      createdDate
      title
    }
  }
`

export const MarkClientNotificationAsRead = gql`
  mutation markClientNotificationAsRead($notificationId: UUID!) {
    updateClientToNotificationById(
      input: { id: $notificationId, clientToNotificationPatch: { read: true } }
    ) {
      clientMutationId
      clientToNotification {
        id
        read
      }
    }
  }
`

export const MarkClientNotificationAsReadDelivered = gql`
  mutation markClientNotificationAsRead(
    $notificationId: UUID!
    $readDate: Datetime
  ) {
    updateClientToNotificationById(
      input: {
        id: $notificationId
        clientToNotificationPatch: {
          read: true
          delivered: true
          readDate: $readDate
        }
      }
    ) {
      clientMutationId
      clientToNotification {
        id
        read
        delivered
        readDate
      }
    }
  }
`

export const MarkNotificationsReadMutation = gql`
  mutation markRead($userId: UUID!, $ids: [UUID]!, $readDate: Datetime) {
    markClientNotificationsRead(
      input: { userId: $userId, ids: $ids, readDate: $readDate }
    ) {
      data {
        notificationByNotification {
          subject
          content
          createdDate
          title
        }
        read
        readDate
        delivered
        deliveredDate
        id
      }
    }
  }
`
