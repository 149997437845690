import React from 'react'
import Button from 'react-bootstrap/Button'
import { persistentSort } from '../../../helpers/moneyMind'
import { MmHeroProps } from '../../../objects/moneyMind'

export const MoneyMindTitle = (props: MmHeroProps) => {
  const {
    displayPrimary,
    contactName,
    updateUserView,
    moneymind,
    buttonLink
  } = props
  const sortedMoneyMind = persistentSort([
    ['Protection', moneymind?.fear || 0],
    ['Commitment', moneymind?.commitment || 0],
    ['Happiness', moneymind?.happiness || 0]
  ])
  const displayBackLink = displayPrimary ? null : (
    <Button
      type='button'
      variant=''
      onClick={() => updateUserView()}
      style={{ color: '#22263F', width: 'min-content', zIndex: 1 }}
      className='d-flex'>
      <i className='bi bi-chevron-left me-2' />
      Back
    </Button>
  )

  return sortedMoneyMind[0][1] === sortedMoneyMind[1][1] ? (
    <div className='mm-hero__title-w'>
      <div className='mm-hero__title mm-hero__title-col '>
        {displayBackLink}
        <div className='mm-hero__title'>
          {!displayPrimary ? (
            `${contactName || ''}'s MoneyMind® is`
          ) : (
            <span>Your MoneyMind® is a blend of</span>
          )}
        </div>
        <div className='mm-hero__title-text'>
          <span className={`mm-hero__title-text--${sortedMoneyMind[0][0]}`}>
            {sortedMoneyMind[0][0]}
          </span>
          <span className='mm-hero__title-plus'> + </span>
          <span className={`mm-hero__title-text--${sortedMoneyMind[1][0]}`}>
            {sortedMoneyMind[1][0]}
          </span>
        </div>
      </div>
      <div>
        <Button
          size='lg'
          className='mm-hero__btn match-btn-width'
          type='button'
          onClick={() => buttonLink()}>
          Retake
        </Button>
      </div>
    </div>
  ) : (
    <div className='mm-hero__title-w'>
      <div className='mm-hero__title mm-hero__title-col '>
        {displayBackLink}
        <div className='mm-hero__title'>
          {!displayPrimary ? (
            `${contactName || ''}'s MoneyMind® is`
          ) : (
            <span>Your MoneyMind® is</span>
          )}
        </div>
        <div
          className={`mm-hero__title-text mm-hero__title-text--${sortedMoneyMind[0][0]}`}>
          {sortedMoneyMind[0][0]}
        </div>
      </div>
      {displayPrimary ? (
        <div>
          <Button
            size='lg'
            className='mm-hero__btn match-btn-width'
            type='button'
            onClick={() => buttonLink()}>
            Retake
          </Button>
        </div>
      ) : null}
    </div>
  )
}
