import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { fileRenameSchema, folderRenameSchema } from '../../utils/validators'
import { clickOrDisabledKey, splitExtension } from '../../helpers/docvault'
import TextInput from '../../components/TextInput'
import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface EditModalProps {
  files?: any
  id: string
  name: string
  type: string
  editModal(id: string, name: string, folderId?: string): void
  closeModal(id: string, name: string): void
}

interface EditModalState {
  name: string
  ext: string
  inputValue: string
  disableButton: boolean
}

const FileExtInfo = () => (
  <div className='text-dark fw-bold fs-7'>
    <i className='bi bi-exclamation-circle fs-5 me-2 text-info' />
    File type cannot be changed
  </div>
)

class EditModal extends React.Component<EditModalProps, EditModalState> {
  constructor(props: any) {
    super(props)
    const extension = this.isFolder() ? null : splitExtension(props.name).ext
    this.state = {
      name: props.name,
      ext: extension,
      inputValue: props.name || '',
      disableButton: true
    }
  }

  public editFn = (e) => {
    e.preventDefault()
    const { id, editModal } = this.props
    const { inputValue } = this.state
    editModal(id, inputValue)
  }

  public closeFn = () => {
    this.props.closeModal('', '')
  }

  public editName = (inputObj: { isValid: boolean; inputValue: string }) => {
    this.setState({
      inputValue: inputObj.inputValue,
      disableButton: !inputObj.isValid
    })
  }

  public isFolder = () => {
    const { type } = this.props
    return type === 'folder'
  }

  public fileExtensionMask = (value: string) => {
    const { ext } = this.state
    if (this.isFolder() || !ext) return value
    const { name } = splitExtension(value)
    return name + ext
  }
  /**
   * does not allow input caret to go past mask inside of input
   * onClick, using arrow key or Delete key
   * @param e - keyboard or mouse event
   */
  public handleFileExtCaret = (e) => {
    const { ext, inputValue } = this.state
    if (this.isFolder() || !ext) return
    if (clickOrDisabledKey(e)) {
      const caretPos = inputValue.length - ext.length
      const input = e.target as HTMLInputElement
      if (input.selectionStart >= caretPos) {
        e.preventDefault()
        e.stopPropagation()
        input.setSelectionRange(caretPos, caretPos)
      }
    }
  }

  public render() {
    const { name, disableButton } = this.state
    const { files } = this.props
    const isFolder = this.isFolder()

    //propose: existing file validation
    const typeMatchFiles = files && {
      currentName: this.props.name,
      names: files.map(({ content }) => content.name)
    }

    return (
      <>
        <h1 className='vault-modal__title'>
          {isFolder ? 'Rename Folder' : 'Rename File'}
        </h1>
        <Form onSubmit={this.editFn}>
          <TextInput
            defaultValue={name ? name : ''}
            name={isFolder ? 'folder-name' : 'file-name'}
            className={isFolder ? 'pb-3' : 'vault-modal__file-name-input'}
            label={isFolder ? 'Folder name' : 'File name'}
            placeholder={isFolder ? 'Enter a folder name' : 'Enter a file name'}
            callBack={this.editName}
            validator={isFolder ? folderRenameSchema : fileRenameSchema}
            typeMatch={typeMatchFiles}
            mask={this.fileExtensionMask}
            allowedChars={/(?:)/}
            onClick={this.handleFileExtCaret}
            onKeyDown={this.handleFileExtCaret}
          />
          {!isFolder && <FileExtInfo />}
          <div className='vault-modal__buttons'>
            <Button
              id='edit-modal-save'
              variant='primary'
              size='lg'
              disabled={disableButton}
              className='match-btn-width'
              type='submit'>
              Save
            </Button>
            <Button
              id='edit-modal-cancel'
              variant='link'
              onClick={this.closeFn}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

export default ControlledModalWrapper(EditModal)
