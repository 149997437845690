import React from 'react'
import { Modal, Button } from 'react-bootstrap'

interface DeleteAccountModalProps {
  deleteAccount(): void
  closeModal(): void
  show: boolean
}

class DeleteAccountModal extends React.Component<DeleteAccountModalProps> {
  public render() {
    const { closeModal, deleteAccount, show } = this.props

    return (
      <Modal
        className='networth-delete-account'
        backdrop='static'
        onHide={closeModal}
        contentClassName='p-x2'
        show={show}>
        <Modal.Header className='p-0' closeButton>
          <h3 className='networth-delete-account__title'>Delete Account</h3>
        </Modal.Header>
        <div className='networth-delete-account__subtitle'>
          Are you sure you want to delete this account?
        </div>
        <div className='networth-delete-account__buttons'>
          <Button
            id='delete-modal-save'
            type='button'
            onClick={deleteAccount}
            className='networth-delete-account__button fs-6 fw-bold'>
            Delete
          </Button>
          <Button
            id='delete-modal-cancel'
            onClick={closeModal}
            variant='link'
            className='networth-delete-account__button fs-6 fw-bold'>
            Cancel
          </Button>
        </div>
      </Modal>
    )
  }
}

export default DeleteAccountModal
