import React, { useState, Fragment } from 'react'
import YodleeEditModal from './Edit/yodleeEditModal'
import { getAccountRow } from './AccountRows'
import { YodleeObj } from '../../objects/yodlee'
import { ClientAccountObj } from '../../objects/clientAccount'

import { Accordion } from 'react-bootstrap'
import { useWindowSize } from '../../hooks'
import { NetworthTable } from './NetworthTable'

export interface ErrorAccountsTableProps {
  accounts: {
    sum: number
    category: string
    accounts: ClientAccountObj[]
    errMessage?: string
  }
  isUpgradeTile?: boolean
  yodlee: YodleeObj
  toggleModal(): void
  hideErrorCell?: boolean
}

const ErrorAccountsTable = (props: ErrorAccountsTableProps): JSX.Element => {
  const [showEdit, setShowEdit] = useState(false)

  const { width } = useWindowSize()
  const {
    accounts: accountsCollection,
    isUpgradeTile,
    yodlee,
    toggleModal,
    hideErrorCell = false
  } = props

  const { category, accounts } = accountsCollection

  const generateTableData = () => {
    return accounts.map((item) => ({
      id: item.id,
      cellComponentProps: {
        content: {
          ...item,
          toggleModal,
          hideErrorCell
        },
        account: item
      }
    }))
  }

  // Avoid the accordion grabbing button click
  const loginToAccount = (e) => {
    e.stopPropagation()
    showEditToggle()
  }

  const showEditToggle = () => setShowEdit(!showEdit)

  const tableData = generateTableData()
  const accountRows = getAccountRow(width)

  return (
    <Fragment>
      <Accordion className='account-category__accordion'>
        <Accordion.Item eventKey='0'>
          <Accordion.Header as='div'>
            <h2 className='account-category__error-name'>{category}</h2>
            <div className='account-category__error-button'>
              <div
                className='btn btn-outline-primary font-color--dark fs-6 me-4 account-category__btn-lg'
                onClick={loginToAccount}>
                {`Login to ${isUpgradeTile ? 'upgrade' : 'resolve'}`}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <NetworthTable
              id='file-vault-table'
              className='account-category__table'
              columns={accountRows}
              data={tableData}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <YodleeEditModal
        closeModal={showEditToggle}
        account={props.accounts.accounts[0]}
        fastlinkUrl={yodlee.fastlinkUrl}
        show={showEdit}
      />
    </Fragment>
  )
}

export default ErrorAccountsTable
