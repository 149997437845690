import dayjs from 'dayjs'
import { reduce } from 'lodash-es'
import { ClientAccountObj } from '../objects/clientAccount'
import { isObjEmpty, dollarFormat } from './index'
import { TotalAllocatedCalculated } from '../objects/allocation'

export const mapAllocationColor = {
  equity: '#3B98E2',
  fixed: '#61B75E',
  cash: '#E4E6F5',
  alt: '#F4A83E',
  unclassified: '#89579D'
}

export const mapAllocationName = {
  equity: 'Equity',
  fixed: 'Fixed Income',
  cash: 'Cash and Cash Equivalents',
  alt: 'Alternatives',
  unclassified: 'Unclassified'
}

// constant data for each asset type to be used in donut charts
// current allocation
export const currentAllocMap = reduce(
  mapAllocationName,
  (p, name, alloc) => {
    const color = mapAllocationColor[alloc]
    p[alloc] = { name, fill: color, stroke: color }
    return p
  },
  {} as any
)

// target allocation
const targetAllocMap = {
  targetEquityAllocation: currentAllocMap.equity,
  targetFixedAllocation: currentAllocMap.fixed,
  targetCashAllocation: currentAllocMap.cash,
  targetOtherAllocation: currentAllocMap.alt
}

// helper func to create a data formatter for donut charts
export const mapAllocData = (
  dataMap: any,
  valueGetter: (data: any) => number
) => (dataObj: any) => {
  return reduce(
    dataObj,
    (p, data, key) => {
      if (!dataMap[key]) {
        return p
      }
      const sector = { ...dataMap[key], value: valueGetter(data) }
      p = p.concat(sector)
      return p
    },
    []
  )
}

export const currentDonutData = mapAllocData(
  currentAllocMap,
  (val) => val?.percent
)
export const targetDonutData = mapAllocData(targetAllocMap, (val) => val)

export const formatInvAmount = (account: ClientAccountObj): string => {
  return account.guidebookClassification === 'Short-Term Liabilities' ||
    account.guidebookClassification === 'Long-Term Liabilities' ||
    account.totalValue < 0
    ? dollarFormat(account.totalValue, 0, true)
    : dollarFormat(account.totalValue, 0)
}

export const getFormattedTotal = (amount) => {
  if (amount || amount === 0) {
    const sum = Number(amount)
    return sum < 0 ? dollarFormat(sum, 0, true) : dollarFormat(sum, 0)
  } else {
    return null
  }
}

export const sortByAllocations = (a, b) => {
  const {
    equityPercent: equityA,
    fixedPercent: fixedA,
    cashPercent: cashA,
    altPercent: altA,
    unclassifiedPercent: unclassifiedA
  } = a
  const {
    equityPercent: equityB,
    fixedPercent: fixedB,
    cashPercent: cashB,
    altPercent: altB,
    unclassifiedPercent: unclassifiedB
  } = b
  return (
    equityA - equityB ||
    fixedA - fixedB ||
    altA - altB ||
    cashA - cashB ||
    unclassifiedA - unclassifiedB
  )
}

export const sortByString = (a, b, field) => {
  const va = !a[field] ? '' : String(a[field])
  const vb = !b[field] ? '' : String(b[field])
  if (va === '' && vb === '') return sortNullValue(a, b, field)
  if (va === '') return -1
  if (vb === '') return 1
  return va.localeCompare(vb)
}

export const sortByNumber = (a, b, field) => {
  const aScore = a[field] || 0
  const bScore = b[field] || 0
  if (!a[field] && !b[field]) return sortNullValue(a, b, field)
  if (aScore === 0) return -1
  if (bScore === 0) return 1
  return aScore - bScore
}

export const sortNullValue = (a, b, field) => {
  if (field === 'value') return sortByString(a, b, 'id')
  return sortByNumber(a, b, 'value')
}

export const investmentAccountDateMap = (date: string) => {
  const year = date.split('-')[0]
  const month = dayjs(date).format('MMM')
  const day = date.split('-')[2]
  return `${month} ${day}, ${year}`
}

export const getHoldingDonutChartWidth = (width: number) => {
  const mediumWindowWidth = 768
  return width <= mediumWindowWidth ? 200 : 280
}

export const getHoldingAllocationData = (account: ClientAccountObj) => {
  return Object.keys(account.discreteAllocation).map((allocation, index) => {
    const amount = Number(
      account.totalValue * (account.discreteAllocation[allocation] / 100)
    )
    return {
      name: allocation,
      id: index,
      amount,
      value: Math.round(account.discreteAllocation[allocation]),
      fill: mapAllocationColor[allocation],
      stroke: mapAllocationColor[allocation]
    }
  })
}

export const calculateAccountTotal = (clientAccounts) => {
  if (clientAccounts && !isObjEmpty(clientAccounts)) {
    const total = clientAccounts.reduce((sum, key) => {
      return key.category === 'Short-Term Liabilities' ||
        key.category === 'Long-Term Liabilities'
        ? (sum -= key.totalValue)
        : (sum += key.totalValue)
    }, 0)
    const rawTotal = clientAccounts.reduce(
      (sum, key) => (sum += key.totalValue),
      0
    )
    return { total, rawTotal }
  } else return { total: 0, rawTotal: 0 }
}

export const calculateAssetAllocation = (clientAccounts) => {
  const calculatedAllocations: TotalAllocatedCalculated = {
    equity: { percent: 0, amount: 0, rawAmount: 0 },
    fixed: { percent: 0, amount: 0, rawAmount: 0 },
    cash: { percent: 0, amount: 0, rawAmount: 0 },
    alt: { percent: 0, amount: 0, rawAmount: 0 },
    unclassified: { percent: 0, amount: 0, rawAmount: 0 }
  }
  if (clientAccounts && !isObjEmpty(clientAccounts)) {
    let accountTotal = {
      total: 0,
      rawTotal: 0
    }
    accountTotal = calculateAccountTotal(clientAccounts)
    if (
      (accountTotal.total || accountTotal.rawTotal) &&
      clientAccounts.length > 0
    ) {
      clientAccounts.forEach((account) => {
        if (
          account.discreteAllocation &&
          isObjEmpty(account.discreteAllocation)
        ) {
          if (
            account.category === 'Short-Term Liabilities' ||
            account.category === 'Long-Term Liabilities'
          ) {
            calculatedAllocations.unclassified.amount -= account.totalValue
            calculatedAllocations.unclassified.rawAmount += account.totalValue
          } else {
            calculatedAllocations.unclassified.amount += account.totalValue
            calculatedAllocations.unclassified.rawAmount += account.totalValue
          }
        } else {
          Object.keys(account.discreteAllocation).forEach((allocation) => {
            const calc = Number(
              account.totalValue *
                (account.discreteAllocation[allocation] / 100)
            )
            if (
              account.category === 'Short-Term Liabilities' ||
              account.category === 'Long-Term Liabilities'
            ) {
              calculatedAllocations[allocation].amount -= calc
              calculatedAllocations[allocation].rawAmount += calc
            } else {
              calculatedAllocations[allocation].amount += calc
              calculatedAllocations[allocation].rawAmount += calc
            }
          })
        }
      })
      Object.keys(calculatedAllocations).forEach(
        (key) =>
          (calculatedAllocations[key].percent = Math.round(
            (calculatedAllocations[key].rawAmount / accountTotal.rawTotal) * 100
          ))
      )
    }
  }
  return calculatedAllocations
}
