import React from 'react'
import { connect } from 'react-redux'
import Card from 'react-bootstrap/Card'
import { GradientHero } from '../../components/Hero'
import { GlobalState } from '../../reducers'
import { labelItems } from '../../helpers/goals'

export type GoalsHeroProps = ReturnType<typeof mapStateToProps>

const LabelItems = (goalActions) => {
  return Object.entries(labelItems).map(([name, { color, shadowColor }]) => {
    return (
      <div key={name}>
        <div className='py-1 goal-hero__label-col'>
          <div className='goal-hero__label-subheader'>
            <span
              className='goal-hero__label-dot'
              style={{
                backgroundColor: color,
                boxShadow: `0px 0px 0px 5px ${shadowColor}`
              }}
            />
            <span className='goal-hero__label-amount'>
              {goalActions &&
                goalActions.filter((goal) => goal.status === name).length}
            </span>
          </div>
          <div>
            <span className='goal-hero__label-light'>{name}</span>
          </div>
        </div>
      </div>
    )
  })
}

export const GoalsHero = (props: GoalsHeroProps) => {
  const { goalActions } = props
  const noGoals = !goalActions || goalActions.length === 0
  return (
    <div>
      <GradientHero
        className={`goal-hero__container goal-hero__container${
          noGoals ? '-null' : ''
        }`}
      />
      <Card
        className='border-0'
        style={{
          padding: '0px',
          margin: '0px',
          background: 'none',
          height: '25vh'
        }}>
        <div
          className={`goal-hero__content goal-hero__content${
            !noGoals ? '-null' : ''
          }`}>
          <div className='py-1 goal-hero__content-col'>
            <span className='goal-hero__content-title'>Goals</span>
          </div>
          {noGoals ? null : (
            <div className='goal-hero__label'>{LabelItems(goalActions)}</div>
          )}
        </div>
      </Card>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    goalActions: store.goalActions.goals
  }
}

export default connect(mapStateToProps)(GoalsHero)
