import React, { useState } from 'react'
import AvatarEditor from 'react-avatar-editor'

import Button from 'react-bootstrap/Button'
import ControlledModalWrapper from '../../../components/ControlledModalWrapper'

interface IEditPhotoModal {
  photoUrl: string
  closeModal: () => void
  uploadPhoto: (photo: string) => void
}
const EditPhotoModal = (props: IEditPhotoModal) => {
  const { photoUrl, closeModal, uploadPhoto } = props

  const [editor, setEditor] = useState(null)

  const handleApplyPhoto = () => {
    const canvas = editor.getImageScaledToCanvas()
    const image = canvas.toDataURL().split('base64,')[1]
    uploadPhoto(image)
  }

  return (
    <>
      <div className='pb-5 pt-x2 ps-x2 pe-x2'>
        <h3 className='rfont--h3 fw-bold text-dark mb-3'>Edit Photo</h3>
        <p className='rfont--body2 text-dark fw-bold mb-3'>
          Drag to reposition.
        </p>
      </div>
      <div className='edit-modal m-0 ms-md-x2 me-md-x2'>
        <AvatarEditor
          ref={setEditor}
          image={photoUrl}
          width={280}
          height={280}
          border={[150, 0]}
          borderRadius={600}
          color={[255, 255, 255, 0.6]}
          scale={1.2}
          crossOrigin=''
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: '100vh',
            margin: 'auto'
          }}
        />
        <div className='edit-modal__overlay'>
          <hr className='edit-modal__overlay--horizontal-line' />
          <hr className='edit-modal__overlay--horizontal-line2' />
          <hr className='edit-modal__overlay--vertical-line' />
          <hr className='edit-modal__overlay--vertical-line2' />
        </div>
      </div>
      <div className='edit-modal__btn-container'>
        <Button variant='primary' size='lg' onClick={handleApplyPhoto}>
          Apply
        </Button>
        <Button
          className='match-btn-width font-color--dark mt-2 mt-md-0 ms-0 ms-md-2'
          variant='outline-primary'
          size='lg'
          onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </>
  )
}

export default ControlledModalWrapper(EditPhotoModal)
