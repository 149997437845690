import {
  HonestConversationsExerciseObj,
  HouseholdCardObj,
  ContactCardScoreObj,
  HouseholdCardObjInterface,
  CardScoreInterface,
  CardScoreObj,
  HonestConversationObj,
  ClientScoringSession
} from '../../objects/honestConversation'
import { ContactsInterface } from '../../objects/contact'

import dayjs from 'dayjs'

const dataSetTemplate = {
  label: 'Score History',
  fill: false,
  lineTension: 0,
  borderCapStyle: 'butt',
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderColor: 'rgba(255,255,255,1)',
  pointHoverBorderWidth: 2,
  pointHitRadius: 10,
  pointBorderColor: 'white'
}

const defaultPointRadius: number = 4
export const PRIMARY_LINE_COLOR = '#989DC2'
export const SECONDARY_LINE_COLOR = '#CBBFB4'

const MAX_SHARED_PRIORITY_GRAPH_DISPLAY: number = 4

const setPointColors = (pointArray: number[]) => {
  const pointColors: string[] = []
  pointArray.forEach((point) => {
    pointColors.push(point >= 7 ? '#068667' : '#d8761c')
  })
  return pointColors
}

export const generateContactCardScores = (
  isPrimaryContact: boolean,
  contacts: ContactsInterface,
  honestConversation: HonestConversationObj
) => {
  const { primary, secondary } = contacts
  const cardScoreObj: ContactCardScoreObj = {}
  if (honestConversation && honestConversation.clientScoringSessions) {
    const scoreSessions = honestConversation.clientScoringSessions
    const primaryActiveSession = honestConversation.activeScoringSessionPrimary
    const secondaryActiveSession =
      honestConversation.activeScoringSessionSecondary

    if (
      isPrimaryContact &&
      primary?.id &&
      primaryActiveSession &&
      scoreSessions?.[primary?.id]
    ) {
      cardScoreObj.primaryCardScores =
        (scoreSessions &&
          scoreSessions?.[primary?.id]?.[primaryActiveSession]?.cardScores) ||
        null
    }
    if (
      !isPrimaryContact &&
      secondary?.id &&
      secondaryActiveSession &&
      scoreSessions?.[secondary?.id]
    ) {
      cardScoreObj.secondaryCardScores =
        (scoreSessions &&
          scoreSessions?.[secondary?.id]?.[secondaryActiveSession]
            ?.cardScores) ||
        null
    }
  }
  return cardScoreObj
}

export const sortHouseholdCards = (cards: HouseholdCardObjInterface) => {
  return Object.keys(cards)
    .sort((a, b) => cards[a].ranking - cards[b].ranking)
    .map((key) => cards[key])
}

export const findScoreCard = (
  scores: CardScoreInterface,
  cardObject: HouseholdCardObj
): CardScoreObj => {
  let card: CardScoreObj = null
  scores &&
    Object.keys(scores).map((key) => {
      const id =
        key &&
        scores[key] &&
        scores[key].householdCard &&
        scores[key].householdCard.id
      const cardId = cardObject && cardObject.id
      if (id === cardId) {
        card = scores[key]
      }
    })
  return card
}

const sortCardScoresByDate = (
  scoringSession: ClientScoringSession,
  maxReturned?: number
): CardScoreInterface[] => {
  const sortedByDate: CardScoreInterface[] =
    scoringSession &&
    Object.keys(scoringSession)
      .sort((scoreA: string, scoreB: string) => {
        return dayjs(scoringSession[scoreA].createdDate).isBefore(
          scoringSession[scoreB].createdDate
        )
          ? -1
          : 1
      })

      .map((scoreA) => {
        return scoringSession[scoreA].cardScores
      })

  if (maxReturned && sortedByDate?.length > maxReturned) {
    return sortedByDate.splice(Math.max(sortedByDate?.length - maxReturned, 0))
  }
  return sortedByDate
}

const getCardScores = (
  selectedCardId: string,
  cardScores: CardScoreInterface[]
) => {
  const scoreNumbers: number[] = []
  const scoreDots: number[] = []

  const filteredCardScores = cardScores
    ?.filter((cardScore) => cardScore !== undefined || null)
    .forEach((cardScore: CardScoreInterface, index: number) => {
      // empty score sessions
      if (cardScore) {
        if (!Object.keys(cardScore)?.length) {
          scoreNumbers.push(0)
          scoreDots.push(defaultPointRadius)
        }
        Object.keys(cardScore).forEach((key) => {
          if (cardScore[key]?.householdCard?.id === selectedCardId) {
            scoreNumbers.push(cardScore[key].score || 0)
            scoreDots.push(defaultPointRadius)
          }
        })
      }
    })

  return { scoreNumbers, scoreDots, filteredCardScores }
}

export const getIndividualScoreDataSet = (
  honestConversation: HonestConversationsExerciseObj,
  selectedCard: HouseholdCardObj,
  contacts: ContactsInterface
) => {
  const data: { labels: string[]; datasets } = {
    labels: [],
    datasets: []
  }
  if (!honestConversation || !contacts) {
    return data
  }

  const { clientScoringSessions } = honestConversation

  if (Object.keys(clientScoringSessions).length === 0) {
    return data
  }

  const primaryScoringSessions = clientScoringSessions[contacts.primary?.id]
  const primaryCardScores: CardScoreInterface[] =
    primaryScoringSessions &&
    sortCardScoresByDate(
      primaryScoringSessions,
      MAX_SHARED_PRIORITY_GRAPH_DISPLAY
    )

  const primaryScoresObj =
    primaryCardScores && getCardScores(selectedCard.id, primaryCardScores)
  const primaryScores: number[] = primaryScoresObj?.scoreNumbers || []
  const primaryPointRadius: number[] = primaryScoresObj?.scoreDots || []

  const secondaryScoringSessions =
    clientScoringSessions[contacts?.secondary?.id]

  const secondaryCardScores: CardScoreInterface[] =
    secondaryScoringSessions &&
    sortCardScoresByDate(
      secondaryScoringSessions,
      MAX_SHARED_PRIORITY_GRAPH_DISPLAY
    )

  const secondaryScoresObj =
    secondaryCardScores && getCardScores(selectedCard.id, secondaryCardScores)
  const secondaryScores: number[] = secondaryScoresObj?.scoreNumbers || []
  const secondaryPointRadius: number[] = secondaryScoresObj?.scoreDots || []

  // set the length of labels on the longest score length
  // this gives the end to end line look
  const labelCount =
    secondaryScores.length > primaryScores.length
      ? secondaryScores.length
      : primaryScores.length
  data.labels = Array<string>(labelCount).fill('')

  const primaryDataSet = {
    ...dataSetTemplate,
    label: 'Primary Score History',
    backgroundColor: PRIMARY_LINE_COLOR,
    borderColor: PRIMARY_LINE_COLOR,
    pointRadius: primaryPointRadius,
    pointBackgroundColor: setPointColors(primaryScores),
    data: primaryScores
  }
  data.datasets.push(primaryDataSet)

  if (secondaryScores.length > 0) {
    data.datasets.push({
      ...dataSetTemplate,
      label: 'Secondary Score History',
      backgroundColor: SECONDARY_LINE_COLOR,
      borderColor: SECONDARY_LINE_COLOR,
      pointRadius: secondaryPointRadius,
      pointBackgroundColor: setPointColors(secondaryScores),
      data: secondaryScores
    })
  }

  return data
}
