import React from 'react'
import { map } from 'lodash-es'
import PlanInputTable from './PlanInputTable'
import {
  FinancialGoalObj,
  FinancialGoalType,
  FinancialGoalsByType
} from '../../../objects/plan'

export interface PlanInputsProps {
  goalsByType: FinancialGoalsByType
  isMgp: boolean
  onEditGoal(goal: FinancialGoalObj): void
  onDeleteGoal(): void
}

const goalTypeToTitle: { [K in FinancialGoalType]: string } = {
  'Spending Goal': 'Spending',
  'Savings Goal': 'Savings',
  'Legacy Goal': 'Legacy',
  'Timing Control': 'Timing',
  'Retirement/Other Income': 'Retirement Income'
}

const PlanInputs = ({
  goalsByType,
  isMgp,
  onEditGoal,
  onDeleteGoal
}: PlanInputsProps) => (
  <div>
    {map(goalsByType, (goals, goalType) => (
      <PlanInputTable
        key={`goals-table-${goalType}`}
        goals={goals}
        title={goalTypeToTitle[goalType]}
        hidePriority={!isMgp}
        onEditGoal={onEditGoal}
        onDeleteGoal={onDeleteGoal}
      />
    ))}
  </div>
)

export default PlanInputs
