import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'

import { getHouseholdPartner } from '../../selectors/moneymind'
import { getHonestConversation } from '../../selectors/priorities'
import {
  generateContactCardScores,
  getIndividualScoreDataSet
} from '../../graphs/utils/priorities'
import { HouseholdCardObj } from '../../objects/honestConversation'
import PrioritiesGraph from '../../components/PrioritiesGraph'
import { ContactObj, ContactsInterface } from '../../objects/contact'
import { UserObj } from '../../objects/user'
import { scoreCardCommentByHouseholdCard } from '../../helpers/priorities'

type HonestConversationsScoreStore = ReturnType<typeof mapStateToProps>
export type HonestConversationsScoreProps = HonestConversationsScoreStore

const GraphLabels = (props: {
  partner: ContactObj
  user: UserObj
  contacts: ContactsInterface
}) => {
  const { partner, user, contacts } = props
  const userState = contacts?.primary?.id === user.id
  const partnerState = contacts?.secondary?.id === user.id

  const userLineColor = `priority-graphs__line priority-graphs__line--${
    userState ? 'secondary' : 'primary'
  }`

  const partnerLineColor = `priority-graphs__line priority-graphs__line--${
    partnerState ? 'secondary' : 'primary'
  }`

  return (
    <div className='priority-graphs__title'>
      <div className='priority-graphs__title-names'>
        <div className={userLineColor} />
        <div>{user.firstName}</div>
      </div>
      {partner && (
        <div className='priority-graphs__title-names'>
          <div className={partnerLineColor} />
          <div>{partner.firstName}</div>
        </div>
      )}
    </div>
  )
}

const HonestConversationsScore = (
  props: HonestConversationsScoreProps
): JSX.Element => {
  const {
    graphDataSet,
    partner,
    user,
    userComment,
    partnerComment,
    contacts
  } = props
  const [priorityDataSet, setPriorityDataSet] = useState(graphDataSet)

  useEffect(() => {
    setPriorityDataSet(graphDataSet)
  }, [graphDataSet])

  const showGraph = graphDataSet?.labels?.length >= 2
  const cardClasses = 'bg-white p-3 p-md-x2 position-relative'

  return (
    showGraph && (
      <div className='priority-graphs__chart'>
        <div
          id='honest-conversation-score-chart'
          className={`${cardClasses} mt-x2 mb-5`}>
          <div className='d-flex justify-content-between'>
            <h4 className='fs-6 fw-bold text-dark'>Card Score History</h4>
            <GraphLabels partner={partner} user={user} contacts={contacts} />
          </div>
          <PrioritiesGraph graphDataSet={priorityDataSet} />
        </div>
        {userComment && (
          <div id='honest-conversation-user-comment' className={cardClasses}>
            <h4 className='fs-6 fw-bold text-dark'>Your Comment</h4>
            <span className='priority-graphs__comment'>{userComment}</span>
          </div>
        )}
        {partner && partnerComment && (
          <div
            id='honest-conversation-partner-comment'
            className={`${cardClasses} mb-x2`}>
            <h4 className='fs-6 fw-bold text-dark'>{`${partner.firstName}'s Comment`}</h4>
            <span className='priority-graphs__comment'>{partnerComment}</span>
          </div>
        )}
      </div>
    )
  )
}

const mapStateToProps = (store: GlobalState, { selectedCard }) => {
  const { user, contacts } = store
  const partner = getHouseholdPartner(store)
  const exercise = getHonestConversation(store)
  let graphDataSet = undefined
  let householdCard = undefined
  let userCardScores = undefined
  let userComment = undefined
  let partnerComment = undefined
  let partnerCardScores = undefined
  let defaultCard = selectedCard
  if (exercise) {
    const userIsPrimary = user.id === contacts?.primary?.id
    const partnerIsPrimary = partner?.id === contacts?.primary.id

    userCardScores = generateContactCardScores(
      userIsPrimary,
      contacts,
      exercise
    )

    partnerCardScores = generateContactCardScores(
      partnerIsPrimary,
      contacts,
      exercise
    )

    if (!selectedCard) {
      Object.keys(exercise.householdCards).forEach((cardKey) => {
        if (exercise.householdCards[cardKey].ranking === 1) {
          defaultCard = exercise.householdCards[cardKey].card.id
        }
      })
    }
    userComment = scoreCardCommentByHouseholdCard(
      userCardScores[
        userIsPrimary ? 'primaryCardScores' : 'secondaryCardScores'
      ],
      selectedCard
    )
    partnerComment = scoreCardCommentByHouseholdCard(
      partnerCardScores[
        partnerIsPrimary ? 'primaryCardScores' : 'secondaryCardScores'
      ],
      selectedCard
    )

    householdCard = Object.keys(exercise.householdCards).reduce(
      (obj, cardId) => {
        return (
          (exercise.householdCards[cardId].card.id === defaultCard &&
            exercise.householdCards[cardId]) ||
          obj
        )
      },
      {}
    )
    graphDataSet = getIndividualScoreDataSet(
      exercise,
      householdCard as HouseholdCardObj,
      contacts
    )
  }

  return {
    user,
    contacts,
    householdCard,
    graphDataSet,
    userCardScores,
    partnerCardScores,
    partnerComment,
    userComment,
    partner,
    selectedCard: defaultCard
  }
}

export default connect(mapStateToProps)(HonestConversationsScore)
