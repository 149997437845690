import React from 'react'
import { Button } from 'react-bootstrap'
import ManualAccountForm from '../ManualAccountForm'

export interface ManualLinkProps {
  onBack: () => void
  onClose: () => void
}

const ManualLink = ({ onBack, onClose }: ManualLinkProps) => (
  <>
    <Button className='manual-link__button' variant='' onClick={onBack}>
      <i className='bi bi-chevron-left manual-link__icon'></i> Back
    </Button>
    <ManualAccountForm
      className='mb-x2'
      modalType='add'
      onClose={onClose}
      closeOnError={true}
    />
  </>
)

export default ManualLink
