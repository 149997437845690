import { dollarFormat } from '../helpers'

export const headerOptions = {
  'Life Insurance': [
    'Policy Number',
    'Owner',
    'Insured',
    'Death Benefit',
    'Beneficiary',
    'Annual Premium',
    'Company Name'
  ],
  Annuity: [
    'Policy Number',
    'Owner',
    '',
    'Yearly Benefit',
    'Surrender Period',
    'Annual Premium',
    'Company Name'
  ],
  Disability: [
    'Policy Number',
    'Insured',
    'Duration',
    'Monthly Benefit',
    'Elimination Period',
    'Annual Premium',
    'Company Name'
  ],
  'Long Term Care': [
    'Policy Number',
    'Insured',
    'Duration',
    'Monthly Benefit',
    'Elimination Period',
    'Annual Premium',
    'Company Name'
  ]
}

export const dollarAmountField = [
  'Death Benefit',
  'Monthly Benefit',
  'Yearly Benefit',
  'Annual Premium'
]

export const getFormattedAmount = (totalAmount) => {
  const accountValue = Number(totalAmount)
  const formattedTotalValue =
    accountValue < 0
      ? dollarFormat(accountValue, 0, true)
      : dollarFormat(accountValue, 0)
  return formattedTotalValue
}
