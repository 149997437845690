import { useReducer } from 'react'

export enum EModalActions {
  close,
  showUpload,
  showEdit,
  showDelete,
  showLoader
}

const initModalState = {
  showUploadModal: false,
  showEditModal: false,
  showDeleteModal: false,
  showLoaderModal: false
}

const modalReducer = (state, action: EModalActions) => {
  switch (action) {
    case EModalActions.showUpload:
      return { ...initModalState, showUploadModal: true }
    case EModalActions.showEdit:
      return { ...initModalState, showEditModal: true }
    case EModalActions.showDelete:
      return { ...initModalState, showDeleteModal: true }
    case EModalActions.showLoader:
      return { ...initModalState, showLoaderModal: true }
    case EModalActions.close:
      return { ...initModalState }
    default:
      throw new Error('No proper action was passed')
  }
}

export const useModalReducer = () => {
  const [modalState, handleModal] = useReducer(modalReducer, initModalState)
  return { modalState, handleModal }
}
