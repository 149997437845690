import dayjs from 'dayjs'
import { map } from 'lodash-es'
import {
  GoalActionObj,
  GoalActionSortByTypes,
  GoalActionSortOption,
  StatusType,
  GoalType
} from '../objects/goals'

export const labelItems: {
  [K in StatusType]?: { color: string; shadowColor: string }
} = {
  'Optimal Position': {
    color: 'rgba(91, 208, 179, 1)',
    shadowColor: 'rgba(91, 208, 179, 0.25)'
  },
  'Improvement Needed': {
    color: 'rgba(244, 168, 62, 1)',
    shadowColor: 'rgba(244, 168, 62, 0.25)'
  },
  'Immediate Action Needed': {
    color: 'rgba(225, 96, 122, 1)',
    shadowColor: 'rgba(225, 96, 122, 0.25)'
  }
}

export const typeToTab = (type: GoalType) => {
  switch (type) {
    case 'both':
      return ['Life', 'Financial']
    case 'financial':
      return ['Financial']
    case 'life':
      return ['Life']
    default:
      return []
  }
}

export const sortOptionsMap: {
  [K in GoalActionSortByTypes]?: GoalActionSortOption
} = {
  Status: { value: 'Status', label: 'Sort by Status' },
  'Due Date': { value: 'Due Date', label: 'Sort by Check-In Date' },
  Type: { value: 'Type', label: 'Sort by Type' },
  Name: { value: 'Name', label: 'Sort by Name' },
  Owner: { value: 'Owner', label: 'Sort by Owner' }
}

export const sortOptions = map(sortOptionsMap)

// convert status string to sort order number
export const statusToOrder = (status: StatusType) => {
  const statusOptions: StatusType[] = [
    'Immediate Action Needed',
    'Improvement Needed',
    'Optimal Position',
    'NA | Pending Review'
  ]
  return statusOptions.indexOf(status)
}

// sort function callback to check for null/undefined values
// return undefined to indicate neither value is null/undefined
export const nullSort = (a: any, b: any) => {
  if (!a && !b) {
    return 0
  }

  if (!a) {
    return 1
  }

  if (!b) {
    return -1
  }

  return undefined
}

// sort function callbacks for each goal field
// status, type, and dueDate
export const statusSort = (a: StatusType, b: StatusType) => {
  return statusToOrder(a) - statusToOrder(b)
}

export const stringSort = (a: GoalType, b: GoalType) => {
  return a.localeCompare(b)
}

export const dateSort = (a: string, b: string) => {
  return dayjs(a).diff(dayjs(b))
}

const determineClient = (goal: GoalActionObj) => {
  if (goal?.assigneePrimaryId && goal?.assigneeSecondaryId) {
    return '0'
  } else if (goal?.assigneePrimaryId) {
    return '1'
  } else if (goal?.assigneeSecondaryId) {
    return '2'
  } else return '3'
}

// map goal fields to their sort functions
const fieldToSortFunc = {
  status: statusSort,
  type: stringSort,
  dueDate: dateSort,
  name: stringSort
}

// map SortValue to goal fields in the order of priority
// they should be used for sorting
// e.g., 'status' should be sorted by status, then dueDate, then type
const sortBy: { [K in GoalActionSortByTypes]?: string[] } = {
  Status: ['status', 'dueDate', 'type', 'name', 'client'],
  'Due Date': ['dueDate', 'status', 'type', 'name', 'client'],
  Type: ['type', 'dueDate', 'status', 'name', 'client'],
  Name: ['name', 'dueDate', 'status', 'type', 'client'],
  Owner: ['client', 'dueDate', 'status', 'type', 'name']
}

export const sortGoals = (
  key: GoalActionSortByTypes,
  goals: GoalActionObj[]
) => {
  if (!goals || !goals.length) {
    return []
  }

  // get fields used for sorting
  const fields = sortBy[key]
  if (!fields) {
    return goals
  }

  return goals.sort((a: GoalActionObj, b: GoalActionObj) => {
    // loop through fields used for sorting
    // reduce to a single sort value 0, 1, or -1
    return fields.reduce((sortVal, field) => {
      // if a sort function has returned something other than zero
      // then sorting is done. otherwise, the previous two values were equal,
      // so we need to try sorting using the next field in priority order
      if (sortVal !== 0) {
        return sortVal
      }

      // get the field values for comparison
      const valueA = field === 'client' ? determineClient(a) : a?.[field]
      const valueB = field === 'client' ? determineClient(b) : b?.[field]

      // sort any null values first
      const nullSortVal = nullSort(valueA, valueB)
      if (nullSortVal !== undefined) {
        sortVal = nullSortVal
        return sortVal
      }

      // get the sort function for the field and compare values
      const sortFunc = field === 'client' ? stringSort : fieldToSortFunc[field]
      sortVal = sortFunc(valueA, valueB)

      return sortVal
    }, 0)
  })
}
