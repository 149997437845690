import React from 'react'
import Steps from '../../../components/Steps'

export const ScoreTracker = (props): JSX.Element => {
  const { trackerData, scores, currentCardIndex } = props
  return (
    <div className='mt-0 mt-md-3'>
      <div className='honestConversations-score__tracker'>
        <Steps data={trackerData} />
      </div>
      <div className='rfont--h1 pt-x2 pb-x2'>
        {scores.map((score, key) => {
          return key === currentCardIndex ? score.card.title : null
        })}
      </div>
      <div className='rfont--body1'>
        Select how you would score this priority at this point in your life, and
        leave a comment on what you think you can do to improve it.
      </div>
    </div>
  )
}

export default ScoreTracker
