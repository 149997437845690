import Api from '../helpers/api'

export const GET_MARKETPLACE_OFFERS = 'GET_MARKETPLACE_OFFERS'
export const GET_MARKETPLACE_OFFERS_PENDING = 'GET_MARKETPLACE_OFFERS_PENDING'
export const GET_MARKETPLACE_OFFERS_FULFILLED =
  'GET_MARKETPLACE_OFFERS_FULFILLED'
export const GET_MARKETPLACE_OFFERS_REJECTED = 'GET_MARKETPLACE_OFFERS_REJECTED'

export const getMarketplaceOffers = (householdFinId: string) => {
  const apiInstance = Api.getInstance()
  return {
    householdFinId,
    type: GET_MARKETPLACE_OFFERS,
    payload: apiInstance.get(`/marketplaceoffers`).then((response) => {
      return response
    })
  }
}
