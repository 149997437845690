import React, { Fragment } from 'react'
import { persistentSort } from '../../helpers/moneyMind'
import Card from 'react-bootstrap/Card'

const Description = (moneymind: any, moneymindDescription: any) => {
  const { fear, commitment, happiness } = moneymind.moneymind

  const sortedMoneyMind = persistentSort([
    ['protection', fear] as any,
    ['commitment', commitment] as any,
    ['happiness', happiness] as any
  ])

  const isBlended = sortedMoneyMind[0][1] === sortedMoneyMind[1][1]

  const percentage = (influence: any) => {
    if (influence == null) {
      return null
    }
    if (influence.toLowerCase() == 'protection') {
      return moneymind.moneymind.fear
    } else if (influence.toLowerCase() == 'commitment') {
      return moneymind.moneymind.commitment
    }

    return moneymind.moneymind.happiness
  }

  const descriptionDisplay = (key, description, primary, secondary) => {
    const primaryPercentage = percentage(primary)

    const secondaryPercentage = percentage(secondary)

    return (
      <div key={key} className='description__influence'>
        {secondary ? (
          <div>
            <div className='description__influence__title'>
              Blended Influence
            </div>
            <div style={{ paddingLeft: '16px', marginBottom: '30px' }}>
              <span className={'description__influence__title--' + primary}>
                {primary} {primaryPercentage}%
              </span>
              <span className='description--plus'>&nbsp;+&nbsp;</span>
              <span className={'description__influence__title--' + secondary}>
                {secondary} {secondaryPercentage}%
              </span>
            </div>
          </div>
        ) : (
          <div>
            <div className='description__influence__title'>{key} Influence</div>
            <div style={{ paddingLeft: '16px', marginBottom: '30px' }}>
              <span className={'description__influence__title--' + primary}>
                {primary} {primaryPercentage}%
              </span>
            </div>
          </div>
        )}
        <p
          className='description__influence__content'
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    )
  }
  const getDescriptions = () => {
    if (isBlended) {
      return [
        descriptionDisplay(
          'Primary',
          moneymind.moneymindDescription.first.desktop,
          sortedMoneyMind[0][0],
          sortedMoneyMind[1][0]
        ),
        descriptionDisplay(
          'Secondary',
          moneymind.moneymindDescription.second.desktop,
          sortedMoneyMind[2][0],
          null
        )
      ]
    }
    if (percentage(sortedMoneyMind[1][0]) == 0) {
      return [
        descriptionDisplay(
          'Primary',
          moneymind.moneymindDescription.first.desktop,
          sortedMoneyMind[0][0],
          null
        )
      ]
    }
    if (percentage(sortedMoneyMind[2][0]) == 0) {
      return [
        descriptionDisplay(
          'Primary',
          moneymind.moneymindDescription.first.desktop,
          sortedMoneyMind[0][0],
          null
        ),
        descriptionDisplay(
          'Secondary',
          moneymind.moneymindDescription.second.desktop,
          sortedMoneyMind[1][0],
          null
        )
      ]
    }

    return [
      descriptionDisplay(
        'Primary',
        moneymind.moneymindDescription.first.desktop,
        sortedMoneyMind[0][0],
        null
      ),
      descriptionDisplay(
        'Secondary',
        moneymind.moneymindDescription.second.desktop,
        sortedMoneyMind[1][0],
        null
      ),
      descriptionDisplay(
        'Secondary',
        moneymind.moneymindDescription.third.desktop,
        sortedMoneyMind[2][0],
        null
      )
    ]
  }

  return (
    <Fragment>
      {getDescriptions().map((description, keyPos) => (
        <Card
          key={keyPos}
          id='mm-description'
          className='rounded-0 border-0 bg-white description__card'>
          {description}
        </Card>
      ))}
    </Fragment>
  )
}

export default Description
