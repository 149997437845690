import * as actions from '../actions/goalRequests.action'
import { GoalRequestObjState, GoalRequestObj } from '../objects/plan'

export interface GoalRequestObjInterface {
  requests: GoalRequestObjState
}

const initState: GoalRequestObjInterface = {
  requests: {}
}

const GoalRequests = (
  state = initState,
  action: any
): GoalRequestObjInterface => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_GOAL_REQUESTS}_FULFILLED`:
      return { ...state, requests: mapGoalRequests(action.payload) }
    case `${actions.CREATE_GOAL_REQUEST}_FULFILLED`:
    case `${actions.UPDATE_GOAL_REQUEST}_FULFILLED`:
      const newGoalRequest = mapGoalRequest(action.payload)
      if (newGoalRequest.existingId) {
        newState.requests = {
          ...newState.requests,
          [newGoalRequest.existingId]: newGoalRequest
        }
      } else {
        newState.requests = {
          ...newState.requests,
          [newGoalRequest.id]: newGoalRequest
        }
      }
      return newState
    default:
      return state
  }
}

const mapGoalRequests = (goalRequests: GoalRequestObj[]) => {
  const requestObj = {}
  goalRequests.forEach((goalRequest: GoalRequestObj) => {
    if (goalRequest.existingId) {
      requestObj[goalRequest.existingId] = mapGoalRequest(goalRequest)
    } else {
      requestObj[goalRequest.id] = mapGoalRequest(goalRequest)
    }
  })
  return requestObj
}

const mapGoalRequest = (goalRequest: GoalRequestObj) => {
  return {
    id: goalRequest.id,
    existingId: goalRequest.existingId,
    comment: goalRequest.comment,
    requestType: goalRequest.requestType,
    status: goalRequest.status,
    proposedName: goalRequest.proposedName,
    proposedGoalType: goalRequest.proposedGoalType,
    proposedAmount: goalRequest.proposedAmount,
    proposedAmountType: goalRequest.proposedAmountType,
    proposedAnnualIncrease: goalRequest.proposedAnnualIncrease,
    proposedGoalOwnerId: goalRequest.proposedGoalOwnerId,
    proposedStartOfGoal: goalRequest.proposedStartOfGoal,
    proposedEndOfGoal: goalRequest.proposedEndOfGoal
  }
}

export default GoalRequests
