import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { GlobalState } from '../../reducers'
import * as actions from '../../actions/tasks.action'
import { TasksHeader } from './TasksHeader'
import TasksTable from './TasksTable'
import { CompletedCard } from './CompletedCard'
import NullTasks from './NullTasks'
import { tasksSelector } from '../../selectors/tasks'
import store from '../../store'
import { taskRefetch } from '../../helpers/tasks'

type TasksProps = ConnectedProps<typeof connector>

const Tasks = (props: TasksProps) => {
  const {
    getTasks,
    tasks,
    hasCompleted,
    filtered,
    setFiltered,
    setSort,
    sortBy,
    resetSortFilter
  } = props

  useEffect(() => {
    window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true' &&
    store.getState().user?.isFinlifeInstitution === false &&
    store.getState().user?.enableCRMTasks === true
      ? taskRefetch()
      : getTasks()
    return () => {
      resetSortFilter()
    }
  }, [])

  return (
    <div>
      <TasksHeader
        sortBy={sortBy}
        filtered={filtered}
        onSort={setSort}
        onFilter={() => setFiltered(!filtered)}
      />

      <div className='tasks-header task-item__details-subject mw-100 mb-2'>
        Click the circle to mark task complete
      </div>

      <div
        className={
          hasCompleted
            ? 'tasks-page__body--completed'
            : 'tasks-page__body--not-completed'
        }>
        {!tasks.length ? <NullTasks /> : <TasksTable tasks={tasks} />}
      </div>
      {hasCompleted && <CompletedCard />}
    </div>
  )
}

const mapDispatchToProps = {
  getTasks: actions.getTasks,
  setFiltered: actions.filterTasks,
  resetSortFilter: actions.resetSortFilter,
  setSort: actions.sortTasksBy
}

const mapStateToProps = (store: GlobalState) => {
  const { tasks, hasCompleted, filtered, sortBy } = tasksSelector(store)
  return {
    tasks,
    hasCompleted,
    filtered,
    sortBy
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Tasks)
