import Api from '../helpers/api'
import { CardObj } from '../objects/honestConversation'

export const FETCH_PRIORITY_ACTION_LIST: string = 'FETCH_PRIORITY_ACTION_LIST'

export const getPAL = (
  cards
): {
  type: string
  payload: Promise<{
    honestConversations: CardObj
    priorityActions
  }>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_PRIORITY_ACTION_LIST,
    payload: apiInstance
      .get('/palquestions')
      .then((response) => {
        return response.data
      })
      .then((data) => {
        return {
          honestConversations: cards,
          priorityActions: data
        }
      })
  }
}
