import React from 'react'
import { tableColumns } from './PendingUpdateTable'
import { GoalRequestObj, FinancialGoalObj } from '../../../objects/plan'

type TTableData = {
  id: string
  goal: FinancialGoalObj
  request: GoalRequestObj
  onEditRequest: (request: GoalRequestObj) => void
}[]

type TTableColumns = typeof tableColumns

const renderInputTableRows = (data: TTableData, columns: TTableColumns) => {
  return data?.map((item, index) => (
    <tr key={item.id} className='pending-updates__table-row'>
      {columns.map((column, colIndex) => {
        const CellComponent = column.Cell
        return (
          <td
            key={colIndex}
            style={column.style as any}
            className={column.className}>
            <CellComponent
              goal={item.goal}
              onEditRequest={item.onEditRequest}
              request={item.request}
            />
          </td>
        )
      })}
    </tr>
  ))
}

export const CustomPlanUpdateTable = ({
  id,
  data
}: {
  id: string
  data: TTableData
}) => {
  const columns = tableColumns

  return (
    <table id={id}>
      <tbody>{renderInputTableRows(data, columns)}</tbody>
    </table>
  )
}
