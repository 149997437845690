import React from 'react'
import { Link } from 'react-router-dom'

export interface DisclosureFooterProps {
  locationPath: string
  hasOffers: boolean
}

export const DisclosureFooter = (props: DisclosureFooterProps) => {
  const { locationPath, hasOffers } = props

  return (
    <div className='disclosures__footer' style={{ marginTop: '16px' }}>
      <p className='disclosures__text--footer'>
        The information contained in this material is for general informational
        purposes only and does not constitute an offer, solicitation, invitation
        or recommendation to buy or sell any financial instruments or to provide
        any investment advice or service of any kind.
      </p>
      <Link
        to={{
          pathname: '/disclosures',
          state: { section: 'marketplaceOffer', prevPath: locationPath }
        }}
        className='disclosures__link'>
        Click for more important information about this page
      </Link>
      {!hasOffers && (
        <Link
          to={{
            pathname: '/disclosures',
            state: {
              prevPath: locationPath,
              section: 'marketplaceOffer'
            }
          }}
          className='disclosures__link'>
          Click here for important information regarding resource offers
        </Link>
      )}
    </div>
  )
}
