import React, { useEffect, useState } from 'react'
import { acceptTOSandPrivacyPolicy } from '../../actions/user.action'
import { revokeAuthenticationToken } from '../../actions/auth.action'
import { logout } from '../../helpers'
import dayjs from 'dayjs'
import { connect } from 'react-redux'

import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'

type TosModalStore = ReturnType<typeof mapStateToProps>
export type TosModalProps = TosModalStore

const tooltipMessages = {
  'Privacy Notice': 'Please click on Privacy Notice link to continue.',
  'Terms of Use': 'Please click on the Terms of Use link to continue.',
  'Privacy Policy': 'Please click on the Privacy Policy link to continue.'
}

const renderLink = (title: string, destination: string, setState) => {
  return (
    <a
      id={title}
      href={destination}
      target='_blank'
      rel='noopener noreferrer'
      className='tos-modal__body-text--link'
      onClick={setState}>
      {title}
    </a>
  )
}

const setTooltipMessages = (title: string, messages: {}, setter) => {
  const content = Object.keys(messages).reduce((curr, key) => {
    return key !== title ? Object.assign(curr, { [key]: messages[key] }) : curr
  }, {})

  Object.keys(content).length === 0 &&
    Object.assign(content, {
      Attestiation: 'Please confirm by selecting the check box.'
    })

  setter(content)
}

export const TouModal = (props: TosModalProps) => {
  const [openedAllLinks, setOpenedLinks] = useState(false)
  const [attestation, setAttestation] = useState(false)
  const [tooltipContent, setTooltipContent] = useState(tooltipMessages)
  const [isActive, setIsActive] = useState(true)
  useEffect(() => {
    const tooltipContentCount =
      Object.keys(tooltipContent).filter((key) => key !== 'Attestiation')
        .length === 0

    setOpenedLinks(tooltipContentCount)
  }, [tooltipContent])

  const linkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    setTooltipMessages(e.currentTarget.id, tooltipContent, setTooltipContent)
  }

  const checkBoxClick = () => {
    openedAllLinks && setAttestation(!attestation)
  }

  const privacyNoticeLink = renderLink(
    'Privacy Notice',
    window._env_.REACT_APP_PRIVACY_NOTICE_URL,
    linkClick
  )

  const termsLink = renderLink(
    'Terms of Use',
    window._env_.REACT_APP_TERMS_OF_SERVICE_URL,
    linkClick
  )

  const privacyPolicyLink = renderLink(
    'Privacy Policy',
    window._env_.REACT_APP_PRIVACY_POLICY_URL,
    linkClick
  )

  const dispatchAcceptance = (e: React.MouseEvent<HTMLButtonElement>) => {
    const attestatiated: string[] = e.currentTarget.id.split('-')
    if (attestatiated[attestatiated.length - 1] === 'true') {
      const todayDate = dayjs().format('YYYY-MM-DD')
      setIsActive(false)
      props.dispatch(acceptTOSandPrivacyPolicy(todayDate))
    }
  }
  const closeModal = async () => {
    await props.dispatch(revokeAuthenticationToken())
    logout()
  }

  const saveAndContinue = (
    <Button
      size='lg'
      variant='primary'
      disabled={!attestation}
      id={`tou-modal-${attestation}`}
      className='fs-6 tou-modal__save-button'
      onClick={dispatchAcceptance}>
      Save & Continue
    </Button>
  )

  return (
    <Modal show={isActive} onHide={closeModal} className='tou-modal' size='lg'>
      <Modal.Header closeButton className='tou-modal__header'>
        <h1 className='tou-modal__title'>Terms of Use and Privacy Policy</h1>
      </Modal.Header>
      <Modal.Body className='tou-modal__body'>
        <Container className='tou-modal__body-text'>
          <Row className='no-gutters  mx-auto'>
            <Col className='flex-grow-0'>
              <Form.Check
                className='tou-modal__checkbox pe-3'
                name='agree-to'
                id='tou-modal-checkbox'
                checked={attestation}
                onChange={checkBoxClick}
              />
            </Col>

            <Col>
              <span className='mt-4'>
                By checking this box, you (i) agree to receive our Privacy
                Notice electronically and (ii) agree to and acknowledge receipt
                of our {privacyNoticeLink}, {termsLink}, and {privacyPolicyLink}
                .
              </span>
            </Col>
          </Row>

          <Row className='no-gutters mx-auto d-flex d-md-none mt-4'>
            <Col className='flex-grow-0'>
              <i className='bi bi-exclamation-circle fs-3 text-warning pe-3' />
            </Col>
            <Col>
              <span>
                Please check the box above and visit the Privacy Notice, Terms
                of Use and Privacy Policy links above before continuing.
              </span>
            </Col>
          </Row>

          <div className='tou-modal__buttons'>
            {!attestation ? (
              <OverlayTrigger
                placement='bottom'
                overlay={
                  <Tooltip className='tou-modal__tooltip'>
                    <>
                      {Object.keys(tooltipContent).map((key) => {
                        return (
                          <p
                            key={key}
                            className='text-body text-start fs-6 mb-0'>
                            {tooltipContent[key]}
                          </p>
                        )
                      })}
                    </>
                  </Tooltip>
                }>
                <div>{saveAndContinue}</div>
              </OverlayTrigger>
            ) : (
              <div>{saveAndContinue}</div>
            )}

            <Button
              variant='link'
              size='lg'
              id='tou-modal-cancel'
              onClick={closeModal}
              className='ms-3 fs-6 text-primary w-auto'>
              Cancel
            </Button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (store: GlobalState, dispatch: AppDispatch) => {
  return { termsOfServiceAccepted: store.user.termsOfServiceAccepted, dispatch }
}

export default connect(mapStateToProps)(TouModal)
