import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'
import ManualAccountForm from '../ManualAccountForm'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { ClientAccountObj } from '../../../objects/clientAccount'
import { history } from '../../../store'
import LinkedAccountForm from '../LinkedAccountForm'

type NetWorthEditAccountProps = ReturnType<typeof mapStateToProps>

export const NetWorthEditAccount = (props: NetWorthEditAccountProps) => {
  const { account } = props
  const isManualAccount =
    account?.source === null ||
    account?.source === undefined ||
    account?.source === 'Guide Center - Manual'
  const isYodleeAccount = account?.source === 'Guide Center - Yodlee'
  const onClose = () => {
    history.replace('/net-worth')
  }

  useEffect(() => {
    if (!isManualAccount && !isYodleeAccount) {
      onClose()
    }
  }, [isManualAccount, isYodleeAccount])

  return (
    <div className='px-4 px-sm-0'>
      <div style={{ maxWidth: '538px', margin: '0 auto' }}>
        <Card
          id='networth-edit-account'
          className='border-0 rounded-0 p-x2 bg-white'>
          {isManualAccount ? (
            <ManualAccountForm
              className=''
              onClose={onClose}
              account={account}
              modalType='edit'
              closeOnError={true}
            />
          ) : isYodleeAccount ? (
            <LinkedAccountForm
              className=''
              onClose={onClose}
              account={account}
              modalType='edit'
              closeOnError={true}
            />
          ) : null}
        </Card>
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const accountId = match.params.accountId
  const clientAccounts = store.clientAccounts
  const { assets, pending, liabilities } = clientAccounts
  const allAccounts = assets.concat(pending).concat(liabilities)
  let account: ClientAccountObj
  if (allAccounts && accountId) {
    account = allAccounts.find((acc) => acc.id === accountId)
  }
  return {
    account
  }
}
export default withRouter(connect(mapStateToProps)(NetWorthEditAccount))
