import React from 'react'
import { CSSTransition } from 'react-transition-group'
import TaskItem from './TaskItem'
import DashTaskRow from '../Dashboard/Tasks/DashTaskRow'
import { TaskObj } from '../../objects/task'

interface TasksTableProps {
  tasks: TaskObj[]
  dashboard?: boolean
  className?: string
}

const TasksTable = (props: TasksTableProps): any =>
  props.dashboard ? (
    props.tasks.map((task) => <DashTaskRow key={task.id} task={task} />)
  ) : (
    <div className={`${props.className} bg-white`}>
      {props.tasks.map((task) => (
        <CSSTransition
          key={task.id}
          component='div'
          classNames='task-item__fade-animation'
          timeout={{ enter: 400, exit: 400 }}>
          <TaskItem task={task} />
        </CSSTransition>
      ))}
    </div>
  )

export default TasksTable
