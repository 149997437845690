import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { connect, ConnectedProps } from 'react-redux'
import { v4 } from 'uuid'

import { GlobalState } from '../../reducers'

import {
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
} from '../../actions/toasts.action'
import * as actions from '../../actions/documentVault.action'

import { folderDetailSelector } from '../../selectors/docvault'

import { useWindowSize } from '../../hooks'

import UploadFile from './UploadFile/UploadFile'
import NullDocVault from './NullView'
import DeleteModal from './DeleteModal'
import EditModal from './EditModal'
import MoveModal from './MoveModal'

import { getDocVaultFileRow } from './VaultTableRows'

import Api from '../../helpers/api'
import {
  getFileNameWithExtensionInLowerCase,
  downloadFile,
  navigatorPlatformTypeEquals,
  useModalReducer,
  ModalActions
} from '../../helpers/docvault'

export type FolderDetailProps = ConnectedProps<typeof connector>

export const FolderDetail = (props: FolderDetailProps): JSX.Element => {
  const { modalState, handleModal } = useModalReducer()
  const [currentId, setCurrentId] = useState('')
  const [pendingUploads, setPendingUploads] = useState({})
  const [fileFormatError, setFileFormatError] = useState(false)
  const [fileUploadPending, setFileUploadPending] = useState(false)
  const { width } = useWindowSize()

  const {
    folder,
    files,
    getFolders,
    editFile,
    getFolder,
    addSuccessToast,
    addErrorToast,
    networkErrorOrMsg,
    updateArchivedFile,
    moveFile
  } = props

  useEffect(() => {
    getFolders()
  }, [])

  const toggleModal = (
    id: string = '',
    action?: 'edit' | 'delete' | 'upload' | 'move',
    type?: 'file' | 'folder' | 'error'
  ) => {
    setCurrentId(id)
    if (action && type) {
      const modalAction = `show_${action}_${type}` as ModalActions
      handleModal(modalAction)
    } else {
      handleModal('close_modal')
    }
  }

  const toggleFileUpload = () => {
    const { showUploadFile } = modalState
    if (!showUploadFile) {
      handleModal('show_upload_file')
    } else {
      handleModal('close_modal')
    }
  }

  const editFn = async (id: string, name: string) => {
    const folderId = folder.id
    try {
      await editFile(folderId, id, name.replace(/ /g, '_'))
      addSuccessToast({
        message: 'File renamed'
      })
    } catch (error) {
      networkErrorOrMsg(error, 'Unable to rename file')
      getFolder(folderId)
    }
    toggleModal()
  }

  const uploadResponse = (_response, uuid) => {
    delete pendingUploads[uuid]
    setPendingUploads(pendingUploads)
    toggleModal()
    setFileUploadPending(false)
    setFileFormatError(false)

    getFolder(folder.id)
    addSuccessToast({ message: 'File uploaded' })
  }

  const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE

  const uploadFailure = (_reason) => {
    setPendingUploads({})
    setFileUploadPending(false)
    setFileFormatError(true)
    toggleModal()
    addErrorToast({
      message: `Unable to upload file. Please make sure the file is in the right format, and does not exceed ${maxVaultFileSize} MB in size.`,
      actionBtn: {
        text: 'Try Again',
        onClick: () => {
          handleModal('show_upload_file')
        }
      }
    })
  }

  const onUploadProgress = (progressEvent, uuid) => {
    const percentCompleted = Math.floor(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    pendingUploads[uuid].uploadProgress = percentCompleted
    setPendingUploads(pendingUploads)
  }

  const validateFileSizeLimit = (
    rawFile: any,
    uuid: string,
    maxFileSize: number
  ) => {
    //75mb = 78643200(binary) / 1024 / 1024
    const fileSize = rawFile?.size ? rawFile?.size / 1024 / 1024 : 0
    if (fileSize > maxFileSize) {
      delete pendingUploads[uuid]
      setPendingUploads(pendingUploads)
      setFileUploadPending(false)
      setFileFormatError(true)
      return true
    }
    return false
  }

  const uploadFiles = (files: any[]) => {
    files.forEach((rawFile) => {
      const apiInstance = Api.getInstance()
      const uuid = v4()

      let fileName = rawFile.name.replace(/ /g, '_')
      fileName = getFileNameWithExtensionInLowerCase(fileName)

      pendingUploads[uuid] = {
        name: fileName,
        uploadProgress: 0
      }

      //Setting the state for both v1 and v2 architecture
      setPendingUploads(pendingUploads)
      setFileUploadPending(true)
      setFileFormatError(false)

      // add event listener
      if (
        validateFileSizeLimit(
          rawFile,
          uuid,
          window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
        )
      ) {
        return
      }
      const formData = new FormData()
      formData.append('file', rawFile, fileName)

      apiInstance
        .post(`/portaldocumentvault/v2/folders/${folder.id}/files`, formData, {
          onUploadProgress: (progressEvent) =>
            onUploadProgress(progressEvent, uuid),
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout: 0
        })
        .then(
          (_response) => uploadResponse(_response, uuid),
          (_reason) => uploadFailure(_reason)
        )
    })
  }

  const deleteFn = async (id: string) => {
    if (id) {
      const folderId = folder.id
      try {
        await updateArchivedFile(folderId, id, true)
        addSuccessToast({
          message: 'File deleted',
          actionBtn: {
            text: 'Undo',
            onClick: async () => {
              await updateArchivedFile(folderId, id, false)
              await getFolder(folderId)
            }
          }
        })
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to delete file')
        getFolder(folderId)
      }
    }
    toggleModal()
  }

  const moveFn = async (id: string, newFolderId: string) => {
    if (newFolderId) {
      toggleModal()
      const oldFolderId = folder.id
      try {
        await moveFile(oldFolderId, id, newFolderId)
        addSuccessToast({ message: 'File moved' })
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to move file')
        getFolder(oldFolderId)
      }
    }
  }

  const handleRowClick = (row) => {
    const { content } = row
    handleDownloadFile(content?.folderId, content?.id, content?.name)
  }

  const handleDownloadFile = (
    folderId: string,
    fileId: string,
    fileName: string
  ) => {
    if (
      navigatorPlatformTypeEquals('iPhone') ||
      navigatorPlatformTypeEquals('iPad')
    ) {
      addErrorToast({
        message: `You can't download vault files on your tablet through the browser version of GuideCenter.`
      })
      return
    }
    try {
      downloadFile(folderId, fileId, fileName)
    } catch {}
  }

  const generateTableData = () => {
    return files.map((item) => ({
      ...item,
      content: {
        ...item.content,
        toggleModal
      }
    }))
  }

  const getCurrentName = () => {
    if (!currentId) return ''
    return files.find((el) => el.id === currentId)?.content?.name
  }

  const currentName = getCurrentName()
  const tableData = generateTableData()
  const docVaultFileRow = getDocVaultFileRow(width)

  if (!folder) return null

  return (
    <div>
      <Link
        to='/document-vault'
        className='folder-detail__btn--back ps-4 py-3 ps-sm-0'>
        <i className='bi bi-chevron-left fs-7 me-2' />
        Back
      </Link>
      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4 px-4 px-sm-0'>
        <h2 className='rfont--h2 fw-bold text-dark text-break flex-basis--12 flex-basis-md--6 text-wrap mb-3 mb-lg-0'>
          {folder.name}
        </h2>

        <UploadFile
          showUploadFileModal={modalState.showUploadFile}
          fileUploadPending={fileUploadPending}
          toggleUploadFileModal={toggleFileUpload}
          fileFormatError={fileFormatError}
          uploadFiles={uploadFiles}
          folderId={folder.id}
        />
      </div>

      <div id='doc-vault-table-container' className='bg-white p-2 p-sm-x2 mb-9'>
        {tableData.length === 0 ? (
          <NullDocVault
            headerText='No files here'
            supportText='There are no files in this folder.'
          />
        ) : (
          <table id='file-vault-table' className='vault__table'>
            <thead>
              <tr className='vault__table-head'>
                {docVaultFileRow.map((cell) => (
                  <th key={cell.Cell.name} style={cell.columnHeaderStyle}>
                    {cell.columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData) => {
                return (
                  <tr
                    key={rowData.id}
                    role='button'
                    className='vault__table-row vault__table-row--button'>
                    {docVaultFileRow.map((cell) => (
                      <td
                        key={cell.Cell.name + rowData.id}
                        className='vault__table-cell'
                        style={cell.style}
                        onClick={() => {
                          !cell.overflowable && handleRowClick(rowData)
                        }}>
                        {<cell.Cell {...rowData} />}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        )}
      </div>

      <DeleteModal
        showModal={modalState.showDeleteFile}
        type='file'
        id={currentId}
        deleteModal={deleteFn}
        closeModal={toggleModal}
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
      />

      <EditModal
        showModal={modalState.showEditFile}
        files={files}
        type='file'
        id={currentId}
        name={currentName}
        editModal={editFn}
        closeModal={toggleModal}
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
      />

      <MoveModal
        id={currentId}
        showModal={modalState.showMoveFile}
        oldFolderId={folder.id}
        moveModal={moveFn}
        closeModal={toggleModal}
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
      />
    </div>
  )
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    folder: store.documentVault.folders[match.params.folderFinId],
    files: folderDetailSelector(store, match.params.folderFinId)
  }
}

const mapDispatchToProps = {
  getFolders: actions.getFolders,
  editFile: actions.editFile,
  getFolder: actions.getFolder,
  updateArchivedFile: actions.updateArchivedFile,
  moveFile: actions.moveFile,
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default withRouter(connector(FolderDetail))
