import React, { Fragment } from 'react'
import {
  marketplaceOfferObj,
  marketplaceOfferRequestInterface
} from '../../objects/marketplaceOffers'
/**
 * Function that returns array of dom nodes for marketplace copyrightDisclosure
 */
export const marketplaceOfferDisclosures = (
  marketplaceOffers: marketplaceOfferRequestInterface
) => {
  return (
    (marketplaceOffers &&
      Object.keys(marketplaceOffers).reduce((result, key) => {
        const offer: marketplaceOfferObj = marketplaceOffers[key]
        if (offer.enabled && offer.copyrightDisclosure) {
          result.push(
            <Fragment key={offer.id}>
              <h3 className='disclosures__section-subtitle disclosures__section-subtitle--large'>
                {offer.name}
              </h3>
              <p
                className='disclosures__text'
                dangerouslySetInnerHTML={{
                  __html: offer.copyrightDisclosure
                }}></p>
            </Fragment>
          )
        }
        return result
      }, [])) ||
    null
  )
}
