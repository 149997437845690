import { history } from '../store'
import { map } from 'lodash-es'
import dayjs from 'dayjs'
import {
  FinancialGoalsSort,
  FinancialGoalsSortValue,
  FinancialGoalsSortType,
  FinancialGoalType,
  FinancialGoalsByType,
  FinancialGoalObj,
  GoalRequestObj
} from '../objects/plan'
import * as SortHelper from '../helpers/sort'
import { valueOrDefault } from '../helpers'

export const pointColor = {
  other: '#8a8888',
  rangeLow: '#B7343F',
  rangeHigh: '#d8761c',
  optimal: '#068667'
}

export const closeForm = () => history.replace('/financial-plan')

export const handlePlanEdit = (planData: GoalRequestObj | FinancialGoalObj) => {
  history.push(`/financial-plan/edit/${planData.id}`)
}

export const currencyOptions = [
  { value: 'Dollar', label: '$' },
  { value: 'Percent', label: '%' }
]

export const typeOptions = [
  { value: 'Savings Goal', label: 'Savings Goal' },
  { value: 'Spending Goal', label: 'Spending Goal' },
  { value: 'Legacy Goal', label: 'Legacy Goal' },
  { value: 'Timing Control', label: 'Timing Control' },
  { value: 'Retirement/Other Income', label: 'Retirement/Other Income' }
]

export const sortTypes = [
  'Timing',
  'Savings',
  'Spending',
  'Legacy',
  'Retirement'
] as const

export const sortTypeToGoalType: {
  [K in FinancialGoalsSortType]: FinancialGoalType
} = {
  Timing: 'Timing Control',
  Savings: 'Savings Goal',
  Spending: 'Spending Goal',
  Legacy: 'Legacy Goal',
  Retirement: 'Retirement/Other Income'
}

export const defaultByType = (): FinancialGoalsByType => ({
  'Legacy Goal': [],
  'Spending Goal': [],
  'Retirement/Other Income': [],
  'Savings Goal': [],
  'Timing Control': []
})

const fieldToSortFunc = {
  amount: SortHelper.compareNumericField,
  name: SortHelper.compareStringField,
  needsWantsWishes: SortHelper.compareStringField
}

const sortValueToField: { [K in FinancialGoalsSortValue]: string } = {
  Amount: 'amount',
  Name: 'name',
  Period: 'startDate',
  Priority: 'needsWantsWishes'
}

export const sortGoals = (
  goalsByType: FinancialGoalsByType,
  prefs: FinancialGoalsSort
): FinancialGoalsByType => {
  // apply sort to each goal type list
  map(goalsByType, (goals, goalType: FinancialGoalType) => {
    const { sortValue, descending } = prefs[goalType]
    const field: string = sortValueToField[sortValue]
    // same as AC
    // sort valid dates separately from non-dates
    if (field === 'startDate') {
      const validDates: FinancialGoalObj[] = []
      const otherDates: FinancialGoalObj[] = []

      goals.forEach((goal) => {
        if (goal.startDate && dayjs(goal.startDate).isValid()) {
          validDates.push(goal)
        } else {
          otherDates.push(goal)
        }
      })

      validDates.sort((a, b) =>
        SortHelper.compareDates(a[field], b[field], descending)
      )
      otherDates.sort((a, b) => {
        const nullSortVal = SortHelper.nullSort(a[field], b[field], !descending)
        if (nullSortVal !== undefined) {
          return nullSortVal
        }
        return SortHelper.compareStringField(a[field], b[field], descending)
      })

      goalsByType[goalType] = descending
        ? [...validDates, ...otherDates]
        : [...otherDates, ...validDates]
    } else {
      goalsByType[goalType] = goals.sort((a, b) => {
        const valueA = a[field]
        const valueB = b[field]

        // same as AC
        // null amounts are sorted opposite of other null values
        const nullFirst = field === 'amount' ? descending : !descending
        const nullSortVal = SortHelper.nullSort(valueA, valueB, nullFirst)
        if (nullSortVal !== undefined) {
          return nullSortVal
        }

        const sorter = fieldToSortFunc[field]
        if (!sorter) {
          return SortHelper.compareDates(
            a.createdDate,
            b.createdDate,
            descending
          )
        }

        const sorted = sorter(valueA, valueB, descending)
        if (sorted !== 0) {
          return sorted
        }
        // sort by createdDate if sorting is a tie
        return SortHelper.compareDates(a.createdDate, b.createdDate, descending)
      })
    }
  })

  return goalsByType
}

export const getPeriod = (startDate: string, endDate: string): string => {
  if (!startDate && !endDate) {
    return ''
  }

  if (startDate && endDate) {
    return `${startDate} - ${endDate}`
  }

  return `${valueOrDefault(startDate)}${valueOrDefault(endDate)}`
}
