import UAParser from 'ua-parser-js'

/**
 * Browser icons from Alrra
 * @see {@link https://github.com/alrra/browser-logos}
 * @license MIT
 */
import DefaultBrowserIcon from '../assets/images/browsers/web/web_64x64.png'
import ChromeBrowserIcon from '../assets/images/browsers/chrome/chrome_64x64.png'
import FirefoxBrowserIcon from '../assets/images/browsers/firefox/firefox_64x64.png'
import EdgeBrowserIcon from '../assets/images/browsers/edge/edge_64x64.png'
import SafariBrowserIcon from '../assets/images/browsers/safari-ios/safari-ios_64x64.png'
import OperaBrowserIcon from '../assets/images/browsers/opera/opera_64x64.png'

interface BrowserInfoObject {
  name: string
  icon: string
}

/**
 * Internal browser map used for mapping the browser name and icon.
 *
 * @see getBrowserData
 */
const browserMap = {
  default: {
    name: 'Browser',
    icon: DefaultBrowserIcon
  },
  chrome: {
    name: 'Chrome',
    icon: ChromeBrowserIcon
  },
  firefox: {
    name: 'Firefox',
    icon: FirefoxBrowserIcon
  },
  edge: {
    name: 'Edge',
    icon: EdgeBrowserIcon
  },
  safari: {
    name: 'Safari',
    icon: SafariBrowserIcon
  },
  opera: {
    name: 'Opera',
    icon: OperaBrowserIcon
  }
}

/**
 * Prase the UA string and return a global object for consumption.
 */
const BROWSER_INFO = new UAParser()

/**
 * Return the browser icon and name.
 * Because there are different name variations for the same browser
 * we map them to our list.
 *
 * If a browser is not found return the default value.
 */
function getBrowserData(): BrowserInfoObject {
  const { name } = BROWSER_INFO.getBrowser()

  // If for some reason we can't parse the browser name
  if (!name) return browserMap['default']

  switch (name.toLowerCase()) {
    case 'chrome':
    case 'chromium':
      return browserMap['chrome']
    case 'fifefox':
      return browserMap['firefox']
    case 'edge':
    case 'edge chromium':
      return browserMap['edge']
    case 'safari':
    case 'mobile safari':
      return browserMap['safari']
    case 'opera':
    case 'opera mobi':
      return browserMap['opera']
    default:
      return browserMap['default']
  }
}

/**
 * Return the device type of the user.
 * In some older UA strings type is not strictly define but we can figure
 * that our with the help of the OS.
 * Therefore, if a user has any of the popular mobile OS's map it to mobile.
 */
function getDeviceType() {
  const { type } = BROWSER_INFO.getDevice()
  const { name: osName } = BROWSER_INFO.getOS()

  if (type) return type

  switch (osName.toLowerCase()) {
    case 'android':
    case 'blackberry':
    case 'windows mobile':
    case 'windows phone':
    case 'ios':
      return 'mobile'
    default:
      return 'desktop'
  }
}

/**
 * Extract browser info and return the required browser info object.
 */
export default function() {
  const type = getDeviceType()
  const browser = getBrowserData()
  const os = BROWSER_INFO.getOS()

  return { type, browser, os }
}
