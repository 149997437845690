import React, { useEffect, useCallback, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import InsuranceCard from './InsuranceCard'
import InsuranceNull from './InsuranceNull'
import { connect } from 'react-redux'
import * as insuranceActions from '../../actions/insurance.action'
import { GlobalState } from '../../reducers'

type InsuranceActions = typeof mapDispatchToProps
type InsuranceStore = ReturnType<typeof mapStateToProps>
export type InsuranceTableProps = InsuranceActions & InsuranceStore

const getSortedInsurance = (insuranceList) => {
  const insuranceTypes = [
    'Life Insurance',
    'Disability',
    'Long Term Care',
    'Annuity'
  ]
  let sortedInsurance = []
  insuranceTypes.forEach((insuranceType) => {
    sortedInsurance = sortedInsurance.concat(
      insuranceList.filter(
        (insurance) =>
          insurance &&
          insurance.recordTypeName &&
          insurance.recordTypeName === insuranceType
      )
    )
  })
  return sortedInsurance
}

export const InsuranceTable = (props: InsuranceTableProps) => {
  const { insurance, getInsurance } = props
  const [insuranceRequest, setInsuranceRequest] = useState(false)

  const fetchInsuranceData = useCallback(async () => {
    await getInsurance()
    setInsuranceRequest(true)
  }, [])

  useEffect(() => {
    fetchInsuranceData()
  }, [])

  const isNull =
    !insurance.length ||
    (insurance.length === 1 && !insurance[0].recordTypeName)

  const sortedInsurance = getSortedInsurance(insurance)
  return (
    <div>
      {insuranceRequest && isNull && <InsuranceNull />}
      {insuranceRequest && !isNull && (
        <div className='container-fluid'>
          <Row className='flex-column'>
            {sortedInsurance.map((ins) => {
              return (
                <Col>
                  <InsuranceCard insurancePolicy={ins} />
                </Col>
              )
            })}
          </Row>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getInsurance: insuranceActions.getInsurance
}

const mapStateToProps = (store: GlobalState) => {
  return {
    insurance: store.insurance
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceTable)
