import React, { CSSProperties, FC } from 'react'
import classnames from 'classnames'

interface IStepsData {
  label: string | number
  isComplete?: boolean
  isCurrent?: boolean
  isError?: boolean
  isDisabled?: boolean
}

export interface IStepsProps {
  data: IStepsData[]
  containerClassName?: string
  containerStyle?: CSSProperties
  itemClassName?: string
  markerClassName?: string
}
/**
 * @description Steps is functional component that displays sequence of numbered or logical steps.
 */
const Steps: FC<IStepsProps> = (props) => {
  const {
    data,
    containerClassName,
    containerStyle,
    itemClassName,
    markerClassName
  } = props

  const stepsContainerClassName = classnames(
    'steps__container',
    containerClassName
  )
  const stepsItemClass = 'steps__item'
  const stepsMarkerClass = 'steps__marker'

  return (
    <div className={stepsContainerClassName} style={containerStyle}>
      {data?.map((step, index) => {
        const nextStep = data[index + 1]
        const stepsItemClasses = classnames(stepsItemClass, itemClassName, {
          [`${stepsItemClass}--completed`]:
            step.isComplete && (nextStep?.isCurrent || nextStep?.isComplete),
          [`${stepsItemClass}--error`]: step.isError
        })
        const stepsMarkerClasses = classnames(
          stepsMarkerClass,
          markerClassName,
          {
            [`${stepsMarkerClass}--completed`]: step.isComplete,
            [`${stepsMarkerClass}--current`]: step.isCurrent,
            [`${stepsMarkerClass}--error`]: step.isError,
            [`${stepsMarkerClass}--disabled`]: step.isDisabled
          }
        )

        return (
          <div className={stepsItemClasses} key={step.label}>
            <div className={stepsMarkerClasses} />
          </div>
        )
      })}
    </div>
  )
}

export default Steps
