import { useRef, useEffect } from 'react'

/**
 * Skip effects on initial mount
 */
export const useIsMounted = () => {
  const isMounted = useRef(null)

  useEffect(() => {
    isMounted.current = true
  }, [])

  return isMounted.current
}
