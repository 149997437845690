import React, { useEffect } from 'react'
import { Query } from '@apollo/client/react/components'
import { QueryResult } from '@apollo/client'
import { connect } from 'react-redux'
import { getHousehold } from '../../actions/household.action'
import { GlobalState } from '../../reducers'
import { GetMessageThreadQuery } from '../../helpers/queries'
import { sortLatestThreads } from '../../helpers/messages'
import { ThreadList, MessageThread } from '../../objects/messages'
import { getMessageThreadRow } from './TableCells'
import { history } from '../../store'
import MessagesNull from './MessagesNull'
import { isObjEmpty } from '../../helpers'
import { MessagesCustomTable } from './MessagesCustomTable'

const navigateToThread = (row) => {
  history.push(`/messages/${row.threadId}`)
}

const compareSubjectFilter = (
  data: ThreadList,
  subjectFilter: string,
  renderThreadList
) => {
  const inputPattern = subjectFilter?.trim()?.replace(/(?=[^a-zA-Z0-9])/g, '\\')
  return renderThreadList(
    data?.allMessages?.nodes?.filter((item) => {
      return item.subject.match(RegExp(inputPattern, 'gi'))
    })
  )
}

interface MessagesTablePassedProps {
  subjectFilter: string
}

type MessagesTableStore = ReturnType<typeof mapStateToProps>
type MessagesTableActions = typeof mapDispatchToProps
export type MessagesTableProps = MessagesTableStore &
  MessagesTableActions &
  MessagesTablePassedProps

export const MessagesTable = (props: MessagesTableProps) => {
  const {
    householdFinId,
    householdFetched,
    subjectFilter,
    userId,
    getHousehold
  } = props

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [])

  const createTableData = (nodes: MessageThread[]) => {
    const { householdFinId } = props
    return sortLatestThreads(nodes).map(
      (thread: MessageThread, index: number) => {
        const date =
          thread.messagesByParent.nodes.length > 0
            ? thread.messagesByParent.nodes[0].createdDate
            : thread.createdDate
        return {
          id: thread.id,
          cellComponentProps: {
            subject: thread.subject,
            date,
            householdFinId,
            userId,
            threadId: thread.id
          }
        }
      }
    )
  }

  const renderThreadList = (nodes: MessageThread[]) => {
    const tableData = createTableData(nodes)
    return (
      <MessagesCustomTable
        id='message-thread-table'
        data={tableData}
        columns={getMessageThreadRow()}
        onRowClick={navigateToThread}
        className='messages-table__table'
      />
    )
  }

  return (
    <Query
      query={GetMessageThreadQuery}
      variables={{ householdFinId }}
      skip={!householdFinId}>
      {({ loading, error, data }: QueryResult) => {
        if (loading || (!error && !data))
          return <p className='px-3 pb-3'>Loading...</p>
        if (error || isObjEmpty(data) || !data.allMessages?.nodes?.length) {
          return <MessagesNull />
        }
        return (
          <div className='messages-table bg-white'>
            {compareSubjectFilter(data, subjectFilter, renderThreadList)}
          </div>
        )
      }}
    </Query>
  )
}

const mapDispatchToProps = {
  getHousehold
}

const mapStateToProps = (store: GlobalState) => {
  return {
    householdFinId: store.user.householdId,
    householdFetched: store.user.householdFetched,
    userId: store.user.id
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MessagesTable)
