import React from 'react'
import { Card } from 'react-bootstrap'

const PlanNull = () => {
  return (
    <div className='card-margin'>
      <Card id='plan-null' className='rounded-0 border-0 bg-white p-md-x2'>
        <div className='d-flex flex-row justify-content-center plan-null__row-container'>
          <div className='align-self-center plan-null__heading-container'>
            <h2 className='plan-null__heading'>No plan data</h2>
            <div className='plan-null__title-subheading'>
              Contact your advisor to discuss and have a financial plan created
              for you.
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default PlanNull
