import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import ProfileNavigation from './ProfileNavigation'
import { GlobalState } from '../../reducers'
import ProfileRoutes from '../../Routes/SubRoutes'
import { getHousehold as getHouseholdAction } from '../../actions/household.action'
import Overview from './Overview'
import Notifications from './Notifications'

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

type ProfileProps = ConnectedProps<typeof connector>

export const profileLinks = [
  {
    exact: true,
    text: 'Overview',
    path: '/my-profile',
    routes: ['/my-profile'],
    component: Overview
  },
  {
    text: 'Notifications',
    path: '/my-profile/notifications',
    routes: ['/my-profile/notifications'],
    component: Notifications
  }
]

const Profile = ({ householdFetched, getHousehold }: ProfileProps) => {
  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [])

  return (
    <Container fluid className='profile p-0'>
      <Row className='g-0'>
        <Col xs={12} md={3} lg={'auto'}>
          <ProfileNavigation />
        </Col>

        <Col xs={12} md={9} lg={'auto'} className='flex-grow-1'>
          <ProfileRoutes routes={profileLinks} />
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    householdFetched: store.user?.householdFetched
  }
}

const mapDispatchToProps = {
  getHousehold: getHouseholdAction
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Profile)
