import React, { useState, useRef, useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import Form from 'react-bootstrap/Form'
import dayjs from 'dayjs'

import { updateTaskStatus } from '../../actions/tasks.action'
import { addSuccessToast, addErrorToast } from '../../actions/toasts.action'

import { GlobalState } from '../../reducers'
import { TaskObj, TaskStatus } from '../../objects/task'
import { getContact } from '../../selectors/contacts'
import { isAssigned } from '../../helpers/tasks'

type TasksCheckboxOwnProps = {
  task: TaskObj
}

export type TaskCheckboxGroupProps = ConnectedProps<typeof connector> &
  TasksCheckboxOwnProps

export const TaskCheckboxGroup = ({
  task,
  contact,
  updateTaskStatus,
  addErrorToast,
  addSuccessToast
}: TaskCheckboxGroupProps): JSX.Element => {
  const [clicked, setClicked] = useState(false)
  const ref = useRef(false)

  useEffect(() => {
    ref.current = true
    return () => {
      ref.current = false
    }
  }, [])

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async (
    event
  ) => {
    const check = event.target.checked
    setClicked(true)
    const messageStatus = check ? 'complete' : 'in progress'
    const taskStatus = check ? TaskStatus.Completed : TaskStatus.InProgress
    const completedDate =
      taskStatus === TaskStatus.Completed ? dayjs().format('MM-DD-YYYY') : null

    try {
      await updateTaskStatus(task.id, taskStatus, completedDate)
      addSuccessToast({
        message: `Task marked ${messageStatus}`
      })
    } catch {
      addErrorToast({
        message: `Unable to mark task ${messageStatus}`
      })
    }
    if (ref.current) {
      setClicked(false)
    }
  }

  return (
    <Form.Check
      className='tasks__checkbox-input'
      name={task.id}
      id={task.id}
      onChange={handleChange}
      checked={
        task.status === TaskStatus.Completed ||
        task.status === TaskStatus.Cancelled
      }
      disabled={
        !isAssigned(task, contact) ||
        clicked ||
        task.status === TaskStatus.Cancelled
      }
    />
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    contact: getContact(store)
  }
}

const mapDispatchToProps = {
  updateTaskStatus,
  addSuccessToast,
  addErrorToast
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(TaskCheckboxGroup)
