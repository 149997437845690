import React from 'react'
import { labelItems } from '../../helpers/goals'
import { StatusType } from '../../objects/goals'

interface StatusDotProps {
  status: StatusType
}

export const StatusDot = (props: StatusDotProps) => {
  const { status } = props
  const style = labelItems[status] || {
    color: '#8a8888',
    shadowColor: '#e5e5e5'
  }
  return (
    <div>
      <div className='py-1 goal-card__label-col'>
        <span
          className='goal-card__dot'
          style={{
            backgroundColor: style.color,
            boxShadow: `0px 0px 0px 5px ${style.shadowColor}`
          }}
        />
      </div>
    </div>
  )
}

export default StatusDot
