import React from 'react'

import { dollarFormat, dateFormatNet } from '../../helpers'
import { Dropdown, DropdownButton } from 'react-bootstrap'

import { validErrorCode, isYodleeAccount } from '../../helpers/netWorth'
import { setYodleeParamsAccount } from '../../actions/yodlee.action'
import { ClientAccountObj } from '../../objects/clientAccount'

const NameCell = ({ content }) => {
  const { name, accountNumber, category, totalValue } = content
  const accountValue = Number(totalValue)
  const formattedTotalValue =
    accountValue < 0
      ? dollarFormat(accountValue, 0, true)
      : dollarFormat(accountValue, 0)
  return (
    <div className='account-table__name'>
      <span>
        {name}
        <span className='account-table__account-number'>({accountNumber})</span>
      </span>
      {category && <div className='account-table__category'>{category}</div>}
      <div className='account-table__field-mobile'>{formattedTotalValue}</div>
    </div>
  )
}

const TotalValue = ({ content }) => {
  const { totalValue } = content
  const accountValue = Number(totalValue)
  const formattedTotalValue =
    accountValue < 0
      ? dollarFormat(accountValue, 0, true)
      : dollarFormat(accountValue, 0)
  return (
    <div className='account-table__field'>
      <div className='account-table__field-value'>{formattedTotalValue}</div>
      <div className='account-table__label'>Balance</div>
    </div>
  )
}

const BalanceDate = ({ content }) => {
  const { date } = content
  const formattedDate = date ? dateFormatNet(date) : ''

  return (
    <div className='account-table__field'>
      <div className='account-table__field-value'>{formattedDate}</div>
      <div className='account-table__label'>{date ? 'Balance Date' : ''}</div>
    </div>
  )
}

const ErrorCell = ({ content }) => {
  return content &&
    validErrorCode(content.connectedErrorCode) &&
    !content?.hideErrorCell ? (
    <i className='bi bi-exclamation-circle text-warning fs-5' />
  ) : null
}

const setProviderId = (account: ClientAccountObj) => {
  setYodleeParamsAccount(account.providerAccountId)
}

const OverflowMenuCell = ({ content, account }) => {
  const { toggleModal } = content
  if (
    account?.source === 'Guide Center - Manual' ||
    account?.source === 'Guide Center - Yodlee'
  ) {
    return (
      <DropdownButton
        drop='down-centered'
        variant=''
        title={<i className='bi bi-three-dots'></i>}
        className='text-primary'>
        <Dropdown.Item
          className='action-dropdown__item-label'
          onClick={(e) => {
            e.stopPropagation()
            if (
              isYodleeAccount(account) &&
              validErrorCode(account.connectedErrorCode)
            ) {
              setProviderId(account)
              toggleModal(account, 'yodleeEdit')
            } else {
              toggleModal(account, 'edit')
            }
          }}>
          Edit
        </Dropdown.Item>
        <Dropdown.Item
          className='action-dropdown__item-label'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(account, 'delete')
          }}>
          Delete
        </Dropdown.Item>
      </DropdownButton>
    )
  } else return null
}

export const getAccountRow = (width) => {
  return [
    {
      Cell: NameCell,
      className: 'ps-x2 account-category__cell-name'
    },
    {
      Cell: TotalValue,
      className: 'px-3 account-category__cell-total'
    },
    {
      Cell: BalanceDate,
      className: 'account-category__cell-date'
    },
    {
      Cell: ErrorCell,
      className: 'account-category__cell-error'
    },
    {
      Cell: OverflowMenuCell,
      className: 'account-category__cell-overflow'
    }
  ]
}
