// MASTER LAYOUT
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'

import { RouteDataObj } from '../objects/routes'

// Components
import Toasts from '../components/Toasts'
import Header from '../components/Header'

import AppBottomSheet from '../components/Mobile/AppBottomSheet'
import AppBanner from '../components/Mobile/AppBanner'

// Migrated containers
import Auth from '../containers/Auth'
import Disclosures from '../containers/Disclosures'
import LogOut from '../containers/LogOut'
import SiteDisclosure from '../containers/SiteDisclosure'
import FeeSchedule from '../containers/FeeSummary'
// Migrated components
import { FooterContainer } from '../components/Layout'
import TimeoutModal from '../components/TimeoutModal'
import UpliftNavModal from '../components/UpliftNavModal'
// Containers
import Dashboard from '../containers/Dashboard'
import Plan from '../containers/Plan'
import Goals from '../containers/Goals'
import GoalsDetail from '../containers/Goals/Detail'
import FolderDetails from '../containers/DocVault/FolderDetails'
import DocumentVault from '../containers/DocVault'
import TrashedFiles from '../containers/DocVault/TrashedFiles'
import CompletedTasksPage from '../containers/Tasks/CompletedTasksPage'
import Insurance from '../containers/Insurance'
import NetWorth from '../containers/NetWorth'
import NetWorthAddAccount from '../containers/NetWorth/Add'
import NetWorthEditAccount from '../containers/NetWorth/Edit'
import AddEditForm from '../containers/Plan/PlanInputForms/AddEditForm'
import Profile from '../containers/Profile'
import Tasks from '../containers/Tasks'
import Messages from '../containers/Messages'
import MessageDetails from '../containers/Messages/MessageDetails'
import NewMessage from '../containers/Messages/NewMessage'
import MarketplaceOffers from '../containers/Marketplace'
import MoneyMind from '../containers/MoneyMind'
import Investments from '../containers/Investments'
import Positions from '../containers/Investments/Holdings'
import HonestConversations from '../containers/HonestConversations'
import UpdateScores from '../containers/HonestConversations/UpdateScores'
import PersonalPriorities from '../containers/HonestConversations/PersonalPriorities'
import PageNotFound from '../containers/Errors/PageNotFound'
import ErrorBoundary from '../containers/Errors/ErrorBoundary'

export const fullscreenPaths = [
  '/net-worth/account/add',
  '/net-worth/account/edit',
  '/honest-conversations/new-score',
  '/financial-plan/add',
  '/financial-plan/edit',
  '/messages/new'
]

const routeRender = (data, component): any => () => {
  const {
    history: {
      location: { pathname }
    }
  } = data

  const mainContainerCSS = fullscreenPaths.some((path) =>
    pathname.startsWith(path)
  )
    ? 'main-container main-container-fullscreen'
    : 'main-container'
  return (
    <div className={mainContainerCSS}>
      <div className='main-container-inner'>{component}</div>
      <FooterContainer />
    </div>
  )
}

const routeListGenerator = (data) => {
  const routeList = [
    { component: <Dashboard />, path: '/', exact: true },
    { component: <Profile />, path: '/my-profile' },
    { component: <NewMessage />, path: '/messages/new', exact: true },
    { component: <Messages />, path: '/messages', exact: true },
    { component: <MessageDetails />, path: '/messages/:threadId' },
    { component: <Insurance />, path: '/insurance' },
    { component: <NetWorth />, path: '/net-worth', exact: true },
    {
      component: <NetWorthAddAccount />,
      path: '/net-worth/account/add'
    },
    {
      component: <NetWorthEditAccount />,
      path: '/net-worth/account/edit/:accountId',
      exact: true
    },
    {
      component: <AddEditForm />,
      path: '/financial-plan/edit/:goalId',
      exact: true
    },
    {
      component: <AddEditForm />,
      path: '/financial-plan/add',
      exact: true
    },
    { component: <Plan />, path: '/financial-plan' },
    { component: <Goals />, path: '/goals' },
    { component: <GoalsDetail />, path: '/goals-detail/:goalId', exact: true },
    { component: <Investments />, path: '/investments' },
    { component: <MoneyMind />, path: '/money-mind' },
    { component: <TrashedFiles />, path: '/document-vault/trash' },
    { component: <FolderDetails />, path: '/document-vault/:folderFinId' },
    { component: <DocumentVault />, path: '/document-vault' },
    {
      component: <HonestConversations />,
      path: '/honest-conversations',
      exact: true
    },
    { component: <UpdateScores />, path: '/honest-conversations/new-score' },
    {
      component: <PersonalPriorities />,
      path: '/honest-conversations/personal-priorities'
    },
    { component: <Auth />, path: '/auth/salesforce/callback' },
    { component: <LogOut />, path: '/logout' },
    { component: <SiteDisclosure />, path: '/site-disclosure' },
    { component: <Tasks />, path: '/tasks', exact: true },
    { component: <CompletedTasksPage />, path: '/tasks/completed' },
    { component: <Positions />, path: '/holdings/:accountId' },
    { component: <FeeSchedule />, path: '/fee-schedule' },
    { component: <Disclosures />, path: '/disclosures' },
    { component: <MarketplaceOffers />, path: '/resources' },
    { component: <PageNotFound />, path: '*' }
  ]

  return routeList.map(({ path, exact, component }: RouteDataObj) => {
    const isDocVault =
      path === '/document-vault/:folderFinId' || path === '/document-vault'

    return (isDocVault && !data.disableDocumentVault) || !isDocVault ? (
      <Route
        key={path}
        exact={exact || false}
        path={path}
        render={routeRender(data, component)}
      />
    ) : null
  })
}

interface RestrictedRoutesProps {
  isAppBannerVisible: boolean
  disableDocumentVault: boolean
  history: RouteComponentProps['history']
  data: any
  subscribeToMore: any
}

export default function RestrictedRoutes({
  disableDocumentVault,
  history,
  data,
  subscribeToMore
}: RestrictedRoutesProps) {
  const routeList = routeListGenerator({
    data,
    subscribeToMore,
    history,
    disableDocumentVault
  })

  return (
    <>
      <AppBanner />
      <div>
        <TimeoutModal />
        <UpliftNavModal />

        <div className='content nav-closed'>
          <Header
            history={history}
            notifications={data}
            subscribeToMore={subscribeToMore}
          />
          <ErrorBoundary routeProps={history?.location?.pathname}>
            <div className='wrapper__main'>
              <div className='wrapper__main-content'>
                <Switch>{routeList}</Switch>
                <Toasts />
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </div>
      <AppBottomSheet />
    </>
  )
}
