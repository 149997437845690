import React from 'react'

export interface MmGraphProps {
  percent: number
  color: string
  title: string
}

const MoneyMindGraph = (moneymind: MmGraphProps): JSX.Element => {
  const { percent, color, title } = moneymind
  const graph = []
  for (let row = 0; row < 10; row++) {
    const graphRow = []
    for (let column = 9; column >= 0; column--) {
      const position = row * 10 + column
      const graphCell = (
        <div
          className='mm-graphs__graph__cell mm-graphs__graph__cell--10'
          key={`cell ${position}`}
          style={{
            backgroundColor: color,
            opacity: position >= 100 - percent ? '100%' : '25%'
          }}
        />
      )
      graphRow.push(graphCell)
    }
    graph.push(
      <div key={`row ${row}`} className='mm-graphs__graph__row'>
        {graphRow}
      </div>
    )
  }

  return (
    <div className='mm-graphs__result'>
      <span
        className='mm-graphs__result__title'
        style={{
          color
        }}>
        {title}
      </span>
      <div className='mm-graphs__graph'>{graph}</div>
      <span className='mm-graphs__result__percent'>{percent}%</span>
    </div>
  )
}

export default MoneyMindGraph
