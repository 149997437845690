import React from 'react'
import Card from 'react-bootstrap/Card'

const InsuranceNull = (): JSX.Element => (
  <Card
    id='insurance-null-view'
    className='bg-white rounded-0 border-0 mt-4'
    style={{ padding: '32px', margin: '0px' }}>
    <div className='insurance-null__w my-lg-7 my-md-7 my-sm-7'>
      <h3 className='insurance-null__body-header fw-bold'>
        No insurance policies
      </h3>
      <span className='insurance-null__body-description'>
        Contact your advisor to add any insurance policies you have.
      </span>
    </div>
  </Card>
)

export default InsuranceNull
