import Api from '../helpers/api'
import { messageMediaType } from '../objects/messages'
export const FETCH_VIDEO_URL_DATA: string = 'FETCH_VIDEO_URL_DATA'
export const FETCH_THUMBNAIL_URL_DATA: string = 'FETCH_THUMBNAIL_URL_DATA'

export const getVideoData = (
  threadId: string,
  messageId: string,
  dataId: string,
  type: messageMediaType
) => {
  const key = `${type}Url`
  const apiInstance = Api.getInstance()
  return {
    type: `FETCH_${type?.toUpperCase()}_URL_DATA`,
    payload: apiInstance.get(`/videomessage/${dataId}`).then((response) => {
      response.data[key] = response.data?.url || ''
      response.data.messageId = messageId
      response.data.type = type
      response.data.threadId = threadId
      return response
    }),
    meta: {
      messageId,
      threadId,
      type
    }
  }
}
