import { isObjEmpty } from '../helpers'
import { ContactObj } from '../objects/contact'
import {
  ClientScoringSessionsInterface,
  ContactCardScoreObj,
  HouseholdCardObjInterface
} from '../objects/honestConversation'
import { compareNumericField } from './sort'
import hcHappiniessIcon from '../assets/images/logos/hcLogos/hc_happiness.png'
import hcCommitmentIcon from '../assets/images/logos/hcLogos/hc_commitment.png'
import hcProtectionIcon from '../assets/images/logos/hcLogos/hc_protection.png'

export const setTokenCookie = (accessToken: string) => {
  let i = 0
  let domain = window.location.hostname
  const parts = domain.split('.')
  const now = new Date()
  // 60 seconds per minute * 60 minutes per second * 1000ms per second * 4 = 4 hour expiration
  now.setTime(now.getTime() + 60 * 60 * 1000 * 4)
  if (domain === 'localhost') {
    document.cookie = `gcToken=${accessToken}; domain=${domain}; path=/; Expires=${now.toUTCString()};`
  }
  while (
    i < parts.length - 1 &&
    document.cookie.indexOf(`gcToken=${accessToken}`) === -1
  ) {
    i = i + 1
    domain = parts.slice(-1 - i).join('.')
    document.cookie = `gcToken=${accessToken}; domain=${domain}; path=/; Expires=${now.toUTCString()};`
  }
}

export const iconList = {
  Happiness: hcHappiniessIcon,
  Commitment: hcCommitmentIcon,
  Protection: hcProtectionIcon
}

//  ==== findCardScore Function Parameters ====
//  contact = Single contact object (ContactObj)
//  activeSessionId = honestConversation.activeScoringSessionPrimary (HonestConversationObj)
//  clientScoringSession = honestConversation.clientScoringSessions (HonestConversationObj)
//  id = single ID from honestConversation.householdCards card (HonestConversationObj)
export const findCardScore = (
  contact: ContactObj,
  activeSessionId: string,
  clientScoringSession: ClientScoringSessionsInterface,
  id: string
) => {
  const contactId =
    contact?.id && clientScoringSession && clientScoringSession[contact.id]
  const activeScores = contactId && contactId[activeSessionId]?.cardScores
  let cardScore: number | null = null

  activeScores &&
    !isObjEmpty(activeScores) &&
    Object.keys(activeScores).forEach((card, i) => {
      const activeCards = activeScores && activeScores[card]
      if (activeCards?.householdCard?.id === id) {
        cardScore = activeCards?.score
      }
    })
  return cardScore
}

export const findScoreCard = (scores, cardObject) => {
  let card = null
  scores &&
    Object.keys(scores).map((key) => {
      const id = key && scores[key]?.householdCard?.id
      const cardId = cardObject?.id
      if (id === cardId) {
        card = scores[key]
      }
    })
  return card
}

export const getTotalScore = (scores: number[]) => {
  return (
    scores &&
    scores.reduce((perviouseValue, currentValue) => {
      return (perviouseValue += currentValue)
    }, 0)
  )
}

export const getCurrentScoreAndScoringDate = (
  clientScoringSessions: any,
  scoringSessionId: string
) => {
  if (!clientScoringSessions) {
    return null
  }
  const recentScoreSession = clientScoringSessions[scoringSessionId]
  const currentScore =
    recentScoreSession?.cardScores &&
    Object.keys(recentScoreSession.cardScores).map((key: any) => {
      return recentScoreSession.cardScores[key].score
    })
  const singleTotalScore = getTotalScore(currentScore)
  const currentScoringDate =
    recentScoreSession && recentScoreSession.createdDate
  return {
    singleTotalScore,
    currentScoringDate
  }
}

export const generateContactCardScores = (
  contacts,
  honestConversation,
  isPrimaryContact: boolean
) => {
  if (!contacts) return {}
  const { primary, secondary } = contacts
  const cardScoreObj: ContactCardScoreObj = {}
  if (honestConversation && honestConversation.clientScoringSessions) {
    const scoreSessions = honestConversation.clientScoringSessions
    const primaryActiveSession = honestConversation.activeScoringSessionPrimary
    const secondaryActiveSession =
      honestConversation.activeScoringSessionSecondary
    if (
      isPrimaryContact &&
      primary?.id &&
      primaryActiveSession &&
      scoreSessions?.[primary.id]
    ) {
      cardScoreObj.primaryCardScores =
        scoreSessions?.[primary.id]?.[primaryActiveSession]?.cardScores || null
    }
    if (
      !isPrimaryContact &&
      secondary?.id &&
      secondaryActiveSession &&
      scoreSessions?.[secondary.id]
    ) {
      cardScoreObj.secondaryCardScores =
        scoreSessions?.[secondary.id]?.[secondaryActiveSession]?.cardScores ||
        null
    }
  }
  return cardScoreObj
}
export const sortHouseholdCards = (cards: HouseholdCardObjInterface) => {
  return Object.keys(cards)
    .sort((a, b) => cards[a].ranking - cards[b].ranking)
    .map((key) => cards[key])
}

export const generateDefaultSelectedCard = (contact, honestConversation) => {
  const primaryContact = generateContactCardScores(
    contact,
    honestConversation,
    true
  )
  const secondaryContact = generateContactCardScores(
    contact,
    honestConversation,
    false
  )
  const sortedHouseholdCards = sortHouseholdCards(
    honestConversation.householdCards
  )
  const cardObject = Object.values(sortedHouseholdCards)[0]
  const primaryCard = findScoreCard(
    primaryContact.primaryCardScores,
    cardObject
  )
  const secondaryCard = findScoreCard(
    secondaryContact.secondaryCardScores,
    cardObject
  )
  return primaryCard || secondaryCard
    ? {
        primary: primaryCard,
        secondary: secondaryCard,
        id: Object.values(sortedHouseholdCards)[0]?.id,
        hcCardId: Object.values(sortedHouseholdCards)[0]?.card.id
      }
    : null
}

export const getTrackerData = (scores, currentCardIndex, maxCardIndex) => {
  return (
    scores &&
    scores.map((card, index) => {
      return {
        score: 0,
        comment: null,
        label: index,
        isComplete: index === currentCardIndex ? false : index < maxCardIndex,
        isCurrent: index === currentCardIndex,
        StepComponent: () => null
      }
    })
  )
}

export const getCardGoals = (selectCard, goals) => {
  const tileData = goals.filter((goal) => {
    return (
      goal.cards &&
      goal.cards.find((card) => {
        const { id, showCard } = card
        return id === selectCard && showCard
      })
    )
  })
  return tileData
}

export const getRankedClientCards = (cards: any[]) => {
  return cards
    .filter((card) => card.rankedWithin === 'All')
    .sort((a, b) => compareNumericField(a.ranking, b.ranking))
    .reduce((p, card) => {
      p[card.cardId] = card
      return p
    }, {})
}

export const rankedAnyClientCards = (cards: any[]) => {
  return cards.some((card) => Boolean(card.ranking))
}

export const isSingleHH = (contacts) => {
  const { primary, secondary } = contacts || {
    primary: null,
    secondary: null
  }

  return primary === null || secondary === null
}

/**
 *
 * @param clientScoreCards
 * returns comment based on active score card with household cards
 */
export const scoreCardCommentByHouseholdCard = (
  clientScoreCards,
  householdCardId: string
) => {
  let comment = undefined
  clientScoreCards &&
    Object.keys(clientScoreCards).forEach((cardKey) => {
      const { householdCard } = clientScoreCards[cardKey]
      if (householdCard?.cardId === householdCardId) {
        comment = clientScoreCards[cardKey].comment
      }
    })
  return comment
}
