import * as householdActions from '../actions/household.action'
import * as actions from '../actions/investments.action'
import { AllocationObj } from '../objects/allocation'
import { PrioritiesInterface } from '../objects/priorities'
import {
  InvestmentPreferences,
  FeeScheduleObj,
  FeeScheduleApiResponseObj
} from '../objects/investments'

export interface InvestmentsState {
  invresponseId: number
  priorities: PrioritiesInterface
  allocation: AllocationObj
  partners: string[]
  preferences: InvestmentPreferences
  feeSchedule: FeeScheduleObj[]
  strategyAsOfDate: string
}

const initState: InvestmentsState = {
  invresponseId: null,
  priorities: {},
  allocation: {
    currentEquity: null,
    currentFixed: null,
    currentAlt: null,
    currentCash: null,
    currentEquityValue: null,
    currentFixedValue: null,
    currentAltValue: null,
    currentCashValue: null,
    recommendedEquity: null,
    recommendedFixed: null,
    maxDefensiveCash: null,
    maxDefensiveEquity: null,
    maxDefensiveFixed: null,
    maxDefensiveOther: null,
    maxOffensiveCash: null,
    maxOffensiveEquity: null,
    maxOffensiveFixed: null,
    maxOffensiveOther: null,
    targetCashAllocation: null,
    targetEquityAllocation: null,
    targetFixedAllocation: null,
    targetOtherAllocation: null
  },
  partners: [],
  preferences: null,
  feeSchedule: [],
  strategyAsOfDate: ''
}

const Investments = (state = initState, action): InvestmentsState => {
  switch (action.type) {
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      return { ...state, ...mapHousehold(action.payload, state.allocation) }
    case actions.FETCH_INVESTMENT_PREFERENCES_FULFILLED:
      if (action.payload[0]) {
        return { ...state, priorities: mapPriorities(action.payload) }
      } else {
        return state
      }
    case actions.FETCH_PSS_SUMMARY_FULFILLED:
      return {
        ...state,
        allocation: mapAllocation(action.payload, state.allocation)
      }
    case `${actions.FETCH_FEE_SCHEDULE}_FULFILLED`: {
      const { data } = action.payload
      if (!(data?.length > 0)) {
        return state
      }
      const sortedByName = data.sort((a, b) => (a.name > b.name ? 1 : -1))
      return {
        ...state,
        strategyAsOfDate: data?.[0]?.asOfDate || '', // This field will always return the date
        feeSchedule: mapFeeScheduleRespToState(sortedByName)
      }
    }
    default:
      return state
  }
}

const mapPreferences = (preferences): InvestmentPreferences => {
  if (preferences && preferences[0]) {
    return {
      investmentsGroupBy: preferences[0].investmentsInvestmentsGroupBy,
      investmentsSortBy: preferences[0].investmentsInvestmentsSortBy,
      investmentsSortingOrder: preferences[0].investmentsInvestmentsSortingOrder
    }
  } else return null
}

const mapPriorities = (investments) => {
  const obj = {}
  Object.keys(investments).forEach((key) => {
    const investment = investments[key]
    obj[investment.id] = {
      taxes: investment.taxPreference,
      performance: investment.performancePreference,
      protection: investment.protectionPreference,
      cost: investment.costPreference,
      global: investment.globalPreference,
      domestic: investment.domesticPreference,
      globalScore: investment.globalScore,
      domesticScore: investment.domesticScore
    }
  })
  return obj
}

const mapAllocation = (investment, allocation) => {
  if (
    investment &&
    investment.current &&
    investment.current.value &&
    investment.current.allocation
  ) {
    return {
      ...allocation,
      currentEquity: investment.current.allocation.equity || 0,
      currentFixed: investment.current.allocation.fixed || 0,
      currentAlt: investment.current.allocation.other || 0,
      currentCash: investment.current.allocation.cash || 0,
      currentEquityValue: investment.current.value.equity || 0,
      currentFixedValue: investment.current.value.fixed || 0,
      currentAltValue: investment.current.value.other || 0,
      currentCashValue: investment.current.value.cash || 0,
      maxDefensiveCash: investment.position.maxDefensivePosition.cash || 0,
      maxDefensiveEquity: investment.position.maxDefensivePosition.equity || 0,
      maxDefensiveFixed: investment.position.maxDefensivePosition.fixed || 0,
      maxDefensiveOther: investment.position.maxDefensivePosition.other || 0,
      maxOffensiveCash: investment.position.maxOffensivePosition.cash || 0,
      maxOffensiveEquity: investment.position.maxOffensivePosition.equity || 0,
      maxOffensiveFixed: investment.position.maxOffensivePosition.fixed || 0,
      maxOffensiveOther: investment.position.maxOffensivePosition.other || 0
    }
  } else {
    return allocation
  }
}

const mapHousehold = (household, allocation) => {
  return {
    invresponseId: household.invresponseId ? household.invresponseId : null,
    allocation: {
      ...allocation,
      targetCashAllocation: household.targetCashAllocation || 0,
      targetEquityAllocation: household.targetEquityAllocation || 0,
      targetFixedAllocation: household.targetFixedAllocation || 0,
      targetOtherAllocation: household.targetOtherAllocation || 0
    },
    partners: household.onlinePortal ? household.onlinePortal.split(';') : null,
    preferences: mapPreferences(household.preferences)
  }
}

const feeScheduleRespToState = (
  response: FeeScheduleApiResponseObj,
  incFootnoteNumber
): FeeScheduleObj => {
  return {
    id: response.id,
    name: response.name ?? '',
    managerFee: response.managerFee ?? 0,
    manager: response.manager ?? '',
    operationsFee: response.operationalFee ?? 0,
    classification: response.classification ?? '',
    footerAttributes: response.footerAttributes ?? '',
    footnoteNumber: response.footerAttributes ? incFootnoteNumber() : 0
  }
}

const mapFeeScheduleRespToState = (
  response: FeeScheduleApiResponseObj[]
): FeeScheduleObj[] => {
  let footnoteNumber = 0
  function incFootnoteNumber() {
    return ++footnoteNumber
  }
  return response.map((item) => feeScheduleRespToState(item, incFootnoteNumber))
}

export default Investments
