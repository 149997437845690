// export type ClientNotificationSettings = 'MESSAGE'

export const settingsStateValidator = (
  cacheSettings: string[],
  stateSettings: string[]
) => {
  if (cacheSettings.length !== stateSettings.length) return true

  let isChanged = false
  new Set([...cacheSettings, ...stateSettings]).forEach((unique: string) => {
    const cacheCount = cacheSettings.filter(
      (cacheSetting: string) => cacheSetting === unique
    ).length
    const stateCount = stateSettings.filter(
      (stateSetting: string) => stateSetting === unique
    ).length
    isChanged = cacheCount !== stateCount
  })

  return isChanged
}

export const clientSettingsMapping = (responseData: {
  data: { allClients: any }
}) => {
  let settings: {
    desktopSettings: string[]
    emailSettings: string[]
    mobileSettings: string[]
    disabled: boolean
  } = {
    desktopSettings: [],
    emailSettings: [],
    mobileSettings: [],
    disabled: true
  }

  if (!responseData?.data) return settings

  const { allClients } = responseData.data

  settings = {
    desktopSettings: allClients?.nodes?.[0]?.desktopSubject || [],
    emailSettings: allClients?.nodes?.[0]?.emailSubject || [],
    mobileSettings: allClients?.nodes?.[0]?.mobileSubject || [],
    disabled: false
  }

  return settings
}
