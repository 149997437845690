import React from 'react'
import Button from 'react-bootstrap/Button'
import UploadFileModal from './UploadFileModal'

interface UploadFileProps {
  fileFormatError: boolean
  showUploadFileModal: boolean
  fileUploadPending: boolean
  folderId: string
  toggleUploadFileModal(): void
  uploadFiles(files: any[]): void
}

class UploadFile extends React.Component<UploadFileProps, {}> {
  public render() {
    const {
      uploadFiles,
      fileFormatError,
      showUploadFileModal,
      toggleUploadFileModal,
      fileUploadPending
    } = this.props
    return (
      <div>
        <Button
          variant='primary'
          size='lg'
          type='submit'
          className='match-btn-width'
          onClick={toggleUploadFileModal}>
          Upload File
        </Button>

        <UploadFileModal
          showModal={showUploadFileModal}
          fileUploadPending={fileUploadPending}
          fileFormatError={fileFormatError}
          closeModal={toggleUploadFileModal}
          uploadFiles={uploadFiles}
        />
      </div>
    )
  }
}

export default UploadFile
