import React from 'react'

export const EMoneyDisclosure = (): JSX.Element => {
  return (
    <div>
      <p>
        EMONEY: The projections or other information generated by EMONEY
        regarding the likelihood of various investment outcomes are hypothetical
        in nature, do not reflect actual investment results and are not
        guarantees of future results.
      </p>
      <p>
        The return assumptions in EMONEY are not reflective of any specific
        product, and do not include any fees or expenses that may be incurred by
        investing in specific products. The actual returns of a specific product
        may be more or less than the returns used in EMONEY. It is not possible
        to directly invest in an index. Financial forecasts, rates of return,
        risk, inflation, and other assumptions may be used as the basis for
        illustrations. They should not be considered a guarantee of future
        performance or a guarantee of achieving overall financial objectives.
        Past performance is not a guarantee or a predictor of future results of
        either the indices or any particular investment.
      </p>
      <p>EMONEY results may vary with each use and over time.</p>
      <p>
        This report is a diagnostic tool intended to review your current
        financial situation and suggest potential planning ideas and concepts
        that may be of benefit. The purpose of the report is to illustrate how
        accepted financial and estate planning principles may improve your
        current situation.
      </p>
      <p>
        This report is based upon information and assumptions provided by you
        (the client). This report provides broad and general guidelines on the
        advantages of certain financial planning concepts and does not
        constitute a recommendation of any particular technique. The
        consolidated report is provided for informational purposes as a courtesy
        to you. If any of the assumptions are incorrect, you should notify your
        financial advisor. Even small changes in assumptions can have a
        substantial impact on the results shown in this Report. We recommend
        that you review your plan annually, unless changes in your personal or
        financial circumstances require more frequent review. All reports should
        be reviewed in conjunction with your fact summary and this Disclaimer
        page.
      </p>
      <p>
        The term “plan” or “planning,” when used within this report, does not
        imply that a recommendation has been made to implement one or more
        financial plans or make a particular investment. Nor does the plan or
        report provide legal, accounting, financial, tax or other advice.
        Rather, the report and the illustrations therein provide a summary of
        certain potential financial strategies. The reports provide projections
        based on various assumptions and are therefore hypothetical in nature
        and not guarantees of investment returns. You should consult your tax
        and/or legal advisors before implementing any transactions and/or
        strategies concerning your finances.
      </p>
      <p>
        It is important to compare the information on this report with the
        statements you receive from the custodian(s) for your account(s). Please
        note that there may be minor variations due to calculation
        methodologies. If you have any questions, please contact your financial
        representative. Also, your account(s) may not be covered by FDIC or
        SIPC. FDIC and SIPC coverages apply only to certain assets and may be
        subject to limitations. Questions about coverage that may apply should
        be directed to the asset provider or sponsor.
      </p>
      <p>
        Tools such as the Monte Carlo simulation will yield different results
        depending on the variables inputted, and the assumptions underlying the
        calculation. For those reports that perform a Monte Carlo analysis, the
        term ‘Monte Carlo’ will be included in the report title. The assumptions
        with respect to the simulation include the assumed rates of return and
        standard deviations of the portfolio model associated with each asset.
        The assumed rates of return are based on the historical rates of returns
        and standard deviations, for certain periods of time, for the benchmark
        indexes comprising the asset classes in the model portfolio. Since the
        market data used to generate these rates of return change over time your
        results will vary with each use over time.
      </p>
      <p>
        Monte Carlo Analysis is a mathematical process used to implement complex
        statistical methods that chart the probability of certain financial
        outcomes at certain times in the future. This charting is accomplished
        by generating hundreds of possible economic scenarios that could affect
        the performance of your investments.
      </p>
      <p>
        The Monte Carlo simulation uses at most 1000 scenarios to determine the
        probability of outcomes resulting from the asset allocation choices and
        underlying assumptions regarding rates of return and volatility of
        certain asset classes. Some of these scenarios will assume very
        favorable financial market returns, consistent with some of the best
        periods in investing history for investors. Some scenarios will conform
        to the worst periods in investing history. Most scenarios will fall
        somewhere in between.
      </p>
      <p>
        The outcomes presented using the Monte Carlo simulation represent only a
        few of the many possible outcomes. Since past performance and market
        conditions may not be repeated in the future, your investment goals may
        not be fulfilled by following advice that is based on the projections.
      </p>

      <h5>RESULTS USING MONTE CARLO SIMULATIONS</h5>
      <p>
        The results referenced in this guide use Monte Carlo simulations. Tools
        such as the Monte Carlo simulation will yield different results
        depending on the variables inputted, and the assumptions underlying the
        calculation. For those reports that perform a Monte Carlo analysis, the
        term ‘Monte Carlo’ will be included in the report title. The assumptions
        with respect to the simulation include the assumed rates of return and
        standard deviations of the portfolio model associated with each asset.
        The assumed rates of return are based on the historical rates of returns
        and standard deviations, for certain periods of time, for the benchmark
        indices comprising the asset classes in the model portfolio. Since the
        market data used to generate these rates of return change over time your
        results will vary with each use over time.
      </p>
      <p>
        Monte Carlo simulations are used to show how variations in rates of
        return each year can affect your results. A Monte Carlo simulation
        calculates the results of your Plan by running it many times, each time
        using a different sequence of returns. Some sequences of returns will
        give you better results, and some will give you worse results. These
        multiple trials provide a range of possible results, some successful
        (you would have met all your goals) and some unsuccessful (you would not
        have met all your goals). The percentage of trials that were successful
        is the probability that your Plan, with all its underlying assumptions,
        could be successful. In MoneyGuidePro, this is the Probability of
        Success. Analogously, the percentage of trials that were unsuccessful is
        the Probability of Failure. The Results Using Monte Carlo Simulations
        indicate the likelihood that an event may occur as well as the
        likelihood that it may not occur. In analyzing this information, please
        note that the analysis does not take into account actual market
        conditions, which may severely affect the outcome of your goals over the
        long-term.
      </p>
      <p>
        Monte Carlo Analysis is a mathematical process used to implement complex
        statistical methods that chart the probability of certain financial
        outcomes at certain times in the future. This charting is accomplished
        by generating hundreds of possible economic scenarios that could affect
        the performance of your investments. The Monte Carlo simulation uses
        scenarios to determine the probability of outcomes resulting from the
        asset allocation choices and underlying assumptions regarding rates of
        return and volatility of certain asset classes. Some of these scenarios
        will assume very favorable financial market returns, consistent with
        some of the best periods in investing history for investors. Some
        scenarios will conform to the worst periods in investing history. Most
        scenarios will fall somewhere in between.
      </p>
      <p>
        The projections or other information generated by this Monte Carlo
        simulation regarding various investment outcomes are hypothetical in
        nature and do not re ect actual investment results and are not
        guarantees of future results. The outcomes presented using the Monte
        Carlo simulation represent only a few of the many possible outcomes.
        Since past performance and market conditions may not be repeated in the
        future, your investment goals may not be fulfilled by following advice
        that is based on the projections.
      </p>

      <h5>
        REPORT IS A SNAPSHOT AND DOES NOT PROVIDE LEGAL, TAX, OR ACCOUNTING
        ADVICE
      </h5>
      <p>
        This Report provides a snapshot of your current financial position and
        can help you to focus on your financial resources and goals, and to
        create a plan of action. Because the results are calculated over many
        years, small changes can create large differences in future results. The
        information contained in this report is not written or intended as
        financial, tax or legal advice. The information provided herein may not
        be relied on for purposes of avoiding any federal tax penalties. Before
        making decisions with legal, tax, or accounting rami cations, you are
        encouraged to seek financial, tax and legal advice from your
        professional advisors.
      </p>
    </div>
  )
}
