import * as householdActions from '../actions/household.action'
import { isObjEmpty } from '../helpers'
import * as actions from '../actions/honestConversations.action'
import {
  HouseholdCardObjInterface,
  HouseholdCardObj,
  ClientCardObjInterface,
  ClientCardObj,
  ClientScoringSession,
  ScoringSessionsObj,
  HonestConversationsExerciseObj,
  HonestConversationsExerciseInterface
} from '../objects/honestConversation'

export interface HonestConversationsState {
  //@ts-ignore
  hcExerciseId: string
  [key: string]: HonestConversationsExerciseObj
}

const initState = {
  hcExerciseId: null
}

const mapHouseholdCards = (householdCards: HouseholdCardObj[]) => {
  const obj: HouseholdCardObjInterface = {}
  if (!householdCards) {
    return obj
  }
  householdCards.map((householdCard: HouseholdCardObj) => {
    obj[householdCard.id] = {
      id: householdCard.id,
      card: householdCard.card,
      commentPrimary: householdCard.commentPrimary,
      commentSecondary: householdCard.commentSecondary,
      ranking: householdCard.ranking
    }
  })
  return obj
}

const mapClientCards = (clientCards: ClientCardObj) => {
  const obj: ClientCardObjInterface = {}
  if (!clientCards) {
    return obj
  }
  Object.keys(clientCards).forEach((key) => {
    const arr: ClientCardObj[] = []
    const client = clientCards[key]
    client.map((card: ClientCardObj) => {
      arr.push(card)
      obj[key] = arr
    })
  })
  return obj
}

const mappingCardScoresObj = (cardScores: any) => {
  const obj: any = {}
  cardScores &&
    cardScores.map((cardScore: any) => {
      obj[cardScore.id] = {
        comment: cardScore.comment,
        score: cardScore.score,
        createdDate: cardScore.createdDate,
        householdCard: cardScore.householdCard
      }
    })
  return obj
}

const mapClientScoringSessions = (clientScoringSessions: any) => {
  const obj: any = {}
  clientScoringSessions &&
    Object.keys(clientScoringSessions).forEach((clientId: string) => {
      obj[clientScoringSessions[clientId].id] = {
        createdDate: clientScoringSessions[clientId].createdDate,
        cardScores: mappingCardScoresObj(
          clientScoringSessions[clientId].cardScores
        )
      }
    })
  return obj
}

const mapClientScoring = (client: any) => {
  const obj: any = {}
  if (!client) {
    return obj
  }
  Object.keys(client).forEach((clientId: string) => {
    const clientScoringSession = client[clientId]
    obj[clientId] = mapClientScoringSessions(clientScoringSession)
  })
  return obj
}

const mapHcExercise = (exercise: any) => {
  const obj = {}
  obj[exercise.id] = {
    id: exercise.id,
    householdCards: mapHouseholdCards(exercise.householdCards),
    clientCards: mapClientCards(exercise.clientCards),
    createdDate: exercise.createdDate,
    clientScoringSessions: mapClientScoring(exercise.scores),
    emailSentPrimary: exercise.emailSentPrimary,
    emailSentSecondary: exercise.emailSentSecondary,
    activeScoringSessionPrimary: exercise.activeScoringSessionPrimary,
    activeScoringSessionSecondary: exercise.activeScoringSessionSecondary
  }
  return obj
}

const mapUpdatedScoringSessionIds = (exercises: any, newExercise: any) => {
  exercises[newExercise.id] = {
    ...exercises[newExercise.id],
    activeScoringSessionPrimary: newExercise.activeScoringSessionPrimary,
    activeScoringSessionSecondary: newExercise.activeScoringSessionSecondary
  }
  return exercises
}

const mapHousehold = (household) => {
  return {
    hcExerciseId: household.hcExerciseId ? household.hcExerciseId : null
  }
}

const sessionCardScores = (exercises: any, scoredSessionResponse: any) => {
  const { contactId, scoringSessionId, scores } = scoredSessionResponse
  const householdExercise: HonestConversationsExerciseInterface =
    exercises[exercises.hcExerciseId]
  const clientScoringSessions =
    householdExercise.clientScoringSessions[contactId][scoringSessionId]
  const newScores: ClientScoringSession = {}
  Object.keys(scores).forEach((key: string) => {
    const newScore: ScoringSessionsObj = scores[key]
    newScores[newScore.id] = newScore
  })
  clientScoringSessions.scores = newScores
  return exercises
}

const addScoringSessionToExercise = (
  exercise: HonestConversationsExerciseObj,
  newScoringSession: any
) => {
  const {
    id,
    clientId,
    scoredWithAdvisor,
    isPrimary,
    createdDate,
    cardScores
  } = newScoringSession
  if (!exercise.clientScoringSessions[clientId]) {
    exercise.clientScoringSessions[clientId] = {}
  }
  const clientScoringSessions = exercise.clientScoringSessions[clientId]
  clientScoringSessions[id] = {
    id,
    clientId,
    scoredWithAdvisor,
    createdDate,
    cardScores: mappingCardScoresObj(cardScores)
  }
  const activeScoringId: {
    activeScoringSessionPrimary?: string
    activeScoringSessionSecondary?: string
  } = {}
  if (isPrimary === true) {
    activeScoringId.activeScoringSessionPrimary = id
  }
  if (isPrimary === false) {
    activeScoringId.activeScoringSessionSecondary = id
  }
  return {
    ...exercise,
    ...activeScoringId,
    clientScoringSessions: {
      ...exercise.clientScoringSessions,
      [clientId]: {
        ...clientScoringSessions
      }
    }
  }
}

const HonestConversations = (
  state = initState,
  action
): HonestConversationsState => {
  const newState: HonestConversationsState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      return { ...newState, ...mapHousehold(action.payload) }
    case `${actions.FETCH_HONEST_CONVERSATION}_FULFILLED`:
      const exercises = action.payload.data
      const activeExercise = Object.keys(exercises).reduce(
        (active, exercise) => {
          if (exercises[exercise].id === state.hcExerciseId) {
            active = exercises[exercise]
          }
          return active
        },
        {}
      )
      const hc =
        activeExercise &&
        !isObjEmpty(activeExercise) &&
        mapHcExercise(activeExercise)
      return { ...state, ...hc }
    case `${actions.UPDATE_HONEST_CONVERSATION_EXERCISE}_FULFILLED`:
      const newHc = mapUpdatedScoringSessionIds(newState, action.payload.data)
      return { ...newState, ...newHc }
    case `${actions.CREATE_SCORING_SESSIONS}_FULFILLED`:
      const exercise = addScoringSessionToExercise(
        newState[newState.hcExerciseId],
        action.payload
      )
      newState[newState.hcExerciseId] = exercise
      return newState
    case `${actions.CREATE_SCORING_SESSION_SCORE}_FULFILLED`:
      return { ...newState, ...sessionCardScores(newState, action.payload) }
    case `${actions.UPDATE_SCORING_SESSION}_FULFILLED`:
      return newState
    case `${actions.DELETE_SCORING_SESSION}_FULFILLED`:
      return newState
    case `${actions.CREATE_SCORE_AND_UPDATE_SESSION}_FULFILLED`:
      return newState
    default:
      return state
  }
}

export default HonestConversations
