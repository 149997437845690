import { isObjEmpty } from '.'

export const planningSoftwares = [
  'emoney',
  'emoney_enterprise',
  'moneyguidepro',
  'gdx360',
  null
]

export const emoneyPlannings = ['emoney', 'emoney_enterprise', null]
export const calculateAccountTotal = (clientAccounts) => {
  if (clientAccounts && !isObjEmpty(clientAccounts)) {
    const total = clientAccounts.reduce((sum, key) => {
      return key.category === 'Short-Term Liabilities' ||
        key.category === 'Long-Term Liabilities'
        ? (sum -= key.totalValue)
        : (sum += key.totalValue)
    }, 0)
    const rawTotal = clientAccounts.reduce(
      (sum, key) => (sum += key.totalValue),
      0
    )
    return { total, rawTotal }
  } else return { total: 0, rawTotal: 0 }
}

const ARTICLE_MAX_LEN = {
  TITLE: 60,
  SUMMARY: 200
}

export const truncate = (text: string, type: string) => {
  const maxLen = ARTICLE_MAX_LEN[type]
  const suffix = text.length > maxLen ? '...' : ''
  if (suffix) {
    return text.length ? `${text.substr(0, maxLen).trim()}${suffix}` : ''
  } else {
    return text
  }
}
