import * as actions from '../actions/messages.action'
import { MessagesState } from '../objects/messages'

const initState: MessagesState = {
  videoMessages: {}
}

const Messages = (state = initState, action): MessagesState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_THUMBNAIL_URL_DATA}_PENDING`:
    case `${actions.FETCH_VIDEO_URL_DATA}_PENDING`: {
      const { threadId, messageId, type } = action?.meta
      newState.videoMessages[threadId] = {
        ...newState.videoMessages[threadId],
        ...{
          [messageId]: {
            ...newState.videoMessages?.[threadId]?.[messageId],
            ...{
              [`show${type}Loader`]: true
            }
          }
        }
      }
      return newState
    }
    case `${actions.FETCH_THUMBNAIL_URL_DATA}_FULFILLED`:
    case `${actions.FETCH_VIDEO_URL_DATA}_FULFILLED`: {
      const {
        threadId,
        messageId,
        type
      }: {
        messageId: string
        threadId: string
        type: string
      } = action?.payload?.data
      if (messageId && threadId && type) {
        const dataType = `${type}Url`
        newState.videoMessages[threadId] = {
          ...newState.videoMessages[threadId],
          ...{
            [messageId]: {
              ...newState.videoMessages?.[threadId]?.[messageId],
              ...{
                [dataType]: action?.payload?.data?.[dataType] || '',
                [`show${type}Loader`]: false
              }
            }
          }
        }
      }
      return newState
    }
    default:
      return newState
  }
}

export default Messages
