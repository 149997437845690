import React from 'react'

const FinancialGoalDisclosure = (props: any) => {
  const { extraDisclosure } = props
  return (
    <div>
      <h2 className='disclosures__section-title'>
        Financial Planning Software
      </h2>
      <p className='disclosures__text'>
        Investing involves risk and investments may lose value. Please consider
        your objectives before investing. Investments are not FDIC insured. Past
        performance does not guarantee future results. Investment outcomes and
        projections are forward-looking statements and hypothetical in nature.
      </p>
      <p className='disclosures__text'>
        All information and recommendations provided “as is” based on
        information provided by clients. We have treated the information
        provided as reliable, have not independently verified any information
        and make no warranty or guaranty as to the accuracy or completeness of
        the information provided.
      </p>
      <p className='disclosures__text'>
        INFORMATION REGARDING THIRD PARTY SOFTWARE USED TO CALCULATE YOUR
        FUNDING SCORE
      </p>
      <div className='disclosures__text'>
        {extraDisclosure ? extraDisclosure : ''}
      </div>
    </div>
  )
}

export default FinancialGoalDisclosure
