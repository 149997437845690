import React, { FC } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { setUserPreferences } from '../../actions/user.action'
import { Button } from '../Buttons'
import { Modal } from '../Layout'
import { AppDispatch } from '../../store'
import newLayout from '../../assets/images/new-layout.png'
import browserInfo from '../../helpers/browserInfo'

interface UpliftNavModalProps {
  showUpliftModal: boolean
  loading: boolean
  error: boolean
  dispatch: AppDispatch
}

const UpliftNavModal: FC<UpliftNavModalProps> = ({
  loading,
  error,
  showUpliftModal,
  dispatch
}) => {
  const { type } = browserInfo()
  const handleClose = () => {
    dispatch(setUserPreferences({ showUpliftModal: false }))
  }

  const shouldRender =
    type !== 'mobile' && !loading && !error && showUpliftModal

  return shouldRender ? (
    <Modal
      title='Welcome to the new GuideCenter layout'
      closeModal={handleClose}>
      <div style={{ padding: '16px' }}>
        <p>Navigate to different pages using the navigation at the top.</p>
        <div style={{ padding: '24px 0', textAlign: 'center' }}>
          <img src={newLayout} alt='new nav layout' width={260} />
        </div>
        <div style={{ padding: '16px 0', textAlign: 'center' }}>
          <Button primary onClick={handleClose}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  ) : null
}

export default connect((store: GlobalState, dispatch: AppDispatch) => {
  return {
    loading: store.user.preferencesLoading,
    error: store.user.preferencesError,
    showUpliftModal: store.user.preferences?.showUpliftModal,
    dispatch
  }
})(UpliftNavModal)
