import {
  ACCOUNT_ROUTES,
  AUTHENTICATED_ROUTES,
  MARKETPLACE_OFFERS_RESOURCES_ROUTE
} from '../objects/authenticated'
import { UserObj } from '../objects/user'
import { marketplaceOfferRequestInterface } from '../objects/marketplaceOffers'
// user check for navigation items
export const routesAccessHelper = (user: UserObj) => {
  ACCOUNT_ROUTES.forEach((route, i) => {
    if (route.label === 'Documents' && user.disableDocumentVault) {
      ACCOUNT_ROUTES.splice(i, 1)
    }
  })
  return ACCOUNT_ROUTES
}

export const getAuthenticatedRoutes = ({
  user,
  marketplaceOffers
}: {
  user: UserObj
  marketplaceOffers: marketplaceOfferRequestInterface
}) => {
  let authenticatedRoutes = AUTHENTICATED_ROUTES

  if (!user?.showHonestConversations) {
    authenticatedRoutes = authenticatedRoutes.filter(
      (r) => r.label !== 'Priorities'
    )
  }

  if (
    Object.keys(marketplaceOffers).length > 0 &&
    !authenticatedRoutes.some((r) => r.label === 'Resources')
  ) {
    authenticatedRoutes.push(MARKETPLACE_OFFERS_RESOURCES_ROUTE)
  }

  return authenticatedRoutes
}
