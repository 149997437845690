import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { getHonestConversation } from '../../actions/honestConversations.action'
import { getHousehold } from '../../actions/household.action'

type PageNotFoundStore = ReturnType<typeof mapStateToProps>
type PageNotFoundDispatch = typeof mapDispatchToProps
export type PageNotFoundProps = PageNotFoundStore & PageNotFoundDispatch

export const PageNotFound = (props: PageNotFoundProps) => {
  const { getHonestConversation, getHousehold, user } = props

  const fetchHcData = useCallback(async () => {
    const householdFetched = user?.householdFetched
    if (!householdFetched) {
      await getHousehold()
    }
    getHonestConversation()
  }, [])

  useEffect(() => {
    fetchHcData()
  }, [])

  return (
    <div className='main-container'>
      <div className='main-container-inner'>
        <div className='errors__heading-text errors__heading-text--lg'>
          404 Error
        </div>
        <div className='errors__body-text errors__body-text--sm'>
          Come back soon, or contact your advisor. We apologize for any
          inconvenience.
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  getHonestConversation,
  getHousehold
}

const mapStateToProps = (store: GlobalState) => {
  return {
    user: store.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PageNotFound)
