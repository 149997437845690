import { Middleware } from '@reduxjs/toolkit'
import * as authActions from '../actions/auth.action'
import { GlobalState } from '../reducers'

const sessionTimeout: Middleware<unknown, GlobalState> = (store) => (next) => (
  action
) => {
  if (action.type !== authActions.RESET_SESSION_TIMEOUT) {
    store.dispatch(authActions.resetTimeout())
  }
  return next(action)
}

export default sessionTimeout
