import React, { FC, LegacyRef, JSX, CSSProperties } from 'react'
import Slider, { Settings } from 'react-slick'
import Button, { ButtonProps } from 'react-bootstrap/Button'
import classnames from 'classnames'

export interface ISlickCarouselProps
  extends Omit<Settings, 'nextArrow' | 'prevArrow' | 'className'> {
  slides(): JSX.Element[]
  carouselRef?: LegacyRef<Slider>
  containerClassName?: string
  sliderClassName?: string
  containerStyle?: CSSProperties
  dotColor?: 'light' | 'dark'
  buttonProps?: ButtonProps
}
/**
 * @description Slick Carousel component to render responsive carousels with Guidecenter styling
 * @prop slides - function that returns slides to for slider to render, gets called within Slider component
 * @prop carouselRef - ref object to pass to slider
 * @prop containerClassName - class names to be applied to wrapper div
 * @prop sliderClassName - class names to be applied to Slider className property
 * @prop containerStyle - CSS properties passed to wrapper div style prop
 * @prop dotColor - "light" | "dark" - 2 variations of dot colors, dark for blue on blue and light for white on black option.
 * @prop buttonProps - bootsrap button props to be applided to arrow button
 * @prop Slider props are directly pass throught see slick doc - https://react-slick.neostack.com/docs/api, with exceptions: className, nextArrow, prevArrow
 */
const SlickCarousel: FC<ISlickCarouselProps> = (props) => {
  const {
    slides,
    carouselRef,
    containerClassName,
    containerStyle,
    sliderClassName,
    dotColor,
    buttonProps,
    ...carouselProps
  } = props

  const slickContainerClassName = classnames(
    'slick-carousel',
    containerClassName,
    `slick-carousel-dots--${dotColor}`
  )

  return (
    <div className={slickContainerClassName} style={containerStyle}>
      <Slider
        ref={carouselRef}
        className={sliderClassName}
        prevArrow={
          <div>
            <Button {...buttonProps}>
              <i className='bi bi-chevron-left fs-5' />
            </Button>
          </div>
        }
        nextArrow={
          <div>
            <Button {...buttonProps}>
              <i className='bi bi-chevron-right fs-5' />
            </Button>
          </div>
        }
        {...carouselProps}>
        {slides?.()}
      </Slider>
    </div>
  )
}

SlickCarousel.defaultProps = {
  dots: true,
  infinite: false,
  slidesToScroll: 1,
  adaptiveHeight: false,
  autoplay: false,
  autoplaySpeed: null,
  lazyLoad: null,
  pauseOnDotsHover: false,
  pauseOnFocus: false,
  pauseOnHover: false,
  slidesToShow: 1,
  swipe: false,
  arrows: true,
  accessibility: true,
  buttonProps: {
    variant: 'light'
  },
  dotColor: 'dark'
}

export default SlickCarousel
