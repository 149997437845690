import React from 'react'
import Button from 'react-bootstrap/Button'

import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface HardDeleteModalProps {
  type: string
  id: string
  hardDeleteFn(id: string, folderId?: string): void
  closeModal(id?: string): void
}

interface HardDeleteModalState {
  disableButton: boolean
}

class HardDeleteModal extends React.Component<
  HardDeleteModalProps,
  HardDeleteModalState
> {
  constructor(props: HardDeleteModalProps) {
    super(props)
    this.state = {
      disableButton: false
    }
  }
  public hardDeleteFn = () => {
    const { id, hardDeleteFn } = this.props
    this.setState({ disableButton: true })
    hardDeleteFn(id)
  }
  public closeFn = () => {
    this.props.closeModal('')
  }

  public render() {
    const { disableButton } = this.state
    return (
      <>
        <h1 className='vault-modal__title'>Delete File Permanently</h1>
        <div className='vault-modal__content'>
          <p>
            Deleting this file will remove it from the trash, and you will no
            longer be able to access it. Are you sure you want to delete this
            file?
          </p>
          <div className='vault-modal__buttons'>
            <Button
              id='delete-modal-save'
              variant='primary'
              size='lg'
              className='match-btn-width'
              onClick={this.hardDeleteFn}
              disabled={disableButton}>
              Delete
            </Button>
            <Button
              id='delete-modal-cancel'
              variant='link'
              onClick={this.closeFn}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default ControlledModalWrapper(HardDeleteModal)
