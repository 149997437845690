import React from 'react'

import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { dollarFormat } from '../../helpers'
import { Link } from 'react-router-dom'
import { Card } from 'react-bootstrap'
import { ClientAccountObj } from '../../objects/clientAccount'
import { NetWorthGraph } from './NetWorthGraph'
import { GradientHero } from '../../components/Hero'

type NetWorthHeroProps = ReturnType<typeof mapStateToProps>

const NetWorthHero = (props: NetWorthHeroProps) => {
  const getTotal = (accounts) =>
    accounts.reduce((pre, nextObj) => pre + Number(nextObj.totalValue), 0)

  const allAccounts = props.assets.concat(props.liabilities)
  const investmentAccounts = allAccounts.filter((acc) =>
    props.investmentAccounts.some((inv) => inv.id === acc.id)
  )

  const assetsTotal = getTotal(props.assets)
  const liabilitiesTotal = getTotal(props.liabilities)

  const netWorthTotal = dollarFormat(assetsTotal - liabilitiesTotal, 0)
  const netWorthIsNegative = assetsTotal - liabilitiesTotal < 0

  const investmentAccountSum = investmentAccounts.reduce(
    (pre: number, nextObj: ClientAccountObj): number => {
      return nextObj.type === 'Liabilities'
        ? pre - nextObj.totalValue
        : pre + nextObj.totalValue
    },
    0
  )
  const investmentsIsNegative = investmentAccountSum < 0

  const negativeSumDisplay = (sumString: string, cssClass: string) => (
    <span>
      <span className='networth-hero__worth__sum networth-hero__worth__sum-negative'>
        -
      </span>
      <span className={cssClass}>{sumString.substring(1)}</span>
    </span>
  )

  const investmentsTotal = dollarFormat(investmentAccountSum, 0)

  const netWorthTotalStyle = `networth-hero__worth__sum networth-hero__worth__sum-dollar ${netWorthTotal.length >
    15 && 'networth-hero__worth__sum-long'}`

  const investmentsTotalStyle = `networth-hero__worth__sum networth-hero__worth__sum-dollar ${netWorthTotal.length >
    15 && 'networth-hero__worth__sum-long'}`

  const netWorthDisplay = netWorthIsNegative ? (
    negativeSumDisplay(netWorthTotal, netWorthTotalStyle)
  ) : (
    <span className={netWorthTotalStyle}>
      {netWorthTotal !== '$0' ? netWorthTotal : '-'}
    </span>
  )

  const investmentsDisplay = investmentsIsNegative ? (
    negativeSumDisplay(investmentsTotal, investmentsTotalStyle)
  ) : (
    <span className={investmentsTotalStyle}>
      {investmentsTotal !== '$0' ? investmentsTotal : '-'}
    </span>
  )

  return (
    <div>
      <GradientHero className='networth-hero__container' />
      <Card
        className='m-0, p-0, border-0'
        style={{
          background: 'none'
        }}>
        <div className='networth-hero__worth'>
          <div className='py-1 networth-hero__worth-col networth-hero__worth-col--ordered'>
            <span className='networth-hero__worth-title'>Net Worth</span>
            <Link
              to='/investments'
              className='networth-hero__worth__label networth-hero__worth__label-mobile networth-hero__worth__label-button'>
              <span className='networth-hero__worth__label networth-hero__worth__label-mobile'>
                View Investments
              </span>
              <i className='bi bi-chevron-right ms-1' />
            </Link>
          </div>

          <div className='py-1 networth-hero__worth-col'>
            <span className='networth-hero__worth__label networth-hero__worth__label-mobile networth-hero__worth__label-light'>
              Net Worth
            </span>
            {netWorthDisplay}
          </div>

          <div className='py-1 networth-hero__worth-col networth-hero__worth-col--hide'>
            <span className='networth-hero__worth__label networth-hero__worth__label-light'>
              Investments
            </span>
            {investmentsDisplay}
          </div>
        </div>

        <NetWorthGraph
          liabilitiesAmount={liabilitiesTotal}
          assetsAmount={assetsTotal}
        />
      </Card>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user, clientAccounts } = store
  return {
    user,
    assets: clientAccounts.assets,
    liabilities: clientAccounts.liabilities,
    investmentAccounts: clientAccounts.investments
  }
}

export default connect(mapStateToProps)(NetWorthHero)
