import dayjs from 'dayjs'
import { createDeepEqualSelector } from './utilz'

import { GlobalState } from '../reducers'
import {
  VaultDataItem,
  FileSelectorContent,
  FolderSelectorContent,
  FolderObj,
  FileObj
} from '../objects/documentVault'

/** BEGIN GETTERS
 * get redux store values with selector depends on
 */

export const getFolders = (store: GlobalState) => {
  return store.documentVault.folders
}

const getSearch = (store: GlobalState) => {
  return store.documentVault.search
}

const getFiles = (store: GlobalState, folderId: string) => {
  const { folders } = store.documentVault
  if (folderId && folders?.[folderId]?.files)
    return { files: folders[folderId].files, folderId }
  return { files: [], folderId: '' }
}
/**
 * END GETTERS
 */
/**
 * BEGIN SELECTORS
 */
export const docVaultSelector = createDeepEqualSelector(
  [getFolders, getSearch],
  (valutFolders, search) => {
    const files: FileSelectorContent[] = []
    const folders: FolderSelectorContent[] = []
    let vaultItems: VaultDataItem[] = []

    Object.values(valutFolders).forEach((f) => {
      const temp = f.files.map((file) => ({ ...file, folderId: f.id }))
      files.push(...temp)
      const singleFolder = {
        ...f,
        fileCount: f.files.filter((file) => file.archived !== true).length,
        type: 'folder'
      }
      delete singleFolder.files
      folders.push(singleFolder)
    })

    folders.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })
    )
    files.sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { ignorePunctuation: true })
    )

    vaultItems = [...folders, ...files].map((item) => {
      return {
        type: 'type' in item ? 'folder' : 'file',
        id: item.id,
        content: { ...item }
      }
    })

    if (search.length) {
      return vaultItems.filter((item) => {
        const { name } = item.content
        const inputPattern = search.trim().replace(/(?=[^a-zA-Z0-9])/g, '\\')
        return name.match(RegExp(inputPattern, 'gi'))
      })
    } else {
      return vaultItems.filter((item) => item.type === 'folder')
    }
  }
)

export const folderDetailSelector = createDeepEqualSelector(
  [getFiles],
  ({ files, folderId }) => {
    const sortedFiles = files.sort((a, b) => {
      if (dayjs(a.createdDate).isAfter(b.createdDate)) {
        return -1
      } else {
        return 1
      }
    })
    return sortedFiles
      .map(
        (f): VaultDataItem => ({
          type: 'file',
          id: f.id,
          content: { ...f, folderId, archived: f.archived }
        })
      )
      .filter((files) => files.content.archived !== true)
  }
)

export const archivedFilesSelector = createDeepEqualSelector(
  [getFolders],
  (folders): FileObj[] => {
    const archivedFiles: FileObj[] = []
    folders &&
      Object.keys(folders).forEach((key) => {
        if (folders[key].files) {
          folders[key].files.forEach((file: FileObj) => {
            if (file.archived) {
              file.folderId = key
              file.type = 'archived'
              archivedFiles.push(file)
            }
          })
        }
      })
    return archivedFiles
  }
)

export const getOneFolder = (
  store: GlobalState,
  folderId: string
): FolderObj => {
  const { folders } = store.documentVault
  return folderId && folders[folderId]
}

export const noneArchivedFilesSelector = createDeepEqualSelector(
  [getOneFolder],
  (folder): FolderObj => {
    const folderObj = { ...folder }
    const noneArchivedFiles =
      folder?.files &&
      folder.files.filter((file) => {
        return !file.archived
      })
    folderObj.files = noneArchivedFiles
    return folderObj
  }
)

/**
 * END SELECTORS
 */
