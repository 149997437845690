import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { isFinite, map } from 'lodash-es'
import { emoneyPlannings } from '../../helpers/dashboard'
import { ScoreZone } from '../../objects/plan'

export interface PlanScoreBarProps {
  score: number
  minScore: number
  maxScore: number
  source: string
  light?: boolean
  isNull?: boolean
}

type Sections = { [K in ScoreZone]: number }

const markerWidth = 16
const markerOffset = markerWidth / 2
const defaultMin = 75
const defaultMax = 90
const basicSectionWidth = 33.3
const basicSections: Sections = {
  underfunded: basicSectionWidth,
  ideal: basicSectionWidth,
  overfunded: basicSectionWidth
}
const advancedSources = ['gdx360', 'moneyguidepro']

const getMarkerPosition = (score: number) => {
  if (!isFinite(score)) {
    return 50
  }
  if (score > 100) {
    return 100
  }
  if (score < 50) {
    return 0
  }
  return (score - 50) * 2
}

const getSectionsBySource = (
  source: string,
  minScore: number,
  maxScore: number
): Sections => {
  if (advancedSources.includes(source)) {
    const underfunded: number = (minScore - 50) * 2
    const ideal: number = (maxScore - minScore) * 2
    const overfunded: number = (100 - maxScore) * 2

    return {
      underfunded,
      ideal,
      overfunded
    }
  }

  return basicSections
}

export const PlanScoreGradientGraph = () => {
  return (
    <div className='plan-score-bar__gradient-graph-w'>
      <div className='plan-score-bar__gradient-section'>
        <div className='plan-score-bar__gradient-bar-w plan-score-bar__gradient-bar-w--gradient'>
          <div className='plan-score-bar__gradient-bar plan-score-bar__gradient-gradient' />
        </div>
        <div className='plan-score-bar__gradient-label-w'>
          <div className='plan-score-bar__gradient-label'>Underfunded</div>
          <div className='plan-score-bar__gradient-label plan-score-bar__gradient-label--ideal'>
            Ideal
          </div>
          <div className='plan-score-bar__gradient-label plan-score-bar__gradient-label--overfunded'>
            Overfunded
          </div>
        </div>
      </div>
    </div>
  )
}

export const PlanScoreBarSection = ({
  width = 0,
  label = '',
  isNull = false
}) => (
  <div className='plan-score-bar__section' style={{ width: `${width}%` }}>
    <div
      className={`plan-score-bar__section-bar--${
        isNull ? 'null' : label.toLowerCase()
      }`}
    />
    <div className='plan-score-bar__section-label'>{label}</div>
  </div>
)

export const PlanScoreBarNull = ({ className = '' }) => (
  <div className={className}>
    <div className='plan-score-bar__sections'>
      {map(basicSections, (width, label) => (
        <PlanScoreBarSection
          key={`section-${label}`}
          label={label}
          width={width}
          isNull={true}
        />
      ))}
    </div>
  </div>
)

const PlanScoreBar = ({
  score,
  source,
  light,
  isNull = false,
  ...props
}: PlanScoreBarProps) => {
  const baseClasses = classnames('plan-score-bar', {
    'plan-score-bar--light': light
  })

  if (isNull) {
    return <PlanScoreBarNull className={baseClasses} />
  }

  const isGradient = emoneyPlannings.includes(source)

  const minScore =
    source === 'gdx360' || !props.minScore ? defaultMin : props.minScore
  const maxScore =
    source === 'gdx360' || !props.maxScore ? defaultMax : props.maxScore

  const sections = getSectionsBySource(source, minScore, maxScore)

  const markerPosition = getMarkerPosition(score)
  const maxPosition = markerPosition > 92
  const minPosition = markerPosition < 5

  const scoreStyle: CSSProperties = {
    ...(maxPosition ? { right: `calc(100% - ${markerPosition}%)` } : {}),
    ...(minPosition ? { left: `${markerPosition}%` } : {}),
    ...(!maxPosition && !minPosition
      ? { left: `calc(${markerPosition}% - ${markerOffset}px)` }
      : {})
  }

  const scoreClass = classnames('plan-score-bar__score', {
    [`plan-score-bar__score${
      maxPosition ? '--max' : minPosition ? '--min' : ''
    }`]: maxPosition || minPosition
  })

  const dotSection = (): ScoreZone => {
    if (markerPosition > sections.underfunded + sections.ideal) {
      return 'overfunded'
    }
    if (markerPosition > sections.underfunded) {
      return 'ideal'
    }
    return 'underfunded'
  }

  return (
    <div className={baseClasses}>
      {!isNull && <div className='plan-score-bar__score-box' />}
      <div className='plan-score-bar__bar'>
        <div className={scoreClass} style={scoreStyle}>
          <div className='plan-score-bar__score-num'>
            {isFinite(score) ? (score < 100 ? score : 100) : '?'}
          </div>
          <div className='plan-score-bar__score-marker'>
            <div className='plan-score-bar__score-line' />
            <div className={`plan-score-bar__score-dot--${dotSection()}`} />
          </div>
        </div>
        {isGradient ? (
          <div className='plan-score-bar__gradient-w'>
            {PlanScoreGradientGraph()}
          </div>
        ) : (
          <div className='plan-score-bar__sections'>
            {map(sections, (width, label) => (
              <PlanScoreBarSection
                key={`section-${label}`}
                label={label}
                width={width}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default PlanScoreBar
