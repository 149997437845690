import React from 'react'
import Card from 'react-bootstrap/Card'

const GoalsNull = (): JSX.Element => (
  <Card id='goal-null-view' className='p-5 mt-6 bg-white'>
    <div className='goal-null__w'>
      <h3 className='goal-null__body-header mt-2'>
        You have not set any goals
      </h3>
      <span className='goal-null__body-description'>
        Reach out to your advisor to complete this step.
      </span>
    </div>
  </Card>
)

export default GoalsNull
