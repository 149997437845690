import Api from '../helpers/api'
import { ymmQuizURL } from '../helpers/ymmQuizURL'

export const FETCH_MONEY_MIND: string = 'FETCH_MONEY_MIND'
export const FETCH_MONEY_MIND_PENDING: string = 'FETCH_MONEY_MIND_PENDING'
export const FETCH_MONEY_MIND_FULFILLED: string = 'FETCH_MONEY_MIND_FULFILLED'
export const FETCH_MONEY_MIND_REJECTED: string = 'FETCH_MONEY_MIND_REJECTED'
export const FETCH_MONEY_MIND_DESCRIPTION: string =
  'FETCH_MONEY_MIND_DESCRIPTION'
export const FETCH_MONEY_MIND_TOKEN: string = 'FETCH_MONEY_MIND_TOKEN'
export const FETCH_MONEY_MIND_TOKEN_FULFILLED: string =
  'FETCH_MONEY_MIND_TOKEN_FULFILLED'
export const FETCH_MONEY_MIND_DESCRIPTION_PENDING: string =
  'FETCH_MONEY_MIND_DESCRIPTION_PENDING'
export const FETCH_MONEY_MIND_DESCRIPTION_FULFILLED: string =
  'FETCH_MONEY_MIND_DESCRIPTION_FULFILLED'
export const FETCH_MONEY_MIND_DESCRIPTION_REJECTED: string =
  'FETCH_MONEY_MIND_DESCRIPTION_REJECTED'
export const CLEAR_MONEY_MIND: string = 'CLEAR_MONEY_MIND'
export const CLEAR_MONEY_MIND_DESCRIPTIONS: string =
  'CLEAR_MONEY_MIND_DESCRIPTIONS'

export const getToken = (): { type: string; payload: Promise<string> } => {
  const api = Api.getInstance()
  return {
    type: FETCH_MONEY_MIND_TOKEN,
    payload: api
      .get('/ymm/quiz')
      .then((res) => res.data)
      .catch((err) => {
        console.error(err)
        return { url: ymmQuizURL() }
      })
  }
}

export const getResponses = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_MONEY_MIND,
    payload: apiInstance.get('/ymm/responses').then((response) => {
      return response.data
    })
  }
}

export const clearResponses = (): { type: string } => {
  return { type: CLEAR_MONEY_MIND }
}

export const getMoneyMindDescription = (
  contactLevel,
  index,
  primary,
  secondary
): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  let url = `/ymm/moneyminds?primary=${primary}`
  if (secondary != null) {
    url = `/ymm/moneyminds?primary=${primary}&secondary=${secondary}`
  }
  return {
    type: FETCH_MONEY_MIND_DESCRIPTION,
    payload: apiInstance.get(url).then((response) => {
      response.data.contactLevel = contactLevel
      response.data.index = index
      return response.data
    })
  }
}

export const clearMoneyMindDescriptions = (
  contactLevel
): { type: string; payload: string } => {
  return { type: CLEAR_MONEY_MIND_DESCRIPTIONS, payload: contactLevel }
}
