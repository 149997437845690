import React from 'react'

import { GlobalState } from '../../../reducers'
import { connect } from 'react-redux'

import Notifications from './Notifications'
import Contact from './Contact'
import Photo from './Photo'
import { UserObj } from '../../../objects/user'

type ProfileOverviewProps = ReturnType<typeof mapStateToProps>

const getName = (user: UserObj) => {
  const { firstName, lastName } = user || {
    firstName: '',
    lastName: ''
  }
  return `${firstName || ''} ${lastName || ''}`
}

const ProfileOverview = (props: ProfileOverviewProps): JSX.Element => {
  const { user } = props
  const clientName = getName(user)
  const cardClassName = 'pos-relative bg-white mb-4 p-4 p-md-x2'

  return (
    <div className='d-flex flex-column justify-content-start mb-4'>
      <span className='profile-card__title'>{clientName}</span>
      <div id='profile-photo-card' className={cardClassName}>
        <Photo />
      </div>
      <div id='profile-contact-card' className={cardClassName}>
        <Contact />
      </div>
      <div id='profile-notifications-card' className={cardClassName}>
        <Notifications clientId={user.id} />
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user } = store

  return { user }
}

export default connect(mapStateToProps)(ProfileOverview)
