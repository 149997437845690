import React from 'react'
import { GoalsTiles } from '../../Goals/GoalsTiles'
import Accordion from 'react-bootstrap/Accordion'
import { getCardGoals } from '../../../helpers/priorities'

export const ScoreGoals = (props): JSX.Element => {
  const { card, goalActions } = props
  const sortedGoals =
    card && goalActions && getCardGoals(card.card?.id, goalActions)
  return sortedGoals && sortedGoals.length > 0 ? (
    <div className='honestConversations-score__goal my-2'>
      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header className='honestConversations-score__goal-header'>
            <div className='pb-2 pt-2 py-md-4'>
              <h4 className='rfont--h4 text-dark'>Goals</h4>
              <div className='rfont--body3 mt-3 text-secondary fw-bold'>
                {`There are ${sortedGoals.length} goals connected to this priority. `}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Body className='p-0 pb-4 pb-md-x2'>
            <GoalsTiles goals={sortedGoals} hideLink={true} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  ) : null
}

export default ScoreGoals
