import React, { ChangeEvent, useState, useEffect } from 'react'
import classNames from 'classnames'
import { ObjectSchema, StringSchema } from 'yup'
import { useIsMounted } from '../../hooks'
import Form from 'react-bootstrap/Form'

/**
 * name : field name
 * label : field label
 * placeholder : some default text
 */
interface InputProps {
  inputClassName?: string
  className?: string
  defaultValue?: string
  name?: string
  label?: string
  placeholder?: string
  callBack?({
    isValid,
    inputValue
  }: {
    isValid: boolean
    inputValue: string
  }): void
  validator?: StringSchema | ObjectSchema<any>
  typeMatch?: { currentName: string; names: string[] }
  mask?(value: string): string
  onClick?(e: React.MouseEvent<HTMLInputElement>): void
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void
  onKeyUp?(e: React.KeyboardEvent<HTMLInputElement>): void
  allowedChars?: RegExp
  disabled?: boolean
  readOnly?: boolean
  removeTopPadding?: boolean
}

const ErrorDisplay = (props) => {
  const { error } = props
  return (
    <div>
      <i className='bi bi-exclamation-circle text-warning fs-5 me-2' />
      {error}
    </div>
  )
}

const TextInput = React.forwardRef(
  (
    {
      inputClassName = '',
      className = '',
      defaultValue = '',
      disabled = false,
      readOnly = false,
      name,
      label,
      placeholder,
      callBack,
      validator,
      typeMatch,
      mask,
      allowedChars,
      onKeyDown,
      onKeyUp,
      onClick,
      removeTopPadding = false
    }: InputProps,
    inputRef: React.Ref<HTMLInputElement>
  ): JSX.Element => {
    const [inputValue, setInputValue] = useState(defaultValue || '')
    const [errorMessages, setErrorMesages] = useState([])

    const isValid = errorMessages.length === 0
    /**
     * follow up input change with callback if passed
     * skip on initial mount
     */
    const isMounted = useIsMounted()

    useEffect(() => {
      if (!isMounted) return
      callBack?.({ isValid, inputValue })
    }, [inputValue, callBack])

    const validateInput = (
      fieldValue: string,
      schema: StringSchema | ObjectSchema<any>
    ) => {
      const field =
        schema.type === 'string'
          ? fieldValue
          : {
              value: fieldValue,
              currentName: fieldValue,
              names: typeMatch?.names
            }

      try {
        schema.validateSync(field, { abortEarly: false, strict: false })
        setErrorMesages([])
      } catch (error) {
        const { errors }: { errors: string[] } = JSON.parse(
          JSON.stringify(error)
        )
        setErrorMesages(errors)
      }
    }

    const setInputText = (e: ChangeEvent<HTMLInputElement>) => {
      let fieldValue = e.target.value
      // check if allowed
      if (allowedChars) {
        const isAllowed = allowedChars.test(fieldValue)
        if (!isAllowed) return
      }
      // apply mask if exists
      fieldValue = mask?.(e.target.value) || fieldValue
      // run validation
      validator && validateInput(fieldValue, validator)

      setInputValue(fieldValue)
    }

    const classes = classNames('text-input form-control-lg', inputClassName)
    const groupClassName = classNames(className, {
      'pt-3': !removeTopPadding
    })
    return (
      <Form.Group className={groupClassName}>
        <Form.Label className='text-input__label'>{label}</Form.Label>
        <Form.Control
          type='text'
          ref={inputRef}
          name={name}
          isInvalid={!isValid}
          value={inputValue}
          className={classes}
          placeholder={placeholder}
          onChange={setInputText}
          onKeyDown={onKeyDown}
          onKeyUp={onKeyUp}
          onClick={onClick}
          disabled={disabled}
          readOnly={readOnly}
          style={{ color: isValid ? '#22263F' : '', fontSize: '18px' }}
        />

        <Form.Control.Feedback
          type='invalid'
          className='text-dark fw-bold fs-7'>
          {inputValue === '' ? (
            <ErrorDisplay error='This is a required field.' />
          ) : (
            errorMessages.map((error) => (
              <ErrorDisplay key={error} error={error} />
            ))
          )}
        </Form.Control.Feedback>
      </Form.Group>
    )
  }
)

export default TextInput
