import React from 'react'
import Button from 'react-bootstrap/Button'
import { dateFormat } from '../../../helpers'
import { setTokenCookie } from '../../../helpers/priorities'

export type StatusTableProps = {
  emailSent: string
  inProgress: boolean
  completed: boolean
  showButton: boolean
  redirect?: string
  accessToken?: string
}

const StatusTable = ({
  emailSent,
  inProgress,
  completed,
  showButton,
  accessToken,
  redirect
}: StatusTableProps) => {
  const status = completed
    ? 'Completed'
    : inProgress
    ? 'In Progress'
    : 'Not Started'
  const date = emailSent ? dateFormat(emailSent, true) : '-/-/-'
  const buttonLabel = inProgress ? 'Continue' : 'Choose Priorities'

  const handleClick = () => {
    setTokenCookie(accessToken)
    window.open(redirect, '_blank')
  }

  return (
    <div className='d-flex justify-content-between'>
      <div className='flex-basis--4 flex-basis-sm-10'>
        <div className='d-flex justify-content-between'>
          <div className='hc-null-status__box'>
            <div className='hc-null-status__value'>{status}</div>
            <div className='hc-null-status__label'>Exercise Status</div>
          </div>
          <div className='hc-null-status__box'>
            <div className='hc-null-status__value--date'>{date}</div>
            <div className='hc-null-status__label'>Link Sent</div>
          </div>
        </div>
      </div>
      {showButton && (
        <div className='hc-null-status__button'>
          <Button size='lg' onClick={handleClick}>
            {buttonLabel}
          </Button>
        </div>
      )}
    </div>
  )
}

export default StatusTable
