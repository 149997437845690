import Api from '../helpers/api'
import { GoalRequestObj } from '../objects/goalRequests'
export const FETCH_GOAL_REQUESTS = 'FETCH_GOAL_REQUESTS'
export const CREATE_GOAL_REQUEST = 'CREATE_GOAL_REQUEST'
export const UPDATE_GOAL_REQUEST = 'UPDATE_GOAL_REQUEST'

export const getGoalRequests = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_GOAL_REQUESTS,
    payload: apiInstance.get('/goals/requests').then((response) => {
      return response.data
    })
  }
}

export const createGoalRequest = (goalRequest: GoalRequestObj) => {
  const apiInstance = Api.getInstance()
  return {
    type: CREATE_GOAL_REQUEST,
    payload: apiInstance
      .post('/goals/requests', goalRequest)
      .then((response) => {
        return response.data
      })
  }
}

export const updateGoalRequest = (id: string, goalRequest: GoalRequestObj) => {
  const apiInstance = Api.getInstance()
  return {
    type: UPDATE_GOAL_REQUEST,
    payload: apiInstance
      .put(`/goals/requests/${id}`, goalRequest)
      .then((response) => {
        return response.data
      })
  }
}
