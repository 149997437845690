export const AUTHENTICATED_ROUTES = [
  {
    label: 'Summary',
    url: '/',
    target: '',
    routes: ['/']
  },
  {
    label: 'Net Worth',
    url: '/net-worth',
    target: '',
    routes: ['/net-worth']
  },
  {
    label: 'Investments',
    url: '/investments',
    target: '',
    routes: ['/investments']
  },
  {
    label: 'Goals',
    url: '/goals',
    target: '',
    routes: ['/goals']
  },
  {
    label: 'Priorities',
    url: '/honest-conversations',
    target: '',
    routes: ['/honest-conversations']
  },
  {
    label: 'Plan',
    url: '/financial-plan',
    target: '',
    routes: ['/financial-plan']
  }
]

export const MARKETPLACE_OFFERS_RESOURCES_ROUTE = {
  label: 'Resources',
  url: '/resources',
  target: '',
  routes: ['/resources']
}

// exported to src\helpers\authentication.ts for access filtering
export const ACCOUNT_ROUTES = [
  { label: 'Documents', url: '/document-vault', target: '' },
  { label: 'Tasks', url: '/tasks', target: '' },
  { label: 'Profile', url: '/my-profile', target: '' },
  { label: 'Your MoneyMind', url: '/money-mind', target: '' }
]
