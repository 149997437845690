import React from 'react'
import Button from 'react-bootstrap/Button'
import InsuranceTable from './InsuranceTable'
import { history } from '../../store'
import backgroundLogo from '../../assets/images/backgroundlogo.png'

class InsurancePage extends React.Component {
  public navigateToNetWorth = (): void => {
    history.push('/net-worth')
  }

  public render() {
    return (
      <div>
        <img
          alt='ins-bg'
          className='insurance-container__bg-image'
          src={backgroundLogo}
        />
        <span className='insurance-container__button-back'>
          <Button
            className='ps-0'
            variant='link'
            onClick={this.navigateToNetWorth}
            style={{ color: 'black' }}>
            <i className='bi bi-chevron-left'></i> Back
          </Button>
        </span>

        <h2 className='mb-0 insurance-container__title'>
          Insurance and Annuities
        </h2>
        <InsuranceTable />
      </div>
    )
  }
}

export default InsurancePage
