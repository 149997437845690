import React from 'react'
import { Accordion } from 'react-bootstrap'
import ScoreProgress from '../ScoreProgress'

export interface DashProritiesRowProps {
  title: string
  score: number
}

const DashProritiesRow = (props: DashProritiesRowProps): JSX.Element => {
  const { score, title } = props
  const renderScore = () => {
    return score ? (
      <div className='mt-4'>
        <div className='fw-bold'>Score: {score}</div>
        <ScoreProgress score={score} />
      </div>
    ) : null
  }

  return (
    <div className='dash-priorities__card'>
      {score ? (
        <div className='dash-priorities__accordion'>
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>
                <div className='pe-2'>{title}</div>
              </Accordion.Header>
              <Accordion.Body>
                <div>{renderScore()}</div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      ) : (
        <div className='dash-priorities__card-text'>{title}</div>
      )}
    </div>
  )
}

export default DashProritiesRow
