import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { openAddModal } from '../../../helpers/netWorth'

const AccountsNull = (): JSX.Element => (
  <Card
    id='networth-null-view'
    className='bg-white border-0 mt-4'
    style={{ padding: '32px', margin: '0px' }}>
    <div className='accounts-null__w'>
      <h3 className='accounts-null__body-header'>No accounts here</h3>
      <span className='accounts-null__body-description'>
        Add your first account to get started
      </span>
      <Button className='fw-bold' size='lg' onClick={openAddModal}>
        Add Account
      </Button>
    </div>
  </Card>
)

export default AccountsNull
