import React from 'react'
import { map } from 'lodash-es'
import AccountRow from './AccountRow'
import { ClientAccountObj } from '../../objects/clientAccount'

interface AccountsTableProps {
  accounts: { [key: string]: ClientAccountObj }
  noAccounts: boolean
}

const NullState = () => (
  <div
    id='investment-accounts-null'
    className='bg-white position-relative p-3 p-md-x2'>
    <div className='investment-accounts__null'>
      <div className='investment-accounts__null-title'>No accounts here</div>
      <div className='investment-accounts__null-subtitle'>
        Your advisor needs to add accounts to this section.
      </div>
    </div>
  </div>
)

const AccountsTable = ({ accounts, noAccounts }: AccountsTableProps) => {
  if (noAccounts) {
    return <NullState />
  }

  return (
    <div id='investment-accounts-table' className='investment-accounts-table'>
      {map(accounts, (account: ClientAccountObj) => (
        <AccountRow key={account.id} account={account} />
      ))}
    </div>
  )
}

export default AccountsTable
