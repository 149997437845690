import dayjs from 'dayjs'
import * as helpers from './messages'
import { MessageThread } from '../objects/messages'
import { dateFormat, timeFormat } from '.'
import { ClientNotification } from '../objects/settings/clientAdvisor'
import { GQLClient } from '../app'
import {
  GetClientNotifications,
  GetMessageThreadQuery,
  MarkNotificationsReadMutation
} from './queries'

export const sortLatestThreads = (
  threads: MessageThread[],
  limit?: number
): MessageThread[] => {
  const sorted = [...threads].sort((a: MessageThread, b: MessageThread) => {
    const aDate =
      a.messagesByParent.nodes.length > 0
        ? a.messagesByParent.nodes[0].createdDate
        : a.createdDate
    const bDate =
      b.messagesByParent.nodes.length > 0
        ? b.messagesByParent.nodes[0].createdDate
        : b.createdDate
    return dayjs(bDate).diff(dayjs(aDate))
  })

  if (limit) {
    return sorted.slice(0, limit)
  }

  return sorted
}

export const getLatestThread = (
  threads: MessageThread[]
): MessageThread | null => {
  if (!threads.length) {
    return null
  }
  return helpers.sortLatestThreads(threads, 1)?.[0] || null
}

export const timeOrDate = (date: string) => {
  const today = dayjs()
  if (today.isSame(dayjs(date), 'day')) {
    return timeFormat(date)
  }
  return dateFormat(date)
}

export const getSubject = async (threadId: string, householdFinId: string) => {
  const messages = await GQLClient.query({
    query: GetMessageThreadQuery,
    variables: { householdFinId }
  })

  const threads: MessageThread[] = messages.data.allMessages.nodes

  if (!threads.length) {
    return null
  }

  return threads.find((thead) => thead.id === threadId)?.subject
}

export const getAllUnreadMessageNotifications = (
  clientNotifications: ClientNotification[],
  msgThreadId: string
) => {
  const subjectString = 'MESSAGE'
  const notifications = clientNotifications?.length ? clientNotifications : []
  return notifications.filter((notificationsNode: ClientNotification) => {
    const {
      notificationByNotification: { subject }
    } = notificationsNode
    const content = JSON.parse(
      notificationsNode?.notificationByNotification?.content as string
    )

    return (
      subject === subjectString &&
      !notificationsNode.read &&
      (content.data.parentId === msgThreadId ||
        content.data.messageId === msgThreadId)
    )
  })
}

export const getAllUnreadNotificationIds = (
  clientNotifications: ClientNotification[],
  msgThreadId: string
) => {
  return getAllUnreadMessageNotifications(clientNotifications, msgThreadId).map(
    (notification) => notification.id
  )
}

export const markNotificationsRead = async (userId, threadId) => {
  try {
    const { data: notifications } = await GQLClient.query({
      query: GetClientNotifications,
      variables: { userId }
    })
    const filterMessageNotifications = getAllUnreadNotificationIds(
      notifications?.allClientToNotifications?.nodes,
      threadId
    )

    filterMessageNotifications.length &&
      (await GQLClient.mutate({
        mutation: MarkNotificationsReadMutation,
        variables: {
          userId,
          ids: filterMessageNotifications,
          readDate: new Date().toISOString()
        }
      }))
  } catch (error) {
    console.error('An error occurred')
  }
}
