import * as React from 'react'
import { Link } from 'react-router-dom'
import { marketplaceOfferObj } from '../../objects/marketplaceOffers'

export interface OfferProps {
  offer: marketplaceOfferObj
}

const Offer = ({ offer }: OfferProps) => {
  const goToOfferDetails = () => {
    ;/^(http|https):\/\/[^ "]+$/.test(offer.gcOfferUrl) &&
      window.open(offer.gcOfferUrl, '_blank')
  }

  const handleChildClick = (e: any) => {
    e.stopPropagation()
  }

  return (
    <div className='marketplace__offer-w' onClick={goToOfferDetails}>
      <div className='marketplace__offer-left marketplace__offer-left--desktop'>
        <img src={offer.marketplaceLogoUrl} alt='offer logo' />
        <div className='marketplace__offer-name-tag--'>
          <div className='marketplace__offer-name'>{offer.name}</div>
          <div className='marketplace__offer-tag'>{offer.tag}</div>
        </div>
      </div>
      <div className='marketplace__offer-left marketplace__offer-left--mobile'>
        <div className='marketplace__offer-name-tag'>
          <img src={offer.marketplaceLogoUrl} alt='offer logo' />
          <div className='marketplace__offer-name'>{offer.name}</div>
        </div>
        <div className='marketplace__offer-tag'>{offer.tag}</div>
      </div>
      <div className='marketplace__divider' />
      <div className='marketplace__offer-right'>
        <div className='marketplace__offer-details'>{offer.salesPitch}</div>
        <div className='marketplace__offer-details'>{offer.contactInfo}</div>
        <div className='marketplace__offer-details'>{offer.additionalInfo}</div>
        <div className='marketplace__offer-details marketplace__offer-details--gray'>
          {offer.footnote}
        </div>
        {offer.copyrightDisclosure && (
          <Link
            className='marketplace__disclosure'
            onClick={handleChildClick}
            to={{
              pathname: '/disclosures',
              hash: `#marketplaceOffer`
            }}>
            To see disclosures, click here
          </Link>
        )}
      </div>
    </div>
  )
}

export default Offer
