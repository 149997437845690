import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import HonestConversationsGoal from './HonestConversationsGoal'
import HonestConversationsHero from './HonestConversationsHero'
import HonestConversationsScore from './HonestConversationsScore'
import { IndividualPrioritiesCard } from './IndividualPrioritiesCard'
import { NullState } from './Null'
import { getHonestConversation } from '../../actions/honestConversations.action'
import { getHousehold } from '../../actions/household.action'
import { GlobalState } from '../../reducers'
import {
  getClientCards,
  getUserPriorityCards
} from '../../selectors/priorities'

type HonestConversationsStore = ReturnType<typeof mapStateToProps>
type HonestConversationsDispatch = typeof mapDispatchToProps
export type HonestConversationsProps = HonestConversationsStore &
  HonestConversationsDispatch

export const HonestConversations = ({
  getHonestConversation,
  getHousehold,
  householdFetched,
  showNull,
  loading,
  userCards
}: HonestConversationsProps) => {
  useEffect(() => {
    const fetchData = async () => {
      if (!householdFetched) {
        await getHousehold()
      }
      getHonestConversation()
    }
    fetchData()
  }, [])

  const [selectCard, setSelectedCard] = useState(null)

  // avoid rendering null state before HC has been fetched
  if (loading) {
    return null
  }

  return (
    <div className='mb-7'>
      {showNull ? (
        <NullState />
      ) : (
        <>
          <HonestConversationsHero
            selectCard={selectCard}
            setSelectedCard={setSelectedCard}
          />
          <div>
            <HonestConversationsScore selectedCard={selectCard} />
            <HonestConversationsGoal selectCard={selectCard} />
            {userCards?.length > 0 && <IndividualPrioritiesCard />}
          </div>
        </>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getHonestConversation,
  getHousehold
}

const mapStateToProps = (store: GlobalState, ownProps) => {
  const { hasHouseholdCards, loading } = getClientCards(store)

  const userCards = getUserPriorityCards(store)

  return {
    showNull: !hasHouseholdCards,
    loading,
    householdFetched: store.user?.householdFetched,
    userCards,
    ...ownProps
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HonestConversations)
