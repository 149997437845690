import React from 'react'
import { Button } from 'react-bootstrap'
import TextInput from '../../components/TextInput'
import { history } from '../../store'

export interface MessagesHeaderProps {
  setSubjectFilter(text: string): void
}

export const MessagesHeader = (props: MessagesHeaderProps): JSX.Element => {
  const { setSubjectFilter } = props

  const handleCallback = ({ isValid = true, inputValue }) => {
    if (isValid) {
      setSubjectFilter(inputValue)
    }
  }

  const newMessageThread = () => history.push('/messages/new')

  return (
    <div className='d-flex flex-column flex-sm-row align-items-center justify-content-between messages-header'>
      <div className='d-flex flex-column flex-sm-row justify-content-start align-items-start align-items-sm-center align-content-center messages-header-left'>
        <div className='messages-header__title'>Messages</div>
        <TextInput
          name='messages-search'
          placeholder='Search Messages'
          removeTopPadding
          className='pb-3 messages-header__messages-search'
          callBack={handleCallback}
        />
      </div>
      <Button
        className='px-x2 py-3 messages-header__compose-btn'
        onClick={newMessageThread}>
        Compose
      </Button>
    </div>
  )
}
