import React from 'react'
import Button from 'react-bootstrap/Button'
import CreateFolderModal from './CreateFolderModal'

interface CreateFolderState {
  createFolderModalOpen: boolean
}

class CreateFolder extends React.Component<{}, CreateFolderState> {
  constructor(props: any) {
    super(props)

    this.state = {
      createFolderModalOpen: false
    }
  }
  public openCreateFolderModal = () => {
    this.setState({ createFolderModalOpen: true })
  }
  public closeCreateFolderModal = (): void => {
    this.setState({ createFolderModalOpen: false })
  }
  public render() {
    const { createFolderModalOpen } = this.state
    return (
      <div>
        <Button
          variant='primary'
          className='match-btn-width'
          size='lg'
          onClick={this.openCreateFolderModal}>
          Create Folder
        </Button>

        <CreateFolderModal
          isFullscreenOnMobile={false}
          showModal={createFolderModalOpen}
          closeModal={this.closeCreateFolderModal}
          modalProps={{ centered: true }}
        />
      </div>
    )
  }
}

export default CreateFolder
