import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { Link } from 'react-router-dom'
import { history, AppDispatch } from '../../store'
import { TitleContainer } from '../../components/Layout'
import FeeSummaryTable from './FeeSummaryTable'
import FeeFootnotes from './FeeFootnotes'
import iconArrow from '../../assets/images/arrow_right.png'
import iconPrint from '../../assets/images/print.png'
import { FeeScheduleObj } from '../../objects/investments'
import { getFeeSchedule } from '../../actions/investments.action'
import ucLogo from '../../assets/images/logos/United-Capital-Logo-300x100.png'
import { dateFormatStrategy } from '../../helpers'

export interface FeeScheduleProps {
  isFinlifeInstitution: boolean
  feeSchedule: FeeScheduleObj[]
  strategyAsOfDate: string
  dispatch: AppDispatch
}
export class FeeSchedule extends Component<FeeScheduleProps> {
  public componentDidMount() {
    const { isFinlifeInstitution, dispatch, feeSchedule } = this.props
    if (isFinlifeInstitution) {
      history.push('/')
    }
    if (!(feeSchedule.length > 0)) {
      dispatch(getFeeSchedule())
    }
  }

  public printPdf = () => {
    window.print()
  }

  public renderPdfHeader = () => {
    return (
      <div className='feesum-pdf-header'>
        <img className='feesum-pdf-header__logo' src={ucLogo} />
        <p className='feesum-pdf-header__text'>
          PFM Portfolio Manager Fee Summary
        </p>
      </div>
    )
  }

  public render() {
    const { isFinlifeInstitution, feeSchedule, strategyAsOfDate } = this.props
    const feeDataWithManagerOrOperationFees = feeSchedule?.filter(
      (feeListItem) =>
        feeListItem.managerFee > 0 || feeListItem.operationsFee > 0
    )
    const asOfDate = dateFormatStrategy(strategyAsOfDate)
    if (this.props && !isFinlifeInstitution) {
      return (
        <div>
          <div className='feesum-table__btn-w'>
            <Link
              className='feesum-table__back-btn'
              to={{
                pathname: '/disclosures',
                state: { section: 'investment', prevPath: '/investments' }
              }}>
              <img src={iconArrow} alt='arrow' />
              Back
            </Link>
            {feeDataWithManagerOrOperationFees?.length > 0 && (
              <span className='feesum-table__print-btn' onClick={this.printPdf}>
                <img src={iconPrint} alt='print' />
                Print
              </span>
            )}
          </div>
          {this.renderPdfHeader()}
          <TitleContainer title='United Capital Portfolio Manager Fee Summary' />
          <p>
            {`This is a list of strategies that your advisor may recommend or 
            select for your account, based on eligibility. Each strategy includes 
            information about the manager and associated fees, current as of 
            ${asOfDate || ''}. Note that these manager fees and other 
            operational costs are in additional to any United Capital advisory 
            fees, custodial trading fees, and/or fees embedded in any mutual 
            funds or ETFs.  For more details, please reach out to your advisor.`}
          </p>
          <p>
            In certain cases, implementation model definitions may come from the
            third-party sub-managers or sub-advisors listed below, or they may
            involve individually constructed portfolios for clients, which may
            include utilizing other United Capital advisors when appropriate.
            "Multi-Manager" refers to strategies where United Capital has the
            discretion to implement model portfolios from multiple third-party
            sub-managers or sub-advisors. If a manager fee is listed without
            "United Capital" in the "Portfolio Manager" column, those fees
            belong to the third-party sub-manager or sub-advisor.
          </p>
          {feeDataWithManagerOrOperationFees?.length > 0 && (
            <>
              <FeeSummaryTable feeData={feeDataWithManagerOrOperationFees} />
              <FeeFootnotes feeData={feeDataWithManagerOrOperationFees} />
            </>
          )}
        </div>
      )
    } else {
      return null
    }
  }
}

export default connect((store: GlobalState, dispatch: AppDispatch) => {
  return {
    dispatch,
    strategyAsOfDate: store.investments.strategyAsOfDate,
    isFinlifeInstitution: store.user.isFinlifeInstitution,
    feeSchedule: store.investments.feeSchedule
  }
})(FeeSchedule)
