import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import FileUpload from '../../../components/FileUpload'
import ControlledModalWrapper from '../../../components/ControlledModalWrapper'
import {
  uploadValidator,
  ALLOWED_FILE_TYPES,
  FILE_SIZE_LIMIT
} from '../../../utils/validators'

interface UploadFileModalProps {
  fileFormatError: boolean
  fileUploadPending: boolean
  closeModal(): void
  uploadFiles(files: any[]): void
}

interface UploadFileModalState {
  validationMessages: string[]
}

class UploadFileModal extends React.Component<
  UploadFileModalProps,
  UploadFileModalState
> {
  constructor(props: UploadFileModalProps) {
    super(props)
    this.state = {
      validationMessages: []
    }
  }

  public onError = (
    event: React.ChangeEvent<HTMLInputElement>,
    errorFiles: File[]
  ) => {
    const validateDispatch = async () => {
      const validationMessages: string[] = await (
        await uploadValidator(
          errorFiles as any /* workaround for TS File is not File type error*/
        )
      ).filter((message, position, accum) => {
        return accum.indexOf(message) === position && message.length > 0
      })
      this.setState({ validationMessages })
    }

    this.setState({ validationMessages: [] }, () => validateDispatch())
  }

  public onFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    verifiedFiles: File[]
  ) => {
    const validateDispatch = async () => {
      const validationMessages: string[] = await (
        await uploadValidator(
          verifiedFiles as any /* workaround for TS File is not File type error*/
        )
      ).filter((message, position, accum) => {
        return accum.indexOf(message) === position && message.length > 0
      })

      this.setState({ validationMessages }, () => {
        if (!validationMessages.length) {
          this.props.uploadFiles(verifiedFiles)
        }
      })
    }

    this.setState({ validationMessages: [] }, () => validateDispatch())
  }

  public validationMessageView = () => {
    const { validationMessages } = this.state
    if (validationMessages.length) {
      return (
        <div className='upload-files__validation'>
          <i className='bi bi-bell fs-5' />

          <div className='upload-files__validation-message'>
            {validationMessages.map((message) => {
              return (
                <span className='ms-3 w-100' key={message}>
                  {message}
                </span>
              )
            })}
          </div>
        </div>
      )
    }
    return null
  }

  public render() {
    const { fileUploadPending } = this.props
    const maxFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
    return (
      <div className='upload-files-w text-left'>
        <div className='vault-modal__title'>Upload File</div>
        {this.validationMessageView()}
        {fileUploadPending ? (
          <div
            style={{ minHeight: 414 }}
            className='d-flex flex-column align-items-center justify-content-center position-relative'>
            <Spinner animation='border' variant='dark' className='size--8' />
            <p className='rfont--body2 spinner-msg-pos'>Uploading File</p>
          </div>
        ) : (
          <div>
            <div className='vault-modal__body-text'>
              {`File names should not exceed 76 characters in length, or use
                symbols other than underscores. Spaces in file name will be
                converted to underscores. Maximum file size limit is ${maxFileSize}mb`}
            </div>
            <FileUpload
              description='Accepted formats: Documents (pdf, docx, doc, txt),
          Spreadsheets (csv, xls, xlsx), Images (jpg, jpeg, png, bmp gif, tif),
          Presentations (ppt, pptx) and Archives (zip)'
              allowedFileFormats={ALLOWED_FILE_TYPES}
              handleFileError={this.onError}
              allowedFileSize={FILE_SIZE_LIMIT * 2}
              handleAddFile={this.onFileUpload}
            />
          </div>
        )}
      </div>
    )
  }
}

export default ControlledModalWrapper(UploadFileModal)
