import React from 'react'
import { Card, Button } from 'react-bootstrap'

export interface PartnerCardProps {
  contactName: string
  updateUserView: Function
}

export const PartnerCard = (props: PartnerCardProps) => {
  return (
    <Card className='rounded-0 border-0 py-3 my-3'>
      <Card.Body className='d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-start text-sm-left'>
        <h3 className='flex-grow-1 mb-3 mb-sm-0 mm-partner-card__contact'>
          {`Want to see ${props.contactName || 'Partner'}'s MoneyMind®?`}
        </h3>
        <Button
          className='mx-auto mx-sm-0 font-color--dark match-btn-width'
          size='lg'
          variant='outline-primary'
          onClick={() => props.updateUserView()}>
          See Results
        </Button>
      </Card.Body>
    </Card>
  )
}
