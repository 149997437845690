import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Card, Dropdown, DropdownButton } from 'react-bootstrap'
import {
  GoalRequestObj,
  GoalRequestObjState,
  FinancialGoalObj
} from '../../../objects/plan'
import { getGoals } from '../../../actions/goals.action'
import { GlobalState } from '../../../reducers'
import { dollarFormat } from '../../../helpers'
import { CustomPlanUpdateTable } from './CustomPlanUpdateTable'

const renderTypeTag = (request, label) => {
  if (label === 'Name' && request.requestType !== 'EDIT') {
    return (
      <div className='pending-updates__table-tag'>
        {request?.requestType === 'CREATE'
          ? 'New'
          : request?.requestType === 'DELETE'
          ? 'Deleted'
          : null}
      </div>
    )
  } else return null
}

const dynamicRender = (goal, request, label) => {
  if (request.requestType === 'EDIT') {
    if (label === 'Period' && (goal?.startDate || goal?.endDate)) {
      const isStartEqual = goal?.startDate === request?.proposedStartOfGoal
      const isEndEqual = goal?.endDate === request?.proposedEndOfGoal
      return (
        <div className='pending-updates__table-val--strike'>
          {(!isStartEqual || !isEndEqual) &&
            `${goal?.startDate || ''} - ${goal?.endDate || ''}`}
        </div>
      )
    }
    if (label === 'Amount' && goal?.amount) {
      const sanitizedAmount = goal.amount.replace(/[$%,]/g, '') || ''
      return (
        <div className='pending-updates__table-val--strike'>
          {sanitizedAmount !== String(request?.proposedAmount) && goal.amount}
        </div>
      )
    }
    if (label === 'Name' && goal?.name) {
      return (
        <div className='pending-updates__table-val--strike'>
          {goal.name !== request?.proposedName && goal.name}
        </div>
      )
    } else return null
  } else return null
}

const getPeriod = (startDate: string, endDate: string) => {
  if (startDate && !endDate) {
    return startDate
  }
  if (!startDate && endDate) {
    return endDate
  }
  if (startDate && endDate) {
    return `${startDate} - ${endDate}`
  }
  return ''
}

const mapGoalField = (requestLabel: string, goal) => {
  switch (requestLabel) {
    case 'Name':
      return goal?.name || ''
    case 'Amount':
      return goal?.amount || ''
    case 'Period':
      return getPeriod(goal?.startDate || '', goal?.endDate || '')
    default:
      return ''
  }
}

const textCell = (label: string, getField: (request) => string) => ({
  request,
  goal
}) => {
  const requestField = getField(request)
  const goalField = mapGoalField(label, goal)

  return (
    <div>
      {dynamicRender(goal, request, label)}
      {renderTypeTag(request, label)}
      {request.requestType == 'DELETE' ? (
        <div className='pending-updates__table-val--strike'>{requestField}</div>
      ) : (
        <div className='plan-inputs-table__field'>{requestField}</div>
      )}
      {!requestField && !goalField ? (
        <div />
      ) : (
        <div className='plan-inputs-table__label'>{label}</div>
      )}
    </div>
  )
}

const NameCell = textCell('Name', ({ proposedName }) => proposedName)

export const PeriodCell = textCell(
  'Period',
  ({ proposedStartOfGoal, proposedEndOfGoal }) => {
    return getPeriod(proposedStartOfGoal, proposedEndOfGoal)
  }
)

const AmountCell = textCell(
  'Amount',
  ({ proposedAmountType, proposedAmount }) =>
    !proposedAmount
      ? ''
      : proposedAmountType === 'Dollar'
      ? dollarFormat(proposedAmount, 0)
      : `${proposedAmount}%`
)

const MenuCell = ({ request, onEditRequest }) => {
  const handleClick = (callback) => (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation()
    callback(request)
  }

  return (
    <DropdownButton
      className='text-primary plan-inputs__overflow'
      variant=''
      title={<i className='bi bi-three-dots'></i>}
      drop='down-centered'>
      <Dropdown.Item onClick={handleClick(onEditRequest)}>Edit</Dropdown.Item>
    </DropdownButton>
  )
}

// in mobile view, NameCell and MenuCell will sum 35%
export const tableColumns = [
  {
    Cell: NameCell,
    style: { width: '30%' },
    className: 'plan-inputs-table__column'
  },
  {
    Cell: PeriodCell,
    style: { width: '25%' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: AmountCell,
    style: { width: '10%' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: MenuCell,
    style: { width: '5%' },
    overflowable: true,
    className: 'plan-inputs-table__column'
  }
]

interface PassedPendingUpdateTableProps {
  requests: GoalRequestObjState
  onEditRequest(request: GoalRequestObj): void
}

type PendingUpdateTableActions = typeof mapDispatchToProps
type PendingUpdateTableStore = ReturnType<typeof mapStateToProps>
export type PendingUpdateTableProps = PendingUpdateTableActions &
  PendingUpdateTableStore &
  PassedPendingUpdateTableProps

export const PendingUpdateTable = (props: PendingUpdateTableProps) => {
  const { onEditRequest, getGoals, requests } = props

  useEffect(() => {
    getGoals()
  }, [])

  const [showPending, togglePending] = useState(false)

  if (!Object.keys(requests)?.length) {
    return null
  }

  const data = Object.keys(requests).map((reqData) => {
    let goal: FinancialGoalObj
    if (props.goals && requests[reqData].existingId) {
      goal = Object.values(props.goals)?.find((acc) => {
        return acc?.id === requests[reqData].existingId
      })
    }
    return {
      id: requests[reqData].id,
      request: requests[reqData],
      goal,
      onEditRequest
    }
  })

  return (
    <Card
      className='bg-white border-0 p-0 rounded-0 '
      id='plan-inputs-table-card'>
      <div className='pending-updates__header'>
        <h3 className='pending-updates__header-title'>Pending Updates</h3>
        <div className='pending-updates__header-subtitle'>
          <span>Inputs that are pending to be confirmed by your advisor.</span>
          <span
            className='pending-updates__header-link'
            onClick={() => togglePending(!showPending)}>
            {' '}
            <span>｜</span>
            <span>{`${showPending ? 'Hide' : 'Show'}`} </span>
            <i
              className={`bi bi-chevron-down ${
                showPending ? 'pending-updates__icon-up' : ''
              }`}></i>
          </span>
        </div>
      </div>
      {showPending && (
        <CustomPlanUpdateTable id='plan-input-table' data={data} />
      )}
    </Card>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    goals: store.financialGoals.goalsById
  }
}

const mapDispatchToProps = {
  getGoals
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingUpdateTable)
