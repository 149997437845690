import Api from '../helpers/api'

export const FETCH_CONTACTS = 'FETCH_CONTACTS'
export const FETCH_CONTACTS_PENDING = 'FETCH_CONTACTS_PENDING'
export const FETCH_CONTACTS_REJECTED = 'FETCH_CONTACTS_REJECTED'
export const FETCH_CONTACTS_FULFILLED = 'FETCH_CONTACTS_FULFILLED'

export const getContacts = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_CONTACTS,
    payload: apiInstance.get('/contacts').then((response) => {
      return response.data
    })
  }
}
