import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { RouteProps } from 'react-router'

interface SubRouteProps extends RouteProps {
  text: string
}

type SubRoutesProps = { routes: SubRouteProps[] }
const SubRoutes = (props: SubRoutesProps) => {
  const routeComponents = props.routes.map((route) => {
    const { path, component, exact, text } = route
    return (
      <Route
        key={text}
        path={path}
        component={component}
        exact={exact || false}
      />
    )
  })

  return props && <Switch>{routeComponents}</Switch>
}

export default SubRoutes
