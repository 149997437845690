import React from 'react'
import MoneyMindGraph from './Graph'
import Card from 'react-bootstrap/Card'
import { MmHeroProps } from '../../../objects/moneyMind'
import { MoneyMindTitle } from './Title'

const MoneyMindHero = (props: MmHeroProps) => {
  const {
    displayPrimary,
    contactName,
    updateUserView,
    moneymind,
    buttonLink
  } = props

  return (
    <div>
      <div className='mm-hero__container' />
      <Card className='border-0 rounded-0 p-0 m-0 mm-hero__card' id='mmhero'>
        <div className='mm-hero__result'>
          <MoneyMindTitle
            displayPrimary={displayPrimary}
            moneymind={moneymind}
            updateUserView={updateUserView}
            contactName={contactName}
            buttonLink={buttonLink}
          />
          <div className='mm-graphs__w'>
            <MoneyMindGraph
              percent={moneymind?.commitment || 0}
              title='Commitment'
              color='#3878be'
            />
            <MoneyMindGraph
              percent={moneymind?.fear || 0}
              title='Protection'
              color='#3b8469'
            />
            <MoneyMindGraph
              percent={moneymind?.happiness || 0}
              title='Happiness'
              color='#6a4084'
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default MoneyMindHero
