import axios, { AxiosInstance } from 'axios'

const api = (): {
  getInstance(): AxiosInstance
  createInstance(accessToken: string, contact?: string): void
  destroyAuth(): void
} => {
  let instance: AxiosInstance = axios.create({
    baseURL: window._env_.REACT_APP_API_URL
  })
  instance.defaults.timeout = 30000

  const createInstance = (accessToken: string, contact?: string): void => {
    const authHeader = accessToken
      ? { Authorization: `Bearer ${accessToken}` }
      : {}
    const headers: {
      Authorization?: string
      Pragma: string
      Contact?: string
    } = {
      ...authHeader,
      Pragma: 'no-cache'
    }
    if (contact) {
      headers.Contact = contact
    }
    instance = axios.create({
      baseURL: window._env_.REACT_APP_API_URL,
      headers
    })
    instance.defaults.timeout = 30000
  }

  const getInstance = (): AxiosInstance => {
    return instance
  }

  const destroyAuth = (): void => {
    instance = axios.create({
      baseURL: window._env_.REACT_APP_API_URL
    })
    instance.defaults.timeout = 30000
  }
  return { getInstance, createInstance, destroyAuth }
}

export default api()
