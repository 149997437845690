import React from 'react'
import { map } from 'lodash-es'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import Toast from './Toast'
import * as actions from '../../actions/toasts.action'
import { ToastState } from '../../reducers/toasts.reducer'
import { AppDispatch } from '../../store'

export interface ToastsProps {
  toasts: ToastState
  dispatch: AppDispatch
  collapsed?: boolean
}

export class Toasts extends React.Component<ToastsProps> {
  public removeToastById = (id: string) => {
    this.props.dispatch(actions.removeToastById(id))
  }

  public render() {
    const { toasts } = this.props
    const toastArray = map(toasts, (toast, id) => {
      return (
        <Toast
          key={id}
          id={id}
          message={toast.message}
          icon={toast.icon}
          backgroundColor={toast.backgroundColor}
          removeToastById={this.removeToastById}
          color={toast.color}
          type={toast.type}
          actionBtn={toast.actionBtn}
        />
      )
    })
    return <div className='toasts__container'>{toastArray}</div>
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    toasts: store.toasts
  }
}

export default connect(mapStateToProps)(Toasts)
