import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import TasksTable from '../../Tasks/TasksTable'
import DashTileNull from '../../../components/Dashboard/DashTileNull'
import DashTile, { DashTileLink } from '../../../components/Dashboard/DashTile'
import { dashTaskList } from '../../../selectors/tasks'
import * as actions from '../../../actions/tasks.action'
import { taskRefetch } from '../../../helpers/tasks'
import store from '../../../store'

const links: DashTileLink[] = [{ label: 'View Tasks', route: '/tasks' }]

const NullState = () => (
  <DashTileNull
    title='No active tasks'
    description='There are no active tasks assigned to anyone.'
  />
)

type TasksStore = ReturnType<typeof mapStateToProps>
type TasksDispatch = typeof mapDispatchToProps
export type TasksProps = TasksStore & TasksDispatch

export const TasksTile = (props: TasksProps) => {
  const { getTasks, tasks } = props

  useEffect(() => {
    window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true' &&
    store.getState().user?.isFinlifeInstitution === false &&
    store.getState().user?.enableCRMTasks === true
      ? taskRefetch()
      : getTasks()
  }, [])

  return (
    <DashTile
      title='Tasks'
      links={links}
      nullComponent={<NullState />}
      showNull={!tasks?.length}
      rowTileType>
      <TasksTable dashboard tasks={tasks} />
    </DashTile>
  )
}

const mapDispatchToProps = {
  getTasks: actions.getTasks
}

const mapStateToProps = (store: GlobalState) => {
  const tasks = dashTaskList(store)
  return {
    tasks
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksTile)
