import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { AppDispatch, history } from '../../../store'
import { GlobalState } from '../../../reducers'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { ContactsState } from '../../../reducers/contacts.reducer'
import { OfficeTeamMemberObj } from '../../../objects/officeTeam'
import MessageBody from './MessageBody'
import { getSubject, markNotificationsRead } from '../../../helpers/messages'

export interface MessagesTitleProps {
  householdFinId: string
  userId: string
  contacts: ContactsState
  members: OfficeTeamMemberObj[]
  threadId?: string
  dispatch: AppDispatch
}

const navigateToMessages = (): void => {
  history.push('/messages')
}

export const MessagesHeader = (props: { title: string }): JSX.Element => {
  const { title } = props

  return (
    <div className='message-body__container'>
      <div>
        <Button
          variant=''
          onClick={navigateToMessages}
          className='message-body__btn-back ps-4 px-sm-0 pt-4 pt-md-0'>
          <i className='bi bi-chevron-left me-2' />
          Back
        </Button>
      </div>

      <div className='flex-row justify-content-start flex-wrap flex-md-nowrap'>
        <div className='ps-4 ps-sm-0 pe-0'>
          <h3 className='rfont--h3 fw-bold mb-0 message-body__title'>
            {title}
          </h3>
        </div>
      </div>
    </div>
  )
}

const ThankYouMessage = () => {
  return (
    <div className='message-body__thank-you'>
      Thank you for contacting us. All interactions are subject to recordkeeping
      and monitoring. We are unable to accept any trading or transaction
      requests, nor do we provide any legal, tax or investment advice through
      this tool.
    </div>
  )
}

const MessageDetails = (props: MessagesTitleProps) => {
  const {
    contacts,
    householdFinId,
    members,
    threadId,
    userId,
    dispatch
  } = props

  const [subject, setSubject] = useState('')
  const [error, setError] = useState(false)

  useEffect(() => {
    markNotificationsRead(userId, threadId).catch((notificationError) => {
      console.error('An error occurred')
    })

    const getThreadSubject = async () => {
      setSubject(await getSubject(threadId, householdFinId))
    }

    getThreadSubject().catch((err) => {
      // we got an error that isn't 'householdFinId is undefined'
      if (householdFinId) {
        setError(true)
      }
    })
  }, [householdFinId, userId, threadId])

  return (
    <div>
      <MessagesHeader title={subject} />
      <MessageBody
        threadId={threadId}
        householdFinId={householdFinId}
        userId={userId}
        contacts={contacts}
        members={members}
        dispatch={dispatch}
        error={error}
      />
      <ThankYouMessage />
    </div>
  )
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  return {
    householdFinId: store.user.householdId,
    threadId: match.params.threadId,
    userId: store.user.id,
    contacts: store.contacts,
    members: store.officeTeam ? store.officeTeam.members : []
  }
}

export default withRouter(connect(mapStateToProps)(MessageDetails))
