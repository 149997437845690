import React from 'react'
import { Link } from 'react-router-dom'
import { Mutation } from '@apollo/client/react/components'
import { MutationFunction, MutationResult } from '@apollo/client'
import { GroupProfilePictures } from '@unitedcapitalfinancialadvisors/finlife-component-library'
import { Button } from '../Buttons'
import {
  getNotificationContactNames,
  getNotificationLink,
  getClientData,
  notificationDate,
  unreadFromNotification,
  unreadFromAllNotifications
} from '../../helpers/notifications'
import { MarkNotificationsReadMutation } from '../../helpers/queries'
import ProfilePicture from '../ProfilePicture'
import {
  GenericNotificationView,
  MessageNotification,
  NewTaskAssignedView,
  NewVaultFileView
} from './NotificationItemDisplay'

import {
  DisplayableNotification,
  NewTaskAssignedNotification
} from '../../objects/settings/clientAdvisor'
import { OfficeTeamMemberObj } from '../../objects/officeTeam'

export interface NotificationDropdownProps {
  contacts: any
  members: any
  notifications: DisplayableNotification[]
  onNotificationClick?(): any
  userId: string
}

const getMutationOptions = (ids: string[]) => ({
  variables: {
    ids,
    readDate: new Date().toISOString()
  }
})

export const NotificationBellDropdown: React.FC<NotificationDropdownProps> = ({
  contacts,
  members,
  notifications,
  onNotificationClick,
  userId
}) => {
  const notificationMessage = (
    subject: string,
    batchSize: number,
    content: any,
    firstName: string,
    lastName: string
  ) => {
    const contentObj = JSON.parse(content)

    switch (subject) {
      case 'MESSAGE':
        return <MessageNotification />
      case 'NEW_PDV_FILE':
        return (
          <NewVaultFileView
            batchSize={batchSize}
            content={contentObj}
            firstName={firstName}
            lastName={lastName}
          />
        )
      case 'NEW_TASK_ASSIGNED':
        return (
          <NewTaskAssignedView
            batchSize={batchSize}
            name={(contentObj.data as NewTaskAssignedNotification).taskSubject}
          />
        )
      default:
        return <GenericNotificationView />
    }
  }

  const notificationDisplayList = (mutate: MutationFunction) => {
    return notifications
      .map((notification: DisplayableNotification) => {
        const {
          read,
          notificationByNotification: { content, createdDate, subject },
          batchSize,
          householdContacts
        } = notification

        const { triggerType, triggerId } = JSON.parse(content as string)
        let isPrimary =
          triggerType === 'client' && contacts?.primary?.id === triggerId
        let photo: string
        let firstName: string
        let lastName: string

        if (triggerType === 'adviser' && members) {
          members.forEach((member: OfficeTeamMemberObj) => {
            if (member?.user?.id === triggerId) {
              photo = member?.user?.photoUrl
              firstName = member?.user?.firstName
              lastName = member?.user?.lastName
            }
          })
        }
        if (triggerType === 'client') {
          const { cFirstName, cLastName, cPhoto } = getNotificationContactNames(
            triggerId,
            contacts
          )
          firstName = cFirstName
          lastName = cLastName
          photo = cPhoto
        }

        const subjects = ['MESSAGE', 'NEW_TASK_ASSIGNED', 'NEW_PDV_FILE']
        const link = getNotificationLink(subject, notification)

        let notificationHouseholdContacts = getClientData(householdContacts)
        if (subject === 'NEW_TASK_ASSIGNED') {
          const { primaryAssignee, secondaryAssignee } = JSON.parse(
            content as string
          )

          let notificationClient = householdContacts?.primary
          if (primaryAssignee !== householdContacts?.primary?.id) {
            notificationClient = householdContacts?.secondary
            isPrimary = false
            firstName = householdContacts?.secondary?.firstName
            lastName = householdContacts?.secondary?.lastName
            photo = householdContacts?.secondary?.photo
          } else {
            isPrimary = true
            firstName = householdContacts?.primary?.firstName
            lastName = householdContacts?.primary?.lastName
            photo = householdContacts?.primary?.photo
          }

          notificationHouseholdContacts = getClientData({
            primary: notificationClient,
            secondary: secondaryAssignee && householdContacts.secondary
          })
        }

        return subjects.includes(subject) ? (
          <Link
            to={link}
            onClick={() => {
              handleNotificationRead(notification, mutate)
              typeof onNotificationClick === 'function' && onNotificationClick()
            }}
            key={notification.id}
            className={
              read
                ? 'header__notification-dropdown-item'
                : 'header__notification-dropdown-item header__notification-dropdown-item--unread'
            }>
            <div className='header__notification-dropdown-item--image'>
              {triggerType === 'household' ||
              (subject === 'NEW_TASK_ASSIGNED' &&
                notificationHouseholdContacts.secondary) ? (
                <GroupProfilePictures
                  clients={notificationHouseholdContacts}
                  pictureSize={32}
                  overlap={true}
                />
              ) : (
                <ProfilePicture
                  firstName={firstName}
                  lastName={lastName}
                  photo={photo}
                  isPrimary={isPrimary}
                  pictureSize={32}
                />
              )}
            </div>
            <div>
              {notificationMessage(
                subject,
                batchSize,
                content,
                firstName,
                lastName
              )}
              <div className='header__notification-date'>
                {notificationDate(createdDate)}
              </div>
            </div>
          </Link>
        ) : null
      })
      .filter((link: any) => link)
  }

  // mark a notification (or batch) as read
  const handleNotificationRead = (
    notification: DisplayableNotification,
    mutate: MutationFunction
  ) => {
    const unreadIds = unreadFromNotification(notification)
    if (!unreadIds.length) {
      return
    }

    const options = getMutationOptions(unreadIds)
    mutate(options)
  }

  // mark all notifications as read
  const handleAllNotificationsRead = (mutate: MutationFunction) => {
    const unreadIds = unreadFromAllNotifications(notifications)
    if (!unreadIds.length) {
      return
    }

    const options = getMutationOptions(unreadIds)
    mutate(options)
  }

  if (!userId) {
    return null
  }

  return (
    <Mutation mutation={MarkNotificationsReadMutation} variables={{ userId }}>
      {(mutate: MutationFunction, { loading }: MutationResult) => {
        const displayableList = notificationDisplayList(mutate)
        if (!displayableList.length) {
          return (
            <div className='header__notification-dropdown-null'>
              No Notifications.
            </div>
          )
        }
        return (
          <>
            <div className='header__notification-dropdown-list'>
              {displayableList}
            </div>
            <div className='header__notification-dropdown-mark-read'>
              <Button
                clear
                className='text-primary'
                onClick={() => handleAllNotificationsRead(mutate)}
                disabled={loading}>
                Mark All As Read
              </Button>
            </div>
          </>
        )
      }}
    </Mutation>
  )
}

export default NotificationBellDropdown
