import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { openAddModal } from '../../helpers/netWorth'

export const AddAccountCard = () => (
  <Card className='my-4 p-4 p-md-x2 rounded-0 border-0 bg-white'>
    <div className='networth-account-card'>
      <div className='networth-account-card__title'>
        <h3 className='rfont--h3 fw-bold'>Add Account</h3>
        <p>
          Link or manually add accounts to see a full picture of your net worth.
        </p>
      </div>

      <div className='networth-account-card__btn'>
        <Button
          type='button'
          onClick={openAddModal}
          variant='primary'
          size='lg'
          className='fw-bold match-btn-width'>
          Add Account
        </Button>
      </div>
    </div>
  </Card>
)
