import React, { Component } from 'react'

interface ScoreCommentProps {
  value: string
  cardIndex?: any
  onCommentChange(comment: string, key: number): void
  createScoresAndUpdateScoringSession(): void
}

interface ScoreCommentState {
  timeout: ReturnType<typeof setTimeout>
}

class ScoreComment extends Component<ScoreCommentProps, ScoreCommentState> {
  constructor(props: ScoreCommentProps) {
    super(props)
    this.state = {
      timeout: null
    }
  }
  public componentWillUnmount() {
    clearTimeout(this.state.timeout)
  }

  public resetTimeout = (oldTimeout, newTimeout) => {
    clearTimeout(oldTimeout)
    return newTimeout
  }

  public autoSaveFunc = () => {
    if (this.props.value) {
      this.props.createScoresAndUpdateScoringSession()
    }
  }

  public setInputState = (e: React.FormEvent<HTMLTextAreaElement>) => {
    const { value } = e.currentTarget
    const { onCommentChange, cardIndex } = this.props
    this.setState({
      timeout: this.resetTimeout(
        this.state.timeout,
        setTimeout(
          this.autoSaveFunc,
          parseInt(window._env_.REACT_APP_HC_COMMENT_AUTOSAVE_TIMEOUT_MSECONDS)
        )
      )
    })
    onCommentChange(value, cardIndex)
  }

  public render() {
    const { value } = this.props
    return (
      <div className='honestConversations-score__comment--input'>
        <textarea
          className='honestConversations-score__comment--text'
          value={value ? value : ''}
          onChange={this.setInputState}
          onBlur={this.autoSaveFunc}
          maxLength={120}
          placeholder={'Explain what you can do to improve this score'}
        />
        <p className='honestConversations-score__comment--limit'>
          {`${value ? 120 - value.length : 120}/120`}
        </p>
      </div>
    )
  }
}

export default ScoreComment
