interface Params {
  token?: string
  id?: string
}

/**
 * Return true if YMM version is new
 *
 * @returns {boolean}
 */
export function ymmQuizIsNewVersion(): boolean {
  return Number(window._env_.REACT_APP_YMM_OLD_VERSION) === 0
}

/**
 * Returns proper param name (token/cid) for YMM version(new/old)
 *
 * @returns {string}
 */
export function ymmQuizParamName(): string {
  return ymmQuizIsNewVersion() ? 'token' : 'cid'
}

/**
 * Will return token or id from passed object based on YMM version
 *
 * @param param
 *
 * @returns "id" for old & "token" for new YMM version
 */
export function ymmQuizParamValue({
  token,
  id
}: Params | Record<string, any>): string {
  return ymmQuizIsNewVersion() ? token : id
}

/**
 * Returns URL with proper param name based on YMM version
 *
 * @param paramValue - param value (cid or token based on YMM version)
 *
 * @returns
 */
export function ymmQuizURL(paramValue?: string): string {
  const baseURL = window._env_.REACT_APP_YMM_URL
  const paramName = ymmQuizParamName()
  const param = paramValue ? `?${paramName}=${paramValue}` : ''

  return `${baseURL}/${param}`
}
