// MASTER LAYOUT
// @ts-nocheck
import * as React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { MAINTENANCE_MODE } from '../helpers'
// Migrated containers
import Auth from '../containers/Auth'
import LogOut from '../containers/LogOut'
import MaintenancePage from '../containers/Maintenance'
// Migrated components
import TimeoutModal from '../components/TimeoutModal'

import TouModal from '../components/TouModal'

type UnrestrictedRoutesProps = {
  termsOfServiceLoading: boolean
}

export default function UnrestrictedRoutes(props: UnrestrictedRoutesProps) {
  const { termsOfServiceLoading } = props
  return (
    <div>
      <TimeoutModal />
      {!termsOfServiceLoading && <TouModal />}
      <div className='content nav-closed'>
        <div className='content-wrapper'>
          <Switch>
            <Route exact path='/maintenance' component={MaintenancePage} />
            <Route
              path='/'
              render={(props) =>
                MAINTENANCE_MODE ? (
                  <Redirect to='/maintenance' />
                ) : (
                  <Auth {...props} />
                )
              }
            />
            <Route
              path='/auth/salesforce/callback'
              render={(props) => <Auth {...props} />}
            />
            <Route path='/logout' render={(props) => <LogOut {...props} />} />
          </Switch>
        </div>
      </div>
    </div>
  )
}
