import { isFinite } from 'lodash-es'
import * as actions from '../actions/goals.action'
import * as householdActions from '../actions/household.action'
import { FinancialGoalObj } from '../objects/plan'
import {
  FundedScoreObj,
  FinancialGoalPreferences
} from '../objects/financialGoal'
import { dollarFormat } from '../helpers'
export interface FinancialGoalsState {
  fundedScores: FundedScoreObj[]
  goalsById: { [key: string]: FinancialGoalObj }
  preferences: FinancialGoalPreferences
}

const initState: FinancialGoalsState = {
  fundedScores: [],
  goalsById: {},
  preferences: null
}

const FinancialLevers = (state = initState, action): any => {
  switch (action.type) {
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      const newState: FinancialGoalsState = {
        ...state,
        fundedScores: mapFundedScores(
          action.payload.fundedScores,
          action.payload.planningSoftware
        ),
        preferences: mapPreferences(action.payload.preferences)
      }
      return newState
    case `${actions.FETCH_GOALS}_FULFILLED`:
      const goalsById = mapGoals(action.payload)
      return { ...state, goalsById }
    default:
      return state
  }
}

const mapFundedScore = (fundedScore, planningSoftware) => {
  return {
    id: fundedScore.id,
    rangeLow: fundedScore.target.low,
    rangeHigh: fundedScore.target.high,
    score: fundedScore.score,
    source: planningSoftware,
    scoreDate: fundedScore.scoreDate
      ? fundedScore.scoreDate.split('T')[0]
      : null,
    notes: fundedScore.notes,
    inGuidebook: fundedScore.inGuidebook
  }
}

const mapFundedScores = (fundedScores, planningSoftware) => {
  const result: FundedScoreObj[] = []
  fundedScores
    .sort((a: any, b: any) => a.createdDate < b.createdDate)
    .forEach((fundedScore: any) => {
      result.push(mapFundedScore(fundedScore, planningSoftware))
    })
  return result
}

const mapPreferences = (preferences): FinancialGoalPreferences => {
  if (preferences && preferences[0]) {
    return {
      goalsTimingGroupBy: preferences[0].goalsTimingGroupBy,
      goalsTimingSortBy: preferences[0].goalsTimingSortBy,
      goalsTimingSortingOrder: preferences[0].goalsTimingSortingOrder,
      goalsSavingsGroupBy: preferences[0].goalsSavingsGroupBy,
      goalsSavingsSortBy: preferences[0].goalsSavingsSortBy,
      goalsSavingsSortingOrder: preferences[0].goalsSavingsSortingOrder,
      goalsSpendingGroupBy: preferences[0].goalsSpendingGroupBy,
      goalsSpendingSortBy: preferences[0].goalsSpendingSortBy,
      goalsSpendingSortingOrder: preferences[0].goalsSpendingSortingOrder,
      goalsPortfoliosGroupBy: preferences[0].goalsPortfoliosGroupBy,
      goalsPortfoliosSortBy: preferences[0].goalsPortfoliosSortBy,
      goalsPortfoliosSortingOrder: preferences[0].goalsPortfoliosSortingOrder,
      goalsLegacyGroupBy: preferences[0].goalsLegacyGroupBy,
      goalsLegacySortBy: preferences[0].goalsLegacySortBy,
      goalsLegacySortingOrder: preferences[0].goalsLegacySortingOrder,
      goalsRetirementGroupBy: preferences[0].goalsRetirementGroupBy,
      goalsRetirementSortBy: preferences[0].goalsRetirementSortBy,
      goalsRetirementSortingOrder: preferences[0].goalsRetirementSortingOrder
    }
  } else return null
}

const formatGoalAmount = (goal) => {
  if (!isFinite(goal.amount)) {
    return null
  }

  if (!goal.amountType) {
    return goal.amount + '%'
  }

  if (goal.amountType.toLowerCase() === 'dollar') {
    return dollarFormat(goal.amount, 0)
  }

  if (goal.amountType.toLowerCase() === 'percent') {
    return goal.amount + '%'
  }

  return goal.amount
}

const mapFinancialGoal = (goal) => {
  if (goal.type && goal.includeInGuideBook && goal.type !== 'Portfolios') {
    return {
      id: goal.id,
      name: goal.name,
      type: goal.type,
      owner: goal.goalOwnerId,
      startDate: goal.startOfGoal,
      endDate: goal.endOfGoal,
      amount: formatGoalAmount(goal),
      amountType: goal.amountType,
      annualIncrease: goal.annualIncrease,
      needsWantsWishes: goal.needsWantsWishes,
      createdDate: goal.createdDate
    }
  } else return null
}

const mapGoals = (goals) => {
  const financialGoalObj = {}
  goals.forEach((goal) => {
    financialGoalObj[goal.id] = mapFinancialGoal(goal)
    if (!financialGoalObj[goal.id]) {
      delete financialGoalObj[goal.id]
    }
  })
  return financialGoalObj
}

export default FinancialLevers
