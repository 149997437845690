import React from 'react'
import { connect } from 'react-redux'
import { YodleeObj } from '../../objects/yodlee'
import { GlobalState } from '../../reducers'

const FL_CONTAINER_ID = 'container-fastlink'

interface StateProps {
  yodleeParams: YodleeObj
}

interface OwnProps {
  onClose: (data: any) => void
  customizedProps?: any
}

// would be better to use functional component with `useEffects` but react-redux version predates `useSelector`
class YodleeIframe4 extends React.Component<StateProps & OwnProps> {
  public componentDidMount() {
    this.openYodlee()
  }

  public componentWillUnmount() {
    this.closeYodlee()
  }

  public openYodlee = () => {
    const { yodleeParams, onClose, customizedProps } = this.props
    const { token, params, fastlinkUrl } = yodleeParams
    ;(window as any).fastlink.open(
      {
        fastLinkURL: fastlinkUrl,
        accessToken: `Bearer ${token}`,
        iframeScrolling: 'auto',
        containerClass: 'yodlee-fastlink',
        params: {
          configName: 'Aggregation',
          ...params,
          ...customizedProps
        },
        onSuccess() {
          // login successful
          // will be called on success. For list of possible message, refer to onSuccess(data) Method.
        },
        onError() {
          // account linking errors here. Filter on event types
          // will be called on error. For list of possible message, refer to onError(data) Method.
        },
        onClose,
        onEvent() {
          // will be called on intermittent status update.
        }
      },
      FL_CONTAINER_ID
    )
  }

  public closeYodlee = () => {
    ;(window as any).fastlink.close()
  }

  public componentDidUpdate(prevProps) {
    const { yodleeParams } = this.props
    if (yodleeParams !== prevProps.yodleeParams) {
      this.closeYodlee()
      this.openYodlee()
    }
  }

  public render() {
    return (
      <div className='networth-yodlee'>
        <div id={FL_CONTAINER_ID} className='networth-yodlee-container' />
      </div>
    )
  }
}

export default connect<StateProps, never, OwnProps, GlobalState>(
  (store: GlobalState, ownProps) => {
    return {
      yodleeParams: store.yodlee,
      ...ownProps
    }
  }
)(YodleeIframe4)
