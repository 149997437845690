import { useState, useEffect } from 'react'
import { debounce } from 'lodash-es'

interface IWindowSize {
  width: number
  height: number
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: window?.innerWidth || 0,
    height: window?.innerHeight || 0
  })

  useEffect(() => {
    const handleResize = debounce(() => {
      setWindowSize({
        width: window?.innerWidth,
        height: window?.innerHeight
      })
    }, 500) // 500ms delay

    window?.addEventListener('resize', handleResize)

    // cleanup
    return () => {
      handleResize.cancel()
      window?.removeEventListener('resize', handleResize)
    }
  }, [])

  return windowSize
}
