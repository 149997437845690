import Api from '../helpers/api'

export const FETCH_OFFICE_TEAM: string = 'FETCH_OFFICE_TEAM'
export const FETCH_OFFICE_TEAM_PENDING: string = 'FETCH_OFFICE_TEAM_PENDING'
export const FETCH_OFFICE_TEAM_FULFILLED: string = 'FETCH_OFFICE_TEAM_FULFILLED'
export const FETCH_OFFICE_TEAM_REJECTED: string = 'FETCH_OFFICE_TEAM_REJECTED'

export const getOfficeTeam = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_OFFICE_TEAM,
    payload: apiInstance.get('/officeteam').then((response) => {
      return response.data
    })
  }
}
