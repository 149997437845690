import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ManualLink from './ManualLink'
import Main from './Main'
import Fastlink from '../Fastlink'
import { closeAddModal } from '../../../helpers/netWorth'
import { GlobalState } from '../../../reducers'
import { getHousehold } from '../../../actions/household.action'
import { getFastlinkUrl, getYodleeParams } from '../../../actions/yodlee.action'

export enum Views {
  main = 'main',
  instant = 'fastlink',
  manual = 'manual'
}

type NetWorthAddAccountActions = typeof mapDispatchToProps
type NetWorthAddAccountStore = ReturnType<typeof mapStateToProps>
type NetWorthAddAccountProps = NetWorthAddAccountActions &
  NetWorthAddAccountStore

export const NetWorthAddAccount = (props: NetWorthAddAccountProps) => {
  const [view, setView] = useState(Views.main)
  const { user, getHousehold, getFastlinkUrl, getYodleeParams } = props
  const householdFetched = user?.householdFetched
  const hasYodlee = user?.aggregationProvider === 'yodlee'
  const disableManualAccount = user?.disableManualAccount
  const goToMain = () => setView(Views.main)

  // ensure aggregationProvider and disableManualAccount settings
  // have been fetched for user. since this is its own route,
  // a user could land on this page directly without that info.
  // we may need to redirect back to /net-worth if both are disabled
  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [householdFetched])

  // configure yodlee if it's enabled for user
  useEffect(() => {
    if (hasYodlee) {
      getFastlinkUrl()
      getYodleeParams()
    }
  }, [hasYodlee])

  // the user landed directly on this page, but is not allowed
  // to add accounts
  if (householdFetched && !hasYodlee && disableManualAccount) {
    closeAddModal()
    return null
  }

  if (view === Views.main) {
    return (
      <Main
        setView={setView}
        hasYodlee={hasYodlee}
        disableManualAccount={disableManualAccount}
        householdFetched={householdFetched}
      />
    )
  }

  return (
    <div className='px-4 px-sm-0'>
      <div style={{ maxWidth: '538px', margin: '0 auto' }}>
        {view === Views.instant && <Fastlink onClose={closeAddModal} />}
        {view === Views.manual && (
          <ManualLink onBack={goToMain} onClose={closeAddModal} />
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return { user: store.user }
}

const mapDispatchToProps = {
  getHousehold,
  getFastlinkUrl,
  getYodleeParams
}

export default connect(mapStateToProps, mapDispatchToProps)(NetWorthAddAccount)
