import * as React from 'react'
import FilterIcon from '../../assets/images/icons/title-container-filter@2x.png'

interface TitleContainerProps {
  title?: string
  icon?: string
  hasFilter?: boolean
  onFilterClick?(): void
}

export const TitleContainer: React.StatelessComponent<TitleContainerProps> = ({
  title,
  icon,
  children,
  hasFilter,
  onFilterClick
}) => {
  return (
    <div className='legacy-title-container'>
      {icon ? (
        <img className='legacy-title-container__icon' src={icon} alt='icon' />
      ) : null}
      <span className='legacy-title-container__text'>{title}</span>
      {hasFilter && (
        <div
          onClick={onFilterClick}
          className='legacy-title-container__icon-filter'>
          <img src={FilterIcon} width={24} alt='filter icon' />
        </div>
      )}
      {children}
    </div>
  )
}
