import React from 'react'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import FormDropdown from '../../components/FormDropdown/FormDropdown'
import { GlobalState } from '../../reducers'
import { connect } from 'react-redux'
import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface MoveModalProps {
  id: string
  oldFolderId: string
  moveModal(id: string, newFolderId: string): void
  closeModal(id: string): void
  folders: ReturnType<typeof mapStateToProps>['folders'] // from global store, used to list all folders in dropdown
}

interface MoveModalState {
  newFolderId: string
  disableButton: boolean
}

class MoveModal extends React.Component<MoveModalProps, MoveModalState> {
  constructor(props: any) {
    super(props)
    this.state = {
      newFolderId: props.newFolderId,
      disableButton: true
    }
  }

  public moveFn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const { id, moveModal } = this.props
    const { newFolderId } = this.state
    moveModal(id, newFolderId)
  }

  public closeFn = () => {
    this.props.closeModal('')
  }

  public moveFile = (label, value) => {
    this.setState({
      newFolderId: value,
      disableButton: false
    })
  }

  public getListOfFolders() {
    const { oldFolderId, folders } = this.props

    return Object.values(folders)
      .filter((folder) => folder.id !== oldFolderId && folder.archived !== true)
      .sort((a, b) => (a.name > b.name ? 1 : -1))
      .map((option) => ({
        label: option.name,
        value: option.id
      }))
  }

  public render() {
    const { disableButton } = this.state
    return (
      <>
        <h1 className='vault-modal__title'>{'Move File'}</h1>
        <Form onSubmit={this.moveFn}>
          <FormDropdown
            label='Folder Name'
            placeholder='Choose folder'
            options={this.getListOfFolders()}
            validated={false}
            onSelect={this.moveFile}
          />
          <div className='vault-modal__buttons'>
            <Button
              id='move-modal-save'
              variant='primary'
              size='lg'
              disabled={disableButton}
              className='match-btn-width'
              type='submit'>
              Move
            </Button>
            <Button
              id='move-modal-cancel'
              variant='link'
              onClick={this.closeFn}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    folders: store.documentVault.folders
  }
}

export default connect(mapStateToProps)(ControlledModalWrapper(MoveModal))
