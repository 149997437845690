export enum ToastType {
  Success = 'success',
  Error = 'error'
}

export enum ToastIcon {
  Success = 'check-lg',
  Error = 'exclamation-circle'
}

export interface ActionBtnObj {
  text: string
  onClick: Function
}

export interface ToastObj {
  id?: string
  icon?: ToastIcon | any
  message: string
  backgroundColor?: string
  color?: string
  type?: ToastType
  actionBtn?: ActionBtnObj
}
