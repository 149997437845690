import * as qs from 'query-string'
import * as React from 'react'
import { connect } from 'react-redux'
import * as AuthActions from '../../actions/auth.action'
import * as userActions from '../../actions/user.action'
import Api from '../../helpers/api'
import { GlobalState } from '../../reducers'
import { AuthState } from '../../reducers/auth.reducer'
import { getLoginURL, logout } from '../../helpers'
import { history, AppDispatch } from '../../store'

interface AuthProps {
  dispatch?: AppDispatch
  auth: AuthState
}

const OAUTH_ERROR = 'OAUTH_APP_ACCESS_DENIED'

class AuthPage extends React.Component<AuthProps> {
  public async componentDidMount() {
    const { dispatch, auth } = this.props
    if (!auth.accessToken) {
      const grantToken = qs.parse(window.location.search).code as string
      const oauthError = qs.parse(window.location.search).error
      if (oauthError === OAUTH_ERROR) {
        await dispatch(userActions.revokeUser())
        logout()
      } else if (!grantToken) {
        window.location.href = getLoginURL()
      } else {
        await dispatch(AuthActions.getAuthenticationTokens(grantToken))
        await dispatch(userActions.getTOSandPrivacyPolicy())
      }
    } else {
      if (auth.userContactId) {
        // user has pre existing auth token, bypass TOS
        this.props.dispatch(userActions.bypassTermsOfService())
        // and generate API instance with contact passthrough
        await Api.createInstance(auth.accessToken, auth.userContactId)
        await dispatch(userActions.getInstitution())
        this.navigate()
      }
    }
  }

  public async componentDidUpdate(preProps: AuthProps) {
    if (preProps.auth.accessToken !== this.props.auth.accessToken) {
      await Api.createInstance(this.props.auth.accessToken)
      await this.props.dispatch(userActions.getInstitution())
      this.navigate()
    }
  }
  public navigate = () => {
    if (this.props.auth.loadingStatus.isLoaded) {
      let from = localStorage.getItem('redirect')
      localStorage.removeItem('redirect')
      if (!from) {
        from = '/'
      }
      history.push(from)
    }
  }
  public render() {
    return <div />
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    auth: store.auth
  }
}

export default connect(mapStateToProps)(AuthPage)
