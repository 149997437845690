import React from 'react'
import { Row, Card } from 'react-bootstrap'
import LineGraph from './FundedScoreGraph/LineGraph'
import { FundedScoreObj } from '../../objects/plan'
export interface ScoreHistoryTileProps {
  fundedScores: FundedScoreObj[]
}

const ScoreHistoryTile = (props: ScoreHistoryTileProps) => {
  const { fundedScores } = props
  const latestsScoreNote =
    fundedScores?.length >= 1
      ? fundedScores[fundedScores.length - 1]?.notes
      : null

  return (
    <div>
      {fundedScores?.length > 1 && (
        <Card
          id='score-history'
          className={`border-0 rounded-0 bg-white ${!latestsScoreNote &&
            'card-margin'} p-x2`}>
          <Row className='d-flex direction-column funded-score__container'>
            <h2 className='funded-score__container-title'>
              Funded Score History
            </h2>
            <LineGraph />
          </Row>
        </Card>
      )}
      {latestsScoreNote && (
        <Card
          id='score-history-notes'
          className='border-0 rounded-0 bg-white card-margin funded-score__note-card'>
          <h2 className='score-history-tile__notes-title'>Advisor's Note</h2>
          <div className='score-history-tile__notes-subtitle'>
            {latestsScoreNote}
          </div>
        </Card>
      )}
    </div>
  )
}

export default ScoreHistoryTile
