import { isEmpty } from 'lodash-es'
import * as actions from '../actions/user.action'
import { UserObj, Preferences } from '../objects/user'
import * as contactActions from '../actions/contacts.action'
import * as householdActions from '../actions/household.action'
import { REVOKE_ACCESS_TOKEN_FULFILLED } from '../actions/auth.action'

export const initState: UserObj = {
  id: null,
  version: null,
  householdId: null,
  firstName: null,
  lastName: null,
  photoUrl: null,
  fileName: null,
  title: null,
  email: null,
  phone: {},
  institutionOfficePhone: null,
  photoUploadSuccess: false,
  photoUploadPending: false,
  photoUploadError: false,
  communityId: null,
  netWorthDisclosure: null,
  emailDisclosure: null,
  termsOfServiceLoading: true,
  termsOfServiceAccepted: false,
  disableTasks: false,
  institutionLogo: null,
  showBlackDiamondVault: false,
  disableYodlee: false,
  disableDocumentVault: false,
  isFinlifeInstitution: false,
  isAdvisorImpersonation: false,
  showGIR: false,
  preferencesLoading: true,
  preferencesError: false,
  preferences: null,
  planningSoftware: null,
  aggregationProvider: null,
  disableManualAccount: false,
  showHonestConversations: false,
  showInvestments: false,
  showEmoneyClientPortal: true,
  householdFetched: false,
  enableCRMTasks: false
}

export const defaultPreferences: Preferences = {
  showUpliftModal: true
}

const User = (state = initState, action): UserObj => {
  switch (action.type) {
    case actions.FETCH_USER_FULFILLED:
      const { firstName, lastName, id, version } = action.payload
      return { ...state, firstName, lastName, id, version }
    case REVOKE_ACCESS_TOKEN_FULFILLED:
      return initState
    case actions.FETCH_INSTITUTION_FULFILLED:
      return {
        ...state,
        netWorthDisclosure: action.payload.networthDisclaimer,
        emailDisclosure: action.payload.emailDisclaimer,
        institutionLogo: action.payload.photoUrl,
        institutionOfficePhone: action.payload.officePhone,
        disableDocumentVault: action.payload.disableDocumentVault,
        disableYodlee: action.payload.disableYodlee,
        showBlackDiamondVault: action.payload.blackDiamondVault,
        isFinlifeInstitution: action.payload.isFinlifeInstitution,
        disableTasks: action.payload.dataTypesSupported.indexOf('tasks') === -1,
        showGIR: action.payload.showGIR,
        enableCRMTasks: action.payload.enableCRMTasks
      }
    case `${actions.FETCH_TOS_AND_PRIVACY_POLICY}_PENDING`:
      return {
        ...state,
        termsOfServiceLoading: true
      }
    case `${actions.FETCH_TOS_AND_PRIVACY_POLICY}_FULFILLED`:
      const termsOfServiceAccepted = action.payload.termsOfServiceAccepted
        ? action.payload.termsOfServiceAccepted
        : false
      return {
        ...state,
        termsOfServiceAccepted,
        termsOfServiceLoading: false
      }

    case actions.ACCEPT_TOS_AND_PRIVACY_POLICY_FULFILLED:
      return { ...state, termsOfServiceAccepted: true }
    case actions.BYPASS_TOS_AND_PRIVACY_POLICY:
      return { ...state, termsOfServiceAccepted: true }
    case actions.NEW_PHOTO_PENDING:
      return {
        ...state,
        photoUploadError: false,
        photoUploadPending: true,
        photoUploadSuccess: false
      }
    case actions.NEW_PHOTO_FULFILLED:
      return {
        ...state,
        photoUrl: action.payload.data.photoUrl,
        photoUploadError: false,
        photoUploadPending: false,
        photoUploadSuccess: true
      }
    case actions.DELETE_PHOTO_FULFILLED:
      return {
        ...state,
        photoUrl: null,
        photoUploadError: false,
        photoUploadPending: false,
        photoUploadSuccess: true
      }
    case actions.NEW_PHOTO_REJECTED:
      return {
        ...state,
        photoUploadError: true,
        photoUploadPending: false,
        photoUploadSuccess: false
      }
    case actions.STORE_COMMUNITY_ID:
      return { ...state, communityId: action.payload }
    case contactActions.FETCH_CONTACTS_FULFILLED:
      if (action.payload.primary && action.payload.primary.id === state.id) {
        return {
          ...state,
          photoUrl: action.payload.primary.photoUrl,
          fileName: action.payload.primary.photoFilename,
          householdId: action.payload.primary.household
        }
      } else if (
        action.payload.secondary &&
        action.payload.secondary.id === state.id
      ) {
        return {
          ...state,
          photoUrl: action.payload.secondary.photoUrl,
          fileName: action.payload.secondary.photoFilename,
          householdId: action.payload.secondary.household
        }
      } else return { ...state, householdId: action.payload.primary.household }
    case actions.SET_ADVISOR_IMPERSONATION:
      return { ...state, isAdvisorImpersonation: true }
    case actions.FETCH_PREFERENCES_PENDING:
      return { ...state, preferencesLoading: true, preferencesError: false }
    case actions.FETCH_PREFERENCES_REJECTED:
      return { ...state, preferencesError: true, preferencesLoading: false }
    case actions.FETCH_PREFERENCES_FULFILLED:
      // preferences may not exist yet for this user (i.e., not in gcstore table)
      // so load default preferences here if none have been set yet
      const preferences = isEmpty(action.payload)
        ? defaultPreferences
        : action.payload
      return {
        ...state,
        preferencesLoading: false,
        preferencesError: false,
        preferences: {
          ...state.preferences,
          ...preferences
        }
      }
    case actions.SET_PREFERENCES_FULFILLED:
      return {
        ...state,
        preferencesLoading: false,
        preferencesError: false,
        preferences: {
          ...state.preferences,
          ...action.payload
        }
      }
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      return {
        ...state,
        householdFetched: true,
        planningSoftware: action.payload?.planningSoftware,
        aggregationProvider: action.payload?.aggregationProvider,
        disableManualAccount: action.payload?.disableManualAccount,
        showHonestConversations: action.payload?.showHonestConversations,
        showInvestments: action.payload?.showInvestments,
        showEmoneyClientPortal: action.payload?.showEmoneyClientPortal
      }
    default:
      return state
  }
}

export default User
