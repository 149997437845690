import Api from '../helpers/api'
import { AppDispatch, AppThunkAction } from '../store'
import store from '../store'
export const SYNC_TASKS = 'SYNC_TASKS'
export const GET_ALL_TASKS = 'GET_ALL_TASKS'
export const SET_TASKS_V2 = 'SET_TASKS_V2'
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS'
export const UPDATE_TASK_STATUS_V2 = 'UPDATE_TASK_STATUS_V2'
//
export const SORT_TASKS_BY = 'SORT_TASKS_BY'
export const FILTER_TASKS = 'FILTER_TASKS'
export const RESET_SORT_FILTER = 'RESET_SORT_FILTER'
export const SORT_TASKS_BY_V2 = 'SORT_TASKS_BY_V2'
export const FILTER_TASKS_V2 = 'FILTER_TASKS_V2'
export const RESET_SORT_FILTER_V2 = 'RESET_SORT_FILTER_V2'

const setSortMethod = (method) => {
  return {
    type: `${SORT_TASKS_BY}`,
    payload: { sortBy: method }
  }
}

export const filterTasks = (filter: boolean) => {
  return {
    type: FILTER_TASKS,
    payload: filter
  }
}

export const resetSortFilter = () => {
  return {
    type: RESET_SORT_FILTER
  }
}

export const getTasksV2 = (
  payload
): {
  type: string
  payload: any
} => {
  return {
    type: SET_TASKS_V2,
    payload
  }
}

export const getTasks = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()

  return {
    type: GET_ALL_TASKS,
    payload: apiInstance.get('/tasks').then((response) => response.data)
  }
}

export const syncTasks = (): { type: string; payload: any } => {
  const apiInstance = Api.getInstance()
  return {
    type: SYNC_TASKS,
    payload: apiInstance.post(`/tasks/sync`).then((response) => response)
  }
}

export const updateTaskStatus = (
  taskId: string,
  status: string,
  completedDate?: string
) => {
  const apiInstance = Api.getInstance()
  const user = store.getState().user
  return {
    type:
      window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true' &&
      user?.isFinlifeInstitution === false &&
      user?.enableCRMTasks === true
        ? UPDATE_TASK_STATUS_V2
        : UPDATE_TASK_STATUS,
    payload: apiInstance
      .put(`/tasks/${taskId}`, { status, completedDate })
      .then((response) => response.data)
  }
}

export const sortTasksBy = (method: string): AppThunkAction => (
  dispatch: AppDispatch
) => {
  dispatch(setSortMethod(method))
}
