import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getGoalRequests } from '../../actions/goalRequests.action'
import PlanHero from './PlanHero'
import ScoreHistoryTile from './ScoreHistoryTile'
import PlanNull from './PlanNull'
import PlanUpdate from './PlanUpdate'
import PlanInputs from './PlanInputs'
import PartnerLoginTile from './PartnerLoginTile'
import PendingUpdateTable from './PendingUpdates/PendingUpdateTable'
import { GlobalState } from '../../reducers'
import { getGoals } from '../../actions/goals.action'
import { getHousehold } from '../../actions/household.action'
import { isObjEmpty } from '../../helpers'
import { handlePlanEdit } from '../../helpers/plan'
import {
  getPlanInputs,
  hasActiveGoals,
  filteredFundedScores
} from '../../selectors/plan'
import { emoneyPlannings } from '../../helpers/dashboard'
import { emoneyClickThru } from '../../analytics/googleTagManager'

type FinancialPlanStore = ReturnType<typeof mapStateToProps>
type FinancialPlanActions = typeof mapDispatchToProps
export type FinancialPlanProps = FinancialPlanStore & FinancialPlanActions

export const Plan = (props: FinancialPlanProps) => {
  const {
    householdFetched,
    user,
    locationPath,
    filteredFundedScores,
    goalsByType,
    requests,
    activeGoals,
    getHousehold,
    getGoals,
    getGoalRequests
  } = props

  const refreshDeleteData = () => {
    getGoalRequests()
  }

  const loginToEmoney = () => {
    emoneyClickThru(user.planningSoftware, user.id)
    const eMoneyLoginUrl = window._env_.REACT_APP_LOGIN_TO_EMONEY_URL
    window.open(eMoneyLoginUrl, '_blank')
  }

  const numScores = filteredFundedScores?.length || 0
  const latestScore = numScores ? filteredFundedScores[numScores - 1] : null
  const hasNoteOrChart = numScores > 1 || latestScore?.notes

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
    getGoals()
    getGoalRequests()
  }, [])

  return (
    <div>
      <PlanHero latestScore={latestScore} user={user} />
      {!activeGoals && isObjEmpty(requests) ? (
        <PlanNull />
      ) : (
        <div>
          <ScoreHistoryTile fundedScores={filteredFundedScores} />
          {user?.planningSoftware &&
            emoneyPlannings.includes(user.planningSoftware) && (
              <div
                className={`plan-emoney-login${
                  hasNoteOrChart ? '' : '--no-chart'
                }`}>
                <Button
                  size='lg'
                  variant='outline-primary'
                  className='match-btn-width font-color--dark'
                  onClick={loginToEmoney}>
                  Login to eMoney
                </Button>
              </div>
            )}
          <h3 className='plan-partners-login__title card-margin--input-title-mobile'>
            Plan Inputs
          </h3>
          <div>
            <PlanUpdate />
            <div>
              <PlanInputs
                goalsByType={goalsByType}
                isMgp={user?.planningSoftware === 'moneyguidepro'}
                onEditGoal={handlePlanEdit}
                onDeleteGoal={refreshDeleteData}
              />
              <PendingUpdateTable
                requests={requests}
                onEditRequest={handlePlanEdit}
              />
              <PartnerLoginTile />
            </div>
          </div>
        </div>
      )}
      <div className='plan-footer__link-container'>
        <Link
          to={{
            pathname: '/disclosures',
            state: {
              section: 'planningSoftware',
              prevPath: locationPath
            }
          }}
          className='plan-footer__link'>
          Click for more information about this page
        </Link>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  getHousehold,
  getGoals,
  getGoalRequests
}

const mapStateToProps = (store: GlobalState) => {
  const { goalsByType } = getPlanInputs(store)
  const activeGoals = hasActiveGoals(store)

  return {
    filteredFundedScores: filteredFundedScores(store),
    locationPath: store.router.location?.pathname,
    householdFetched: store.user.householdFetched,
    user: store.user,
    requests: store.goalRequests ? store.goalRequests.requests : {},
    activeGoals,
    goalsByType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan)
