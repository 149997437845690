import {
  DocVaultState,
  FileObj,
  FolderObj,
  FolderState
} from '../objects/documentVault'
import * as actions from '../actions/documentVault.action'

export interface DocumentVaultState {
  folders: {
    [key: string]: FolderState
  }
  newFolders: { [key: string]: boolean }
}

const initState: DocVaultState = {
  search: '',
  folders: {},
  newFolders: {}
}

export const mapFile = (file: FileObj): FileObj => {
  return {
    id: file.id,
    name: file.name,
    lastUpdated: file.lastUpdated,
    size: file.size,
    createdDate: file.createdDate,
    archived: file.archived || false,
    folderId: file.folder
  }
}

const mapFiles = (files: FileObj[]): FileObj[] => {
  return files.map((file) => {
    return mapFile(file)
  })
}

const mapFolder = (folder: any): FolderObj => {
  return {
    id: folder.id,
    parentId: folder.household,
    name: folder.name,
    files: folder.files ? mapFiles(folder.files) : [],
    readOnly: folder.readOnly,
    archived: folder.archived
  }
}

const mapFolders = (folders: any[]) => {
  const folderObjs = {}
  folders.forEach((folder) => {
    folderObjs[folder.id] = mapFolder(folder)
  })
  return folderObjs
}

const DocumentVault = (state = initState, action: any): DocVaultState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case actions.FETCH_FOLDERS_FULFILLED:
      const folders = mapFolders(action.payload.data)
      return { ...state, folders: { ...folders } }
    case actions.FETCH_FOLDER_FULFILLED:
      newState.folders[action.payload.data.id] = mapFolder(action.payload.data)
      return newState
    case actions.CREATE_FOLDER_FULFILLED:
      newState.folders[action.payload.data.id] = mapFolder(action.payload.data)
      return newState
    case actions.EDIT_FOLDER_FULFILLED:
      newState.folders[action.payload.data.id].name = action.payload.data.name
      return newState
    case actions.DELETE_FOLDER_FULFILLED:
      return newState
    case actions.HARD_DELETE_FILE_FULFILLED:
    case actions.EMPTY_TRASH_FULFILLED:
      return newState
    case actions.UPDATE_ARCHIVED_FILE_FULFILLED:
      const newFiles: FileObj[] = []
      const files = newState.folders[action.payload.data.folderId]?.files
      files &&
        files.forEach((file: FileObj) => {
          if (action.payload.data.fileId !== file.id) {
            newFiles.push(file)
          }
        })
      newState.folders[action.payload.data.folderId].files = newFiles
      return newState

    case actions.ADD_NEW_FOLDER:
      newState.newFolders[action.payload] = true
      return newState
    case actions.CLEAR_NEW_FOLDERS:
      newState.newFolders = {}
      return newState
    case actions.MOVE_FILE_FULFILLED: {
      const { data, oldFolderId } = action.payload
      newState.folders[oldFolderId].files = newState.folders[
        oldFolderId
      ].files.filter((file) => file.id !== data.id)
      newState.folders[data.folder].files.push(mapFile(data))
      return newState
    }
    case actions.EDIT_FILE_FULFILLED: {
      const { data, folderId } = action.payload
      newState.folders[folderId].files = newState.folders[
        folderId
      ].files.filter((file) => file.id !== data.id)
      newState.folders[data.folder].files.push(mapFile(data))
      return newState
    }
    case actions.UPDATE_VAULT_SEARCH:
      newState.search = action.payload.value
      return newState

    default:
      return state
  }
}

export default DocumentVault
