import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  ForwardedRef
} from 'react'
import { Form } from 'react-bootstrap'

import { dateValidator } from '../../utils/validators'

export interface IDateInputProps {
  label: string
  name: string
  placeholder: string
  defaultValue: string
  validated: any
}

const ErrorDisplay = (props) => {
  const { error } = props
  return (
    <div className='mb-3 mt-0'>
      <i className='bi bi-exclamation-circle text-warning fs-5 me-2' />
      {error}
    </div>
  )
}

const DateInput = forwardRef(
  (
    {
      label,
      name,
      placeholder = 'MM/DD/YYYY',
      defaultValue = '',
      validated
    }: IDateInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [value, setValue] = useState(defaultValue)
    const [isValid, setIsValid] = useState(true)
    // ref hook to reference the input element directly for cursor position and adjustments
    const inputRef = useRef<HTMLInputElement>(null)

    // sync the forwarded ref with the local inputRef
    useEffect(() => {
      if (typeof ref === 'function') {
        ref(inputRef.current)
      } else if (ref) {
        ref.current = inputRef.current
      }
    }, [ref])

    // update the input value when defaultValue changes
    useEffect(() => {
      setValue(defaultValue || '')
    }, [defaultValue])

    const handleChange = (event) => {
      // get current cursor position and input value
      const { selectionStart, value: rawValue, selectionEnd } = event.target
      let cursorPosition = selectionStart // current cursor position before formatting
      const previousValue = value.replace(/\//g, '') // remove slashes from previous value for comparison
      const newValue = rawValue.replace(/\D/g, '') // keep only digits

      // determine if the action was a backspace based on length and cursor position
      const isBackspace =
        newValue.length < previousValue.length &&
        selectionEnd === selectionStart

      // split digits and join with slash
      const parts = [
        newValue.slice(0, 2), // MM
        newValue.slice(2, 4), // DD
        newValue.slice(4) // YYYY
      ]

      // join the parts with a slash
      const formattedValue = parts.filter(Boolean).join('/')

      // adjust cursor position based on the addition or removal of slashes
      if (!isBackspace) {
        const slashCount = (formattedValue.match(/\//g) || []).length
        cursorPosition +=
          slashCount -
          (value.slice(0, cursorPosition).match(/\//g) || []).length
      } else if (rawValue[cursorPosition - 1] === '/') {
        // adjust cursor position backward if directly before a slash during a backspace action
        cursorPosition--
      }
      setValue(formattedValue)

      // setTimeout to ensure cursor position is updated after the state and DOM is updated
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
        }
      }, 0)
    }

    const handleBlur = (event) => {
      const { value: rawValue } = event.target
      const newValue = rawValue.replace(/\D/g, '') // keep only digits
      // split digits and join with slash
      const parts = [
        newValue.slice(0, 2), // MM
        newValue.slice(2, 4), // DD
        newValue.slice(4) // YYYY
      ]
      // join the parts with a slash
      const formattedValue = parts.filter(Boolean).join('/')
      // validation
      if (dateValidator('MM/DD/YYYY').isValidSync(formattedValue)) {
        //  if valid remove error msg
        inputRef.current?.setCustomValidity('')
        setIsValid(true)
      } else {
        inputRef.current?.setCustomValidity('Must be valid date')
        setIsValid(false)
      }
    }

    return (
      <Form.Group controlId={name} className='mb-3'>
        <Form.Label>{label}</Form.Label>
        <Form.Control
          type='text'
          name={name}
          ref={inputRef}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={placeholder}
          maxLength={10}
          required={true}
          isInvalid={value && !isValid}
          className='mb-1'
        />

        <Form.Control.Feedback
          type='invalid'
          className='text-dark fw-bold fs-7'>
          {<ErrorDisplay error='Must be a valid date in format MM/DD/YYYY' />}
        </Form.Control.Feedback>
      </Form.Group>
    )
  }
)

export default DateInput
