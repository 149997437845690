import React, { useEffect, useCallback, useState } from 'react'

import {
  getMoneyMindDescription,
  getResponses,
  getToken
} from '../../actions/moneymind.action'
import { getContacts } from '../../actions/contacts.action'
import { GlobalState } from '../../reducers'
import { connect } from 'react-redux'

import MoneyMindDescription from './Description'
import MoneyMindHero from './Hero'
import { PartnerCard } from './PartnerCard'

import { persistentSort } from '../../helpers/moneyMind'
import {
  getSelfMoneyMind,
  getPartnerMoneyMind
} from '../../selectors/moneymind'
import NullState from './NullState'
import { ymmQuizURL, ymmQuizIsNewVersion } from '../../helpers/ymmQuizURL'

type MoneyMindActions = typeof mapDispatchToProps
type MoneyMindStore = ReturnType<typeof mapStateToProps>
export type MoneyMindProps = MoneyMindActions & MoneyMindStore

export const MoneyMind = (props: MoneyMindProps) => {
  const [displayPrimary, setUser] = useState(true)
  const [moneyMindRequests, setMoneyMindRequests] = useState(false)
  const {
    user,
    contacts,
    selfMoneyMind,
    partnerMoneyMind,
    getResponses,
    getContacts,
    getMoneyMindDescription
  } = props

  const updateDescriptions = (type, moneymind) => {
    const { fear, commitment, happiness } = moneymind || {
      fear: null,
      commitment: null,
      happiness: null
    }
    const sortedMoneyMind = persistentSort([
      ['fear', parseInt(fear, 10)] as any,
      ['commitment', parseInt(commitment, 10)] as any,
      ['happiness', parseInt(happiness, 10)] as any
    ])

    const primary = sortedMoneyMind[0][0]
    const secondary = sortedMoneyMind[1][0]
    const tertiary = sortedMoneyMind[2][0]

    // Blended Money Mind
    if (
      sortedMoneyMind[0][1] > 0 &&
      sortedMoneyMind[0][1] === sortedMoneyMind[1][1]
    ) {
      getMoneyMindDescription(type, 'first', primary, secondary)
      // If the third influence has some value, also grab that data
      if (sortedMoneyMind[2][1] > 0) {
        getMoneyMindDescription(type, 'second', tertiary, null)
      }
      // If all these values are over zero
    } else if (sortedMoneyMind[0][1] > 0) {
      getMoneyMindDescription(type, 'first', primary, null)
      if (sortedMoneyMind[1][1] > 0) {
        getMoneyMindDescription(type, 'second', secondary, null)
      }
      if (sortedMoneyMind[2][1] > 0) {
        getMoneyMindDescription(type, 'third', tertiary, null)
      }
    }
  }

  const fetchContactsResponses = useCallback(async () => {
    await getContacts()
    await getResponses()
    setMoneyMindRequests(true)
    // contacts and responses ready
  }, [selfMoneyMind.moneymind])

  const onClickTakeQuiz = async () => {
    let link = null
    if (ymmQuizIsNewVersion()) {
      const actionRes = await getToken()
      link = (await (await actionRes.payload).valueOf()['url']) || ymmQuizURL()
    } else {
      link = ymmQuizURL(contacts.primary.id)
    }
    window.open(link)
  }

  useEffect(() => {
    fetchContactsResponses()
  }, [])

  useEffect(() => {
    if (
      selfMoneyMind?.moneymind &&
      Object.keys(selfMoneyMind.moneymind).reduce(
        (accum, mm) => accum + parseInt(selfMoneyMind.moneymind[mm], 0),
        0
      ) === 100
    ) {
      // get the current user type
      const currentUserType = Object.keys(contacts)
        .filter((contactType: string) => {
          return contacts[contactType]?.id === user.id
        })
        .join()

      updateDescriptions(currentUserType, selfMoneyMind.moneymind)
      // do partner descriptions
      if (Object.keys(contacts).length > 1) {
        updateDescriptions(
          currentUserType === 'secondary' ? 'primary' : 'secondary',
          partnerMoneyMind.moneymind
        )
      }
    }
  }, [selfMoneyMind.moneymind])

  const nullMM = selfMoneyMind?.moneymind
    ? Object.values(selfMoneyMind.moneymind).every((value) => value === null)
    : true

  const updateUserView = () => {
    window.scrollTo(0, 0)
    setUser(!displayPrimary)
  }

  const currUserMoneyMind = displayPrimary
    ? selfMoneyMind.moneymind
    : partnerMoneyMind.moneymind
  const currUserDesc = displayPrimary
    ? selfMoneyMind.moneymindDescription
    : partnerMoneyMind.moneymindDescription
  return (
    <div>
      {moneyMindRequests && nullMM && (
        <NullState buttonLink={onClickTakeQuiz} />
      )}
      {moneyMindRequests && !nullMM && (
        <div>
          <MoneyMindHero
            moneymind={currUserMoneyMind}
            displayPrimary={displayPrimary}
            updateUserView={updateUserView}
            contactName={partnerMoneyMind?.contactName}
            buttonLink={onClickTakeQuiz}
          />
          <div className='mm-page'>
            {currUserMoneyMind && currUserDesc && (
              <MoneyMindDescription
                moneymind={currUserMoneyMind}
                moneymindDescription={currUserDesc}
              />
            )}
            {partnerMoneyMind?.moneymind && displayPrimary ? (
              <PartnerCard
                contactName={partnerMoneyMind?.contactName}
                updateUserView={updateUserView}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getMoneyMindDescription,
  getResponses,
  getToken,
  getContacts
}

const mapStateToProps = (store: GlobalState) => {
  const { user, contacts } = store
  return {
    user,
    contacts,
    selfMoneyMind: getSelfMoneyMind(store),
    partnerMoneyMind: getPartnerMoneyMind(store)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyMind)
