import React, { useState } from 'react'
import { map, isEmpty } from 'lodash-es'
import PriorityCards from '../PersonalPriorities/CardCarousel'
import StatusTable from './StatusTable'
import { ClientCardObj } from '../../../objects/honestConversation'

export type PrioritiesProps = {
  title: string
  rankedTopCards: { [key: string]: ClientCardObj }
  rankedAnyCards: boolean
  emailSent: string
  currentUser?: boolean
  redirect?: string
  accessToken?: string
}

const Priorities = ({
  title = '',
  rankedTopCards,
  rankedAnyCards = false,
  currentUser = false,
  emailSent = '',
  accessToken,
  redirect
}: PrioritiesProps) => {
  const hasTopCards = !isEmpty(rankedTopCards)
  const showRankedCards = currentUser && hasTopCards
  const showStatus = !showRankedCards || !currentUser
  const showButton = currentUser && !hasTopCards && Boolean(emailSent)
  const [active, setActive] = useState(Object.keys(rankedTopCards)[0])

  return (
    <div className='hc-null-priorities'>
      <h3 className='hc-null-priorities__title'>{title}</h3>
      <div
        id={`priorities-card-${currentUser ? 'current' : 'other'}`}
        className='bg-white p-3 p-md-x2'>
        <div className='hc-null-priorities__title--mobile'>{title}</div>
        {showRankedCards && (
          <>
            <div className='honestConversations__container'>
              <PriorityCards
                cards={map(rankedTopCards, (card) => card)}
                selectCard={active}
                setSelectedCard={setActive}
                isPersonal={true}
              />
            </div>
            <div className='hc-null-priorities__comment'>
              <div className='hc-null-priorities__comment-title'>
                Why did you choose this card?
              </div>
              <div className='hc-null-priorities__comment-text'>
                {rankedTopCards[active]?.comment ||
                  'There is no comment for this card.'}
              </div>
            </div>
          </>
        )}
        {showStatus && (
          <StatusTable
            emailSent={emailSent}
            inProgress={rankedAnyCards}
            completed={hasTopCards}
            showButton={showButton}
            redirect={redirect}
            accessToken={accessToken}
          />
        )}
      </div>
    </div>
  )
}

export default Priorities
