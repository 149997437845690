import React from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'

import * as hcExerciseActions from '../../../actions/honestConversations.action'
import * as HouseholdActions from '../../../actions/household.action'
import { getContacts } from '../../../actions/contacts.action'
import { getGoalActions } from '../../../actions/goalActions.action'
import { ContactsInterface } from '../../../objects/contact'
import { UserObj } from '../../../objects/user'
import { GoalActionObj } from '../../../objects/goals'
import { HonestConversationsExerciseObj } from '../../../objects/honestConversation'
import ScoreForm from './ScoreForm'
import { AppDispatch } from '../../../store'

interface UpdateScoresProps {
  hcExerciseId: string
  dispatch?: AppDispatch
  honestConversation: HonestConversationsExerciseObj
  contacts: ContactsInterface
  user: UserObj
  goalActions: GoalActionObj[]
}

interface UpdateScoresState {
  previousActiveScoringSessionId: string
}

class UpdateScores extends React.Component<
  UpdateScoresProps,
  UpdateScoresState
> {
  constructor(props: UpdateScoresProps) {
    super(props)
    this.state = {
      previousActiveScoringSessionId: null
    }
  }

  public async componentDidMount() {
    const { dispatch, honestConversation, contacts, goalActions } = this.props
    !contacts && dispatch(getContacts())
    if (!honestConversation) {
      dispatch(HouseholdActions.getHousehold())
      dispatch(hcExerciseActions.getHonestConversation())
    }
    if (!goalActions) {
      dispatch(getGoalActions())
    }
    await this.createScoringSession()
    await this.updateActiveScoreSessionId()
  }

  public createScoringSession = async () => {
    const {
      dispatch,
      hcExerciseId,
      user,
      contacts,
      honestConversation
    } = this.props
    const isPrimary =
      honestConversation?.activeScoringSessionPrimary &&
      user.id === contacts.primary.id
    const isSecondary =
      honestConversation?.activeScoringSessionSecondary && !isPrimary
    this.setState({
      previousActiveScoringSessionId: isPrimary
        ? honestConversation?.activeScoringSessionPrimary
        : isSecondary
        ? honestConversation?.activeScoringSessionSecondary
        : null
    })
    await dispatch(
      hcExerciseActions.createScoringSessions(
        hcExerciseId,
        {
          clientId: user.id,
          scoredWithAdvisor: false
        },
        isPrimary
      )
    )
  }

  public updateActiveScoreSessionId = async () => {
    const {
      hcExerciseId,
      honestConversation,
      dispatch,
      contacts,
      user
    } = this.props
    const isPrimary = user.id === contacts.primary.id
    const updatedProperty: {
      activeScoringSessionPrimary?: string
      activeScoringSessionSecondary?: string
    } = {}
    if (isPrimary) {
      updatedProperty.activeScoringSessionPrimary =
        honestConversation.activeScoringSessionPrimary
    } else {
      updatedProperty.activeScoringSessionSecondary =
        honestConversation.activeScoringSessionSecondary
    }
    await dispatch(
      hcExerciseActions.updateHonestConversationExercise(
        hcExerciseId,
        updatedProperty
      )
    )
  }

  public render() {
    const {
      honestConversation,
      contacts,
      user,
      hcExerciseId,
      goalActions
    } = this.props
    return (
      <div
        id='hc-update-scores'
        className='honestConversations-score bg-white position-relative p-3 p-md-x2 mt-n4'>
        {honestConversation && honestConversation.householdCards ? (
          <ScoreForm
            goalActions={goalActions}
            hcExerciseId={hcExerciseId}
            honestConversation={honestConversation}
            contacts={contacts}
            user={user}
            isPrimary={user.id === contacts.primary.id}
            previousActiveScoringSessionId={
              this.state.previousActiveScoringSessionId
            }
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState): UpdateScoresProps => {
  const honestConversation: HonestConversationsExerciseObj = store.honestConversations
    ? store.honestConversations[store.honestConversations.hcExerciseId]
    : null
  return {
    hcExerciseId: store.honestConversations.hcExerciseId,
    contacts: store.contacts,
    honestConversation,
    user: store.user,
    goalActions: store.goalActions.goals
  }
}

export default connect(mapStateToProps)(UpdateScores)
