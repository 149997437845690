import React, { useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { GlobalState } from '../../../reducers'
import Button from 'react-bootstrap/Button'

import ProfilePicture from '../../../components/ProfilePicture'
import DeletePhotoModal from '../ProfileModals/DeletePhotoModal'
import UploadPhotoModal from '../ProfileModals/UploadPhotoModal'
import EditPhotoModal from '../ProfileModals/EditPhotoModal'
import LoaderModal from '../ProfileModals/LoaderModal'

import { getContact } from '../../../selectors/contacts'
import { deletePhoto, addPhoto } from '../../../actions/user.action'
import { addSuccessToast, addErrorToast } from '../../../actions/toasts.action'

import { useModalReducer, EModalActions } from '../../../helpers/profile'

type TPhotoProps = ConnectedProps<typeof connector>

const Photo = (props: TPhotoProps): JSX.Element => {
  const {
    isPrimary,
    contact,
    photoUrl,
    fileName,
    aDeletePhoto,
    aUploadPhoto,
    aAddErrorToast,
    aAddSuccessToast
  } = props

  const { modalState, handleModal } = useModalReducer()
  const [newPhotoUrl, setNewPhotoUrl] = useState(null)
  const [photoFileName, setPhotoFileName] = useState(null)

  const self = isPrimary ? 'primary' : 'secondary'

  const deletePhoto = async () => {
    try {
      await aDeletePhoto(self)
      aAddSuccessToast()
    } catch {
      aAddErrorToast()
    }
    closeModal()
  }

  const addPhoto = (event) => {
    const { files } = event.target
    if (!files[0]) return
    // TODO remove Math.random after fix with caching issues with s3
    setPhotoFileName(Math.random() + files[0].name)
    setNewPhotoUrl(URL.createObjectURL(files[0]))
    handleModal(EModalActions.showEdit)
  }

  const uploadPhoto = async (image: string) => {
    try {
      handleModal(EModalActions.showLoader)
      await aUploadPhoto(photoFileName || fileName, image, self)
      aAddSuccessToast()
    } catch {
      aAddErrorToast()
    }
    closeModal()
  }

  const closeModal = () => {
    clearState()
    handleModal(EModalActions.close)
  }

  const clearState = () => {
    setNewPhotoUrl(null)
    setPhotoFileName(null)
  }

  return (
    <div className='d-flex flex-row justify-content-start align-items-center'>
      <ProfilePicture
        firstName={contact?.firstName}
        lastName={contact?.lastName}
        photo={photoUrl}
        isPrimary={isPrimary}
        pictureSize={96}
        containerStyle={{
          backgroundColor: '#E6ECF3'
        }}
        initialsStyle={{
          fontSize: 24,
          fontWeight: 400,
          color: '#22263F'
        }}
      />
      <div className='d-flex flex-column flex-md-row align-items-center ms-4'>
        <Button
          variant='outline-primary'
          className='font-color--dark'
          onClick={() => handleModal(EModalActions.showUpload)}>
          Upload Photo
        </Button>
        {photoUrl && (
          <Button
            variant='light'
            className='font-color--primary'
            onClick={() => handleModal(EModalActions.showDelete)}>
            Delete
          </Button>
        )}
      </div>

      <DeletePhotoModal
        showModal={modalState.showDeleteModal}
        closeModal={closeModal}
        handleDeletePhoto={deletePhoto}
      />
      <UploadPhotoModal
        showModal={modalState.showUploadModal}
        closeModal={closeModal}
        handleAddPhoto={addPhoto}
        handlePhotoError={aAddErrorToast}
      />
      <EditPhotoModal
        showModal={modalState.showEditModal}
        closeModal={closeModal}
        photoUrl={newPhotoUrl || photoUrl}
        uploadPhoto={uploadPhoto}
        isWithoutPadding
      />
      <LoaderModal
        showModal={modalState.showLoaderModal}
        closeModal={closeModal}
      />
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user, contacts } = store
  return {
    contact: getContact(store),
    isPrimary: contacts?.primary?.id === user?.id,
    photoUrl: user.photoUrl,
    fileName: user.fileName
  }
}

const mapDispatchToProps = {
  aDeletePhoto: deletePhoto,
  aUploadPhoto: addPhoto,
  aAddSuccessToast: () => addSuccessToast({ message: 'Information updated' }),
  aAddErrorToast: () =>
    addErrorToast({
      message: 'Unable to update your information'
    })
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(Photo)
