import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import DashTileNull from '../../../components/Dashboard/DashTileNull'
import DashTile, { DashTileLink } from '../../../components/Dashboard/DashTile'
import { getHonestConversation } from '../../../actions/honestConversations.action'
import { getHousehold } from '../../../actions/household.action'
import DashPrioritiesTable from './DashPrioritiesTable'
import { isObjEmpty } from '../../../helpers'
import { setTokenCookie } from '../../../helpers/priorities'
import {
  createRedirectPath,
  determineEmailStatus,
  getContact
} from '../../../selectors/priorities'

const links: DashTileLink[] = [
  { label: 'View Priorities', route: '/honest-conversations' }
]

export const NullState = (props) => {
  const {
    accessToken,
    redirectUrl,
    hcExerciseId,
    emailSent,
    completedIndividualCards
  } = props
  return (
    <DashTileNull
      title='No Priorities'
      description={
        emailSent
          ? 'Your advisor sent you the link to HonestConversations®'
          : 'There are no HonestConversations® cards.'
      }
      actionBtn={
        emailSent &&
        !completedIndividualCards && {
          text: 'Choose Priorities',
          onClick: () => {
            setTokenCookie(accessToken)
            window.open(
              hcExerciseId ? redirectUrl : window._env_.REACT_APP_HC_URL_V2
            )
          }
        }
      }
    />
  )
}

type PrioritiesStore = ReturnType<typeof mapStateToProps>
type PrioritiesDispatch = typeof mapDispatchToProps
export type PrioritiesProps = PrioritiesStore & PrioritiesDispatch

export const PrioritiesTile = (props: PrioritiesProps) => {
  const {
    getHonestConversation,
    getHousehold,
    contact,
    emailSent,
    honestConversation,
    hcExerciseId,
    user,
    accessToken,
    redirectUrl,
    contactType
  } = props

  const fetchHcData = useCallback(async () => {
    const householdFetched = user?.householdFetched
    if (!householdFetched) {
      await getHousehold()
    }
    getHonestConversation()
  }, [])

  useEffect(() => {
    fetchHcData()
  }, [])

  const completedIndividualCards =
    honestConversation?.clientCards[contact?.id]?.length !== 15

  return user?.showHonestConversations ? (
    <DashTile
      title='Priorities'
      links={links}
      nullComponent={
        <NullState
          emailSent={emailSent}
          accessToken={accessToken}
          redirectUrl={redirectUrl}
          hcExerciseId={hcExerciseId}
          completedIndividualCards={completedIndividualCards}
        />
      }
      showNull={isObjEmpty(honestConversation?.householdCards)}
      rowTileType>
      <DashPrioritiesTable
        honestConversation={honestConversation}
        contact={contact}
        contactType={contactType}
      />
    </DashTile>
  ) : null
}

const mapDispatchToProps = {
  getHonestConversation,
  getHousehold
}

const mapStateToProps = (store: GlobalState) => {
  const redirectUrl = createRedirectPath(store)
  const emailSent = determineEmailStatus(store)
  const { contact, contactType } = getContact(store)
  return {
    contact,
    emailSent,
    user: store.user,
    honestConversation:
      store.honestConversations[store.honestConversations.hcExerciseId],
    hcExerciseId: store.honestConversations.hcExerciseId,
    accessToken: store.auth.accessToken,
    redirectUrl,
    contactType
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrioritiesTile)
