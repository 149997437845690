import { LOCATION_CHANGE } from 'connected-react-router'
import { Middleware } from '@reduxjs/toolkit'
import { GlobalState } from '../reducers'

const navigationScroll: Middleware<unknown, GlobalState> = () => (next) => (
  action
) => {
  if (action.type === LOCATION_CHANGE) {
    window.scrollTo(0, 0)
  }
  return next(action)
}

export default navigationScroll
