import * as actions from '../actions/insurance.action'
import { InsuranceObj } from '../objects/insurance'

const initState: InsuranceObj[] = [
  {
    insuranceRecordType: '',
    policyNumber: '',
    owner: '',
    insured: '',
    beneficiary: '',
    durationBenefitPeriod: '',
    deathBenefit: '',
    yearlyBenefit: '',
    monthlyBenefit: '',
    monthlyBenefitAmountLtc: '',
    eliminationPeriod: '',
    surrenderPeriod: '',
    annualPremium: '',
    recordTypeName: '',
    name: ''
  }
]

const Insurance = (state = initState, action): InsuranceObj[] => {
  switch (action.type) {
    case actions.FETCH_INSURANCE_FULFILLED:
      return mapInsurance(action.payload)
    default:
      return state
  }
}

const mapInsurance = (insuranceType) => {
  const insuranceList = []
  insuranceType.forEach((insurance) => {
    insuranceList.push({
      insuranceRecordType: insurance.type,
      policyNumber: insurance.benefit.policyNumber,
      owner: insurance.benefit.ownerName,
      insured: insurance.insuredName,
      beneficiary: insurance.benefit.beneficiaryName,
      durationBenefitPeriod: insurance.benefit.benefitPeriodYears,
      deathBenefit: insurance.deathBenefit,
      yearlyBenefit: insurance.benefit.yearlyBenefit,
      monthlyBenefitAmountLtc: insurance.benefit.monthlyBenefitAmountLtc,
      monthlyBenefit: insurance.benefit.monthlyBenefitAmount,
      eliminationPeriod: insurance.benefit.eliminationPeriod,
      surrenderPeriod: insurance.benefit.surrenderPeriod,
      annualPremium: insurance.benefit.annualPremium,
      recordTypeName: insurance.recordTypeName,
      name: insurance.name
    })
  })
  return insuranceList
}

export default Insurance
