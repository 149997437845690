import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const Histogram = (props) => {
  const {
    className,
    titleClassName,
    infoLabelClassName,
    totalCount,
    stepCount,
    thresholdLimit,
    percentageSuffix,
    chartLabel,
    infoLabel,
    alertLabel,
    ctaLabel,
    href,
    onClick,
    showPercentageLabel,
    showThresholdLabel,
    showThresholdLabelOnLeft, // threshold label/icon will display on left if true
    thresholdLimitIcon, // mutually exclusive with formattedThresholdLimit
    computeThresholdLimit,
    barBackgroundColor // the background color of the bar - eg. #F6AA33 or cyan
  } = props

  const baseCls = 'histogram'

  const percentage = Math.round((stepCount / totalCount) * 100)
  const formattedPercentage = `${percentage}${percentageSuffix}`
  const formattedThresholdLimit = `${thresholdLimit}${percentageSuffix}`
  const isLimitAboveThreshold = percentage > thresholdLimit

  const styles = {
    height: formattedPercentage,
    background: barBackgroundColor
  }

  const limitStyles = {
    height: formattedThresholdLimit
  }

  const histogramClassName = classnames(
    baseCls,
    `${baseCls}__container`,
    className
  )

  const barClassName = classnames(`${baseCls}__bar`, {
    [`${baseCls}__bar--alert`]: computeThresholdLimit && !isLimitAboveThreshold
  })

  const thresholdLabelClassName = classnames(`${baseCls}__threshold-label`, {
    [`${baseCls}__threshold-label--left`]: showThresholdLabelOnLeft
  })

  const titleCls = classnames('rfont--h4', titleClassName)

  return (
    <div className={histogramClassName}>
      <div className={`${baseCls}__wrapper`}>
        <div className={barClassName} style={styles}>
          {showPercentageLabel && (
            <span className={`${baseCls}__percentage`}>
              {formattedPercentage}
            </span>
          )}
        </div>
        {showThresholdLabel && (
          <>
            <div className={`${baseCls}__limit`} style={limitStyles} />
            <div className={thresholdLabelClassName} style={limitStyles}>
              {thresholdLimitIcon || formattedThresholdLimit}
            </div>
          </>
        )}
      </div>
      <div className={`${baseCls}__info mt-2 text-center`}>
        <h4 className={titleCls}>{chartLabel}</h4>
        <p className={infoLabelClassName}>
          {isLimitAboveThreshold ? infoLabel : alertLabel}
        </p>
        {(href || onClick) && (
          <Link
            to={href}
            onClick={onClick}
            className={`histogram__link ${infoLabelClassName}`}>
            {ctaLabel}
          </Link>
        )}
      </div>
    </div>
  )
}

Histogram.displayName = 'Histogram'

Histogram.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  infoLabelClassName: PropTypes.string,
  totalCount: PropTypes.number.isRequired,
  stepCount: PropTypes.number.isRequired,
  thresholdLimit: PropTypes.number.isRequired,
  percentageSuffix: PropTypes.string,
  chartLabel: PropTypes.node,
  infoLabel: PropTypes.string,
  alertLabel: PropTypes.string,
  ctaLabel: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  showPercentageLabel: PropTypes.bool,
  showThresholdLabel: PropTypes.bool,
  showThresholdLabelOnLeft: PropTypes.bool,
  thresholdLimitIcon: PropTypes.node,
  computeThresholdLimit: PropTypes.bool,
  barBackgroundColor: PropTypes.string
}

Histogram.defaultProps = {
  className: '',
  titleClassName: '',
  infoLabelClassName: '',
  percentageSuffix: '%',
  chartLabel: '',
  infoLabel: '',
  alertLabel: '',
  ctaLabel: '',
  href: undefined,
  onClick: undefined,
  showPercentageLabel: false,
  showThresholdLabel: true,
  showThresholdLabelOnLeft: false,
  thresholdLimitIcon: null,
  computeThresholdLimit: false,
  barBackgroundColor: ''
}

export default Histogram
