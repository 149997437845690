import React from 'react'
import { PieChart, Pie, Label, ResponsiveContainer, Sector } from 'recharts'
import { noop } from 'lodash-es'
import classnames from 'classnames'

const ResponsiveWrapper = ({ responsive, responsiveProps, children }) => {
  if (responsive) {
    return (
      <ResponsiveContainer {...responsiveProps}>{children}</ResponsiveContainer>
    )
  }
  return children
}

const Rechart = ({
  activeIndex, // set the active sector by index
  activeShape, // shape to render when active sector, https://recharts.org/en-US/api/Pie#activeShape
  animate, // animate initial render
  data, // data to show
  dataKey, // specify the value key in data for sector width
  emptyStateData, // custom data to show if data is empty
  emptyStateGradient, // is gradient applied when in empty state
  growActiveShape, // grow pie sector by x pixels when active
  height, // height of the chart
  innerRadius, // inner radius of pie/donut in %
  Label: CustomLabel, // a label to render, e.g., DonutRechartLabel
  outerRadius, // outer radius of pie/donut in %
  onClick, // event handler for pie sector onClick
  onMouseEnter, // event handler for pie sector onMouseEnter
  onMouseLeave, // event handler for pie sector onMouseLeave
  responsive, // should the chart be responsive
  responsiveProps, // props for responsive container, https://recharts.org/en-US/api/ResponsiveContainer#aspect
  style, // custom style applied to chart
  width // width of chart
}) => (
  <ResponsiveWrapper responsive={responsive} responsiveProps={responsiveProps}>
    <PieChart
      className={classnames('d-inline-block', {
        'gradient-mask': emptyStateGradient && data.length === 0
      })}
      height={height}
      width={width}
      style={style}>
      <Pie
        activeIndex={activeIndex}
        activeShape={(shape) =>
          activeShape({ ...shape, shapeWidth: growActiveShape })
        }
        data={data.length ? data : emptyStateData}
        dataKey={dataKey}
        innerRadius={innerRadius}
        isAnimationActive={animate}
        outerRadius={outerRadius}
        paddingAngle={0}
        startAngle={90}
        endAngle={450}
        style={{ cursor: 'pointer' }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}>
        {CustomLabel() && <Label content={CustomLabel} />}
      </Pie>
    </PieChart>
  </ResponsiveWrapper>
)

const defaultActiveShape = (shape) => {
  const { outerRadius, shapeWidth } = shape
  return (
    <g>
      <Sector {...shape} style={{ cursor: 'pointer' }} />
      {shapeWidth && (
        <Sector
          {...shape}
          outerRadius={outerRadius + shapeWidth}
          style={{ cursor: 'pointer' }}
        />
      )}
    </g>
  )
}

Rechart.defaultProps = {
  data: [],
  height: 400,
  Label: noop,
  style: {},
  width: 400,
  activeIndex: -1,
  activeShape: defaultActiveShape,
  animate: false,
  dataKey: 'value',
  emptyStateData: [
    {
      fill: 'black',
      fillOpacity: 0.3,
      stroke: 'transparent',
      value: 1
    }
  ],
  emptyStateGradient: true,
  growActiveShape: 0,
  innerRadius: '70%',
  outerRadius: '100%',
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  responsive: false,
  responsiveProps: {}
}

export default Rechart
