import {
  PalSortByTypes,
  PalPreferenceObj,
  PriorityActionInterface
} from '../objects/priorityAction'
import {
  compareStringField,
  compareStatusField,
  compareNumericField
} from '../helpers/index'

export const translateDefaultSort = (title: PalSortByTypes): string => {
  switch (title) {
    case 'Name':
      return 'name'
    case 'Status':
      return 'status'
    case 'Check in Date':
      return 'checkInDate'
    case 'Weight':
      return 'weight'
    default:
      return null
  }
}

export const sortPalItems = (
  palItems: PriorityActionInterface,
  sortByVal: string,
  desc: boolean
) => {
  if (palItems) {
    return Object.keys(palItems)
      .sort((a, b) => {
        const sortA = palItems[a][sortByVal] ? palItems[a][sortByVal] : ''
        const sortB = palItems[b][sortByVal] ? palItems[b][sortByVal] : ''
        if (sortByVal === 'status') {
          return compareStatusField(sortA, sortB, desc)
        }
        if (sortByVal === 'name' || sortByVal === 'checkInDate') {
          return compareStringField(sortA, sortB, desc)
        }
        if (sortByVal === 'weight') {
          return compareNumericField(
            palItems[a].cards && palItems[a].cards.length,
            palItems[b].cards && palItems[b].cards.length,
            desc
          )
        } else return null
      })
      .map((key: string) => palItems[key])
  } else return null
}

export const generateNewPreferences = (): PalPreferenceObj => {
  return {
    palSortBy: 'Name',
    palSortingOrder: 'Ascending'
  }
}
