import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

import ControlledModalWrapper from '../../../components/ControlledModalWrapper'

const PhotoLoaderModal = () => {
  return (
    <>
      <h3 className='rfont--h3 fw-bold text-dark mb-3'>Upload Photo</h3>
      <div
        style={{ minHeight: 414 }}
        className='d-flex flex-column align-items-center justify-content-center position-relative'>
        <Spinner animation='border' variant='dark' className='size--8' />
        <p className='rfont--body2 spinner-msg-pos'>Uploading Photo</p>
      </div>
    </>
  )
}

export default ControlledModalWrapper(PhotoLoaderModal)
