import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { InsuranceObj } from '../../objects/insurance'
import { camelCase } from 'lodash-es'
import {
  dollarAmountField,
  getFormattedAmount,
  headerOptions
} from '../../helpers/insurance'
interface InsuranceCardProps {
  insurancePolicy: InsuranceObj
}

const InsuranceCard = ({ insurancePolicy }: InsuranceCardProps) => {
  const headers = headerOptions[insurancePolicy.recordTypeName]
  return (
    <div className='my-4'>
      <Card
        id={insurancePolicy.policyNumber + '__upper'}
        className='bg-white rounded-0 border-0 mb-1'>
        <Row className='insurance-card__upper'>
          <Col className='insurance-card__text-name'>
            <h4>{insurancePolicy.recordTypeName}</h4>
            <h3>{insurancePolicy.name}</h3>
          </Col>
          <Col className='insurance-card__text-amount'>
            <h4>{headers[3]}</h4>
            <h3>
              {getFormattedAmount(insurancePolicy[camelCase(headers[3])])}
            </h3>
          </Col>
        </Row>
      </Card>
      <Card
        id={insurancePolicy.policyNumber + '__lower'}
        className='bg-white rounded-0 border-0'>
        <div className='insurance-card__lower'>
          {headers.map((header) => {
            const fieldValue =
              header === 'Duration'
                ? insurancePolicy['durationBenefitPeriod']
                : dollarAmountField.includes(header)
                ? getFormattedAmount(insurancePolicy[camelCase(header)])
                : insurancePolicy[camelCase(header)]
            return (
              //don't show the fields already shown above or ones with missing values
              header !== headers[3] &&
              header !== headers[6] && (
                <div className='insurance-card__text'>
                  {!fieldValue || fieldValue === 'null' ? (
                    <div className='insurance-card__text-null' />
                  ) : (
                    <h4>
                      {header === 'Policy Number' &&
                      fieldValue.toString().length > 4
                        ? `****${fieldValue.toString().slice(-4)}`
                        : fieldValue}
                      {header !== 'Duration'
                        ? ''
                        : fieldValue <= 1
                        ? ' Year'
                        : ' Years'}
                    </h4>
                  )}
                  <div>{header}</div>
                </div>
              )
            )
          })}
        </div>
      </Card>
    </div>
  )
}

export default InsuranceCard
