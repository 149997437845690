import React from 'react'
import { Row, Col } from 'react-bootstrap'
import TaskCheckboxGroup from './TaskCheckboxGroup'
import { TaskObj } from '../../objects/task'
import { dateFormat } from '../../helpers'

interface TaskItemProps {
  task: TaskObj
}

const TaskItem = (props: TaskItemProps): JSX.Element => {
  const { task } = props
  const { subject, dueDate, assigneeNames, status } = task
  return (
    <div className='container-fluid'>
      <Row className='d-flex flex-row justify-content-normal bg-white task-item py-2 py-sm-0'>
        <Col className='task-item__checkbox'>
          <TaskCheckboxGroup task={task} />
        </Col>
        <Col className='align-self-center task-item__details'>
          <Row className='d-flex flex-row'>
            <Col xs={12} sm={6}>
              <p className='task-item__details-assigned-to mb-1'>Description</p>
              <p className='task-item__details-subject' title={subject}>
                {subject || ''}
              </p>
            </Col>
            <Col xs={12} sm={2}>
              {dueDate && (
                <>
                  <p className='task-item__details-assigned-to mb-1'>
                    Due Date
                  </p>
                  <p className='task-item__details-assignees mb-0'>
                    {dateFormat(dueDate)}
                  </p>
                </>
              )}
            </Col>
            <Col xs={12} sm={2}>
              <p className='task-item__details-assigned-to mb-1'>Status</p>
              <p className='task-item__details-assignees mb-0'>
                {status || ''}
              </p>
            </Col>
            <Col xs={12} sm={2}>
              <p className='task-item__details-assigned-to mb-0'>Assigned to</p>
              <p className='task-item__details-assignees mb-0'>
                {assigneeNames || ''}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default TaskItem
