import React from 'react'
import SlickCarousel from '../../../components/SlickCarousel'
import PriorityCard from '../PriorityCards/PriorityCard'
import { useWindowSize } from '../../../hooks'

const MOBILE_SCREEN_SIZE = 576

export const CardCarousel = (props) => {
  const { cards, selectCard, setSelectedCard } = props
  const { width } = useWindowSize()
  const mobileMode = width <= MOBILE_SCREEN_SIZE

  const renderSlides = () =>
    cards.map((cardObject, index) => {
      const { category, title } = cardObject && cardObject.card
      const active = !selectCard
        ? index === 0
        : selectCard === cardObject.card?.id

      return (
        <div
          key={index}
          role='button'
          onClick={() => setSelectedCard(cardObject.card?.id)}
          className='personal-priorities__priority-button'>
          <PriorityCard
            active={active}
            title={title}
            primaryScore={cardObject.card.score}
            secondaryScore={null}
            category={category}
            isPersonal={true}
          />
        </div>
      )
    })

  return (
    <div
      id='honest-conversation-personal-cards'
      className='personal-priorities__carousel-cont'>
      <SlickCarousel
        containerStyle={{
          width: '100%'
        }}
        slides={renderSlides}
        slidesToShow={mobileMode ? 1 : 5}
        dotColor='dark'
        buttonProps={{
          variant: 'light',
          size: 'sm'
        }}
      />
    </div>
  )
}

export default CardCarousel
