import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import classnames from 'classnames'
import { ReactComponent as BellIcon } from '../../assets/images/icons/bell.svg'

interface NullTileActionBtn {
  text: string
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export interface DashTileNullProps {
  title: string
  description: string
  warning?: string
  actionBtn?: NullTileActionBtn
  className?: string
}

const DashTileNull = ({
  className,
  title,
  description,
  warning,
  actionBtn
}: DashTileNullProps) => (
  <Row
    className={classnames(
      'justify-content-center align-items-center flex-column dashboard-tile-null',
      className
    )}>
    <Col className='dashboard-tile-null__content'>
      <h3 className='dashboard-tile-null__content-title'>{title}</h3>
      {warning && (
        <div className='dashboard-tile-null__content-warning'>
          <BellIcon className='me-3' />
          <div>{warning}</div>
        </div>
      )}
      <p className='dashboard-tile-null__content-desc'>{description}</p>
      {actionBtn && (
        <Button
          className='dashboard-tile-null__content-btn'
          onClick={actionBtn.onClick}>
          {actionBtn.text}
        </Button>
      )}
    </Col>
  </Row>
)

export default DashTileNull
