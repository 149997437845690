import Api from '../helpers/api'
import { GoalActionSortByTypes } from '../objects/goalActions'

export const FETCH_GOAL_ACTIONS = 'FETCH_GOAL_ACTIONS'
export const FETCH_GOAL_ACTIONS_BY_ID = 'FETCH_GOAL_ACTIONS_BY_ID'
export const SET_SESSION_SORT = 'SET_SESSION_SORT'

export const getGoalActions = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_GOAL_ACTIONS,
    payload: apiInstance.get('/goalactions').then((response) => response.data)
  }
}

export const getGoalActionData = (goalActionId: string) => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_GOAL_ACTIONS_BY_ID,
    payload: apiInstance
      .get(`/goalactions/${goalActionId}`)
      .then((response) => response)
  }
}

export const setSessionSort = (sortBy: GoalActionSortByTypes) => {
  return {
    type: SET_SESSION_SORT,
    payload: sortBy
  }
}
