import React, { RefObject, Fragment, Component } from 'react'
import { Query } from '@apollo/client/react/components'
import { QueryResult } from '@apollo/client'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash-es'
import MessageBodyItem from './MessageBodyItem'
import MessageReply from './MessageReply'
import MessageNull from './MessageNull'
import { MessageBodyQuery } from '../../../helpers/queries'
import { OfficeTeamMemberObj } from '../../../objects/officeTeam'
import { getVideoData } from '../../../actions/messages.action'
import MessagesError from '../../../components/Messages/MessagesError'
import { dateFormat } from '../../../helpers'
import {
  MessageBodyInterface,
  MessageBodyProps,
  messageMediaType
} from '../../../objects/messages'
import { Card } from 'react-bootstrap'

class MessageBody extends Component<MessageBodyProps, {}> {
  public messagesEnd: RefObject<HTMLDivElement> = React.createRef()

  public componentDidUpdate() {
    this.scrollDown()
  }

  public scrollDown = () => {
    if (this.messagesEnd.current) {
      this.messagesEnd.current.scrollTop = this.messagesEnd.current.scrollHeight
    }
  }

  public dateDivider = (date: string) => {
    const today = dayjs()
    return (
      <div className='message-body__divider-w'>
        <div className='message-body__divider-line' />
        <div className='message-body__divider-date'>
          {today.isSame(date, 'day') ? 'Today' : dateFormat(date)}
        </div>
        <div className='message-body__divider-line message-body__divider-line__right' />
      </div>
    )
  }

  public getVideoData = async (
    messageId: string,
    dataId: string,
    dataType: messageMediaType
  ) => {
    const { threadId, dispatch } = this.props
    await dispatch(getVideoData(threadId, messageId, dataId, dataType))
  }

  public messageList = () => {
    const { threadId, householdFinId, contacts, members } = this.props
    return (
      <Query
        query={MessageBodyQuery}
        variables={{ householdFinId, threadId }}
        skip={!householdFinId}>
        {({ loading, error, data }: QueryResult) => {
          if (loading || (!error && !data))
            return <p className='p-3'>Loading...</p>
          if (error || isEmpty(data)) {
            return <MessagesError className='message-body__list-error' />
          }
          let previousDate: string
          let newDate = false
          if (data && data.allMessages && data.allMessages.nodes) {
            return data.allMessages.nodes.map(
              (
                {
                  id: messageId,
                  message,
                  adviserByAdviserSender,
                  clientByClientSender,
                  createdDate,
                  videoId,
                  thumbnailId
                }: MessageBodyInterface,
                index: number
              ) => {
                if (dayjs(previousDate).isSame(createdDate, 'day')) {
                  newDate = false
                } else {
                  newDate = true
                  previousDate = createdDate
                }
                const authorName =
                  adviserByAdviserSender && adviserByAdviserSender?.name
                    ? adviserByAdviserSender?.name
                    : clientByClientSender && clientByClientSender.name
                    ? clientByClientSender.name
                    : null
                let photo =
                  clientByClientSender &&
                  contacts &&
                  contacts?.primary &&
                  clientByClientSender?.client === contacts?.primary?.id
                    ? contacts?.primary?.photo
                    : ''
                photo =
                  clientByClientSender &&
                  contacts &&
                  contacts?.secondary &&
                  clientByClientSender?.client === contacts?.secondary?.id
                    ? contacts?.secondary?.photo
                    : photo
                if (members) {
                  members.forEach((member: OfficeTeamMemberObj) => {
                    photo =
                      adviserByAdviserSender &&
                      member.user &&
                      adviserByAdviserSender.adviser &&
                      member?.user?.id === adviserByAdviserSender?.adviser
                        ? member?.user?.photoUrl
                        : photo
                  })
                }

                // check if the message is a video message
                const isVideoMessage = Boolean(videoId && thumbnailId)

                return (
                  <Fragment key={index}>
                    {newDate ? this.dateDivider(previousDate) : null}
                    <MessageBodyItem
                      key={messageId}
                      message={message}
                      threadId={threadId}
                      authorName={authorName}
                      messageId={messageId}
                      createdDate={createdDate}
                      isVideoMessage={isVideoMessage}
                      isOldVideoMessage={
                        message?.indexOf('/VideoMessage') !== -1
                      }
                      getThumbnailUrl={() => {
                        isVideoMessage &&
                          this.getVideoData(messageId, thumbnailId, 'thumbnail')
                      }}
                      getVideoUrl={() =>
                        this.getVideoData(messageId, videoId, 'video')
                      }
                      photo={photo}
                    />
                  </Fragment>
                )
              }
            )
          } else return null
        }}
      </Query>
    )
  }

  public messageBody = () => {
    const { threadId, householdFinId, userId } = this.props

    return (
      <div>
        <div className='message-body__list' ref={this.messagesEnd}>
          {this.messageList()}
        </div>
        <MessageReply
          householdFinId={householdFinId}
          threadId={threadId}
          userId={userId}
          onReply={this.scrollDown}
        />
      </div>
    )
  }

  public render() {
    const { threadId, householdFinId, userId, error } = this.props
    if (householdFinId && threadId && userId) {
      return (
        <Card
          id={threadId}
          className='p-3 p-md-x2 bg-white rounded-0 border-0 message-body-w'>
          {this.messageBody()}
        </Card>
      )
    }
    return <MessageNull error={error} />
  }
}

export default MessageBody
