import * as contactActions from '../actions/contacts.action'
import * as hcActions from '../actions/honestConversations.action'
import * as moneyMindActions from '../actions/moneymind.action'
import * as userActions from '../actions/user.action'
import { formatPhoneNumber } from '../helpers'
import { ymmQuizParamValue, ymmQuizURL } from '../helpers/ymmQuizURL'
import { ContactObj } from '../objects/contact'

export interface ContactsState {
  primary: ContactObj
  secondary: ContactObj
}

const initState: ContactsState = {
  primary: {
    id: '',
    firstName: '',
    lastName: '',
    photo: '',
    fileName: '',
    email: '',
    mobile: '',
    home: '',
    work: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    idealLifeIndexScore: 0,
    idealLifeIndexBenchmark: 8,
    idealLifeIndexDate: '',
    ymmURL: '',
    moneymind: {
      fear: null,
      commitment: null,
      happiness: null
    },
    moneymindDescription: {
      first: {
        desktop: '',
        mobile: ''
      },
      second: {
        desktop: '',
        mobile: ''
      },
      third: {
        desktop: '',
        mobile: ''
      }
    }
  },
  secondary: {
    id: '',
    firstName: '',
    lastName: '',
    photo: '',
    fileName: '',
    email: '',
    mobile: '',
    home: '',
    work: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    idealLifeIndexScore: 0,
    idealLifeIndexBenchmark: 8,
    idealLifeIndexDate: '',
    ymmURL: '',
    moneymind: {
      fear: null,
      commitment: null,
      happiness: null
    },
    moneymindDescription: {
      first: {
        desktop: '',
        mobile: ''
      },
      second: {
        desktop: '',
        mobile: ''
      },
      third: {
        desktop: '',
        mobile: ''
      }
    }
  }
}

const Contacts = (state = initState, action): ContactsState => {
  switch (action.type) {
    case contactActions.FETCH_CONTACTS_FULFILLED:
      return {
        ...state,
        primary: mapContact(action.payload.primary, state.primary),
        secondary: mapContact(action.payload.secondary, state.secondary)
      }
    case moneyMindActions.FETCH_MONEY_MIND_TOKEN_FULFILLED: {
      const {
        payload: { link }
      } = action

      return {
        ...state,
        primary: {
          ...state.primary,
          ymmURL: link
        },
        secondary: {
          ...state.secondary,
          ymmURL: link
        }
      }
    }
    case moneyMindActions.FETCH_MONEY_MIND_FULFILLED:
      return {
        ...state,
        primary: mapMoneyMind(action.payload.primary, state.primary),
        secondary: mapMoneyMind(action.payload.secondary, state.secondary)
      }
    case moneyMindActions.FETCH_MONEY_MIND_DESCRIPTION_FULFILLED:
      if (action.payload.contactLevel === 'primary') {
        return {
          ...state,
          primary: mapMoneyMindDescription(action.payload, state.primary)
        }
      } else {
        return {
          ...state,
          secondary: mapMoneyMindDescription(action.payload, state.secondary)
        }
      }
    case moneyMindActions.CLEAR_MONEY_MIND:
      return {
        ...state,
        primary: clearMoneyMind(state.primary),
        secondary: clearMoneyMind(state.secondary)
      }
    case moneyMindActions.CLEAR_MONEY_MIND_DESCRIPTIONS:
      if (action.payload === 'primary') {
        return { ...state, primary: clearMoneyMindDescription(state.primary) }
      } else {
        return {
          ...state,
          secondary: clearMoneyMindDescription(state.secondary)
        }
      }
    case `${hcActions.FETCH_HONEST_CONVERSATION}_FULFILLED`:
      if (action.payload[0]) {
        const primary =
          action.payload[0].scores && action.payload[0].scores.length > 0
            ? action.payload[0].scores[0].spouse1
            : null
        const secondary =
          action.payload[0].scores && action.payload[0].scores.length > 0
            ? action.payload[0].scores[0].spouse2
            : null
        const idealLifeIndexDate =
          action.payload[0].scores &&
          action.payload[0].scores.length > 0 &&
          action.payload[0].scores[0].dateOfScore
            ? action.payload[0].scores[0].dateOfScore.split('T')[0]
            : null
        return {
          ...state,
          primary: mapHonestConversation(
            primary,
            state.primary,
            idealLifeIndexDate
          ),
          secondary: mapHonestConversation(
            secondary,
            state.secondary,
            idealLifeIndexDate
          )
        }
      } else {
        return state
      }
    case userActions.NEW_PHOTO_FULFILLED:
      if (action.payload.contactLevel === 'primary') {
        return {
          ...state,
          primary: mapContactPhoto(action.payload.data.photoUrl, state.primary)
        }
      } else {
        return {
          ...state,
          secondary: mapContactPhoto(
            action.payload.data.photoUrl,
            state.secondary
          )
        }
      }
    case userActions.DELETE_PHOTO_FULFILLED:
      if (action.payload.contactLevel === 'primary') {
        return { ...state, primary: mapContactPhoto('', state.primary) }
      } else {
        return { ...state, secondary: mapContactPhoto('', state.secondary) }
      }

    default:
      return state
  }
}

const mapContactPhoto = (photo, stateContact): ContactObj => {
  return {
    ...stateContact,
    photo
  }
}

const mapContact = (contact, stateContact): ContactObj => {
  if (contact) {
    return {
      ...stateContact,
      id: contact.id,
      firstName: contact.firstName && contact.firstName,
      lastName: contact.lastName && contact.lastName,
      photo: contact.photoUrl,
      fileName: contact.photoFilename,
      email: contact.email,
      mobile: formatPhoneNumber(contact.mobilePhone),
      work: formatPhoneNumber(contact.workPhone),
      home: formatPhoneNumber(contact.homePhone),
      street: contact.mailingStreet,
      city: contact.mailingCity,
      state: contact.mailingState,
      zip: contact.mailingPostalCode,
      idealLifeIndexBenchmark:
        contact.benchmarkClientPortal && contact.benchmarkClientPortal > 0
          ? contact.benchmarkClientPortal / 10
          : 8,
      ymmURL: ymmQuizURL(ymmQuizParamValue(contact))
    }
  } else {
    return null
  }
}

const clearMoneyMind = (stateContact) => {
  if (stateContact) {
    return {
      ...stateContact,
      moneymind: {
        fear: 0,
        commitment: 0,
        happiness: 0
      }
    }
  } else return stateContact
}

const clearMoneyMindDescription = (stateContact) => {
  if (stateContact) {
    return {
      ...stateContact,
      moneymindDescription: {
        first: {
          desktop: '',
          mobile: ''
        },
        second: {
          desktop: '',
          mobile: ''
        },
        third: {
          desktop: '',
          mobile: ''
        }
      }
    }
  } else return stateContact
}

const mapMoneyMind = (moneymind, stateContact) => {
  if (stateContact) {
    if (moneymind && moneymind.myScores) {
      return {
        ...stateContact,
        moneymind: {
          happiness: moneymind.myScores.happiness,
          fear: moneymind.myScores.fear,
          commitment: moneymind.myScores.commitment
        }
      }
    } else {
      return {
        ...stateContact,
        moneymind: null
      }
    }
  } else return stateContact
}

const mapMoneyMindDescription = (moneymindDescription, stateContact) => {
  if (stateContact) {
    if (moneymindDescription) {
      return {
        ...stateContact,
        moneymindDescription: mapMoneyMindIndexedDescription(
          moneymindDescription.index,
          moneymindDescription[0],
          stateContact.moneymindDescription
        )
      }
    } else {
      return {
        ...stateContact,
        moneymindDescription: null
      }
    }
  } else return stateContact
}

const mapMoneyMindIndexedDescription = (
  index,
  description,
  stateDescription
) => {
  if (stateDescription) {
    if (index === 'first') {
      return {
        ...stateDescription,
        first: {
          desktop: description.text.long,
          mobile: description.text.mobile.primary
        }
      }
    } else if (index === 'second') {
      return {
        ...stateDescription,
        second: {
          desktop: description.text.short,
          mobile: description.text.mobile.secondary
        }
      }
    } else if (index === 'third') {
      return {
        ...stateDescription,
        third: {
          desktop: description.text.short,
          mobile: description.text.mobile.secondary
        }
      }
    } else return stateDescription
  } else return stateDescription
}

const mapHonestConversation = (
  honestConversation,
  stateContact,
  idealLifeIndexDate
) => {
  if (stateContact) {
    if (honestConversation) {
      const score = honestConversation.idealLifeIndex / 10
      return {
        ...stateContact,
        idealLifeIndexScore: Math.round(score),
        idealLifeIndexDate
      }
    } else {
      return {
        ...stateContact,
        idealLifeIndexScore: null,
        idealLifeIndexDate: null
      }
    }
  } else return stateContact
}

export default Contacts
