import qs from 'query-string'
import { push, CALL_HISTORY_METHOD } from 'connected-react-router'
import { Middleware } from '@reduxjs/toolkit'
import * as AuthActions from '../actions/auth.action'
import { GlobalState } from '../reducers'
import { getCookieValue, logout, MAINTENANCE_MODE } from '../helpers'

const unauthedRoutes = ['/auth/salesforce/callback', '/logout', '/maintenance']

const forceAuth: Middleware<unknown, GlobalState> = (store) => (next) => async (
  action
) => {
  if (
    action.type.includes('@@router') &&
    !unauthedRoutes.includes(action.payload?.location?.pathname) &&
    !action.type.includes(CALL_HISTORY_METHOD)
  ) {
    const state = store.getState()
    // check to see if we have state done
    if (!state.auth || (state.auth && state.auth.accessToken == null)) {
      // Only do the advisor flow if we have a contact passed in and have a token set
      const contact = qs.parse(window.location?.search).contact as string
      const token: string = getCookieValue('token')
      if (contact && token) {
        // verify that an advsior can do stuff
        // But do that later
        await store.dispatch(AuthActions.authPassthrough(token, contact))
        // send the router out to the auth page
        store.dispatch(push('/auth/salesforce/callback'))
        return next(action)
      } else {
        // Disable redirect in maintenance mode
        if (!MAINTENANCE_MODE) {
          // store current path in localstorage
          localStorage.setItem(
            'redirect',
            window?.location?.pathname + window?.location?.search
          )
          // Manually get out of the app and redirect to window
          window.location.href = '/auth/grant'
        }
      }
    }
  }
  if (action.type.includes('_REJECTED')) {
    if (
      action.payload &&
      action.payload.response &&
      action.payload.response.status === 401
    ) {
      logout()
    }
  }
  return next(action)
}

export { forceAuth }
