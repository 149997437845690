import { map } from 'lodash-es'
import { GlobalState } from '../reducers'
import { TaskObj } from '../objects/task'
import { createDeepEqualSelector } from './utilz'
import { getContact } from './contacts'
import { filterAndSort } from '../helpers/tasks'
import { TaskObjV2 } from '../objects/taskV2'

const getAllTasks = (store: GlobalState) => {
  return window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true' &&
    store.user?.isFinlifeInstitution === false &&
    store.user?.enableCRMTasks === true
    ? store.tasksV2.tasks
    : store.tasks.tasks
}

export const getSortMethod = (store: GlobalState) => {
  return store.tasks.sortBy
}

const isFiltered = (store: GlobalState) => {
  return store.tasks.filtered
}

export const tasksSelector = createDeepEqualSelector(
  [getAllTasks, getSortMethod, isFiltered, getContact],
  (allTasks, sortBy, filtered, contact) => {
    const list: (TaskObj | TaskObjV2)[] = map(allTasks, (task) => task)
    const hasCompleted = list.some((task) => task.complete)
    const incomplete = list.filter((task) => !task.complete)
    return {
      tasks: filterAndSort(incomplete, sortBy, filtered, contact),
      hasCompleted,
      filtered,
      sortBy
    }
  }
)

export const dashTaskList = createDeepEqualSelector(
  [getAllTasks, isFiltered, getContact],
  (allTasks, filtered, contact) => {
    const list: (TaskObj | TaskObjV2)[] = map(allTasks, (task) => task)
    const incomplete = list.filter((task) => !task.complete)
    return filterAndSort(incomplete, 'dueDate', filtered, contact).slice(0, 4)
  }
)

export const completedTasksSelector = createDeepEqualSelector(
  [getAllTasks, isFiltered, getContact],
  (tasks, filtered, contact) => {
    const list = map(tasks, (task) => task)
    const complete = list.filter((task) => task.complete)
    return {
      completedTasks: filterAndSort(complete, null, filtered, contact),
      hasTasks: Boolean(tasks),
      filtered
    }
  }
)
