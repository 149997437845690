import React from 'react'
import { MessagesHeader } from './MessagesHeader'
import MessagesTable from './MessagesTable'

const Messages = () => {
  const [subjectFilter, setSubjectFilter] = React.useState('')
  return (
    <div>
      <MessagesHeader setSubjectFilter={(name) => setSubjectFilter(name)} />
      <MessagesTable subjectFilter={subjectFilter} />
    </div>
  )
}

export default Messages
