import Api from '../helpers/api'
import {
  ScoringSessionsObj,
  SessionCardScoreInterface,
  CardScoreRequestObj
} from '../objects/honestConversation'
import { AxiosResponse } from 'axios'

export const FETCH_HONEST_CONVERSATION = 'FETCH_HONEST_CONVERSATION'
export const FETCH_HONEST_CONVERSATION_PENDING =
  'FETCH_HONEST_CONVERSATION_PENDING'
export const FETCH_HONEST_CONVERSATION_REJECTED =
  'FETCH_HONEST_CONVERSATION_REJECTED'
export const FETCH_HONEST_CONVERSATION_FULFILLED =
  'FETCH_HONEST_CONVERSATION_FULFILLED'
export const UPDATE_HONEST_CONVERSATION_EXERCISE =
  'UPDATE_HONEST_CONVERSATION_EXERCISE'
export const CREATE_SCORING_SESSIONS = 'CREATE_SCORING_SESSIONS'
export const UPDATE_SCORING_SESSION = 'UPDATE_SCORING_SESSION'
export const DELETE_SCORING_SESSION = 'DELETE_SCORING_SESSION'
export const CREATE_SCORING_SESSION_SCORE = 'CREATE_SCORING_SESSION_SCORE'
export const CREATE_SCORE_AND_UPDATE_SESSION = 'CREATE_SCORE_AND_UPDATE_SESSION'

export const getHonestConversation = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_HONEST_CONVERSATION,
    payload: apiInstance
      .get('/honestconversation/exercise')
      .then((response) => {
        return response.data
      })
  }
}

export const updateHonestConversationExercise = (
  exerciseId: string,
  body: any
) => {
  const apiInstance = Api.getInstance()
  return {
    type: UPDATE_HONEST_CONVERSATION_EXERCISE,
    payload: apiInstance
      .put(`/honestconversation/exercise/${exerciseId}`, body)
      .then((response) => {
        return response
      })
  }
}

export const createScoringSessions = (
  exerciseId: string,
  scoringSession: ScoringSessionsObj,
  isPrimary: boolean
) => {
  const apiInstance = Api.getInstance()
  return {
    type: CREATE_SCORING_SESSIONS,
    payload: apiInstance
      .post(
        `/honestconversation/exercises/${exerciseId}/scoringsessions`,
        scoringSession
      )
      .then((response) => {
        return { ...response.data, isPrimary }
      })
  }
}

export const deleteScoringSession = (
  exerciseId: string,
  scoringSessionId: string
) => {
  const apiInstance = Api.getInstance()
  return {
    type: DELETE_SCORING_SESSION,
    payload: apiInstance
      .delete(
        `/honestconversation/exercises/${exerciseId}/scoringsessions/${scoringSessionId}`
      )
      .then((response) => {
        return { ...response.data }
      })
  }
}

export const createCardScore = (
  contactId: string,
  exerciseId: string,
  scoringSessionId: string,
  scoredCards: CardScoreRequestObj[]
) => {
  const apiInstance = Api.getInstance()
  const apiUrl = `/honestconversation/exercise/${exerciseId}/scoringsessions/${scoringSessionId}/cardscores`
  const requestList: Promise<AxiosResponse<any>>[] = []
  scoredCards.forEach((card) => {
    requestList.push(apiInstance.post(apiUrl, card))
  })

  return {
    type: CREATE_SCORING_SESSION_SCORE,
    payload: Promise.all(requestList).then((responseArray) => {
      const sessionContainer: SessionCardScoreInterface = {}
      responseArray.forEach((response: any) => {
        sessionContainer[response.data.id] = {
          ...response.data
        }
      })
      return {
        contactId,
        scoringSessionId,
        scores: sessionContainer
      }
    })
  }
}

export const updateScoringSession = (
  exerciseId: string,
  scoringSessionId: string,
  updatedScores: { clientId: string; cardScoreIds: string[] }
) => {
  const apiInstance = Api.getInstance()
  const apiUrl = `/honestconversation/exercises/${exerciseId}/scoringsessions/${scoringSessionId}`
  return {
    type: UPDATE_SCORING_SESSION,
    payload: apiInstance.put(apiUrl, updatedScores).then((response: any) => {
      return response.data
    })
  }
}

export const createCardScoreAndUpdateScoringSession = (
  contactId: string,
  exerciseId: string,
  scoringSessionId: string,
  newScoredCards: CardScoreRequestObj[],
  cardScoreIds: string[]
) => {
  return {
    type: CREATE_SCORE_AND_UPDATE_SESSION,
    payload: createCardScore(
      contactId,
      exerciseId,
      scoringSessionId,
      newScoredCards
    ).payload.then((response) => {
      const updatedScoreIds = cardScoreIds.concat(Object.keys(response.scores))
      const updatedScores = {
        clientId: contactId,
        cardScoreIds: updatedScoreIds
      }
      return updateScoringSession(exerciseId, scoringSessionId, updatedScores)
        .payload
    })
  }
}
