import { SortSettingProperty, SortType } from '../objects/preferenceSortSetting'
import {
  ClientAccountObjState,
  NetworthPreferences
} from '../objects/clientAccount'
import {
  mapValueToOption,
  compareStringField,
  compareNumericField,
  sanitizeSortFieldValues
} from '../helpers/index'

export const sortOrderOptions: SortSettingProperty[] = [
  {
    value: 'ascending',
    label: 'Ascending'
  },
  {
    value: 'descending',
    label: 'Descending'
  }
]

export const sortByOptions: SortSettingProperty[] = [
  {
    value: 'name',
    label: 'Account Name'
  },
  {
    value: 'accountNumber',
    label: 'Account Number'
  },
  {
    value: 'totalValue',
    label: 'Amount'
  },
  {
    value: 'guidebookClassification',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const groupByOptions: SortSettingProperty[] = [
  {
    value: 'guidebookClassification',
    label: 'Classification'
  },
  {
    value: 'registrationType',
    label: 'Registration Type'
  }
]

export const sortNetWorthAccounts = (
  clientAccounts: ClientAccountObjState,
  optionValues: SortType
) => {
  const groupBy = mapValueToOption(groupByOptions, optionValues.groupBy)
  const groupByVal = groupBy && groupBy.value ? groupBy.value : ''
  const sortBy = mapValueToOption(sortByOptions, optionValues.sortBy)
  const sortByVal = sortBy && sortBy.value ? sortBy.value : ''
  const sortingOrder = mapValueToOption(
    sortOrderOptions,
    optionValues.sortingOrder
  )
  const sortingOrderVal =
    sortingOrder && sortingOrder.value ? sortingOrder.value : ''
  const descending = sortingOrderVal === 'descending'
  const accountsToSort = sanitizeSortFieldValues(clientAccounts, {
    groupBy: groupByVal,
    sortBy: sortByVal
  })
  const sortedAccounts = {}

  if (accountsToSort) {
    Object.keys(accountsToSort)
      .sort((a, b) => {
        const acctGroupA = accountsToSort[a][groupByVal]
        const acctGroupB = accountsToSort[b][groupByVal]
        const acctSortA = accountsToSort[a][sortByVal]
        const acctSortB = accountsToSort[b][sortByVal]
        let grouped
        let sorted

        if (groupByVal === 'guidebookClassification') {
          grouped = compareStringField(acctGroupA, acctGroupB, false)
        }
        if (groupByVal === 'registrationType') {
          grouped = compareStringField(acctGroupA, acctGroupB, false)
        }
        if (sortByVal === 'totalValue') {
          sorted = compareNumericField(acctSortA, acctSortB, descending)
        }
        if (sortByVal === 'name') {
          sorted = compareStringField(acctSortA, acctSortB, descending)
        }
        if (sortByVal === 'accountNumber') {
          sorted = compareStringField(acctSortA, acctSortB, descending)
        }
        if (sortByVal === 'guidebookClassification') {
          grouped = compareStringField(acctSortA, acctSortB, descending)
        }
        if (sortByVal === 'registrationType') {
          grouped = compareStringField(acctSortA, acctSortB, descending)
        }
        return grouped || sorted
      })
      .forEach((key: string) => {
        sortedAccounts[key] = accountsToSort[key]
      })
  }
  return sortedAccounts
}

export const generateNewPreferences = (): NetworthPreferences => {
  return {
    cashGroupBy: 'Classification',
    cashSortBy: 'Account Name',
    cashSortingOrder: 'Ascending',
    investmentsGroupBy: 'Classification',
    investmentsSortBy: 'Account Name',
    investmentsSortingOrder: 'Ascending',
    otherAssetsGroupBy: 'Classification',
    otherAssetsSortBy: 'Account Name',
    otherAssetsSortingOrder: 'Ascending',
    liabilitiesGroupBy: 'Classification',
    liabilitiesSortBy: 'Account Name',
    liabilitiesSortingOrder: 'Ascending',
    unclassifiedGroupBy: 'Classification',
    unclassifiedSortBy: 'Account Name',
    unclassifiedSortingOrder: 'Ascending'
  }
}
