import React from 'react'
import Card from 'react-bootstrap/Card'

export interface NullTasksProps {
  completed?: boolean
}

const NullTasks = ({ completed }: NullTasksProps) => (
  <Card id='null-tasks' className='null-tasks bg-white rounded-0 border-0'>
    <div className='null-tasks__w'>
      <h3>{`No ${completed ? 'completed' : 'incomplete'} tasks`}</h3>
      {!completed && <p>Please contact your advisor for help adding a task.</p>}
    </div>
  </Card>
)

export default NullTasks
