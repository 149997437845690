import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'

import { history, AppDispatch } from '../../../store'

import { newApolloClient } from '../../../gql/initializer'
import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { clientSettingsMapping } from '../../../helpers/clientSettings'
import Button from 'react-bootstrap/Button'
import { NotificationTile } from './NotificationTile'
import {
  queryNotificationSettings,
  saveNotificationSettings,
  sameMembers
} from '../../../helpers/notifications'

export interface ClientNotificationsProps {
  dispatch: AppDispatch
  userId: string
  accessToken: string
}

interface ClientNotificationsState {
  desktopSettings: string[] // state container
  emailSettings: string[] // state container
  mobileSettings: string[] // state container
  desktopSettingsInitial: string[]
  emailSettingsInitial: string[]
  mobileSettingsInitial: string[]
  disableSettings: boolean
  disableSaveButton: boolean
}

let apolloClient: ApolloClient<NormalizedCacheObject> = null
export class Notifications extends Component<
  ClientNotificationsProps,
  ClientNotificationsState
> {
  constructor(props: ClientNotificationsProps) {
    super(props)
    this.state = {
      desktopSettings: [],
      emailSettings: [],
      mobileSettings: [],
      desktopSettingsInitial: [],
      emailSettingsInitial: [],
      mobileSettingsInitial: [],
      disableSettings: true,
      disableSaveButton: true
    }
  }

  public componentWillUnmount = () => {
    apolloClient.clearStore()
    apolloClient.stop()
  }

  public componentDidUpdate(prevProps) {
    if (prevProps.userId !== this.props.userId) {
      queryNotificationSettings(this.props.userId, apolloClient).then(
        (queryResponse: any) => {
          this.setStateFromQuery(queryResponse)
        }
      )
    }
  }

  public componentDidMount = () => {
    apolloClient = newApolloClient(this.props.accessToken)
    queryNotificationSettings(this.props.userId, apolloClient).then(
      (queryResponse: any) => {
        this.setStateFromQuery(queryResponse)
      }
    )
  }

  /**
   *
   * @param queryResponse
   * map and set state
   *
   */
  public setStateFromQuery = (queryResponse: any) => {
    const {
      desktopSettings,
      emailSettings,
      mobileSettings,
      disabled
    } = clientSettingsMapping(queryResponse)
    const desktopSettingsInitial = Array.from(desktopSettings)
    const emailSettingsInitial = Array.from(emailSettings)
    const mobileSettingsInitial = Array.from(mobileSettings)
    this.setState({
      desktopSettings,
      emailSettings,
      mobileSettings,
      desktopSettingsInitial,
      emailSettingsInitial,
      mobileSettingsInitial,
      disableSettings: disabled,
      disableSaveButton: true
    })
  }

  public resetSettings = () => {
    queryNotificationSettings(this.props.userId, apolloClient).then(
      (queryResponse: any) => {
        this.setStateFromQuery(queryResponse)
        history.push('/my-profile')
      }
    )
  }

  public saveSettings = () => {
    const { desktopSettings, emailSettings, mobileSettings } = this.state
    saveNotificationSettings(
      this.props.userId,
      apolloClient,
      desktopSettings,
      emailSettings,
      mobileSettings,
      this.props.dispatch
    )
  }

  public changeNotificationChecks = (event) => {
    const {
      desktopSettings,
      emailSettings,
      mobileSettings,
      desktopSettingsInitial,
      emailSettingsInitial,
      mobileSettingsInitial
    } = this.state

    const { checked, name } = event.target

    let settings = Array.from(desktopSettings)
    const isEmailNotification = name.startsWith('EMAIL_')
    const isMobileNotification = name.startsWith('MOBILE_')
    const checkedNotificationType = isEmailNotification
      ? name.replace('EMAIL_', '')
      : isMobileNotification
      ? name.replace('MOBILE_', '')
      : name.replace('WEB_', '')
    if (isEmailNotification) {
      settings = Array.from(emailSettings)
    }
    if (isMobileNotification) {
      settings = Array.from(mobileSettings)
    }
    if (checked) {
      settings.push(checkedNotificationType)
    } else {
      settings = settings.filter(
        (setting) => setting !== checkedNotificationType
      )
    }

    if (isEmailNotification) {
      this.setState({
        disableSaveButton:
          sameMembers(emailSettingsInitial, settings) &&
          sameMembers(desktopSettingsInitial, desktopSettings) &&
          sameMembers(mobileSettingsInitial, mobileSettings),
        emailSettings: settings
      })
    } else if (isMobileNotification) {
      this.setState({
        disableSaveButton:
          sameMembers(mobileSettingsInitial, settings) &&
          sameMembers(desktopSettingsInitial, desktopSettings) &&
          sameMembers(emailSettingsInitial, emailSettings),
        mobileSettings: settings
      })
    } else {
      this.setState({
        disableSaveButton:
          sameMembers(desktopSettingsInitial, settings) &&
          sameMembers(emailSettingsInitial, emailSettings) &&
          sameMembers(mobileSettingsInitial, mobileSettings),
        desktopSettings: settings
      })
    }
  }

  public render() {
    const {
      disableSaveButton,
      desktopSettings,
      emailSettings,
      mobileSettings
    } = this.state
    return (
      <div className='d-flex flex-column justify-content-start mb-4'>
        <span className='profile-card__title'>
          Notifications and Alerts
          <p className='profile-card__title--sub'>
            Choose your email and in-app notification preferences
          </p>
        </span>
        {NotificationTile({
          desktopSettings,
          emailSettings,
          mobileSettings,
          changeNotificationChecks: this.changeNotificationChecks
        })}
        <div className='mt-3'>
          <Button
            variant='outline-primary'
            size='lg'
            className='font-color--dark profile-card__btn-lg'
            disabled={disableSaveButton}
            onClick={this.saveSettings}>
            Save
          </Button>
          <Button
            variant='outline-primary'
            size='lg'
            className='font-color--dark mt-2 mt-md-0 ms-md-2 profile-card__btn-lg'
            onClick={this.resetSettings}>
            Cancel
          </Button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState) => {
  return {
    userId: store?.user?.id,
    accessToken: store?.auth?.accessToken
  }
}
const mapDispatchToProps = (dispatch) => {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications)
