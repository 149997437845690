import React, { useRef } from 'react'
import Button from 'react-bootstrap/Button'

interface IFileUploadProps {
  handleAddFile: (
    event: React.ChangeEvent<HTMLInputElement>,
    verifiedFiles?: File[]
  ) => void
  handleFileError: (
    event: React.ChangeEvent<HTMLInputElement>,
    errorFiles?: File[]
  ) => void
  allowedFileFormats?: string[]
  allowedFileSize?: number
  description?: string
  inputName?: string
}

const FileUpload = (props: IFileUploadProps) => {
  const {
    handleAddFile,
    handleFileError,
    allowedFileFormats,
    allowedFileSize,
    description,
    inputName
  } = props

  const fileInputRef = useRef(null)

  const handleBrowseClick = () => {
    fileInputRef?.current?.click()
  }

  const validateFormat = (file) => {
    const { name } = file
    const extensionIndex = name.lastIndexOf('.')
    const fileExtension =
      name && extensionIndex >= 1 ? name.slice(extensionIndex) : ''
    const isTypeAllowed = allowedFileFormats
      ? allowedFileFormats.some(
          (fileType) => fileType === fileExtension.toLowerCase()
        )
      : true
    return isTypeAllowed
  }

  const validateSize = (file) => {
    const { size } = file
    return allowedFileSize ? size <= allowedFileSize : true
  }

  const handleFileInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = Array.from(event.target?.files || [])
    const verifiedFiles = []
    const errorFiles = []
    // run validation if props passed
    if (allowedFileFormats || allowedFileSize) {
      files.forEach((file) => {
        if (validateFormat(file) && validateSize(file)) {
          verifiedFiles.push(file)
        } else {
          errorFiles.push(file)
        }
      })
    }

    if (errorFiles.length > 0) {
      handleFileError(event, errorFiles)
    }

    if (verifiedFiles.length > 0) {
      handleAddFile(event, verifiedFiles)
    }
  }

  return (
    <>
      <div className='file-upload__dropzone'>
        <i className='bi bi-file-earmark-text fs-2' />
        <h3 className='rfont--h3 fw-bold text-dark my-3'>
          Drag and Drop File Here
        </h3>
        <p className='text-center'>{description}</p>
        <input
          accept={allowedFileFormats.join(',')}
          ref={fileInputRef}
          className='file-upload__input'
          type='file'
          name={inputName}
          onChange={handleFileInputChange}
        />
      </div>

      <div className='file-upload__divider'>
        <div className='file-upload__divider-line' />
        <div className='file-upload__divider-text'>or</div>
        <div className='file-upload__divider-line' />
      </div>

      <Button
        variant='primary'
        size='lg'
        onClick={handleBrowseClick}
        className='d-block mx-auto match-btn-width'>
        Browse
      </Button>
    </>
  )
}

export default FileUpload
