export interface TaskObjV2 {
  id: string
  lifeActionTaskId?: string
  crmId: string
  crmSource?: string
  subject: string
  description?: string
  dueDate: string
  createdDate: string
  completedDate: string
  status: string
  assigneeType: string
  assigneeNames: string
  includeInGuidebook: boolean
  advisorAssignee?: string
  primaryAssignee?: string
  secondaryAssignee?: string
  isClosed?: boolean
  lastModifiedDate?: string
  complete: boolean
}

export interface CrmTaskObject {
  lifeActionTaskId?: string
  crmTaskId: string
  description: string
  status: string
  assigneeName: string
  showInGuidecenter: boolean
}

export interface TaskObjStateV2 {
  [key: string]: TaskObjV2
}

export enum TaskStatusV2 {
  Completed = 'Completed',
  Not_Completed = 'In Progress'
}

export enum TaskSortV2 {
  DueDate = 'due_date',
  Assignee = 'assignee'
}

export interface TasksStateV2 {
  tasks: TaskObjStateV2
  sortBy: TaskSortV2
  filtered: boolean
}
