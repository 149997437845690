import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import { RouteComponentProps, withRouter } from 'react-router'

import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import Dropdown from 'react-bootstrap/Dropdown'
import Button from 'react-bootstrap/Button'
import Offcanvas from 'react-bootstrap/Offcanvas'

import { getUser, getUserPreferences } from '../../actions/user.action'
import { getContacts } from '../../actions/contacts.action'
import { getOfficeTeam } from '../../actions/officeteam.action'
import { getFolders } from '../../actions/documentVault.action'
import { getMarketplaceOffers } from '../../actions/marketplaceOffers.actions'

import ProfilePicture from '../ProfilePicture'
import NotificationBell from './NotificationBell'
import ucLogo from '../../assets/images/logos/United-Capital-Logo-300x100.png'

import { UserObj } from '../../objects/user'
import { ContactsState } from '../../reducers/contacts.reducer'
import { ClientNotificationSubscription } from '../../helpers/queries'
import { marketplaceOfferRequestInterface } from '../../objects/marketplaceOffers'
import {
  getAuthenticatedRoutes,
  routesAccessHelper
} from '../../helpers/authentication'
import { fullscreenPaths } from '../../Routes/RestrictedRoutes'
import { AppDispatch } from '../../store'

export interface HeaderProps {
  contacts: ContactsState
  history: RouteComponentProps['history']
  notifications: any
  subscribeToMore?: any
  user: UserObj
  marketplaceOffers: marketplaceOfferRequestInterface
  dispatch?: AppDispatch
}
// Extend HeaderProps to include router props
type HeaderAndRouterProps = HeaderProps & RouteComponentProps

interface HeaderState {
  currentLocation: string
  accountOptions: any[]
  showDrawer: boolean
}

const notificationsSubcriber = (subscribeToMore) => {
  subscribeToMore &&
    subscribeToMore({
      document: ClientNotificationSubscription,
      variables: null,
      onError: (err: any) => {
        console.error('An error occurred')
      },
      updateQuery: (
        prev: any,
        { subscriptionData }: { subscriptionData: any }
      ) => {
        const {
          id,
          subject,
          content,
          createdDate,
          title
        } = subscriptionData.data.notification

        const newContent = JSON.parse(content)

        const newItem = {
          subject,
          content: newContent,
          createdDate,
          title
        }

        const updatedCache = Object.assign({}, prev, {
          allClientToNotifications: {
            nodes: [
              {
                notificationByNotification: {
                  ...newItem,
                  __typename: 'Notification'
                },
                id,
                read: false,
                readDate: null,
                delivered: false,
                deliveredDate: null,
                __typename: 'ClientToNotification'
              },
              ...prev.allClientToNotifications.nodes
            ],
            __typename: 'ClientToNotificationsConnection'
          }
        })
        return updatedCache
      }
    })
}

export class Header extends React.Component<HeaderAndRouterProps, HeaderState> {
  constructor(props: HeaderAndRouterProps) {
    super(props)
    this.state = {
      currentLocation: props.location?.pathname,
      accountOptions: routesAccessHelper(this.props.user),
      showDrawer: false
    }
  }

  public componentDidMount = () => {
    const { user, dispatch, subscribeToMore } = this.props

    const callApi = async () => {
      try {
        await dispatch(getUser())
        dispatch(getContacts())
        dispatch(getOfficeTeam())
        dispatch(getUserPreferences())
        dispatch(getFolders())
      } catch {}
      dispatch(getMarketplaceOffers(user?.householdId))
    }

    if (!user?.id) {
      callApi()
      notificationsSubcriber(subscribeToMore)
    }

    if (user?.householdId) {
      dispatch(getMarketplaceOffers(user.householdId))
    }
  }

  public componentDidUpdate = (prev: HeaderProps) => {
    const { history, user } = this.props
    const pathCompare =
      prev?.history?.location?.pathname !== this.state.currentLocation
    if (pathCompare) {
      this.setState({
        currentLocation: history?.location?.pathname,
        accountOptions: [{}]
      })
    }
    if (!pathCompare) {
      if (this.state.accountOptions?.length === 1) {
        this.setState({
          accountOptions: routesAccessHelper(user)
        })
      }
    }
  }

  public isNoOptionPaths = (currentPath: string) => {
    return fullscreenPaths.some((path) => currentPath.startsWith(path))
  }

  public getUserContact = (user: UserObj, contacts: ContactsState) => {
    const { primary, secondary } = contacts || {
      primary: null,
      secondary: null
    }

    if (primary?.id === user?.id) {
      return primary
    }
    if (secondary?.id === user?.id) {
      return secondary
    }

    return null
  }

  public getLogo = () => {
    const { user } = this.props
    return {
      path: ucLogo || null,
      alt: user?.institutionLogo ? 'UCFA' : '',
      url: '/'
    }
  }

  public AvatarToggle = React.forwardRef<
    HTMLImageElement,
    { onClick: (e: Event) => void }
  >((avatarProps, ref) => {
    const { user, contacts } = this.props
    const { onClick } = avatarProps
    const userContact = this.getUserContact(user, contacts)

    return (
      <ProfilePicture
        forwardRef={ref}
        firstName={userContact && userContact.firstName}
        lastName={userContact && userContact.lastName}
        photo={user?.photoUrl}
        isPrimary={contacts?.primary?.id === user?.id}
        pictureSize={48}
        onClick={onClick}
        containerStyle={{ cursor: 'pointer' }}
      />
    )
  })

  public getCustomComponents = (accountNavigationActions) => {
    const { notifications, history } = this.props
    const navigate = history.push

    if (this.isNoOptionPaths(history.location.pathname)) {
      const isHonestConversation = history.location.pathname.startsWith(
        '/honest-conversations'
      )
      return isHonestConversation ? null : (
        <Button
          variant='light'
          className='p-2'
          onClick={() =>
            navigate(`/${history.location.pathname.split('/')[1]}`)
          }>
          <i className='header__nav-icon bi bi-x-lg' />
        </Button>
      )
    }

    return (
      <>
        <div className='d-flex ms-auto'>
          <Button
            variant='light'
            className='px-2 py-1'
            onClick={() => navigate('/messages')}>
            <i className='bi bi-envelope header__nav-icon' />
          </Button>
          <NotificationBell notifications={notifications} />
        </div>
        <Navbar.Collapse className='flex-grow-0'>
          <Dropdown title='Dropdown' align='end'>
            <Dropdown.Toggle as={this.AvatarToggle} />
            <Dropdown.Menu className='header__dropdown-menu shadow-sm'>
              {accountNavigationActions.map((action) => (
                <Dropdown.Item
                  key={action.label + 'dropdown'}
                  as={ReactRouterLink}
                  to={action.url}
                  className='header__dropdown-item'>
                  {action.label}
                </Dropdown.Item>
              ))}
              <Dropdown.Item
                className='header__dropdown-item header__dropdown-item--logout'
                onClick={() => navigate('/logout')}>
                Log Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Navbar.Collapse>
        <Button
          variant='light'
          onClick={() => this.setState({ showDrawer: true })}
          className='d-flex d-lg-none px-2 py-1'>
          <i className='header__nav-icon bi bi-list' />
        </Button>
      </>
    )
  }

  public render() {
    const { user, contacts, history, marketplaceOffers } = this.props

    const { accountOptions } = this.state

    const userContact = this.getUserContact(user, contacts)

    const navigate = history.push

    const showNavigationOptions = !this.isNoOptionPaths(
      history.location.pathname
    )

    const logo = this.getLogo()

    const navigationActions = showNavigationOptions
      ? getAuthenticatedRoutes({ user, marketplaceOffers })
      : []
    const accountNavigationActions = showNavigationOptions ? accountOptions : []
    let currentRoute = history?.location?.pathname
    if (currentRoute.startsWith('/holdings/')) {
      currentRoute = '/investments'
    }
    if (currentRoute.startsWith('/goals-detail/')) {
      currentRoute = '/goals'
    }

    return (
      <div id='header' className='header'>
        {user.isAdvisorImpersonation && (
          <div className='header__advisor-impersonation'>
            {`You are logged in as ${(userContact && userContact.firstName) ||
              ''} ${(userContact && userContact.lastName) ||
              ''}. Some features may not be available.`}
            &nbsp;
            <span className='header__logout' onClick={window.close}>
              Log out
            </span>
          </div>
        )}

        <Navbar expand='lg' sticky='top' className='py-0'>
          <Container className='header__nav-container' fluid>
            <div className='header__logo-container'>
              <img
                src={logo.path}
                alt={logo.alt}
                className='header__logo'
                onClick={() => navigate('/')}
              />
            </div>
            <Navbar.Collapse>
              <Nav variant='underline' activeKey={currentRoute}>
                {navigationActions.map((action) => (
                  <Nav.Item key={action.label + 'nav'}>
                    <Nav.Link
                      as={ReactRouterLink}
                      className='header__nav-link'
                      to={action.url}
                      active={currentRoute === action.url}>
                      {action.label}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </Navbar.Collapse>
            {this.getCustomComponents(accountNavigationActions)}
          </Container>
        </Navbar>
        <Offcanvas
          placement='end'
          show={this.state.showDrawer}
          backdrop='static'
          onHide={() => {
            this.setState({ showDrawer: false })
          }}>
          <Offcanvas.Header closeButton className='header__drawer-head' />
          <Offcanvas.Body className='header__drawer-body'>
            <ul className='header__drawer-list'>
              {navigationActions.map((action) => (
                <li
                  key={action.label + 'drawer'}
                  className={`header__drawer-item header__drawer-item--hover ${
                    currentRoute === action.url
                      ? 'header__drawer-item--active'
                      : ''
                  }`}
                  onClick={() => navigate(action.url)}>
                  {action.label}
                </li>
              ))}
              <div className='header__drawer-account-actions list-unstyled'>
                {accountNavigationActions.map((action) => (
                  <li
                    key={action.label + 'drawer'}
                    className={`header__drawer-item header__drawer-item-account`}
                    onClick={() => navigate(action.url)}>
                    {action.label}
                  </li>
                ))}
                <li
                  className='header__drawer-item'
                  onClick={() => navigate('/logout')}>
                  Logout
                </li>
              </div>
            </ul>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    )
  }
}
const mapStateToProps = (
  store: GlobalState,
  { history, notifications, subscribeToMore }: any
) => {
  return {
    user: store.user,
    contacts: store.contacts,
    marketplaceOffers: store.marketplaceOffers,
    history,
    notifications,
    subscribeToMore
  }
}

export default connect(mapStateToProps)(withRouter(Header))
