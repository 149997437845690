import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import ControlledModalWrapper from '../../../components/ControlledModalWrapper'

interface IDeletePhotoModalProps {
  closeModal: () => void
  handleDeletePhoto: () => void
}

const DeletePhotoModal = (props: IDeletePhotoModalProps) => {
  const { closeModal, handleDeletePhoto } = props

  const [isWaiting, setIsWaiting] = useState(false)
  const deletePhoto = async () => {
    setIsWaiting(true)
    await handleDeletePhoto()
    setIsWaiting(false)
  }
  return (
    <>
      <h3 className='rfont--h3 fw-bold text-dark mb-3'>Delete Photo?</h3>
      <p className='rfont--body2 text-dark fw-bold mb-5'>
        Are you sure you want to delete this photo?
      </p>
      <Button
        variant='primary'
        size='lg'
        className='match-btn-width'
        onClick={deletePhoto}
        disabled={isWaiting}>
        Delete
      </Button>
      <Button variant='link' className='match-btn-width' onClick={closeModal}>
        Cancel
      </Button>
    </>
  )
}

export default ControlledModalWrapper(DeletePhotoModal)
