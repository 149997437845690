import React, { useState } from 'react'
import logo from '../../assets/images/gc-logo-blue.svg'
import browserInfo from '../../helpers/browserInfo'
import generateDeeplink, { shouldShowDrawer } from '../../helpers/deeplink'
import Button from 'react-bootstrap/Button'

export default function AppBottomSheet() {
  const [isVisible, setVisibility] = useState(true)
  const { browser } = browserInfo()
  const isShown = shouldShowDrawer()
  const deeplink = generateDeeplink()
  return isShown ? (
    <div className={`bottom-sheet ${isVisible ? 'bottom-sheet--active' : ''}`}>
      <div className='bottom-sheet__overlay'></div>
      <div className='bottom-sheet__content'>
        <div className='bottom-sheet__header'>See GuideCenter in...</div>
        <div className='bottom-sheet__actions'>
          <div className='bottom-sheet__action'>
            <div className='bottom-sheet__image-wrapper'>
              <img src={logo} alt='Logo' className='bottom-sheet__image' />
            </div>
            <span className='bottom-sheet__action-title'>GC Mobile App</span>
            <div className='ms-auto pt-2 bottom-sheet__open-button-wrapper'>
              <Button
                href={deeplink}
                className='pt-2 bottom-sheet__open-button'>
                Open
              </Button>
            </div>
          </div>
          <div className='bottom-sheet__action'>
            <div className='bottom-sheet__image-wrapper'>
              <img className='bottom-sheet__image' src={browser.icon} />
            </div>
            <span className='bottom-sheet__action-title'>{browser.name}</span>
            <div className='ms-auto bottom-sheet__continue-button-wrapper'>
              <Button
                className='bottom-sheet__continue-button'
                onClick={() => setVisibility(false)}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null
}
