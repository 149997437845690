import * as React from 'react'
import { connect } from 'react-redux'
import * as AuthAuctions from '../../actions/auth.action'
import Api from '../../helpers/api'
import { UserObj } from '../../objects/user'
import { GlobalState } from '../../reducers'
import { AuthState } from '../../reducers/auth.reducer'
import { AppDispatch } from '../../store'
import { logout } from '../../helpers'
import { setUser } from '../../analytics/googleTagManager'

interface LogoutProps {
  dispatch?: AppDispatch
  auth: AuthState
  user: UserObj
}
interface LogoutState {
  isLoggedOut: boolean
}

class LogoutPage extends React.Component<LogoutProps, LogoutState> {
  constructor(props) {
    super(props)
    this.state = {
      isLoggedOut: false
    }
    this.revokeToken()
  }
  public revokeToken() {
    this.props.dispatch(AuthAuctions.revokeAuthenticationToken())
  }
  public componentDidMount() {
    Api.destroyAuth()
    logout()
    setUser(null, null, true)
  }
  public render() {
    return null
  }
}

const LogOut = connect(
  (store: GlobalState, dispatch: AppDispatch): LogoutProps => {
    return {
      auth: store.auth,
      user: store.user,
      dispatch
    }
  }
)(LogoutPage)

export default LogOut
