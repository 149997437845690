import React from 'react'

interface NullDocVaultProps {
  headerText: string
  supportText: string
}

const NullDocVault = (props: NullDocVaultProps) => {
  const { headerText, supportText } = props

  return (
    <div className='folder__null--stateBackground'>
      <h3 className='rfont--h3 fw-bold'>{headerText}</h3>
      <div className='folder__null--description'>{supportText}</div>
    </div>
  )
}

export default NullDocVault
