import React from 'react'
import classnames from 'classnames'

export interface DashRowProps {
  children?: any
  className?: string
}

export interface DashRowChildProps {
  fullWidth?: boolean // does the child have no sibling and will span the whole row?
}

const DashRow = ({ children = [], className }: DashRowProps) => {
  const numChildren = React.Children.count(children)
  if (!numChildren) {
    return null
  }

  // pass some additional context to children
  const childProps: DashRowChildProps = {
    fullWidth: numChildren === 1
  }

  return (
    <div
      className={classnames(
        'd-flex',
        'flex-wrap',
        'justify-content-start',
        'dashboard-row',
        className
      )}>
      {React.Children.map(
        children,
        (child) => child && React.cloneElement(child, childProps)
      )}
    </div>
  )
}

export default DashRow
