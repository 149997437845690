import React from 'react'
import { ToastType, ToastIcon, ActionBtnObj } from '../../objects/toasts'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

import { default as BToast } from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'

export interface ToastProps {
  id?: string
  message: string
  icon?: ToastIcon | any
  backgroundColor?: string
  color?: string
  type?: ToastType
  actionBtn?: ActionBtnObj
  removeToastById(id: string): void
}

class Toast extends React.Component<ToastProps> {
  public removeToastById = () => {
    const { removeToastById, id } = this.props
    removeToastById(id)
  }

  public onActionBtnClick = () => {
    const { actionBtn } = this.props
    actionBtn.onClick()
    this.removeToastById()
  }

  public render() {
    const {
      id,
      backgroundColor,
      color,
      type,
      icon,
      message,
      actionBtn
    } = this.props
    const Icon = icon

    // support legacy v3 toasts that don't specify a type
    return type ? (
      <BToast
        id={`toast-${id}`}
        show={true}
        className='toast__container'
        onClose={this.removeToastById}>
        <BToast.Header className='justify-content-end' />
        <BToast.Body className='toast__content'>
          <i className={`bi bi-${icon} fs-5 toast__content-icon--${type}`} />

          <p className='toast__content-text'>{message}</p>
          {actionBtn ? (
            <div className='toast__content-action-btn'>
              <Button variant='primary' onClick={this.onActionBtnClick}>
                {actionBtn.text}
              </Button>
            </div>
          ) : null}
        </BToast.Body>
      </BToast>
    ) : (
      <div
        style={{
          border: '1px solid #A7D500',
          padding: 20,
          backgroundColor: backgroundColor || '#A7D500',
          color: color || 'black',
          width: '100%',
          height: 50,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}>
          {Icon && <Icon />}
          <span style={{ marginLeft: 8 }}>{message}</span>
        </div>
        <CloseIcon
          onClick={this.removeToastById}
          style={{ cursor: 'pointer' }}
        />
      </div>
    )
  }
}

export default Toast
