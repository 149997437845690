import React from 'react'

interface IChartTextProps {
  value?: string
  className?: string
  x?: string
  y?: string
  dy?: string
}

interface IChartLabelProps {
  centerText: IChartTextProps
  bottomText: IChartTextProps
}

const centerProps = {
  x: '50%',
  y: '50%',
  textAnchor: 'middle',
  dominantBaseline: 'middle'
}

const ChartLabel = (props: IChartLabelProps) => {
  const { centerText, bottomText } = props
  return (
    <g>
      <text {...centerProps}>
        {centerText.value && (
          <tspan {...centerProps} {...centerText}>
            {centerText.value}
          </tspan>
        )}
        {bottomText.value && (
          <tspan {...centerProps} {...bottomText}>
            {bottomText.value}
          </tspan>
        )}
      </text>
    </g>
  )
}

export default ChartLabel
