import React from 'react'
import { connect } from 'react-redux'
import { every } from 'lodash-es'
import { GlobalState } from '../../reducers'
import AssetAllocation from './AssetAllocationChart'
import { dollarFormat } from '../../helpers'
import { calculateAccountTotal } from '../../helpers/investments'

type InvestmentHeroProps = ReturnType<typeof mapStateToProps>

const InvestmentHero = (props: InvestmentHeroProps) => {
  const { allocation, accounts, noTargets } = props

  const investmentsTotal = dollarFormat(
    calculateAccountTotal(accounts).total,
    0
  )

  const investmentsTotalStyle = `investment-hero__worth__sum${
    investmentsTotal.length > 13 ? ' investment-hero__worth__sum-long' : ''
  }`

  return (
    <div>
      <div className='investment-hero__container' />
      <div id='investments-hero' className='position-relative'>
        <div className='investment-hero__worth'>
          <div
            className='investment-hero__worth-col investment-hero__worth-col--ordered'
            style={{ flex: 1 }}>
            <div>
              <div className='investment-hero__worth-title'>Investments</div>
              <div className='investment-hero__worth-subtitle'>
                {accounts.length
                  ? 'Here is a breakdown of all your investment accounts'
                  : 'Your advisor needs to add accounts to this section.'}
              </div>
            </div>
            <div className='investment-hero__worth-balance'>
              <div className='investment-hero__worth__label investment-hero__worth__label-light investment-hero__worth__label-mobile'>
                Total Balance
              </div>
              <div className={investmentsTotalStyle}>
                {investmentsTotal !== '$0' ? investmentsTotal : '-'}
              </div>
            </div>
          </div>
          <div
            className='investment-hero__worth-col investment-hero__worth-charts'
            style={{ flex: 2 }}>
            <AssetAllocation
              allocation={allocation}
              accounts={accounts}
              noTargets={noTargets}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { allocation } = store.investments

  return {
    accounts: store.clientAccounts.investments,
    allocation,
    noTargets: every(allocation, (val) => !val)
  }
}

export default connect(mapStateToProps)(InvestmentHero)
