import { isEmpty } from 'lodash-es'
import { ContactObj } from '../objects/contact'
import { GlobalState } from '../reducers'
import { createDeepEqualSelector } from './utilz'
import {
  getRankedClientCards,
  rankedAnyClientCards
} from '../helpers/priorities'
import { ClientCardsData } from '../objects/honestConversation'

const getHcCards = (store: GlobalState) =>
  store.honestConversations[store.honestConversations.hcExerciseId]
const getUser = (store: GlobalState) => store.user
const getContacts = (store: GlobalState) => store.contacts
const getExerciseId = (store: GlobalState) =>
  store.honestConversations.hcExerciseId
export const getHonestConversation = (store: GlobalState) =>
  store.honestConversations[store.honestConversations.hcExerciseId]
const getUserId = (store: GlobalState) => store.user.id
const getAccessToken = (store: GlobalState) => store.auth.accessToken

export const getContact = createDeepEqualSelector(
  getContacts,
  getUserId,
  (contacts, userId) => {
    return {
      contact: Object.values(contacts).find((x) => x?.id === userId),
      contactType: Object.values(contacts).findIndex((x) => x?.id === userId)
    }
  }
)

export const createRedirectPath = createDeepEqualSelector(
  [getHcCards, getUser, getExerciseId],
  (hcCards, user, hcExerciseId) => {
    const fifteenCardValues =
      hcCards?.clientCards && hcCards.clientCards[user && user.id]
        ? hcCards.clientCards[user.id].map((e) => e.ranking)
        : null
    const validFifteenCards =
      fifteenCardValues && fifteenCardValues.filter((rank) => rank).length
    const fiveCardsValues =
      hcCards &&
      Object.keys(hcCards.householdCards).map(
        (e) => hcCards.householdCards[e].ranking
      )
    const validFiveCards =
      fiveCardsValues && fiveCardsValues.filter((rank) => rank).length

    let url: string = ''
    if (!validFiveCards && !validFifteenCards) {
      url = ''
    } else if (validFifteenCards > 0 && validFifteenCards < 15) {
      url = '/pick/fifteen'
    } else if (validFifteenCards === 15 && validFiveCards < 5) {
      url = '/pick/five'
    } else {
      url = '/congratulations'
    }
    return `${window._env_.REACT_APP_HC_URL_V2}${url}?exerciseId=${hcExerciseId}`
  }
)

export const determineEmailStatus = createDeepEqualSelector(
  [getContacts, getUser, getHonestConversation],
  (contacts, user, honestConversation) => {
    if (
      contacts?.primary?.id === user?.id &&
      honestConversation?.emailSentPrimary
    ) {
      return true
    } else if (
      contacts?.secondary?.id === user?.id &&
      honestConversation?.emailSentSecondary
    ) {
      return true
    } else return false
  }
)

const defaultHcData = () => ({
  user: {
    cards: [],
    rankedAnyCards: false,
    rankedTopCards: {},
    emailSent: null,
    redirectPath: ''
  } as ClientCardsData,
  partner: {
    cards: [],
    rankedAnyCards: false,
    rankedTopCards: {},
    emailSent: null,
    firstName: null
  } as ClientCardsData,
  isSingleHH: true,
  rankedAnyCards: false,
  rankedTopCards: false,
  hasHouseholdCards: false,
  loading: true,
  exerciseId: null
})

export const getClientCards = createDeepEqualSelector(
  [
    getContact,
    getContacts,
    getExerciseId,
    getHonestConversation,
    createRedirectPath,
    getAccessToken
  ],
  (
    { contact: user },
    contacts,
    exerciseId,
    exercise,
    redirectPath,
    accessToken
  ) => {
    const data = defaultHcData()
    if (!user) {
      // we're still fetching data
      return data
    }

    data.loading = false
    // seen edge case where HH exerciseId is not found
    // in HC exercises state, so exercise is null
    if (!exercise || exerciseId !== exercise.id) {
      return data
    }

    const partner: ContactObj = Object.values(contacts).find(
      (x) => x?.id !== user.id
    )

    const userIsPrimary = contacts?.primary?.id === user.id

    const userCards = exercise?.clientCards?.[user.id] || []
    data.user = {
      cards: userCards,
      rankedAnyCards: rankedAnyClientCards(userCards),
      rankedTopCards: getRankedClientCards(userCards),
      emailSent: userIsPrimary
        ? exercise?.emailSentPrimary
        : exercise?.emailSentSecondary,
      redirectPath,
      accessToken
    }

    if (partner?.id) {
      const partnerCards = exercise?.clientCards?.[partner.id] || []
      data.isSingleHH = false
      data.partner = {
        cards: partnerCards,
        rankedAnyCards: rankedAnyClientCards(partnerCards),
        rankedTopCards: getRankedClientCards(partnerCards),
        emailSent: userIsPrimary
          ? exercise?.emailSentSecondary
          : exercise?.emailSentPrimary,
        firstName: partner.firstName
      }
    }

    data.rankedAnyCards =
      data.user.rankedAnyCards || data.partner.rankedAnyCards
    data.rankedTopCards =
      !isEmpty(data.user.rankedTopCards) &&
      (data.isSingleHH || !isEmpty(data.partner.rankedTopCards))

    data.hasHouseholdCards = !isEmpty(exercise.householdCards)
    data.exerciseId = exerciseId

    return data
  }
)

export const getPartnerPriorityCards = createDeepEqualSelector(
  [getContacts, getUserId, getHonestConversation],
  (contacts, userId, honestConversation) => {
    const partner: ContactObj = Object.values(contacts).find(
      (x) => x?.id !== userId
    )
    if (!partner || !honestConversation) {
      return undefined
    }

    const clientCards = honestConversation?.clientCards?.[partner.id]
    const rankedWithinAll =
      (clientCards &&
        Object.keys(clientCards)
          .map((cardId) => {
            return (
              clientCards?.[cardId].rankedWithin.toLowerCase() === 'all' &&
              clientCards?.[cardId]
            )
          })
          .filter((item) => item)) ||
      []

    const sorted = rankedWithinAll.sort(
      (cardA, cardB) => cardA.ranking - cardB.ranking
    )

    return sorted
  }
)

export const getUserPriorityCards = createDeepEqualSelector(
  [getUserId, getHonestConversation],
  (userId, honestConversation) => {
    if (!honestConversation) {
      return undefined
    }
    const clientCards = honestConversation?.clientCards?.[userId]
    const rankedWithinAll =
      (clientCards &&
        Object.keys(clientCards)
          .map((cardId) => {
            return (
              clientCards?.[cardId].rankedWithin.toLowerCase() === 'all' &&
              clientCards?.[cardId]
            )
          })
          .filter((item) => item)) ||
      []

    return rankedWithinAll
  }
)

export const getUserScoreCards = createDeepEqualSelector(
  [getUserId, getHonestConversation],
  (userId, honestConversation) => {
    if (!honestConversation) {
      return undefined
    }
    const {
      activeScoringSessionSecondary,
      clientScoringSessions
    } = honestConversation

    const scoreCards =
      clientScoringSessions[userId][activeScoringSessionSecondary]

    return scoreCards
  }
)
