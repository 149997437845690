import * as React from 'react'
import avatar from '../../assets/images/avatar.png'
import avatarSecondary from '../../assets/images/avatar-gray.png'

export interface ProfilePictureProps {
  firstName: string
  lastName: string
  photo: string
  isPrimary: boolean
  isCarousel?: boolean
  pictureSize?: number
  containerStyle?: React.CSSProperties
  initialsStyle?: React.CSSProperties
  forwardRef?: any
  onClick?: (e: any) => void
}

export class ProfilePicture extends React.Component<ProfilePictureProps> {
  public profilePicture = () => {
    return (
      <img
        className='profile-picture__picture'
        src={this.props.photo}
        alt='profile picture'
      />
    )
  }

  public initials = () => {
    const {
      firstName,
      lastName,
      isPrimary,
      isCarousel,
      initialsStyle
    } = this.props
    const firstInitial = firstName ? firstName[0].toUpperCase() : ''
    const lastInitial = lastName ? lastName[0].toUpperCase() : ''

    return (
      <div
        className={
          isCarousel
            ? 'profile-picture__initials--carousel'
            : isPrimary
            ? 'profile-picture__initials--primary'
            : 'profile-picture__initials--secondary'
        }
        style={initialsStyle}>
        {firstInitial + lastInitial}
      </div>
    )
  }

  public avatarPicture = () => {
    const { isPrimary } = this.props
    return (
      <img src={isPrimary ? avatar : avatarSecondary} alt='avatar picture' />
    )
  }

  public render() {
    const {
      isPrimary,
      isCarousel,
      pictureSize,
      photo,
      firstName,
      lastName,
      containerStyle,
      forwardRef,
      onClick
    } = this.props
    const textSize = pictureSize <= 32 ? 16 : 20
    const letters = /^[A-Za-z]/
    const hasInitials =
      this.props && (firstName?.match(letters) || lastName?.match(letters))
        ? true
        : false

    return (
      <div
        style={{
          minWidth: pictureSize,
          minHeight: pictureSize,
          maxWidth: pictureSize,
          maxHeight: pictureSize,
          fontSize: textSize,
          ...containerStyle
        }}
        className={`profile-picture__w ${
          isCarousel
            ? `profile-picture__w--carousel`
            : isPrimary
            ? `profile-picture__w--primary`
            : `profile-picture__w--secondary`
        }`}
        ref={forwardRef}
        onClick={onClick}>
        {photo
          ? this.profilePicture()
          : hasInitials
          ? this.initials()
          : this.avatarPicture()}
      </div>
    )
  }
}

export default ProfilePicture
