import React from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { history } from '../../store'

export const InsuranceCard = () => {
  const goToInsurance = () => {
    history.push('/insurance')
  }
  return (
    <Card className='my-4 p-4 p-md-x2 rounded-0 border-0 networth-account-card__light'>
      <Row className='d-flex flex-column flex-sm-row justify-content-normal'>
        <Col xs={12} sm={8} md={9} className='mb-4 pe-2 mb-sm-0'>
          <h3 className='rfont--h3 fw-bold'>Insurance and Annuities</h3>
          <p>Click to view insurance and annuity information.</p>
        </Col>
        <Col xs={12} sm={4} md={3} className='networth-account-card__ins-btn'>
          <Button
            className='font-color--dark w-100 d-flex justify-content-around align-items-center'
            variant='outline-primary'
            onClick={goToInsurance}>
            View Insurance
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
