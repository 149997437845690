import React, { CSSProperties } from 'react'
import { NavLink } from 'react-router-dom'
import { GlobalButton } from '../Buttons'

interface MainTileProps {
  title?: string
  plusLink?: boolean
  mmLink?: string
  extra?: any
  middleExtra?: JSX.Element
  helpText?: string
  page?: string
  class?: string
  headerLink?: string
  style?: CSSProperties
}

export class MainTile extends React.Component<MainTileProps, {}> {
  constructor(props) {
    super(props)
  }
  public renderHeader() {
    return (
      <div className='legacy-tile-header legacy-link-tile-header '>
        <div className='legacy-tile-header-w'>
          <span className='legacy-tile-title'>
            <span className='legacy-tile-title-text'>{this.props.title}</span>
          </span>
          {this.props.helpText && (
            <div title={this.props.helpText}>
              <span className='legacy-tooltip-icon'>i</span>
            </div>
          )}
          {this.props.middleExtra && this.props.middleExtra}
          {this.props.plusLink === true && (
            <GlobalButton link='/investments' type='plus-button' />
          )}
          {this.props.extra && this.props.extra}
        </div>
      </div>
    )
  }
  public render() {
    let mainTileText: any
    if (this.props.title) {
      mainTileText = (
        <div className='legacy-mainTile'>
          {this.props.headerLink ? (
            <NavLink to={this.props.headerLink}>{this.renderHeader()}</NavLink>
          ) : (
            this.renderHeader()
          )}
          <div className='legacy-mainTile__body'>{this.props.children}</div>
        </div>
      )
    } else {
      mainTileText = (
        <div className='legacy-mainTile'>
          <div className='legacy-mainTile__body'>{this.props.children}</div>
        </div>
      )
    }
    return (
      <div className='legacy-tile-w' style={this.props.style}>
        {mainTileText}
      </div>
    )
  }
}
