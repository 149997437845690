import React, { useState } from 'react'
import { GlobalState } from '../../reducers'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'
import { ContactsState } from '../../reducers/contacts.reducer'
import NotificationBellDropdown from './NotificationBellDropdown'
import { Query } from '@apollo/client/react/components'
import { GetClientNotificationSettingsQuery } from '../../helpers/queries'

import { AllSetting } from '../../objects/settings/clientAdvisor'
import classnames from 'classnames'
import {
  enabledNotifications,
  extendedNotificationsDisplayables
} from '../../helpers/notifications'
import { ToastState } from '../../reducers/toasts.reducer'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'

interface HeaderNotificationProps {
  subjects: AllSetting[]
  contacts: ContactsState
  toastState: ToastState
  notifications: any
  members: any
  userId: string
}
/* onClickOutside hoc doesn't work well with arrow style functions */
/* eslint-disable-next-line */
function HeaderNotification({
  contacts,
  notifications,
  members,
  subjects,
  userId
}: HeaderNotificationProps): JSX.Element {
  const [showDropdown, toggleDropdown] = useState(false)

  // handler for onClickOutsideHoc
  HeaderNotification.prototype.handleClickOutside = () => toggleDropdown(false)
  let unreadCount = 0
  // all notifications are returned as an array ordered by createdDate
  const notificationsNodes = notifications?.allClientToNotifications?.nodes
  const enabledNotificationsList = enabledNotifications(
    subjects,
    notificationsNodes
  )
  const notificationDisplayables = extendedNotificationsDisplayables(
    contacts,
    enabledNotificationsList
  )
  notificationDisplayables.forEach((notificationBatch) => {
    if (!notificationBatch.read) unreadCount++
  })

  /**
   * setTimeout avoids race condition between redux store updates, navigation and local state update
   */
  const handleCloseAfterNav = () => {
    setTimeout(() => toggleDropdown(false), 500)
  }

  return (
    <div className='position-relative'>
      <Button
        variant='light'
        className='px-2 py-1 ms-2'
        onClick={() => toggleDropdown(true)}>
        <i className='header__notification-icon bi bi-bell' />
      </Button>
      {Boolean(unreadCount) && (
        <Badge
          pill
          bg='success'
          className={classnames('header__notification-badge', {
            'header__notification-badge--high-count': unreadCount >= 100
          })}>
          {unreadCount}
        </Badge>
      )}

      {showDropdown && (
        <div className='header__notification-dropdown'>
          <NotificationBellDropdown
            members={members}
            contacts={contacts}
            notifications={notificationDisplayables}
            onNotificationClick={handleCloseAfterNav}
            userId={userId}
          />
        </div>
      )}
    </div>
  )
}

const ExtendedHeaderNotification = onClickOutside(HeaderNotification, {
  handleClickOutside: () => HeaderNotification.prototype.handleClickOutside
})

const HeaderNotificationWithQuery = (
  props: Omit<HeaderNotificationProps, 'subjects'> & {
    userId: string
  }
) => {
  const { userId, ...otherProps } = props
  if (!userId) {
    return null
  }
  return (
    <Query
      query={GetClientNotificationSettingsQuery}
      fetchPolicy='network-only'
      variables={{ clientId: props.userId }}>
      {({ data }: { data: any }) => {
        const { desktopSubject = [] } = data?.allClients?.nodes?.[0] || {
          desktopSubject: []
        }
        return (
          <ExtendedHeaderNotification
            subjects={desktopSubject}
            userId={userId}
            {...otherProps}
          />
        )
      }}
    </Query>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    contacts: store.contacts,
    userId: store.user.id,
    members: store.officeTeam ? store.officeTeam.members : [],
    toastState: store.toasts
  }
}

export default connect(mapStateToProps)(HeaderNotificationWithQuery)
