import React from 'react'
import { isObjEmpty } from '../../../helpers'
import { findCardScore } from '../../../helpers/priorities'
import DashPrioritiesRow from './DashPrioritiesRow'
import { ContactObj } from '../../../objects/contact'
import {
  HonestConversationObj,
  HouseholdCardObjInterface
} from '../../../objects/honestConversation'

export interface DashPrioritiesTableProps {
  honestConversation: HonestConversationObj
  contactType: number
  contact: ContactObj
}

const sortHouseholdCards = (cards: HouseholdCardObjInterface) => {
  return Object.keys(cards)
    .sort((a, b) => cards[a].ranking - cards[b].ranking)
    .map((key) => cards[key])
}

const DashPrioritiesTable = (props: DashPrioritiesTableProps): JSX.Element => {
  const { honestConversation, contact, contactType } = props
  const renderHouseholdCards = () => {
    const sortedHouseholdCards = sortHouseholdCards(
      honestConversation.householdCards
    )
    const scoringSession =
      contactType === 0
        ? honestConversation.activeScoringSessionPrimary
        : contactType === 1
        ? honestConversation.activeScoringSessionSecondary
        : null
    return (
      sortedHouseholdCards &&
      !isObjEmpty(sortedHouseholdCards) &&
      Object.keys(sortedHouseholdCards).map((card) => {
        const score = findCardScore(
          contact,
          scoringSession,
          honestConversation.clientScoringSessions,
          sortedHouseholdCards[card]?.id
        )
        return card && sortedHouseholdCards[card] ? (
          <DashPrioritiesRow
            key={sortedHouseholdCards[card]?.id}
            title={sortedHouseholdCards[card]?.card?.title}
            score={score}
          />
        ) : null
      })
    )
  }
  const combinedCards = renderHouseholdCards()
  return <>{combinedCards}</>
}

export default DashPrioritiesTable
