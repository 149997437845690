import React from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import YodleeIframe4 from './YodleeIframe4'
import YodleeIframe from './YodleeIframe'

interface StateProps {
  fastlinkUrl: string
}

interface OwnProps {
  onClose: (data: any) => void
  customizedProps?: any
}

// would be better to use functional component with `useEffects`
// but react-redux version predates `useSelector`
class Fastlink extends React.Component<StateProps & OwnProps> {
  public render() {
    const { fastlinkUrl, ...rest } = this.props
    let Fastlink: any = YodleeIframe
    // Fastlink 4 is enabled by updating the YODLEE_FASTLINK_URL env in FLCA
    if (fastlinkUrl?.endsWith('/fastlink/')) {
      Fastlink = YodleeIframe4
    }
    return <Fastlink {...rest} />
  }
}

export default connect<StateProps, any, OwnProps, GlobalState>(
  (store, ownProps) => {
    return {
      fastlinkUrl: store.yodlee.fastlinkUrl,
      ...ownProps
    }
  }
)(Fastlink)
