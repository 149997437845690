import * as actions from '../actions/tasks.action'
import {
  TaskObjV2,
  TaskObjStateV2,
  TasksStateV2,
  TaskSortV2,
  CrmTaskObject,
  TaskStatusV2
} from '../objects/taskV2'

export const initState: TasksStateV2 = {
  tasks: null,
  sortBy: TaskSortV2.DueDate,
  filtered: false
}

const mapNativeTask = (task: TaskObjV2): TaskObjV2 => {
  return {
    id: task.id,
    crmId: task.crmId,
    status: task.status,
    createdDate: task.createdDate,
    completedDate: task.completedDate,
    dueDate: task.dueDate,
    subject: task.subject,
    assigneeType: task.assigneeType,
    assigneeNames: task.assigneeNames,
    includeInGuidebook: task.includeInGuidebook,
    advisorAssignee: task.advisorAssignee,
    primaryAssignee: task.primaryAssignee,
    secondaryAssignee: task.secondaryAssignee,
    isClosed: task.isClosed || task.status === TaskStatusV2.Completed,
    lastModifiedDate: task.lastModifiedDate,
    complete: task.status === TaskStatusV2.Completed
  }
}

const mapCrmTask = (task: CrmTaskObject): TaskObjV2 => {
  return {
    id: task.crmTaskId,
    crmId: task.crmTaskId,
    status: task.status,
    createdDate: null,
    completedDate: null,
    dueDate: null,
    subject: task.description,
    // all CRM tasks should have assigneeType user
    assigneeType: 'user',
    assigneeNames: task.assigneeName,
    includeInGuidebook: task.showInGuidecenter,
    advisorAssignee: null,
    primaryAssignee: null,
    secondaryAssignee: null,
    isClosed: task.status === TaskStatusV2.Completed,
    lastModifiedDate: null,
    complete: task.status === TaskStatusV2.Completed
  }
}

const mapTasks = (tasks: any[], isNative: boolean): TaskObjStateV2 => {
  const TasksObj = {}
  tasks.sort((a, b) => Date.parse(a.dueDate) - Date.parse(b.dueDate))
  tasks.forEach((task) => {
    if (isNative && task.includeInGuidebook === true) {
      TasksObj[task.id] = task && task.id && mapNativeTask(task)
    } else if (task.showInGuidecenter === true) {
      TasksObj[task.crmTaskId] = task && task.crmTaskId && mapCrmTask(task)
    }
  })
  return TasksObj
}

const TasksV2 = (state = initState, action): TasksStateV2 => {
  switch (action.type) {
    case `${actions.SET_TASKS_V2}`:
      return {
        ...state,
        tasks: {
          ...mapTasks(action.payload.nativeTasks, true),
          ...mapTasks(action.payload.crmTasks, false)
        }
      }
    case `${actions.UPDATE_TASK_STATUS_V2}_FULFILLED`:
      if (action.payload?.id) {
        const tasks = {
          ...state.tasks,
          [action.payload.id]: mapNativeTask(action.payload)
        }
        return { ...state, tasks }
      }
      return state
    case `${actions.SORT_TASKS_BY_V2}`:
      return { ...state, sortBy: action.payload.sortBy }
    case actions.FILTER_TASKS_V2:
      return { ...state, filtered: action.payload }
    case actions.RESET_SORT_FILTER_V2:
      return { ...state, filtered: false, sortBy: TaskSortV2.DueDate }
    default:
      return state
  }
}

export default TasksV2
