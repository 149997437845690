import React, { Fragment, useEffect } from 'react'

import { connect, ConnectedProps } from 'react-redux'
import { GlobalState } from '../../reducers'

import NetWorthHero from './NetWorthHero'
import { AddAccountCard } from './AddAccountCard'
import { InsuranceCard } from './InsuranceCard'
import AccountsTable from './AccountsTable'
import AccountsNull from './NetworthNull'
import ErrorAccountSection from './ErrorAccountSection'

import {
  categorizedAccounts,
  pendingAccounts,
  renderAccountGroup
} from '../../selectors/networth'

import * as actions from '../../actions/clientAccounts.action'
import {
  getFastlinkUrl,
  getYodleeAccounts,
  getYodleeParams
} from '../../actions/yodlee.action'
import { addSuccessToast, addErrorToast } from '../../actions/toasts.action'
import { getInstitution } from '../../actions/user.action'
import { getHousehold } from '../../actions/household.action'

import YodleeEditModal from './Edit/yodleeEditModal'

import DeleteAccountModal from './Delete'
import { useModalReducer, ModalActions } from '../../helpers/netWorth'
import { Link } from 'react-router-dom'
import { history } from '../../store'

export type NetWorthProps = ConnectedProps<typeof connector>

export const NetWorth = (props: NetWorthProps) => {
  const { modalState, handleModal } = useModalReducer()

  const {
    cashAccounts,
    investmentAccounts,
    otherAccounts,
    liabilityAccounts,
    unclassifiedAccounts,
    errorAccounts,
    user,
    yodlee,
    locationPath,
    removeAccount,
    addSuccessToast,
    addErrorToast,
    getInvestmentAccounts,
    getAccounts,
    getYodleeAccounts,
    getHousehold,
    getInstitution,
    getFastlinkUrl,
    getYodleeParams
  } = props

  const toggleModal = (
    account?: Object,
    action?: 'add' | 'edit' | 'delete' | 'yodleeEdit'
  ) => {
    if (action) {
      const modalAction = `show_${action}_account` as ModalActions
      handleModal({ type: modalAction, account })
    } else {
      handleModal({ type: 'close_modal', account })
    }
  }

  const isNullState =
    !cashAccounts?.accounts.length &&
    !investmentAccounts?.accounts.length &&
    !otherAccounts?.accounts.length &&
    !liabilityAccounts?.accounts.length &&
    !unclassifiedAccounts.accounts.length

  const editAccount = (account) => {
    history.push(`/net-worth/account/edit/${account.id}`)
  }

  const deleteAccount = async () => {
    // Close modal right away so can't click on "delete" a 2nd time
    toggleModal()

    const { id } = modalState.showDeleteAccount.account
    try {
      await removeAccount(id)
      // refresh list
      getInvestmentAccounts()
      getAccounts()
      addSuccessToast({
        message: 'Investment account deleted'
      })
    } catch (error) {
      addErrorToast({
        message: 'Unable to delete investment account.\nPlease try again'
      })
    }
  }

  const hasYodlee = user?.aggregationProvider === 'yodlee'
  const householdFetched = user?.householdFetched
  const showYodleeEditModal = Boolean(
    modalState?.showYodleeEditAccount?.status && hasYodlee
  )

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [householdFetched])

  useEffect(() => {
    getInstitution()
    getAccounts()
    getInvestmentAccounts()
  }, [])

  useEffect(() => {
    if (hasYodlee) {
      getYodleeAccounts()
      getFastlinkUrl()
      getYodleeParams()
    }
  }, [hasYodlee])

  if (modalState.showEditAccount.status) {
    editAccount(modalState.showEditAccount.account)
    return null
  }

  const disableAddAccountButton =
    user?.aggregationProvider !== 'yodlee' && user?.disableManualAccount

  const allAccounts = [
    ...cashAccounts.accounts,
    ...investmentAccounts.accounts,
    ...otherAccounts.accounts,
    ...liabilityAccounts.accounts,
    ...unclassifiedAccounts.accounts
  ]
  return (
    <div>
      <NetWorthHero />
      <div className='networth-account'>
        {isNullState ? (
          !disableAddAccountButton && <AccountsNull />
        ) : (
          <Fragment>
            {errorAccounts && errorAccounts.length !== 0 && (
              <ErrorAccountSection
                allAccounts={allAccounts}
                errorAccounts={errorAccounts}
                toggleModal={toggleModal}
                yodlee={yodlee}
              />
            )}
            {!disableAddAccountButton && <AddAccountCard />}
            <AccountsTable accounts={cashAccounts} toggleModal={toggleModal} />
            <AccountsTable
              accounts={investmentAccounts}
              toggleModal={toggleModal}
            />
            <AccountsTable accounts={otherAccounts} toggleModal={toggleModal} />
            <AccountsTable
              accounts={liabilityAccounts}
              toggleModal={toggleModal}
            />
            <AccountsTable
              accounts={unclassifiedAccounts}
              toggleModal={toggleModal}
            />
          </Fragment>
        )}
        <InsuranceCard />
        <div className='networth-footer'>
          <p className='disclosures__text--footer'>
            The information contained in this material is for general
            informational purposes only and does not constitute an offer,
            solicitation, invitation or recommendation to buy or sell any
            financial instruments or to provide any investment advice or service
            of any kind. PLEASE REFER TO YOUR ACCOUNT CUSTODIAN CONFIRMATIONS
            AND STATEMENTS FOR THE MOST ACCURATE INFORMATION ABOUT YOUR
            ACCOUNT(S).
          </p>
          <div className='networth-footer__link'>
            <Link
              to={{
                pathname: '/disclosures',
                state: { section: 'account', prevPath: locationPath }
              }}>
              Click for more important information about this page
            </Link>
          </div>
        </div>
      </div>

      <YodleeEditModal
        closeModal={toggleModal}
        account={modalState.showYodleeEditAccount.account}
        fastlinkUrl={yodlee.fastlinkUrl}
        show={showYodleeEditModal}
      />

      <DeleteAccountModal
        deleteAccount={deleteAccount}
        closeModal={toggleModal}
        show={modalState.showDeleteAccount.status}
      />
    </div>
  )
}

const mapDispatchToProps = {
  getAccounts: actions.getClientAccounts,
  removeAccount: actions.removeAccount,
  addSuccessToast,
  addErrorToast,
  getInvestmentAccounts: actions.getInvestmentAccounts,
  getYodleeAccounts,
  getHousehold,
  getInstitution,
  getFastlinkUrl,
  getYodleeParams
}

const mapStateToProps = (store: GlobalState) => {
  const { user, yodlee } = store
  const preferences = store.clientAccounts.preferences

  const shortTermAccounts = categorizedAccounts(store, 'Short-Term Liabilities')
  const longTermAccounts = categorizedAccounts(store, 'Long-Term Liabilities')

  const cash = categorizedAccounts(store, 'Cash & Equivalents')
  const investment = categorizedAccounts(store, 'Investments')
  const others = categorizedAccounts(store, 'Other')

  const liabilities = {
    accounts: shortTermAccounts.accounts.concat(longTermAccounts.accounts),
    category: 'Liabilities',
    sum: shortTermAccounts.sum + longTermAccounts.sum
  }
  const errorAccounts = store.clientAccounts.errorAccounts

  const unclassifiedAccounts = pendingAccounts(store)
  const invalidPreferences = preferences === null || preferences === undefined

  return {
    user,
    yodlee,
    cashAccounts: {
      accounts: invalidPreferences
        ? cash.accounts
        : renderAccountGroup(
            cash.accounts,
            preferences.cashGroupBy,
            preferences.cashSortBy,
            preferences.cashSortingOrder
          ),
      category: 'Cash & Cash Equivalents',
      sum: cash.sum
    },
    investmentAccounts: {
      accounts: invalidPreferences
        ? investment.accounts
        : renderAccountGroup(
            investment.accounts,
            preferences.investmentsGroupBy,
            preferences.investmentsSortBy,
            preferences.investmentsSortingOrder
          ),
      category: investment.category,
      sum: investment.sum
    },
    otherAccounts: {
      accounts: invalidPreferences
        ? others.accounts
        : renderAccountGroup(
            others.accounts,
            preferences.otherAssetsGroupBy,
            preferences.otherAssetsSortBy,
            preferences.otherAssetsSortingOrder
          ),
      category: others.category,
      sum: others.sum
    },
    liabilityAccounts: {
      accounts: invalidPreferences
        ? liabilities.accounts
        : renderAccountGroup(
            liabilities.accounts,
            preferences.liabilitiesGroupBy,
            preferences.liabilitiesSortBy,
            preferences.liabilitiesSortingOrder
          ),
      category: liabilities.category,
      sum: liabilities.sum
    },
    unclassifiedAccounts,
    errorAccounts,
    locationPath: store?.router?.location?.pathname
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default connector(NetWorth)
