import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import {
  createGoalRequest,
  getGoalRequests
} from '../../../actions/goalRequests.action'
import { GoalRequestObj, FinancialGoalObj } from '../../../objects/plan'
import { sanitizeCurrency } from '../../../helpers'
import { addErrorToast, addSuccessToast } from '../../../actions/toasts.action'

interface PassedDeleteInputModalProps {
  goal: FinancialGoalObj
  closeModal(): void
  onDeleteGoal(): void
  show?: boolean
}

export type DeleteInputModalProps = ConnectedProps<typeof connector> &
  PassedDeleteInputModalProps

export const DeleteInputModal = (props: DeleteInputModalProps) => {
  const {
    createGoalRequest,
    closeModal,
    addSuccessToast,
    addErrorToast,
    goal,
    show
  } = props

  const handleDeleteGoal = async (goal: FinancialGoalObj) => {
    const requestBody: GoalRequestObj = {
      comment: '',
      existingId: goal.id,
      requestType: 'DELETE',
      status: 'PENDING',
      proposedName: goal?.name,
      proposedGoalType: goal?.type,
      proposedAmount: sanitizeCurrency(String(goal?.amount)),
      proposedAmountType: goal?.amountType,
      proposedAnnualIncrease: sanitizeCurrency(String(goal?.annualIncrease)),
      proposedGoalOwnerId: goal?.owner,
      proposedStartOfGoal: goal?.startDate,
      proposedEndOfGoal: goal?.endDate
    }

    try {
      await createGoalRequest(requestBody)
      closeModal()
      props.onDeleteGoal()
      addSuccessToast({
        message: 'Plan Input change request completed.'
      })
    } catch (error) {
      addErrorToast({
        message: 'Unable to change plan input.\nPlease try again'
      })
    }
  }

  return (
    <Modal
      show={show}
      onHide={closeModal}
      backdrop='static'
      contentClassName='p-x2'
      className='delete-input-modal'>
      <Modal.Header className='p-0' closeButton>
        <div className='delete-input-modal__title'>Delete Input</div>
      </Modal.Header>

      <h4 className='delete-input-modal__subheading'>
        Are you sure you want to delete this input?
      </h4>
      <div className='delete-input-modal__buttons'>
        <Button
          id='delete-modal-save'
          type='button'
          onClick={() => handleDeleteGoal(goal)}
          className='delete-input-modal__button-delete'>
          Delete
        </Button>
        <Button
          id='delete-modal-cancel'
          onClick={closeModal}
          variant='link'
          className='delete-input-modal__button-cancel'>
          Cancel
        </Button>
      </div>
    </Modal>
  )
}

const mapDispatchToProps = {
  createGoalRequest,
  getGoalRequests,
  addSuccessToast,
  addErrorToast
}

const connector = connect(null, mapDispatchToProps)

export default connector(DeleteInputModal)
