import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { ModalActions, useModalReducer } from '../../helpers/docvault'

import { getDocVaultFileRow } from './VaultTableRows'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { GlobalState } from '../../reducers'
import * as actions from '../../actions/documentVault.action'
import {
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
} from '../../actions/toasts.action'
import { history } from '../../store'
import { archivedFilesSelector, getFolders } from '../../selectors/docvault'
import NullDocVault from './NullView'
import RestoreModal from './RestoreModal'
import HardDeleteModal from './HardDeleteModal'
import EmptyTrash from './EmptyTrash'
import { mapFile } from '../../reducers/documentVault.reducer'
import { useWindowSize } from '../../hooks'

const TrashedFiles = (props): JSX.Element => {
  const { modalState, handleModal } = useModalReducer()
  const [currentId, setCurrentId] = useState('')
  const toggleModal = (
    id: string = '',
    action?:
      | 'edit'
      | 'delete'
      | 'hard_delete'
      | 'upload'
      | 'move'
      | 'restore'
      | 'empty',
    type?: 'file' | 'folder' | 'error' | 'archived'
  ) => {
    setCurrentId(id)
    if (action && type) {
      const modalAction = `show_${action}_${type}` as ModalActions
      handleModal(modalAction)
    } else {
      handleModal('close_modal')
    }
  }

  const hardDeleteFunc = async (id: string) => {
    const {
      hardDeleteFile,
      getFolder,
      addSuccessToast,
      networkErrorOrMsg
    } = props
    const currentFile = docVaultItems.filter((f) => f.id === id)[0]
    if (id) {
      const folderId = currentFile.folderId
      try {
        await hardDeleteFile(folderId, id)
        addSuccessToast({
          message: 'File deleted permanently'
        })
        getFolder(currentFile.folderId)
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to permanently delete file')
      }
    }
    toggleModal()
  }

  const toggleEmptyTrashModal = () => {
    const { showEmptyArchived } = modalState
    if (!showEmptyArchived) {
      handleModal('show_empty_archived')
    } else {
      handleModal('close_modal')
    }
  }
  const restoreFunc = async (id: string) => {
    const {
      restoreFile,
      folders,
      createFolder,
      moveFile,
      getFolder,
      addNewFolder,
      addSuccessToast,
      networkErrorOrMsg
    } = props
    const currentFile = docVaultItems.filter((f) => f.id === id)[0]
    if (id) {
      const folderId = currentFile.folderId
      try {
        // If the restoring file's folder does not exist we need to make a new folder for it and move the file to the new folder
        if (folders && folders[currentFile.folderId].archived) {
          // Create new folder
          const newFolder = await createFolder(currentFile.name)
          const newFolderId = newFolder.value.data.id
          // Move file to the new folder
          const movedFile = await moveFile(
            currentFile.folderId,
            id,
            newFolderId
          )
          const movedFileFolderId = movedFile.value.data.folder
          // Restore file from archive
          try {
            await restoreFile(movedFileFolderId, id, false)
            getFolder(movedFileFolderId)
            const mappedFile: any = mapFile(movedFile.value.data)
            // Dispatch an action to mark this folder as new in the UI
            addNewFolder(mappedFile.folderId)
            addSuccessToast({
              message: 'File restored',
              actionBtn: {
                text: 'View file',
                onClick: async () => {
                  history.push(`/document-vault/${mappedFile.folderId}`)
                }
              }
            })
          } catch (error) {
            networkErrorOrMsg(error, 'Unable to restore file')
          }
        } else {
          // If the trashed file's folder does exist, restore the file
          try {
            await restoreFile(folderId, id, false)
            addSuccessToast({
              message: 'File restored',
              actionBtn: {
                text: 'View file',
                onClick: async () => {
                  history.push(`/document-vault/${folderId}`)
                }
              }
            })
          } catch (error) {
            networkErrorOrMsg(error, 'Unable to restore file')
          }
        }
      } catch (error) {
        networkErrorOrMsg(error, 'Unable to restore file')
      }
    }
    toggleModal()
  }
  const emptyTrashFunc = async () => {
    const { emptyTrash, getFolders, addSuccessToast, networkErrorOrMsg } = props
    try {
      await emptyTrash()
      addSuccessToast({
        message: 'Trash emptied'
      })
      getFolders()
    } catch (error) {
      networkErrorOrMsg(error, 'Unable to empty trash')
    }
    toggleModal()
  }

  const { width } = useWindowSize()
  const { docVaultItems } = props

  const generateTableData = () => {
    return docVaultItems.map((item) => ({
      ...item,
      content: {
        ...item,
        toggleModal
      }
    }))
  }
  const tableData = generateTableData()
  const docVaultFileRow = getDocVaultFileRow(width)

  return (
    <div>
      <Link
        to='/document-vault'
        className='folder-detail__btn--back ps-4 py-3 ps-sm-0'>
        <i className='bi bi-chevron-left fs-7 me-2' />
        Back
      </Link>
      <div className='d-flex flex-column flex-md-row align-items-md-center justify-content-between mb-4 px-4 px-sm-0'>
        <h2 className='rfont--h2 fw-bold text-dark mb-3 mb-lg-0'>Trash</h2>

        <EmptyTrash
          inactive={docVaultItems.length < 1}
          emptyTrashFunc={emptyTrashFunc}
          showEmptyTrashModal={modalState.showEmptyArchived}
          toggleEmptyTrashModal={toggleEmptyTrashModal}
        />
      </div>
      <div
        id='doc-vault-trash-card-container'
        className='bg-white p-2 p-sm-x2 mb-9'>
        {tableData.length ? (
          <table id='doc-vault-trash-table' className='vault__table'>
            <thead>
              <tr className='vault__table-head'>
                {docVaultFileRow.map((cell) => (
                  <th key={cell.Cell.name} style={cell.columnHeaderStyle}>
                    {cell.columnHeader}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((rowData) => {
                return (
                  <tr
                    key={rowData.id}
                    role='button'
                    className='vault__table-row'>
                    {docVaultFileRow.map((cell) => (
                      <td
                        key={cell.Cell.name + rowData.id}
                        className='vault__table-cell'
                        style={cell.style}>
                        {<cell.Cell {...rowData} />}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        ) : (
          <NullDocVault
            headerText='No deleted items'
            supportText='You have no deleted items in trash.'
          />
        )}
      </div>

      <RestoreModal
        type='file'
        showModal={modalState.showRestoreArchived}
        id={currentId}
        restoreModal={restoreFunc}
        closeModal={toggleModal}
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
      />

      <HardDeleteModal
        showModal={modalState.showHardDeleteFile}
        type='file'
        id={currentId}
        hardDeleteFn={hardDeleteFunc}
        closeModal={toggleModal}
        isFullscreenOnMobile={false}
        modalProps={{ centered: true }}
      />
    </div>
  )
}

const mapStateToProps = (
  store: GlobalState,
  { match }: RouteComponentProps
) => {
  return {
    folders: getFolders(store),
    docVaultItems: archivedFilesSelector(store),
    match
  }
}

const mapDispatchToProps = {
  getFolder: actions.getFolder,
  getFolders: actions.getFolders,
  addNewFolder: actions.addNewFolder,
  restoreFile: actions.updateArchivedFile,
  hardDeleteFile: actions.hardDeleteFile,
  emptyTrash: actions.emptyTrash,
  createFolder: actions.createFolder,
  moveFile: actions.moveFile,
  addSuccessToast,
  addErrorToast,
  networkErrorOrMsg
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrashedFiles)
)
