import React from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import Card from 'react-bootstrap/Card'

import { investmentPartners, allowedPartners } from '../../objects/partners'

export type PartnerLoginTileProps = ReturnType<typeof mapStateToProps>

const partnerDisplay = (onlinePortal) => (
  <a
    href={onlinePortal.link}
    target='_blank'
    rel='noopener noreferrer'
    key={onlinePortal.name}>
    <div className='plan-partners-login__item'>
      <img src={onlinePortal.img} alt={onlinePortal.name} />
    </div>
  </a>
)

export const PartnerLoginTile = (props: PartnerLoginTileProps) => {
  const { partners, user } = props

  if (!partners || !partners.length) {
    return null
  }

  const onlinePortalOptions = () => {
    return allowedPartners.reduce((displayablePartners, partner) => {
      if (partners?.includes(partner)) {
        const partnerObj = investmentPartners(partner, user)
        partnerObj && displayablePartners.push(partnerDisplay(partnerObj))
      }
      return displayablePartners
    }, [])
  }

  return (
    <div className='plan-partners-login__container mt-4'>
      <h3 className='plan-partners-login__title'>Partner Login</h3>
      <div className='plan-partners-login__title'>
        For more information on your financial plan, log in below.
      </div>

      <Card
        id='partner-login'
        style={{
          padding: '0px',
          margin: '0px',
          background: 'none',
          border: 'none'
        }}>
        <div className='plan-partners-login__list'>{onlinePortalOptions()}</div>
      </Card>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user, investments } = store
  const { partners } = investments
  return {
    partners,
    user
  }
}

export default connect(mapStateToProps)(PartnerLoginTile)
