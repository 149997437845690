import React from 'react'
import Form from 'react-bootstrap/Form'
import {
  AllSetting,
  allSettings,
  captionKeyList,
  captionList,
  captionTitleList,
  ClientMobileNotifications,
  ClientNotificationTypes,
  isAdjustable,
  settingTypes
} from '../../../objects/settings/clientAdvisor'

export const createNotificationTile: React.FC<{
  key: AllSetting
  captionName: string
  desktopSettings: string[]
  emailSettings: string[]
  mobileSettings: string[]
  changeNotificationChecks
}> = (props) => {
  const {
    captionName,
    desktopSettings,
    emailSettings,
    mobileSettings,
    changeNotificationChecks
  } = props
  const settingType = captionKeyList[captionName]

  const checkActive = (activeType: settingTypes) => {
    return activeType === 'EMAIL_'
      ? emailSettings?.includes(settingType)
      : activeType === 'MOBILE_'
      ? mobileSettings?.includes(settingType)
      : desktopSettings?.includes(settingType)
  }

  return (
    <div key={settingType} className='mb-4'>
      <div
        id='profile-photo'
        className='profile-card bg-white pos-relative mb-4 p-4 p-md-x2 '>
        <div className='rfont--h3 mb-4'>{captionName}</div>
        <span className='rfont--body1'>
          {`Select how you want to be notified when you ${captionTitleList[settingType]}`}
        </span>
        <Form>
          {ClientMobileNotifications.includes(
            settingType as ClientNotificationTypes
          ) ? (
            <Form.Check
              className={`MOBILE_${settingType} notif-settings__check`}
              name={'MOBILE_' + settingType}
              checked={checkActive('MOBILE_')}
              onChange={changeNotificationChecks}
              label='Mobile Notification'
            />
          ) : null}
          {settingType !== 'NEW_PDV_FILE' ? (
            <Form.Check
              className={`EMAIL_${settingType} notif-settings__check`}
              name={'EMAIL_' + settingType}
              checked={checkActive('EMAIL_')}
              onChange={changeNotificationChecks}
              label='Email Notification'
            />
          ) : null}
          <Form.Check
            className={`WEB_${settingType} notif-settings__check`}
            name={'WEB_' + settingType}
            checked={checkActive('WEB_')}
            onChange={changeNotificationChecks}
            label='Web Notification'
          />
        </Form>
      </div>
    </div>
  )
}

export const NotificationTile = (props) => {
  const {
    desktopSettings,
    emailSettings,
    mobileSettings,
    changeNotificationChecks
  } = props

  return allSettings.reduce((uniq, setting: AllSetting) => {
    const captionName = captionList[setting]
    if (!captionName || !isAdjustable(setting)) {
      return uniq
    }
    const tile = createNotificationTile({
      key: setting,
      captionName,
      desktopSettings,
      emailSettings,
      mobileSettings,
      changeNotificationChecks
    })
    return uniq.some((notificationTile) => notificationTile.key === tile.key)
      ? uniq
      : [...uniq, tile]
  }, [])
}

export default NotificationTile
