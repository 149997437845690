import React from 'react'
import { Card, Dropdown } from 'react-bootstrap'
import { sortOptions, sortOptionsMap } from '../../helpers/goals'
import {
  GoalActionSortByTypes,
  GoalActionSortOption
} from '../../objects/goals'
export interface GoalsSortProps {
  onSort: (value: GoalActionSortByTypes) => void
  sortBy: GoalActionSortByTypes
}
const GoalsSort = ({ onSort, sortBy }: GoalsSortProps) => {
  const handleChange = (eventKey) => {
    onSort(eventKey)
  }

  return (
    <Card className='goal-sort__card rounded-0 border-0 bg-white'>
      <div className='goal-sort'>
        <div>
          <h3 className='goal-sort__title'>Sort Goals</h3>
          <p>You can sort your goals by status, due date or type.</p>
        </div>
        <Dropdown onSelect={handleChange} style={{ minWidth: '240px' }}>
          <Dropdown.Toggle
            variant=''
            id='dropdown-basic-button'
            className='d-flex justify-content-between align-items-center text-start goal-sort__dropdown-toggle goal-sort__caret'>
            {sortOptionsMap[sortBy]?.label}{' '}
            <i className='bi bi-chevron-down'></i>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {sortOptions.map((option: GoalActionSortOption) => {
              return (
                <Dropdown.Item
                  className={`goal-sort__dropdown-item`}
                  key={option.value}
                  eventKey={option.value}
                  active={option.value === sortBy}>
                  {option.label}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Card>
  )
}
export default GoalsSort
