import React, { CSSProperties, FC } from 'react'
import classnames from 'classnames'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

export interface HorizontalBarChartProps {
  className?: string
  style?: CSSProperties
  graphName?: string
  itemList: { name: string; percent: number; color: string; text?: string }[]
  tooltipOn?: boolean
}

const RenderTooltip = React.forwardRef((tooltipProps: any, ref) => {
  const { text, name, percent, ...otherProps } = tooltipProps
  return (
    <Tooltip ref={ref} {...otherProps}>
      <div
        className='HorizontalBarChart__tooltip-content'
        dangerouslySetInnerHTML={{ __html: text || `${name}<br/>${percent}%` }}
      />
    </Tooltip>
  )
})

const HorizontalBarChart: FC<HorizontalBarChartProps> = (props) => {
  const { className, style, graphName, itemList, tooltipOn } = props

  const filteredData = itemList.filter((item) => item.percent !== 0)

  const { displayName } = HorizontalBarChart

  const horizontalBarChartClassName = classnames(
    `${displayName}__container`,
    className
  )
  const barTooltipClassName = classnames(`${displayName}__bargraph`)

  return (
    <div style={style} className={horizontalBarChartClassName}>
      <div className={`${displayName}__chart-container`}>
        {filteredData.map(({ name, percent, color, text }) =>
          tooltipOn ? (
            <OverlayTrigger
              key={`${name}_toolTip`}
              placement='top'
              overlay={
                <RenderTooltip text={text} name={name} percent={percent} />
              }>
              <div
                key={`${name}_bar`}
                className={barTooltipClassName}
                style={{ backgroundColor: `${color}`, width: `${percent}%` }}
              />
            </OverlayTrigger>
          ) : (
            <div
              key={`${name}_bar`}
              className={barTooltipClassName}
              style={{ backgroundColor: `${color}`, width: `${percent}%` }}
            />
          )
        )}
      </div>
      <span className={`${displayName}__graph-name`}>{graphName}</span>
    </div>
  )
}

HorizontalBarChart.displayName = 'HorizontalBarChart'

HorizontalBarChart.defaultProps = {
  className: '',
  style: {},
  graphName: '',
  itemList: [],
  tooltipOn: false
}

export default HorizontalBarChart
