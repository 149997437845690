import { createDeepEqualSelector } from './utilz'
import { GlobalState } from '../reducers'
import { sortGoals, sortOptionsMap } from '../helpers/goals'

const getGoals = (store: GlobalState) => store.goalActions.goals
const getSortBy = (store: GlobalState) => {
  const sortBy =
    store.goalActions.sessionSortBy || store.goalActions.preferenceSortBy
  if (!sortBy || !sortOptionsMap[sortBy]) {
    return 'Due Date'
  }
  return sortBy
}

export const getSortedGoals = createDeepEqualSelector(
  getGoals,
  getSortBy,
  (goals, sortBy) => {
    return {
      goals: sortGoals(sortBy, goals),
      sortBy
    }
  }
)
