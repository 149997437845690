import React, { CSSProperties } from 'react'

export interface MessagesErrorProps {
  className?: string
  style?: CSSProperties
  showLabel?: boolean
}

const label = (
  <span className='messages-error__text'>Messages cannot be loaded.</span>
)

const MessagesError = ({
  className,
  style,
  showLabel = true
}: MessagesErrorProps) => (
  <div className={className} style={style}>
    <i className='bi bi-exclamation-circle messages-error__icon'>
      {showLabel && label}
    </i>
  </div>
)

export default MessagesError
