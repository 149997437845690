import { createSelectorCreator, defaultMemoize } from 'reselect'

import { isEqual } from '../helpers'

// changing default select memorization '===' with check for undefined, null and 'isEqual' to work with objects
export const extendedMemorize = (value1: any, value2: any) => {
  if (typeof value1 === 'undefined' && typeof value2 === 'undefined') {
    return true
  }

  if (value1 === value2) return true

  return isEqual(value1, value2)
}

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  extendedMemorize
)
