import React, { useState } from 'react'
import { Form, Dropdown } from 'react-bootstrap'
import classnames from 'classnames'

interface IFormDropdownProps {
  label: string
  name?: string
  options: { label: string; value: string }[]
  placeholder: string
  defaultValue?: string
  validated: boolean
  onSelect?: (label, value, inputName) => void
}

const FormDropdown = ({
  label,
  name,
  options,
  placeholder,
  defaultValue,
  validated,
  onSelect
}: IFormDropdownProps) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || null)

  // eventKey is the same as value
  const handleSelect = (eventKey, event) => {
    event.preventDefault()
    const selectedOption = options.find((option) => option.value === eventKey)
    setSelectedValue(selectedOption ? selectedOption.value : null)
    // callback if defined
    onSelect && onSelect(selectedOption.label, selectedOption.value, name)
  }

  const getToggleText = () => {
    if (!selectedValue) return placeholder
    const currentValue = selectedValue || defaultValue
    const selectedOption = options.find(
      (option) => option.value === currentValue
    )
    return selectedOption?.label
  }

  return (
    <Form.Group
      controlId={name}
      className={classnames('form-dropdown', {
        'form-dropdown__dropdown-is-invalid': validated && !selectedValue
      })}>
      <Form.Label>{label}</Form.Label>
      <Dropdown onSelect={handleSelect}>
        <Dropdown.Toggle variant='' className='d-flex text-start w-100'>
          <div className='gtext-ellipsis'>{getToggleText()}</div>
          <i className='bi bi-chevron-down ms-auto fs-5'></i>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {options.map((option, index) => (
            <Dropdown.Item
              as={'button'}
              key={option.value}
              eventKey={option.value}
              active={selectedValue === option.value}
              className='form-dropdown__dropdown-item'>
              {option.label}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {/* hidden input to store the selected value for form submission */}
      <input type='hidden' name={name} value={selectedValue} />
    </Form.Group>
  )
}

export default FormDropdown
