import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'
import GoalsHero from './GoalsHero'
import { GoalsTiles } from './GoalsTiles'
import GoalsSort from './GoalsSort'
import {
  getGoalActions,
  setSessionSort
} from '../../actions/goalActions.action'
import { getHousehold } from '../../actions/household.action'
import { getSortedGoals } from '../../selectors/goals'
import GoalsNull from './GoalsNull'

type GoalsActions = typeof mapDispatchToProps
type GoalsStore = ReturnType<typeof mapStateToProps>
export type GoalsProps = GoalsActions & GoalsStore

export const Goals = (props: GoalsProps) => {
  const {
    goalActions,
    sortBy,
    householdFetched,
    getGoalActions,
    setSessionSort,
    getHousehold
  } = props

  useEffect(() => {
    getGoalActions()

    if (!householdFetched) {
      getHousehold()
    }

    return () => {
      setSessionSort(null)
    }
  }, [])

  const noGoals = !goalActions || goalActions.length === 0
  return (
    <div className={noGoals ? '' : 'mb-17'}>
      <GoalsHero />
      <div>
        <div>
          {noGoals ? (
            <GoalsNull />
          ) : (
            <div>
              <GoalsSort onSort={setSessionSort} sortBy={sortBy} />
              <GoalsTiles goals={goalActions} />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = {
  getGoalActions,
  setSessionSort,
  getHousehold
}
const mapStateToProps = (store: GlobalState) => {
  const { goals, sortBy } = getSortedGoals(store)
  return {
    goalActions: goals,
    sortBy,
    householdFetched: store.user.householdFetched
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Goals)
