import { createDeepEqualSelector } from './utilz'
import { GlobalState } from '../reducers'
import {
  generateNewPreferences,
  sortInvestmentAccounts
} from '../helpers/investmentSort'

const getAccounts = (store: GlobalState) => store.clientAccounts.investments
const getPreferences = (store: GlobalState) => store.investments.preferences

export const getInvestments = createDeepEqualSelector(
  getAccounts,
  getPreferences,
  (accounts, preferences) => {
    const currentPreferences = preferences || generateNewPreferences()
    const {
      investmentsGroupBy,
      investmentsSortBy,
      investmentsSortingOrder
    } = currentPreferences

    const mappedAccounts = accounts.reduce((p, acct) => {
      p[acct.id] = acct
      return p
    }, {})

    const sortedAccounts = sortInvestmentAccounts(mappedAccounts, {
      groupBy: investmentsGroupBy,
      sortBy:
        investmentsSortBy === 'Product Name' ? 'Strategy' : investmentsSortBy,
      sortingOrder: investmentsSortingOrder
    })

    return { accounts: sortedAccounts, noAccounts: !accounts.length }
  }
)
