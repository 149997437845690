import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Button from 'react-bootstrap/Button'
import { GlobalState } from '../../reducers'
import { history } from '../../store'
import PriorityCards from './PriorityCards'
import {
  generateDefaultSelectedCard,
  getCurrentScoreAndScoringDate,
  isSingleHH
} from '../../helpers/priorities'
import {
  getActiveScoringSession,
  getLoggedInUser
} from '../../selectors/contacts'
import { dateFormat } from '../../helpers'

const updateScores = () => {
  history.push(`/honest-conversations/new-score`)
}

export type HonestConversationsHeroProps = ReturnType<typeof mapStateToProps>

export const HonestConversationsHero = (
  props: HonestConversationsHeroProps
) => {
  const {
    contacts,
    honestConversation,
    user,
    selectCard,
    setSelectedCard
  } = props

  useEffect(() => {
    const defaultSelectedCard =
      contacts &&
      honestConversation &&
      generateDefaultSelectedCard(contacts, honestConversation)
    defaultSelectedCard &&
      setSelectedCard(
        selectCard === null
          ? defaultSelectedCard && defaultSelectedCard.hcCardId
          : selectCard
      )
  }, [])

  const contact = contacts && getLoggedInUser(user, contacts)
  const activeScoringSession = getActiveScoringSession(
    honestConversation,
    user,
    contacts
  )
  const singleHH = isSingleHH(contacts)
  const isSecondaryActive = contacts?.secondary?.id === user?.id
  const activeContactCurrentScoreAndScoringDate = getCurrentScoreAndScoringDate(
    activeScoringSession &&
      contact &&
      honestConversation &&
      honestConversation.clientScoringSessions[contact.id],
    activeScoringSession
  )
  const satisficationScore =
    activeContactCurrentScoreAndScoringDate &&
    activeContactCurrentScoreAndScoringDate.singleTotalScore / 5

  const scoreDate = dateFormat(
    activeContactCurrentScoreAndScoringDate &&
      activeContactCurrentScoreAndScoringDate.currentScoringDate,
    true
  )
  const score = Math.round(satisficationScore)
  return (
    <div>
      <div className='honestConversations-hero__container' />
      <div id='honestConversations-hero' className='position-relative'>
        <div className='honestConversations-hero__worth'>
          <div className='honestConversations-hero__worth--col'>
            {score ? (
              <div>
                <div className='honestConversations-hero__title rfont--h1'>
                  <span>{'Your Satisfaction Score is '}</span>
                  <span
                    id='honestConversations-hero-score'
                    className={`honestConversations-hero__number${
                      score < 7 ? '--low' : ''
                    }`}>
                    {score}
                  </span>
                </div>
                <div className='honestConversations-hero__subtitle rfont--body3 mt-2'>
                  {`Scored on ${scoreDate}`}
                </div>
              </div>
            ) : (
              <div>
                <div className='honestConversations-hero__title rfont--h1'>
                  Your Priorities
                </div>
                <div className='honestConversations-hero__subtitle rfont--body3'>
                  You should score your priorites to keep track of your
                  progress.
                </div>
              </div>
            )}
          </div>
          <div className='honestConversations-hero__button'>
            <Button
              size='lg'
              className='match-btn-width'
              onClick={updateScores}>
              {score ? 'Update My Scores' : 'Score My Priorities'}
            </Button>
          </div>
        </div>
        <PriorityCards
          honestConversation={honestConversation}
          contacts={contacts}
          selectCard={selectCard}
          setSelectedCard={setSelectedCard}
          isSecondaryActive={isSecondaryActive}
          singleHH={singleHH}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState, ownProps) => {
  return {
    contacts: store.contacts,
    user: store.user,
    honestConversation:
      store.honestConversations[store.honestConversations.hcExerciseId],
    ...ownProps
  }
}
export default connect(mapStateToProps)(HonestConversationsHero)
