import * as actions from '../actions/priorityActions.action'
import * as householdActions from '../actions/household.action'
import {
  PALCardObj,
  PalPreferenceObj,
  PriorityActionObj
} from '../objects/priorityAction'
import { generateNewPreferences } from '../helpers/palSort'
import { isObjEmpty } from '../helpers'
import { HonestConversationsExerciseObj } from '../objects/honestConversation'

export interface PriorityActionsState {
  actionList: PriorityActionObj[]
  preferences: PalPreferenceObj
}

const initState: PriorityActionsState = {
  actionList: [],
  preferences: null
}

const PriorityActions = (state = initState, action): PriorityActionsState => {
  switch (action.type) {
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      const { preferences } = action.payload
      const preferenceObj =
        preferences && preferences[0]
          ? mapHousehold(preferences[0])
          : { preferences: generateNewPreferences() }
      return { ...state, ...preferenceObj }
    case `${actions.FETCH_PRIORITY_ACTION_LIST}_FULFILLED`:
      const { priorityActions, honestConversations } = action.payload
      return {
        ...state,
        actionList: mapPriorityActionList(priorityActions, honestConversations)
      }
    default:
      return state
  }
}

const mapHousehold = (preferences) => {
  return {
    preferences: mapPreferences(preferences)
  }
}

const mapPalCards = (
  action,
  honestConversations: HonestConversationsExerciseObj
) => {
  const arr: PALCardObj[] = []
  if (action.palItem && action.palItem.hcCards) {
    action.palItem.hcCards.forEach(
      (hcCard: { id: string; title: string; category: string }) => {
        arr.push({
          id: hcCard.id,
          title: hcCard.title,
          cardType: hcCard.category
        })
      }
    )
  }
  return (
    honestConversations &&
    !isObjEmpty(honestConversations.householdCards) &&
    arr.filter((card) =>
      Object.values(honestConversations.householdCards).some(
        (item) => item.card.title === card.title
      )
    )
  )
}

const mapPriorityActionList = (priorityActions, honestConversations) => {
  const priorityActionList = []
  priorityActions.forEach((action) => {
    const status =
      action.status === 'None' ? 'NA | Pending Review' : action.status
    priorityActionList.push({
      id: action.id,
      name: action.name,
      notes: action.notes,
      status,
      checkInDate: action.checkInDate ? action.checkInDate.split('T')[0] : null,
      cards: mapPalCards(action, honestConversations)
    })
  })
  return priorityActionList
}

const mapPreferences = (preferences): PalPreferenceObj => {
  if (preferences) {
    return {
      palSortBy: preferences.palSortBy,
      palSortingOrder: preferences.palSortingOrder
    }
  } else return generateNewPreferences()
}

export default PriorityActions
