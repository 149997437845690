export const persistentSort = (moneyMindarr) => {
  return moneyMindarr.sort((a, b) => {
    // in case of a tie, we follow the chart order (commitment, protection, happiness)
    const sortKey = {
      commitment: 3,
      COMMITMENT: 3,
      Commitment: 3,
      protection: 2,
      PROTECTION: 2,
      Protection: 2,
      happiness: 1,
      Happiness: 1,
      HAPPINESS: 1
    }
    return b[1] - a[1] || sortKey[b[0]] - sortKey[a[0]]
  })
}
