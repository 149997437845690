import * as actions from '../actions/tasks.action'
import { taskActionTimePeriod } from '../helpers/tasks'
import { isComplete } from '../helpers/tasks'
import { TaskObj, TaskObjState, TasksState, TaskSort } from '../objects/task'

export const initState: TasksState = {
  tasks: null,
  sortBy: TaskSort.DueDate,
  filtered: false
}

const mapTask = (task: TaskObj): TaskObj => {
  // leave CRM task functionality as is, native tasks show actual status from BE
  const status = task.crmId
    ? taskActionTimePeriod(task).statusDisplay
    : task.status
  return {
    id: task.id,
    crmId: task.crmId,
    status,
    createdDate: task.createdDate,
    completedDate: task.completedDate,
    complete: isComplete(task, status),
    dueDate: task.dueDate,
    subject: task.subject,
    assigneeType: task.assigneeType,
    assigneeNames: task.assigneeNames,
    includeInGuidebook: task.includeInGuidebook,
    primaryAssignee: task.primaryAssignee,
    secondaryAssignee: task.secondaryAssignee,
    isClosed: task.isClosed
  }
}

const mapTasks = (tasks: TaskObj[]): TaskObjState => {
  const TasksObj = {}
  tasks.sort((a, b) => Date.parse(a.dueDate) - Date.parse(b.dueDate))
  tasks.forEach((task) => {
    if (task.includeInGuidebook === true) {
      TasksObj[task.id] = task && task.id && mapTask(task)
    }
  })
  return TasksObj
}

const Tasks = (state = initState, action): TasksState => {
  switch (action.type) {
    case `${actions.GET_ALL_TASKS}_FULFILLED`:
      return { ...state, tasks: { ...mapTasks(action.payload) } }
    case `${actions.UPDATE_TASK_STATUS}_FULFILLED`:
      if (action.payload?.id) {
        const tasks = {
          ...state.tasks,
          [action.payload.id]: mapTask(action.payload)
        }
        return { ...state, tasks }
      }
      return state
    case `${actions.SORT_TASKS_BY}`:
      return { ...state, sortBy: action.payload.sortBy }
    case actions.FILTER_TASKS:
      return { ...state, filtered: action.payload }
    case actions.RESET_SORT_FILTER:
      return { ...state, filtered: false, sortBy: TaskSort.DueDate }
    default:
      return state
  }
}

export default Tasks
