import React from 'react'
import { FeeScheduleObj } from '../../objects/investments'

export interface FeeFootnotesProps {
  feeData: FeeScheduleObj[]
}

const FeeFootnotes: React.FC<FeeFootnotesProps> = ({
  feeData
}): JSX.Element => {
  const feesWithFootnotes = feeData
    .filter((fee) => fee.footerAttributes)
    .sort((a, b) => (a.footnoteNumber > b.footnoteNumber ? 1 : -1))
  return (
    <div className='feesum-table__footer_text'>
      <ol>
        {feesWithFootnotes.map((fee) => (
          <li key={fee.id}>{fee.footerAttributes}</li>
        ))}
      </ol>
    </div>
  )
}

export default FeeFootnotes
