import React, { RefObject } from 'react'
import { Line } from 'react-chartjs-2'

import { getGraphOptions } from '../../graphs/lineGraphOptions'

import { ChartData } from 'chart.js'

const priorityGraphId: string = 'hc-priority-graph'

const priorityGraphRef: RefObject<HTMLCanvasElement> = React.createRef()

const graphDataOptions = getGraphOptions()

const PrioritiesGraph = (props: { graphDataSet: ChartData }) => {
  const { graphDataSet } = props

  return (
    <div className='priority-graphs__graph-w'>
      <Line
        ref={priorityGraphRef}
        id={priorityGraphId}
        data={graphDataSet ? graphDataSet : {}}
        options={graphDataOptions}
      />
    </div>
  )
}

export default PrioritiesGraph
