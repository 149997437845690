import React, { ComponentType } from 'react'
import Modal, { ModalProps } from 'react-bootstrap/Modal'
import classnames from 'classnames'

type TWrapperModalProps = Omit<ModalProps, 'show' | 'onHide' | 'fullscreen'>

interface IControlledModal {
  showModal: boolean
  closeModal: () => void
  isWithoutPadding?: boolean
  isFullscreenOnMobile?: boolean
  hideCloseButton?: boolean
  modalProps?: TWrapperModalProps
}

const getDisplayName = (WrappedComponent: ComponentType<any>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'WrappedModal'
}

const ControlledModalWrapper = <P extends object>(
  WrappedComponent: ComponentType<P>
) => {
  const HOC = (props: P & IControlledModal) => {
    const {
      showModal,
      closeModal,
      isWithoutPadding,
      isFullscreenOnMobile = true,
      hideCloseButton,
      modalProps,
      ...componentProps
    } = props

    const modalClassNames = classnames({
      'p-0': isWithoutPadding,
      'p-x2': !isWithoutPadding
    })
    return (
      <Modal
        show={showModal}
        onHide={closeModal}
        {...(isFullscreenOnMobile && { fullscreen: 'md-down' })}
        {...modalProps}>
        {!hideCloseButton && <Modal.Header className='mb-n5 z-1' closeButton />}
        <Modal.Body className={modalClassNames}>
          <WrappedComponent
            closeModal={closeModal}
            {...(componentProps as P)}
          />
        </Modal.Body>
      </Modal>
    )
  }
  HOC.displayName = getDisplayName(WrappedComponent)
  return HOC
}

export default ControlledModalWrapper
