import * as householdActions from '../actions/household.action'
import * as actions from '../actions/clientAccounts.action'
import { validErrorCode } from '../helpers/netWorth'
import {
  ClientAccountObj,
  NetworthPreferences,
  ClientAccountPositionsObj
} from '../objects/clientAccount'

export interface ClientAccountsState {
  assets: ClientAccountObj[]
  liabilities: ClientAccountObj[]
  investments: ClientAccountObj[]
  pending: ClientAccountObj[]
  errorAccounts: ClientAccountObj[]
  errorMessage: string
  saved: boolean
  successMessage: string
  guidecenterOptions: string
  preferences: NetworthPreferences
}

const initState: ClientAccountsState = {
  assets: [],
  liabilities: [],
  investments: [],
  pending: [],
  errorAccounts: [],
  errorMessage: null,
  saved: false,
  successMessage: null,
  guidecenterOptions: null,
  preferences: null
}

const ClientAccounts = (
  state = initState,
  action: any
): ClientAccountsState => {
  switch (action.type) {
    case actions.FETCH_CLIENT_ACCOUNTS_FULFILLED:
      const assets = mapClientAccounts(action.payload, 'Assets')
      const liabilities = mapClientAccounts(action.payload, 'Liabilities')
      const pending = mapClientAccounts(action.payload, null)
      return {
        ...state,
        assets,
        liabilities,
        pending,
        errorAccounts: filterErrorAccounts([
          ...assets,
          ...liabilities,
          ...pending
        ]),
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      const household = mapHousehold(action.payload)
      return { ...state, ...household }
    case actions.FETCH_CLIENT_ACCOUNTS_REJECTED:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.FETCH_CLIENT_ACCOUNTS_PENDING:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.SAVE_MANUAL_ACCOUNT_PENDING:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.SAVE_MANUAL_ACCOUNT_FULFILLED:
      return {
        ...state,
        saved: true,
        successMessage: 'Added Account Successfully',
        errorMessage: null
      }
    case actions.SAVE_MANUAL_ACCOUNT_REJECTED:
      return {
        ...state,
        errorMessage: 'Could Not Save Account',
        saved: false,
        successMessage: null
      }
    case actions.REMOVE_ACCOUNT_PENDING:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.REMOVE_ACCOUNT_FULFILLED:
      return {
        ...state,
        saved: true,
        successMessage: 'Removed Account Successfully',
        errorMessage: null
      }
    case actions.REMOVE_ACCOUNT_REJECTED:
      return {
        ...state,
        saved: false,
        errorMessage: 'Could Not Remove Account',
        successMessage: null
      }
    case actions.FETCH_INVESTMENT_ACCOUNTS_FULFILLED:
      return {
        ...state,
        investments: mapInvestments(action.payload),
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.RESET:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.UPDATE_MANUAL_ACCOUNT_PENDING:
      return {
        ...state,
        saved: false,
        errorMessage: null,
        successMessage: null
      }
    case actions.UPDATE_MANUAL_ACCOUNT_FULFILLED:
      return {
        ...state,
        saved: true,
        errorMessage: null,
        successMessage: 'Updated Account Successfully'
      }
    case actions.UPDATE_MANUAL_ACCOUNT_REJECTED:
      return {
        ...state,
        saved: false,
        errorMessage: 'Could Not Update Account',
        successMessage: null
      }
    default:
      return state
  }
}

const mapPosition = (position: ClientAccountPositionsObj) => {
  return {
    clientAccount: position.clientAccount,
    createdDate: position.createdDate,
    asOfDate: position.asOfDate,
    id: position.id,
    isSleeve: position.isSleeve,
    name: position.name,
    price: position.price,
    quantity: position.quantity,
    rawValue: position.rawValue,
    totalCost: position.totalCost,
    value: position.value,
    altPercent: position.altPercent,
    cashPercent: position.cashPercent,
    equityPercent: position.equityPercent,
    fixedPercent: position.fixedPercent,
    unclassifiedPercent: position.unclassifiedPercent,
    securityDescription: position.securityDescription,
    securityId: position.securityId,
    securityStyle: position.securityStyle,
    securityType: position.securityType,
    tickerSymbol: position.tickerSymbol,
    symbol: position.symbol,
    allocationOverridden: position.allocationOverridden
  }
}
const mapAccountPositions = (positions: ClientAccountPositionsObj[]) => {
  const positionsObj = {}
  positions.forEach((pos) => {
    if (positionsObj[pos.id]) {
      positionsObj[pos.id] = mapPosition(pos)
    }
  })
  return positionsObj
}

export const mapClientAccounts = (clientAccounts, accountType) => {
  const accountList: ClientAccountObj[] = []
  if (clientAccounts && clientAccounts.length) {
    clientAccounts.forEach((clientAccount) => {
      const { type, subType, category } = mapClientAccountType(
        clientAccount.guidebookClassification
      )
      if (type === accountType) {
        accountList.push({
          accountName: clientAccount.accountName,
          name: clientAccount.accountNickname,
          accountType: clientAccount.accountType,
          allocationOverriden: clientAccount.allocationOverriden,
          allocations: clientAccount.allocations,
          balanceDate: clientAccount.balanceDate,
          discreteAllocation: clientAccount.discreteAllocation,
          totalValueManual: clientAccount.totalValueManual,
          id: clientAccount.id,
          accountNickname: clientAccount.accountNickname,
          totalValue: clientAccount.totalValueManual,
          type,
          subType,
          category,
          guidebookClassification: clientAccount.guidebookClassification,
          pssSelected: clientAccount.pssSelected,
          accountNumber:
            clientAccount.source &&
            clientAccount.source.toLowerCase() === 'emoney'
              ? '****'
              : clientAccount.accountNumber,
          description: clientAccount.description,
          date: clientAccount.balanceDate
            ? clientAccount.balanceDate.split('T')[0]
            : null,
          liquid: clientAccount.liquid,
          managed: clientAccount.managed,
          registrationType: clientAccount.type,
          status: clientAccount.status,
          source: clientAccount.source,
          providerAccountId: clientAccount.providerAccountId,
          connectedErrorCode: clientAccount.connectedAccountSyncStatusCode,
          connectedErrorCodeDescription:
            clientAccount.connectedAccountSyncStatusMessage,
          custodianText: clientAccount.textCustodian,
          positions:
            clientAccount.positions &&
            mapAccountPositions(clientAccount.positions)
        })
      }
    })
  }
  return accountList
}

export const filterErrorAccounts = (accounts) =>
  accounts?.length
    ? accounts.filter(
        (account) => account && validErrorCode(account.connectedErrorCode)
      )
    : null

// same as mapInvestments
export const mapInvestments = (clientAccounts) => {
  const accountList: ClientAccountObj[] = []
  if (clientAccounts && clientAccounts.length) {
    clientAccounts.forEach((clientAccount) => {
      const { type, subType, category } = mapClientAccountType(
        clientAccount.guidebookClassification
      )
      accountList.push({
        accountName: clientAccount.accountName,
        name: clientAccount.accountNickname,
        accountType: clientAccount.accountType,
        allocationOverriden: clientAccount.allocationOverriden,
        allocations: clientAccount.allocations,
        balanceDate: clientAccount.balanceDate,
        discreteAllocation: clientAccount.discreteAllocation,
        totalValueManual: clientAccount.totalValueManual,
        id: clientAccount.id,
        accountNickname: clientAccount.accountNickname,
        totalValue: clientAccount.totalValueManual,
        type,
        subType,
        category,
        guidebookClassification: clientAccount.guidebookClassification,
        pssSelected: clientAccount.pssSelected,
        accountNumber:
          clientAccount.source &&
          clientAccount.source.toLowerCase() === 'emoney'
            ? '****'
            : clientAccount.accountNumber,
        description: clientAccount.description,
        date: clientAccount.balanceDate
          ? clientAccount.balanceDate.split('T')[0]
          : null,
        liquid: clientAccount.liquid,
        managed: clientAccount.managed,
        registrationType: clientAccount.type,
        status: clientAccount.status,
        source: clientAccount.source,
        providerAccountId: clientAccount.providerAccountId,
        connectedErrorCode: clientAccount.connectedAccountSyncStatusCode,
        connectedErrorCodeDescription:
          clientAccount.connectedAccountSyncStatusMessage,
        productName: clientAccount.productName,
        strategy: clientAccount.strategy,
        positions: clientAccount.positions
      })
    })
  }
  return accountList
}

export const mapClientAccountType = (
  classification
): { type: string; subType: string; category: string } => {
  switch (classification) {
    case 'Cash & Equivalents':
      return {
        type: 'Assets',
        subType: 'Cash & Equivalents',
        category: 'Cash & Equivalents'
      }
    case 'Taxable Investments':
      return {
        type: 'Assets',
        subType: 'Investments',
        category: 'Taxable Investments'
      }
    case 'Tax-Deferred Investments':
      return {
        type: 'Assets',
        subType: 'Investments',
        category: 'Tax-Deferred Investments'
      }
    case 'Tax-Exempt Investments':
      return {
        type: 'Assets',
        subType: 'Investments',
        category: 'Tax-Exempt Investments'
      }
    case 'Real Estate':
      return { type: 'Assets', subType: 'Other', category: 'Real Estate' }
    case 'Personal Assets':
      return { type: 'Assets', subType: 'Other', category: 'Personal Assets' }
    case 'Business Assets':
      return { type: 'Assets', subType: 'Other', category: 'Business Assets' }
    case 'Other - Personal Assets':
      return { type: 'Assets', subType: 'Other', category: 'Personal Assets' }
    case 'Other - Business Assets':
      return { type: 'Assets', subType: 'Other', category: 'Business Assets' }
    case 'Short-Term Liabilities':
      return {
        type: 'Liabilities',
        subType: 'Short-Term Liabilities',
        category: 'Short-Term Liabilities'
      }
    case 'Long-Term Liabilities':
      return {
        type: 'Liabilities',
        subType: 'Long-Term Liabilities',
        category: 'Long-Term Liabilities'
      }
    default:
      return { type: null, subType: null, category: null }
  }
}

const mapPreferences = (preferences): NetworthPreferences => {
  if (preferences && preferences[0]) {
    return {
      cashGroupBy: preferences[0].networthCashGroupBy,
      cashSortBy: preferences[0].networthCashSortBy,
      cashSortingOrder: preferences[0].networthCashSortingOrder,
      investmentsGroupBy: preferences[0].networthInvestmentsGroupBy,
      investmentsSortBy: preferences[0].networthInvestmentsSortBy,
      investmentsSortingOrder: preferences[0].networthInvestmentsSortingOrder,
      otherAssetsGroupBy: preferences[0].networthOtherAssetsGroupBy,
      otherAssetsSortBy: preferences[0].networthOtherAssetsSortBy,
      otherAssetsSortingOrder: preferences[0].networthOtherAssetsSortingOrder,
      liabilitiesGroupBy: preferences[0].networthLiabilitiesGroupBy,
      liabilitiesSortBy: preferences[0].networthLiabilitiesSortBy,
      liabilitiesSortingOrder: preferences[0].networthLiabilitiesSortingOrder,
      unclassifiedGroupBy: preferences[0].networthUnclassifiedGroupBy,
      unclassifiedSortBy: preferences[0].networthUnclassifiedSortBy,
      unclassifiedSortingOrder: preferences[0].networthUnclassifiedSortingOrder,
      goalActionsSortBy: preferences[0].goalActionsSortBy
    }
  } else return null
}

const mapHousehold = (household: any) => {
  const gcArray = household.guidecenterOptions
    ? household.guidecenterOptions.split(';')
    : null
  return {
    guidecenterOptions: gcArray,
    preferences: mapPreferences(household.preferences)
  }
}

export default ClientAccounts
