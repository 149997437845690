import React from 'react'
import { connect } from 'react-redux'
import * as YodleeActions from '../../../actions/yodlee.action'
import * as NetworthActions from '../../../actions/clientAccounts.action'
import { ClientAccountObj } from '../../../objects/clientAccount'
import { Modal } from 'react-bootstrap'
import Fastlink from '../Fastlink'
import { AppDispatch } from '../../../store'

interface YodleeEditModalProps {
  dispatch: AppDispatch
  closeModal(): void
  account: ClientAccountObj
  fastlinkUrl: string
  show?: boolean
}

export class YodleeEditModal extends React.Component<YodleeEditModalProps> {
  public syncYodleeAccounts = async () => {
    this.props.closeModal()
    this.props.dispatch(YodleeActions.getYodleeParams())
    await this.props.dispatch(YodleeActions.getYodleeAccounts())
    this.props.dispatch(NetworthActions.getClientAccounts())
  }

  public render() {
    return (
      <Modal
        backdrop='static'
        onHide={this.syncYodleeAccounts}
        contentClassName='p-x2'
        show={this.props?.show}>
        <Modal.Header closeButton />
        <div className='yodlee-edit-modal__content-container'>
          <Fastlink
            onClose={this.syncYodleeAccounts}
            customizedProps={{
              providerAccountId: this.props?.account?.providerAccountId,
              flow: 'edit',
              configName: 'Aggregation'
            }}
          />
        </div>
      </Modal>
    )
  }
}

export default connect()(YodleeEditModal)
