import React from 'react'
import { Query } from '@apollo/client/react/components'
import { QueryResult } from '@apollo/client'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { DashTile, DashTileNull } from '../../components/Dashboard'
import { DashTileLink } from '../../components/Dashboard/DashTile'
import { DashRowChildProps } from '../../components/Dashboard/DashRow'
import MessagesError from '../../components/Messages/MessagesError'
import { LatestMessageThreadQuery } from '../../helpers/queries'
import { getLatestThread, timeOrDate } from '../../helpers/messages'
import { GlobalState } from '../../reducers'
import { MessageThread } from '../../objects/messages'

export interface MessagesTileProps extends DashRowChildProps {
  householdId?: string
}

export interface MessageProps {
  fullWidth: boolean
  thread: MessageThread
  error: boolean
}

const links = (threadId: string): DashTileLink[] => [
  { label: 'Read Full Message', route: `/messages/${threadId}` }
]

export const NullState = () => (
  <DashTileNull
    title='No Messages'
    description='Messages between you and your advisor
    will appear here.'
  />
)

export const Message = ({ fullWidth, thread, error = false }: MessageProps) => {
  if (error && !thread) {
    return (
      <div
        className={`dashboard-message__container${fullWidth ? '--full' : ''}`}>
        <MessagesError />
      </div>
    )
  }

  const message = thread.messagesByParent?.nodes[0] || thread
  const { adviserByAdviserSender, clientByClientSender, createdDate } = message

  const author =
    adviserByAdviserSender && adviserByAdviserSender?.name
      ? adviserByAdviserSender?.name
      : clientByClientSender && clientByClientSender.name
      ? clientByClientSender.name
      : null

  return (
    <div className={`dashboard-message__container${fullWidth ? '--full' : ''}`}>
      <h3 className='dashboard-message__subject'>
        {message.subject || thread.subject || ''}
      </h3>
      <div className='dashboard-message__author'>
        {author && `${author} • `}
        {timeOrDate(createdDate)}
      </div>
      <div className='dashboard-message__body'>
        {message.videoId ? (
          <div className='dashboard-message__video-link'>
            <Link to={`/messages/${thread.id}`}>
              <span className='dashboard-tile__links-link-text'>
                Click to view video message
              </span>
            </Link>
          </div>
        ) : (
          message.message
        )}
      </div>
    </div>
  )
}

export const MessagesTile = ({ fullWidth, householdId }: MessagesTileProps) => {
  return (
    <Query
      query={LatestMessageThreadQuery}
      variables={{ householdId }}
      skip={!householdId}
      fetchPolicy='network-only'>
      {({ data, loading, error }: QueryResult) => {
        const threads = data?.allMessages?.nodes || []
        const thread = getLatestThread(threads)

        return (
          <DashTile
            className='dashboard-message'
            title='Messages'
            links={thread ? links(thread.id) : []}
            nullComponent={<NullState />}
            showNull={!thread && !error}
            loading={loading || (!data && !error)}
            error={Boolean(error)}>
            <Message
              fullWidth={fullWidth}
              thread={thread}
              error={Boolean(error)}
            />
          </DashTile>
        )
      }}
    </Query>
  )
}

const mapStateToProps = (store: GlobalState) => {
  return {
    householdId: store.user.householdId
  }
}

export default connect(mapStateToProps)(MessagesTile)
