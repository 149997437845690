import Api from '../helpers/api'

export const FETCH_HOUSEHOLD = 'FETCH_HOUSEHOLD'
export const FETCH_HOUSEHOLD_PENDING = 'FETCH_HOUSEHOLD_PENDING'
export const FETCH_HOUSEHOLD_REJECTED = 'FETCH_HOUSEHOLD_REJECTED'
export const FETCH_HOUSEHOLD_FULFILLED = 'FETCH_HOUSEHOLD_FULFILLED'

export const getHousehold = (): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_HOUSEHOLD,
    payload: apiInstance.get('/account').then((response) => {
      return response.data
    })
  }
}
