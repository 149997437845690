import React from 'react'
import Button from 'react-bootstrap/Button'

const NullState = (props) => {
  return (
    <div>
      <div className='d-flex flex-column justify-content-between align-items-start null__margin-flexbox'>
        <div className='null__fonts-first'>Your MoneyMind® quiz</div>
        <span className='null__fonts-second null__margin-second-text'>
          Help us better understand your relationship with money.
        </span>
        <div className='null__fonts-third null__margin-second-text'>
          When you understand what your money means to you, you can make clear,
          smart choices about your financial life.
        </div>
        <Button
          size='lg'
          onClick={props.buttonLink}
          className='null__margin-button'>
          Take MoneyMind® Quiz
        </Button>
      </div>
    </div>
  )
}

export default NullState
