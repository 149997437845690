import React, { KeyboardEvent, useState } from 'react'
import { FormControl, Button } from 'react-bootstrap'
import { GQLClient } from '../../../app'
import {
  MessageBodyQuery,
  CreateMessageReplyQuery
} from '../../../helpers/queries'

export interface MessageReplyProps {
  householdFinId: string
  threadId: string
  userId: string
  onReply(): void
}

const MessageReply = (props: MessageReplyProps) => {
  const [value, setValue] = useState('')
  const [isValid, setIsValid] = useState(true) // track if input valid
  const [submitted, setSubmitted] = useState(false) // track if submission attempted

  const onChange = (e) => {
    const newValue = e.target.value
    setValue(newValue)
  }
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      e.stopPropagation()
      sendMessage()
    }
  }
  const sendMessage = () => {
    const { householdFinId, threadId, userId, onReply } = props
    setSubmitted(true) // submission attempt

    if (!value) {
      setIsValid(false) // set isValid to false if input is empty on submit
      return
    }
    setIsValid(true) // reset validation state on successful submission

    GQLClient.mutate({
      mutation: CreateMessageReplyQuery,
      variables: { value, householdFinId, threadId, userId },
      refetchQueries: [
        {
          query: MessageBodyQuery,
          variables: { householdFinId, threadId }
        }
      ]
    })
      .then(() => {
        setValue('')
        setIsValid(true)
        setTimeout(onReply, 1000)
      })
      .catch((err) => {
        console.error('An error occurred')
      })
  }
  return (
    <div className='d-flex flex-row justify-content-start message-reply__container'>
      <div className='message-reply-w'>
        <div className='pb-2 message-reply-field__label'>Reply</div>
        <FormControl
          name='message-reply'
          type='text'
          placeholder='your reply'
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          isInvalid={!isValid && submitted}
          className='message-reply-field__input'
        />
        <Button
          size='lg'
          onClick={sendMessage}
          className='message-reply-field__button match-btn-width'>
          Send
        </Button>
      </div>
    </div>
  )
}

export default MessageReply
