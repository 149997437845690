import React from 'react'
import { dollarFormat } from '../../helpers'

interface NetWorthGraphProps {
  liabilitiesAmount: number
  assetsAmount: number
}

export class NetWorthGraph extends React.Component<NetWorthGraphProps, {}> {
  public render() {
    const { liabilitiesAmount, assetsAmount } = this.props
    const liabilitiesTotalFormat = dollarFormat(liabilitiesAmount, 0)
    const assetsTotalFormat = dollarFormat(assetsAmount, 0)

    const liabilitiesGraphFill =
      liabilitiesAmount === 0 && assetsAmount === 0
        ? '0%'
        : liabilitiesAmount > assetsAmount
        ? '100%'
        : Math.ceil((liabilitiesAmount / assetsAmount) * 100) + '%'

    const assetsGraphFill =
      liabilitiesAmount === 0 && assetsAmount === 0
        ? '0%'
        : assetsAmount > liabilitiesAmount
        ? '100%'
        : Math.ceil((assetsAmount / liabilitiesAmount) * 100) + '%'

    return (
      <div className='networth-hero-graph'>
        <div className='networth-hero-graph__content'>
          <span className='networth-hero-graph__title'>Assets</span>
          <span
            className={`networth-hero-graph__bar ${
              assetsGraphFill === '0%' ? 'networth-hero-graph__bar--null' : ''
            }`}>
            <span
              className='networth-hero-graph__bar-fill'
              style={{ width: assetsGraphFill }}
            />
          </span>
          <span className='networth-hero-graph__amount'>
            <span className='networth-hero-graph__dollar'>
              {assetsTotalFormat !== '$0' ? assetsTotalFormat : '-'}
            </span>
          </span>
        </div>

        <div className='networth-hero-graph__content'>
          <span className='networth-hero-graph__title'>Liabilities</span>
          <span
            className={`networth-hero-graph__bar ${
              liabilitiesGraphFill === '0%'
                ? 'networth-hero-graph__bar--null'
                : ''
            }`}>
            <span
              className='networth-hero-graph__bar-fill--liabilities'
              style={{ width: liabilitiesGraphFill }}
            />
          </span>
          <span className='networth-hero-graph__amount'>
            <span className='networth-hero-graph__dollar'>
              {liabilitiesTotalFormat !== '$0' ? liabilitiesTotalFormat : '-'}
            </span>
          </span>
        </div>
      </div>
    )
  }
}
