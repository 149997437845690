import dayjs, { Dayjs } from 'dayjs'
import qs from 'query-string'
import { isFinite } from 'lodash-es'
import store from '../store'
import { revokeAuthenticationToken } from '../actions/auth.action'
import { SortSettingProperty } from '../objects/preferenceSortSetting'
import { DiscreteAllocationObj } from '../objects/discreteAllocation'

const shortFormat = 'M/D/YY'
const longFormat = 'MM/DD/YYYY'
const strategyFormat = 'MMMM Do, YYYY'

export const dateFormat = (date: string | Date | Dayjs, fullYear?: boolean) => {
  if (date) {
    return dayjs(date).format(fullYear ? 'M/D/YYYY' : shortFormat)
  } else return null
}

export const timeFormat = (date: string) => {
  return dayjs(date).format('h:mm A')
}

const dateFormatter = (format: string) => (date: string | Date | Dayjs) => {
  if (date) {
    return dayjs(date).format(format)
  } else return null
}

export const dateFormatNet = dateFormatter(longFormat)
export const dateFormatStrategy = dateFormatter(strategyFormat)

export const simpleDateFormat = (date: string | Date | Dayjs) => {
  if (date && dayjs(date).isValid()) {
    return dayjs.utc(date).format('M/D/YY')
  } else return null
}

export const dollarFormat = (
  value: number,
  precision: number = 2,
  parentheses?: boolean
): string => {
  if (value || value === 0) {
    const sign = value < 0 ? '-' : ''
    const dollars = Math.abs(value)
      .toFixed(precision)
      .replace(/(\B)(?=(\d{3})+(?!\d))/g, ',')
    let dollarString = sign + '$' + dollars
    if (parentheses) {
      dollarString = `($${dollars})`
    }
    return dollarString
  } else {
    return null
  }
}

export const isObjEmpty = (obj: object) => !obj || Object.keys(obj).length < 1

export const roundToTwo = (num) => {
  var m = Number((Math.abs(num) * 100).toPrecision(15))
  return (Math.round(m) / 100) * Math.sign(num)
}

export const sanitizeCurrency = (input: string) => {
  if (input) return Math.round(parseFloat(input.replace(/[^0-9-.]/g, '')))
  else return null
}

export const valueOrDefault = (value: any, defaultValue = '') => {
  if (isFinite(value) || !isObjEmpty(value)) {
    return value
  }
  return defaultValue
}

export const getLoginURL = (): string => {
  const url: string = `${window.location.protocol}//${window.location.host}`
  const authQS = qs.stringify({
    response_type: 'code',
    client_id: window._env_.REACT_APP_CLIENT_KEY,
    redirect_uri: url + window._env_.REACT_APP_OAUTH_REDIRECT_URI
  })
  return `${window._env_.REACT_APP_OAUTH_URL_BASE}/services/oauth2/authorize/expid_GC?${authQS}`
}

export const revokeAndLogout = async () => {
  // We await this action because we don't want
  // the connection interrupted before the token can be revoked
  await store.dispatch(revokeAuthenticationToken())
  logout()
}

export const logout = () => {
  const loginURL = getLoginURL()
  const logoutUrl = `${
    window._env_.REACT_APP_LOGOUT_URL
  }?startURL=${encodeURIComponent(loginURL)}`
  window.location.href = logoutUrl
}

export const getCookieValue = (name: string) => {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'))
  return match ? match[2] : null
}

export const formatPhoneNumber = (phoneNum: string | number) => {
  if (!phoneNum) {
    return null
  }
  if (typeof phoneNum === 'number') {
    phoneNum = phoneNum.toString()
  }

  //split between phone number and opt extension.  Keep only digits from each
  const [phone, ext] = phoneNum
    .split(/ext/g)
    .map((str) => str.replace(/\D/g, ''))

  // we've only been asked to format if 7, 10, 11, 12+
  if (phone.length < 9 && phone.length !== 7) {
    return phoneNum
  }

  const phoneNumbers = phone.split('')
  const takeRight = (c: number) =>
    phoneNumbers.splice(Math.max(phoneNumbers.length - c, 0)).join('')
  const [line, exchange, areaCode, intCode] = [
    4,
    3,
    3,
    phoneNumbers.length
  ].map(takeRight)

  // +111 (222) 333-4444 ext. 123
  return `${intCode.length ? `+${intCode} ` : ''}${
    areaCode.length ? `(${areaCode}) ` : ''
  }${exchange.length ? `${exchange}-` : ''}${line}${
    ext && ext.length ? ` ext. ${ext}` : ''
  }`
}

export const compareStringField = (
  a: string,
  b: string,
  descending?: boolean
) => {
  return descending === true ? b.localeCompare(a) : a.localeCompare(b)
}

export const compareStatusField = (a: string, b: string, desc: boolean) => {
  const statusOrder = {
    'Optimal Position': 0,
    'Improvement Needed': 1,
    'Immediate Action Needed': 2,
    'NA | Pending Review': 3,
    'Not Selected': 4,
    'Show All': 5
  }
  return desc
    ? statusOrder[a] - statusOrder[b]
    : statusOrder[b] - statusOrder[a]
}

export const compareNumericField = (
  a: number,
  b: number,
  descending?: boolean
) => {
  const comparisonScore = a - b > 0 ? 1 : -1
  return descending === true ? -comparisonScore : comparisonScore
}

export const mapValueToOption = (
  optionSet: SortSettingProperty[],
  value: string
) => {
  return optionSet.filter((option) => {
    return value === option.label || value === option.value
  })[0]
}

export const compareAllocationField = (
  a: DiscreteAllocationObj,
  b: DiscreteAllocationObj,
  descending?: boolean
) => {
  return descending
    ? b.equity - a.equity ||
        b.fixed - a.fixed ||
        b.cash - a.cash ||
        b.alt - a.alt ||
        b.unclassified - a.unclassified
    : a.equity - b.equity ||
        a.fixed - b.fixed ||
        a.cash - b.cash ||
        a.alt - b.alt ||
        a.unclassified - b.unclassified
}

export const sanitizeSortFieldValues = (accountObjects: any, fields: any) => {
  const { sortBy, groupBy } = fields
  const sanitizedAccountObjects = {}
  Object.keys(accountObjects).forEach((key: string) => {
    const account = accountObjects[key]
    sanitizedAccountObjects[key] = account
    if (!account[sortBy]) {
      sanitizedAccountObjects[key][sortBy] = ''
    }
    if (!account[groupBy]) {
      sanitizedAccountObjects[key][groupBy] = ''
    }
  })
  return sanitizedAccountObjects
}

export const isEqual = (value: any, other: any) => {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false

  // Compare the length of the length of the two items
  const valueLen =
    type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen =
    type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) return false

  // Compare two items

  const compare = (item1: any, item2: any) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) return false

      // Otherwise, do a simple comparison
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false
      } else {
        if (item1 !== item2) return false
      }
    }
    return true
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false
    }
  } else {
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        if (compare(value[key], other[key]) === false) return false
      }
    }
  }

  // If nothing failed, return true
  return true
}

// constants
export const MAINTENANCE_MODE =
  window?._env_.REACT_APP_MAINTENANCE_MODE === 'true'
