import React from 'react'
import ControlledModalWrapper from '../../../components/ControlledModalWrapper'
import FileUpload from '../../../components/FileUpload'

interface IUploadPhotoModalProps {
  handleAddPhoto: (event: any) => void
  handlePhotoError: (name: any, errors: any) => void
}

const FILE_SIZE_MAX = 20971520
const ALLOWED_FILE_FORMATS = ['.jpg', '.jpeg', '.png', '.bmp', '.gif', '.tif']

const UploadPhotoModal = (props: IUploadPhotoModalProps) => {
  const { handleAddPhoto, handlePhotoError } = props

  return (
    <>
      <h3 className='rfont--h3 fw-bold text-dark mb-3'>Upload Photo</h3>
      <FileUpload
        handleAddFile={handleAddPhoto}
        handleFileError={handlePhotoError}
        description='Accepted formats: jpg, jpeg, png, bmp, gif, tif'
        allowedFileFormats={ALLOWED_FILE_FORMATS}
        allowedFileSize={FILE_SIZE_MAX}
      />
    </>
  )
}

export default ControlledModalWrapper(UploadPhotoModal)
