import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import ControlledModalWrapper from '../../components/ControlledModalWrapper'
import TextInput from '../../components/TextInput'

import { createFolder } from '../../actions/documentVault.action'
import { addSuccessToast, addErrorToast } from '../../actions/toasts.action'

import { folderRenameSchema } from '../../utils/validators'

type TCreateFolderModalActions = ReturnType<typeof mapDispatchToProps>
export interface CreateFolderModalProps {
  closeModal(): void
}

interface CreateFolderModalState {
  folderName: string
  createButtonDisabled: boolean
}

class CreateFolderModal extends React.Component<
  CreateFolderModalProps & TCreateFolderModalActions,
  CreateFolderModalState
> {
  constructor(props: CreateFolderModalProps & TCreateFolderModalActions) {
    super(props)
    this.state = {
      folderName: null,
      createButtonDisabled: true
    }
  }
  public createFolder = async (e) => {
    e.preventDefault()
    const { folderName } = this.state
    const {
      aCreateFolder,
      aAddSuccessToast,
      aAddErrorToast,
      closeModal
    } = this.props
    if (folderName) {
      closeModal()
      try {
        await aCreateFolder(folderName)
        aAddSuccessToast({ message: 'Folder successfully created' })
      } catch (error) {
        aAddErrorToast({
          message: 'Unable to create folder'
        })
      }
    }
  }

  public updateFolderName = (inputObj: {
    isValid: boolean
    inputValue: string
  }) => {
    this.setState({
      folderName: inputObj.inputValue,
      createButtonDisabled: !inputObj.isValid
    })
  }
  public cancelCreateFolder = () => {
    this.props.closeModal()
  }
  public render() {
    const { createButtonDisabled } = this.state
    return (
      <>
        <h1 className='vault-modal__title'>Create Folder</h1>
        <Form name='create-folder-form' onSubmit={this.createFolder}>
          <TextInput
            name='folder-name'
            label='Folder Name'
            placeholder='e.g. Personal Goals'
            className='pb-3'
            callBack={this.updateFolderName}
            validator={folderRenameSchema}
          />
          <div className='vault-modal__buttons'>
            <Button
              disabled={createButtonDisabled}
              type='submit'
              variant='primary'
              size='lg'
              className='match-btn-width'>
              Save
            </Button>
            <Button
              onClick={this.cancelCreateFolder}
              variant='link'
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      aCreateFolder: createFolder,
      aAddSuccessToast: addSuccessToast,
      aAddErrorToast: addErrorToast
    },
    dispatch
  )

export default connect(
  null,
  mapDispatchToProps
)(ControlledModalWrapper(CreateFolderModal))
