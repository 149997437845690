import { v4 } from 'uuid'
import { AppDispatch, AppThunkAction } from '../store'
import { ToastObj, ToastType, ToastIcon } from '../objects/toasts'

export const ADD_TOAST: string = 'ADD_TOAST'
export const REMOVE_TOAST_ID = 'REMOVE_TOAST_ID'
export const TOAST_TIMEOUT = 10000

// needed for v3 toasts
export const addToast = (toast: ToastObj): AppThunkAction => (dispatch) => {
  dispatch(addToastWithTimeout(toast))
}

const addToastById = (id: string, toast: ToastObj) => {
  return {
    type: ADD_TOAST,
    payload: {
      ...toast,
      id
    }
  }
}

export const removeToastById = (id: string) => {
  return {
    type: REMOVE_TOAST_ID,
    payload: id
  }
}

const addToastWithTimeout = (toast: ToastObj) => (dispatch: AppDispatch) => {
  const id = v4()
  dispatch(addToastById(id, toast))

  setTimeout(() => {
    dispatch(removeToastById(id))
  }, TOAST_TIMEOUT)
}

export const addSuccessToast = (toast: ToastObj): AppThunkAction => (
  dispatch
) => {
  dispatch(
    addToast({ ...toast, icon: ToastIcon.Success, type: ToastType.Success })
  )
}

export const addErrorToast = (toast: ToastObj): AppThunkAction => (
  dispatch
) => {
  dispatch(addToast({ ...toast, icon: ToastIcon.Error, type: ToastType.Error }))
}

export const networkErrorOrMsg = (
  error: any,
  errorMsg: string
): AppThunkAction => (dispatch) => {
  const message = error.message !== 'Network Error' ? errorMsg : 'System error'
  dispatch(addErrorToast({ message }))
}
