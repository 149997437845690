import React from 'react'
import Button from 'react-bootstrap/Button'
import EmptyTrashModal from './EmptyTrashModal'

interface EmptyTrashProps {
  inactive: boolean
  showEmptyTrashModal: boolean
  emptyTrashFunc(): void
  toggleEmptyTrashModal(): void
}

class EmptyTrash extends React.Component<EmptyTrashProps, {}> {
  public render() {
    const {
      inactive,
      emptyTrashFunc,
      toggleEmptyTrashModal,
      showEmptyTrashModal
    } = this.props
    return (
      <div>
        <Button
          variant='primary'
          size='lg'
          className='match-btn-width'
          onClick={toggleEmptyTrashModal}
          disabled={inactive}>
          Empty Trash
        </Button>

        <EmptyTrashModal
          isFullscreenOnMobile={false}
          modalProps={{ centered: true }}
          showModal={showEmptyTrashModal}
          emptyTrashFn={emptyTrashFunc}
          closeModal={toggleEmptyTrashModal}
        />
      </div>
    )
  }
}

export default EmptyTrash
