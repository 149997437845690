import * as actions from '../actions/yodlee.action'
import { YodleeObj } from '../objects/yodlee'

const initState: YodleeObj = {
  app: '10003600',
  redirectReq: 'true',
  rsession: null,
  token: null,
  //legacy.
  extraParams: '',
  params: {},
  fastlinkUrl: null
}

const Yodlee = (state = initState, action): YodleeObj => {
  switch (action.type) {
    case actions.FETCH_FASTLINK_FULFILLED:
      const fastlinkUrl = action.payload as string
      return { ...state, fastlinkUrl }
    case actions.FETCH_YODLEE_PARAMS_FULFILLED:
      const { rsession, token } = action.payload
      return { ...state, rsession, token, extraParams: '', params: {} }
    case actions.SET_YODLEE_PARAMS_ACCOUNT:
      const extraParams = action.payload
        ? 'siteAccountId=' + action.payload + '&flow=edit'
        : ''
      const params = { providerAccountId: action.payload, flow: 'edit' }
      return { ...state, extraParams, params }
    default:
      return state
  }
}

export default Yodlee
