import React from 'react'

export const FinancewareDisclosure = (): JSX.Element => {
  return (
    <div>
      <p className='disclosures__text'>
        GDX360: The projections or other information generated by Wealthcare
        Capital Management, Inc (“WCM”) regarding the likelihood of various
        investment outcomes are hypothetical in nature, do not reflect actual
        investment results and are not guarantees of future results.
      </p>
      <p className='disclosures__text'>
        The return assumptions in GDX360 are not reflective of any specific
        product, and do not include any fees or expenses that may be incurred by
        investing in specific products. The actual returns of a specific product
        may be more or less than the returns used in GDX360. It is not possible
        to directly invest in an index. Financial forecasts, rates of return,
        risk, inflation, and other assumptions may be used as the basis for
        illustrations. They should not be considered a guarantee of future
        performance or a guarantee of achieving overall financial objectives.
        Past performance is not a guarantee or a predictor of future results of
        either the indices or any particular investment.
      </p>
      <p className='disclosures__text'>
        GDX360 results may vary with each use and over time.
      </p>
      <p className='disclosures__text'>
        This report is a diagnostic tool intended to review your current
        financial situation and suggest potential planning ideas and concepts
        that may be of benefit. The purpose of the report is to illustrate how
        accepted financial and estate planning principles may improve your
        current situation.
      </p>
      <p className='disclosures__text'>
        This report is based upon information and assumptions provided by you
        (the client). This report provides broad and general guidelines on the
        advantages of certain financial planning concepts and does not
        constitute a recommendation of any particular technique. The
        consolidated report is provided for informational purposes as a courtesy
        to you. If any of the assumptions are incorrect, you should notify your
        financial advisor. Even small changes in assumptions can have a
        substantial impact on the results shown in this Report. We recommend
        that you review your plan annually, unless changes in your personal or
        financial circumstances require more frequent review. All reports should
        be reviewed in conjunction with your fact summary and this Disclaimer
        page.
      </p>

      <h3 className='disclosures__section-subtitle'>
        RESULTS USING MONTE CARLO SIMULATIONS
      </h3>
      <p className='disclosures__text'>
        The results referenced in this guide use Monte Carlo simulations. Tools
        such as the Monte Carlo simulation will yield different results
        depending on the variables inputted, and the assumptions underlying the
        calculation. For those reports that perform a Monte Carlo analysis, the
        term ‘Monte Carlo’ will be included in the report title. The assumptions
        with respect to the simulation include the assumed rates of return and
        standard deviations of the portfolio model associated with each asset.
        The assumed rates of return are based on the historical rates of returns
        and standard deviations, for certain periods of time, for the benchmark
        indices comprising the asset classes in the model portfolio. Since the
        market data used to generate these rates of return change over time your
        results will vary with each use over time.
      </p>
      <p className='disclosures__text'>
        Monte Carlo simulations are used to show how variations in rates of
        return each year can affect your results. A Monte Carlo simulation
        calculates the results of your Plan by running it many times, each time
        using a different sequence of returns. Some sequences of returns will
        give you better results, and some will give you worse results. These
        multiple trials provide a range of possible results, some successful
        (you would have met all your goals) and some unsuccessful (you would not
        have met all your goals). The percentage of trials that were successful
        is the probability that your Plan, with all its underlying assumptions,
        could be successful. In MoneyGuidePro, this is the Probability of
        Success. Analogously, the percentage of trials that were unsuccessful is
        the Probability of Failure. The Results Using Monte Carlo Simulations
        indicate the likelihood that an event may occur as well as the
        likelihood that it may not occur. In analyzing this information, please
        note that the analysis does not take into account actual market
        conditions, which may severely affect the outcome of your goals over the
        long-term.
      </p>
      <p className='disclosures__text'>
        Monte Carlo Analysis is a mathematical process used to implement complex
        statistical methods that chart the probability of certain financial
        outcomes at certain times in the future. This charting is accomplished
        by generating hundreds of possible economic scenarios that could affect
        the performance of your investments. The Monte Carlo simulation uses
        scenarios to determine the probability of outcomes resulting from the
        asset allocation choices and underlying assumptions regarding rates of
        return and volatility of certain asset classes. Some of these scenarios
        will assume very favorable financial market returns, consistent with
        some of the best periods in investing history for investors. Some
        scenarios will conform to the worst periods in investing history. Most
        scenarios will fall somewhere in between.
      </p>
      <p className='disclosures__text'>
        The projections or other information generated by this Monte Carlo
        simulation regarding various investment outcomes are hypothetical in
        nature and do not reflect actual investment results and are not
        guarantees of future results. The outcomes presented using the Monte
        Carlo simulation represent only a few of the many possible outcomes.
        Since past performance and market conditions may not be repeated in the
        future, your investment goals may not be fulfilled by following advice
        that is based on the projections.
      </p>

      <h3 className='disclosures__section-subtitle'>
        REPORT IS A SNAPSHOT AND DOES NOT PROVIDE LEGAL, TAX, OR ACCOUNTING
        ADVICE
      </h3>
      <p className='disclosures__text'>
        This Report provides a snapshot of your current financial position and
        can help you to focus on your financial resources and goals, and to
        create a plan of action. Because the results are calculated over many
        years, small changes can create large differences in future results. The
        information contained in this report is not written or intended as
        financial, tax or legal advice. The information provided herein may not
        be relied on for purposes of avoiding any federal tax penalties. Before
        making decisions with legal, tax, or accounting ramifications, you are
        encouraged to seek financial, tax and legal advice from your
        professional advisors.
      </p>

      <h3 className='disclosures__section-subtitle'>ANALYSIS METHODOLOGY</h3>
      <p className='disclosures__text'>
        The general methodology has these common characteristics as user inputs:
      </p>
      <ol>
        <li>One or more estimated financial spending goals;</li>
        <li>
          One or more financial resources that may be invested to generate
          funding for current or future financial goals;
        </li>
        <li>One or more estimated sources of cash flows into the plan;</li>
        <li>
          One or more investment portfolio designs based either on the risk and
          return characteristics of portfolios linked to financial resources as
          a whole or multiple portfolio designs based on those resources’ tax
          treatment characteristics and/or time periods during which a portfolio
          design is to apply;
        </li>
        <li>
          Tax rate assumptions, portfolio management, investment advisor and
          related investment expenses and portfolio turnover;
        </li>
        <li>
          Client information necessary to the calculation of estimated income
          taxes on the client(s)’ portfolio and social security benefits; and,
        </li>
        <li>
          Client and spouse or life partner date of birth, life expectancy (or
          random mortality analysis can be used).
        </li>
      </ol>
      <p className='disclosures__text'>
        These inputs are then calculated together in chronological sequence
        (cash in flows and out flows) in annual periods for each year through
        the end of the user selected planning period. The cash flows include
        simulated target portfolio investment results (gains or losses), net of
        estimated taxes, using one of three methods (the three evaluation or
        simulation methods available are Historic Audit, WealthSimulator®, and
        Monte Carlo
      </p>

      <h3 className='disclosures__section-subtitle'>HISTORICAL AUDIT</h3>
      <p className='disclosures__text'>
        This simulation method tests an investor’s financial strategies against
        historical returns using rolling historical periods.
      </p>

      <h3 className='disclosures__section-subtitle'>MONTE CARLO SIMULATION</h3>
      <p className='disclosures__text'>
        This simulation method tests an investor’s financial strategies against
        random scenarios of investment returns an investor might encounter
        during their lifetime based on the user’s capital market assumption for
        each asset class or portfolio. See above for additional information
        about the Monte Carlo simulation.
      </p>

      <h3 className='disclosures__section-subtitle'>WEALTH SIMULATOR®</h3>
      <p className='disclosures__text'>
        This simulation method tests an investor’s financial strategies against
        historical returns that have been placed in random order.
      </p>
      <p className='disclosures__text'>
        The process is repeated approximately 1,000 times, to generate
        approximately 1,000 “lifetimes” of hypothetical investment results and
        overfunded or underfunded wealth management results for each
        programmatic run. The resulting data comprises a “plan” or “plan
        scenario.” Results on up to four sets of plan scenarios may be presented
        in this report.
      </p>

      <h3 className='disclosures__section-subtitle'>
        LIMITATIONS OF THE ANALYSIS AND SENSITIVITY OF ASSUMPTIONS TO PLAN
        RESULTS
      </h3>
      <p className='disclosures__text'>
        The results of all simulated trials are used to evaluate and describe a
        hypothetical distribution of outcomes, but do not represent a forecast
        or prediction of actual expected investment or financial outcomes.
      </p>
      <p className='disclosures__text'>
        The results in this report are materially affected by the capital market
        assumptions used. For the Monte Carlo simulation methodology, this
        report uses asset class level capital market assumptions developed by
        WCM using an approach described in detail in its whitepaper titled “Are
        You Modeling What You Intended?”, as revised from time-to-time, which
        can be found at:{' '}
        <a
          href='https://www.wealthcarecapital.com/wp-content/uploads/2015/01/WP_areyoumodeling.pdf'
          target='_blank'
          rel='noopener noreferrer'>
          www.wealthcarecapital.com/wp-content/uploads/2015/01/WP_areyoumodeling.pdf
        </a>
      </p>
      <p className='disclosures__text'>
        Advisors may utilize the capital market assumptions provided by WCM or
        may choose to override them.
      </p>
      <p className='disclosures__text'>
        The estimated fees, costs and income taxes and other cash flow input
        assumptions may be materially different over the course of the related
        planning time horizon than the actual fees, costs and income tax
        consequences and other cash flows that will be incurred.
      </p>
      <p className='disclosures__text'>
        WCM licenses certain investment analysis and other technology tool
        components to certain Financial Advisors (or anyone that purchases
        technology tools from WCM via the Internet) under a subscription
        agreement (“Technology Subscriber”). Any Technology Subscriber that
        licenses this technology has the ability to control most of the
        assumptions utilized in the analysis presented in this report. It is
        important that you understand that the assumptions used regarding
        capital market assumptions and other financial or life event assumptions
        (such as retirement date, savings, spending needs, life expectancy,
        income tax rates, among others) will determine the results of any
        analysis, and therefore any results presented in this report could be
        materially misleading and have not been endorsed, validated, or examined
        by WCM acting as a registered investment advisor and should not be
        relied upon. Any investors not completely confident in their
        understanding of the complexity of these analytics should seek the
        advice of a professional who thoroughly understands the implications of
        the analysis and any assumptions used. If this report was prepared by
        your Financial Advisor who is a Technology Subscriber of WCM, it is your
        advisor’s obligation to completely disclose all material assumptions
        used, the basis for such assumptions, and also to clearly indicate that
        WCM has not endorsed, tested or validated any of their assumptions other
        than WCM’s default capital market assumptions for Monte Carlo or Wealth
        Simulator® if such advisor used those default assumptions and WCM may
        have made different recommendations if WCM were acting in a contractual
        advisory capacity.
      </p>
    </div>
  )
}
