import { createDeepEqualSelector } from './utilz'
import { GlobalState } from '../reducers'
import { UserObj } from '../objects/user'
import { ContactsState } from '../reducers/contacts.reducer'
import { ContactObj } from '../objects/contact'

const getContacts = (store: GlobalState) => store.contacts
const getUserId = (store: GlobalState) => store.user.id

export const getContact = createDeepEqualSelector(
  getContacts,
  getUserId,
  (contacts, userId) => {
    return Object.values(contacts).find((x) => x?.id === userId) as ContactObj
  }
)

export const getHouseholdContacts = createDeepEqualSelector(
  getContacts,
  (contacts) => contacts
)

export const getLoggedInUser = (user: UserObj, contacts: ContactsState) => {
  const { primary, secondary } = contacts || {
    primary: null,
    secondary: null
  }

  if (primary?.id === user?.id) {
    return primary
  }
  if (secondary?.id === user?.id) {
    return secondary
  }

  return null
}

export const getActiveScoringSession = (honestConversation, user, contacts) => {
  const { primary, secondary } = contacts || {
    primary: null,
    secondary: null
  }

  if (primary?.id === user?.id) {
    return honestConversation && honestConversation.activeScoringSessionPrimary
  }
  if (secondary?.id === user?.id) {
    return (
      honestConversation && honestConversation.activeScoringSessionSecondary
    )
  }

  return null
}
