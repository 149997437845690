import React from 'react'
import { Card } from 'react-bootstrap'

const MessagesNull = (): JSX.Element => (
  <Card id='messages-null-view' className='bg-white border-0 mt-5'>
    <div className='messages-null__w'>
      <h3 className='messages-null__body-header'>No messages here</h3>
      <span className='messages-null__body-description'>
        Start a new conversation by clicking "Compose" button on the top right.
      </span>
    </div>
  </Card>
)

export default MessagesNull
