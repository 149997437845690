import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import DashTileNull from '../../components/Dashboard/DashTileNull'
import DashTile, { DashTileLink } from '../../components/Dashboard/DashTile'
import { GlobalState } from '../../reducers'
import * as actions from '../../actions/clientAccounts.action'
import { getHousehold } from '../../actions/household.action'
import { history } from '../../store'
import { dollarFormat } from '../../helpers'
import { calculateAccountTotal } from '../../helpers/dashboard'

type DashAccountActions = typeof mapDispatchToProps
type DashAccountStore = ReturnType<typeof mapStateToProps>
export type DashAccountProps = DashAccountActions & DashAccountStore

const links: DashTileLink[] = [
  { label: 'View Net Worth', route: '/net-worth' },
  { label: 'View Investments', route: '/investments' }
]
const negativeValueDisplay = (sumString: string, cssClass: string) => (
  <span>
    <span className='dashboard-account__sum dashboard-account__sum-negative'>
      -
    </span>
    <span className={cssClass}>{sumString.substring(1)}</span>
  </span>
)

const nullValueDisplay = <div className='mt-3 dashboard-account__sum-null' />

const valueTotalStyle = (sumString: string) =>
  `dashboard-account__sum dashboard-account__sum-dollar ${sumString.length >
    15 && 'dashboard-account__sum-long'}`

const getTotal = (accounts) =>
  accounts.reduce((pre, nextObj) => pre + Number(nextObj.totalValue), 0)

export const AccountNullState = (props) => (
  <DashTileNull
    className='dashboard-account__null-container'
    title='No Accounts'
    description={
      props.disableBtn
        ? 'Contact your advisor to add accounts.'
        : 'Add your first account to get started.'
    }
    actionBtn={
      props.disableBtn
        ? null
        : {
            text: 'Add Account',
            onClick: () => {
              history.push('/net-worth/account/add')
            }
          }
    }
  />
)

export const AccountDisplay = (props) => {
  const { allNetAccounts, clientAccounts } = props
  const assetsTotal = getTotal(clientAccounts.assets)
  const liabilitiesTotal = getTotal(clientAccounts.liabilities)
  const netWorthTotal = dollarFormat(assetsTotal - liabilitiesTotal, 0)
  const netWorthIsNegative = assetsTotal - liabilitiesTotal < 0
  const noNetAccount = !allNetAccounts || allNetAccounts.length === 0

  const { investments } = clientAccounts
  const investmentValue = calculateAccountTotal(investments).total
  const investmentIsNegative = investmentValue < 0
  const investmentsTotal = dollarFormat(investmentValue, 0)
  const noInvestmentAccount = !investments || investments.length === 0

  const netWorthDisplay = netWorthIsNegative ? (
    negativeValueDisplay(netWorthTotal, valueTotalStyle(netWorthTotal))
  ) : noNetAccount ? (
    nullValueDisplay
  ) : (
    <span className={valueTotalStyle(netWorthTotal)}>{netWorthTotal}</span>
  )

  const investmentDisplay = investmentIsNegative ? (
    negativeValueDisplay(investmentsTotal, valueTotalStyle(investmentsTotal))
  ) : noInvestmentAccount ? (
    nullValueDisplay
  ) : (
    <span className={valueTotalStyle(investmentsTotal)}>
      {investmentsTotal}
    </span>
  )

  return (
    <div>
      <div>
        <div className='dashboard-account__tile'>Net Worth</div>
        {netWorthDisplay}
      </div>
      <div>
        <div className='dashboard-account__tile mt-4'>Investments</div>
        {investmentDisplay}
      </div>
    </div>
  )
}

export const AccountsTile = (props) => {
  const {
    clientAccounts,
    user,
    getAccounts,
    getInvestmentAccounts,
    getHousehold
  } = props
  const { investments } = clientAccounts
  const householdFetched = user?.householdFetched

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [householdFetched])

  useEffect(() => {
    getAccounts()
    getInvestmentAccounts()
  }, [])

  const disableAddAccountButton =
    user?.aggregationProvider !== 'yodlee' && user?.disableManualAccount

  const allNetAccounts = clientAccounts.assets.concat(
    clientAccounts.liabilities
  )
  const noAccounts =
    (!allNetAccounts || allNetAccounts.length === 0) &&
    (!investments || investments.length === 0)
  return (
    <DashTile
      title='Accounts'
      links={links}
      nullComponent={<AccountNullState disableBtn={disableAddAccountButton} />}
      showNull={noAccounts}>
      {clientAccounts && investments && (
        <AccountDisplay
          clientAccounts={clientAccounts}
          allNetAccounts={allNetAccounts}
        />
      )}
    </DashTile>
  )
}

const mapDispatchToProps = {
  getAccounts: actions.getClientAccounts,
  getInvestmentAccounts: actions.getInvestmentAccounts,
  getHousehold
}
const mapStateToProps = (store: GlobalState) => {
  return {
    clientAccounts: store.clientAccounts,
    user: store.user
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountsTile)
