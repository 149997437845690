import React from 'react'
import GeneralDisclosures from '../../containers/Disclosures/GeneralDisclosures'

export function FooterContainer() {
  const renderFooterLinks = () => {
    const footerLink = (href: string, title: string) => {
      return (
        <a
          href={href}
          className='footer__links-link'
          rel='noopener noreferrer'
          target='_blank'>
          {title}
        </a>
      )
    }

    return (
      <div className='footer__links-container'>
        {footerLink(
          'https://www.unitedcapitalwealth.com/privacy-policy/',
          'Privacy Policy'
        )}
        {footerLink(
          'https://www.unitedcapitalwealth.com/disclosures/',
          'Site Disclosures'
        )}
      </div>
    )
  }

  return (
    <div className='footer__w'>
      <div className='footer__links-w'>
        {renderFooterLinks()}
        <GeneralDisclosures />
      </div>
    </div>
  )
}
