import React from 'react'
import dayjs from 'dayjs'

import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { ReactComponent as FolderIcon } from '../../assets/images/icons/folder_marcus.svg'
import { ReactComponent as RestoredFileIcon } from '../../assets/images/icons/base.svg'

export const IconCell = ({ type, content }) => {
  const isNew = content.newFolders && content.newFolders[content.id]
  if (type === 'folder')
    return isNew ? (
      <div>
        <span className='trash__newicon'>
          {' '}
          <RestoredFileIcon />{' '}
        </span>
        <span className='p-2'>
          <FolderIcon xlinkTitle='folder' />
        </span>
      </div>
    ) : (
      <div className='p-2'>
        <FolderIcon xlinkTitle='folder' />
      </div>
    )

  return <i className='bi bi-file-earmark-text fs-5' />
}

const NameCell = ({ content }) => (
  <div className='vault-table__text'>{content?.name} </div>
)

const MetaCell = ({ type, content }) => {
  let info: string
  if (type === 'folder') {
    info = `${content?.fileCount} ${
      content?.fileCount === 1 ? 'File' : 'Files'
    }`
  } else {
    info =
      dayjs().format('M/DD/YYYY') ===
      dayjs(content?.createdDate).format('M/DD/YYYY')
        ? 'Today'
        : dayjs(content?.createdDate).format('M/DD/YYYY')
  }

  return <div className='vault-table__text'>{info}</div>
}

const OverflowMenuCell = ({ id, type, content }) => {
  const { readOnly, fileCount, toggleModal } = content
  if (readOnly) return null

  return (
    <DropdownButton
      drop='down-centered'
      variant='link'
      size='sm'
      title={<i className='bi bi-three-dots fs-5 text-primary'></i>}
      className='vault__dropdown-button'>
      {(type === 'file' || (type === 'folder' && fileCount === 0)) && (
        <Dropdown.Item
          className='vault__dropdown-item'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(id, 'delete', type)
          }}>
          Delete
        </Dropdown.Item>
      )}
      {type !== 'archived' && (
        <Dropdown.Item
          className='vault__dropdown-item'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(id, 'edit', type)
          }}>
          Rename
        </Dropdown.Item>
      )}

      {type === 'archived' && (
        <Dropdown.Item
          className='vault__dropdown-item'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(id, 'restore', type)
          }}>
          Restore
        </Dropdown.Item>
      )}
      {type === 'archived' && (
        <Dropdown.Item
          className='vault__dropdown-item'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(id, 'hard_delete', type)
          }}>
          Delete Permanently
        </Dropdown.Item>
      )}
      {type === 'file' && (
        <Dropdown.Item
          className='vault__dropdown-item'
          onClick={(e) => {
            e.stopPropagation()
            toggleModal(id, 'move', type)
          }}>
          Move
        </Dropdown.Item>
      )}
    </DropdownButton>
  )
}

export const getDocVaultTableRow = (width: number) => {
  return [
    {
      Cell: IconCell,
      style: { width: width > 768 ? 53 : 42, padding: '24px 8px' }
    },
    {
      Cell: NameCell,
      style: {
        padding: '24px 8px',
        wordBreak: 'break-word' as React.CSSProperties['wordBreak']
      }
    },
    {
      Cell: MetaCell,
      style: { width: width > 768 ? 144 : 110, padding: '24px 8px' }
    },
    {
      Cell: OverflowMenuCell,
      overflowable: true,
      style: { width: 50, padding: '24px 8px' }
    }
  ]
}

export const getDocVaultFileRow = (width) => {
  return [
    {
      Cell: IconCell,
      columnHeader: '',
      columnHeaderStyle: { width: width > 768 ? 53 : 42 },
      style: { padding: '24px 8px' }
    },
    {
      Cell: NameCell,
      columnHeader: 'Name',
      columnHeaderStyle: { padding: '24px 8px', fontSize: 14 },
      style: {
        padding: '24px 8px',
        wordBreak: 'break-word' as React.CSSProperties['wordBreak']
      }
    },
    {
      Cell: MetaCell,
      columnHeader: 'Date',
      columnHeaderStyle: {
        width: width > 768 ? 144 : 110,
        padding: '24px 8px',
        fontSize: 14
      },
      style: { padding: '24px 8px' }
    },
    {
      Cell: OverflowMenuCell,
      overflowable: true,
      columnHeader: '',
      columnHeaderStyle: { width: 50 },
      style: { padding: '24px 8px' }
    }
  ]
}
