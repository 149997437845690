import * as actions from '../actions/auth.action'
import { AuthObj } from '../objects/auth'
import { LoadingStatus } from './index'

export interface AuthState extends AuthObj {
  loadingStatus: LoadingStatus
  // the value doesn't matter here as long as it changes so the timeout resets
  sessionTimeoutToggle: boolean
  sessionTimeout: number
}

const initState: AuthState = {
  userContactId: '',
  accessToken: null,
  loadingStatus: {
    isLoading: false,
    isLoaded: false,
    hasFailed: false
  },
  sessionTimeoutToggle: false,
  sessionTimeout: 60
}

const Auth = (state = initState, action): AuthState => {
  const hasLoaded = {
    isLoading: false,
    isLoaded: true,
    hasFailed: false
  }
  switch (action.type) {
    case actions.FETCH_AUTHENTICATION_TOKENS_FULFILLED:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        loadingStatus: hasLoaded
      }
    case actions.REVOKE_ACCESS_TOKEN_FULFILLED:
      return { ...state, accessToken: null, loadingStatus: hasLoaded }
    case actions.RESET_SESSION_TIMEOUT:
      // convert to seconds
      const sessionTimeout =
        Number(window._env_.REACT_APP_SESSION_TIMEOUT_MINUTES) * 60
      return {
        ...state,
        sessionTimeout,
        sessionTimeoutToggle: !state.sessionTimeoutToggle
      }
    case actions.AUTH_PASSTHROUGH:
      return {
        ...state,
        accessToken: action.payload.accessToken,
        userContactId: action.payload.contactId,
        loadingStatus: hasLoaded
      }
    case `${action.SYNC_USER_HOUSEHOLDS}_FULFILLED`:
      return {
        ...state
      }
    default:
      return state
  }
}

export default Auth
