import React, { useState } from 'react'
import { startCase } from 'lodash-es'
import { FinancialGoalObj } from '../../../objects/plan'
import { getPeriod } from '../../../helpers/plan'
import DeleteInputModal from '../PlanInputForms/DeleteInputModal'
import { Dropdown, Card, DropdownButton } from 'react-bootstrap'

export interface PlanInputTableProps {
  title: string
  goals: FinancialGoalObj[]
  hidePriority: boolean
  onEditGoal(goal: FinancialGoalObj): void
  onDeleteGoal(): void
}

type TTableData = {
  id: string
  goal: FinancialGoalObj
  onEditGoal: (goal: FinancialGoalObj) => void
  toggleDeleteModal: (goal: any) => void
  setDeleteGoal: React.Dispatch<(prevState: undefined) => undefined>
}[]

export const textCell = (
  label: string,
  getField: (goal: FinancialGoalObj) => string
) => ({ goal }: { goal: FinancialGoalObj }) => {
  const field = getField(goal)
  return (
    field && (
      <div>
        <div className='plan-inputs-table__field'>{getField(goal)}</div>
        <div className='plan-inputs-table__label'>{label}</div>
      </div>
    )
  )
}

export const NameCell = textCell('Name', ({ name }) => name)

export const MobileNameCell = ({ goal }) => {
  const { name, startDate = '', endDate = '' } = goal
  return (
    <div>
      <div className='plan-inputs-table__field'>{name}</div>
      <div className='plan-inputs-table__label'>
        {getPeriod(startDate, endDate)}
      </div>
    </div>
  )
}

export const PeriodCell = textCell('Period', ({ startDate, endDate }) => {
  return getPeriod(startDate, endDate)
})

export const PriorityCell = textCell('Priority', ({ needsWantsWishes }) =>
  startCase(needsWantsWishes?.toLowerCase())
)

export const AmountCell = textCell('Amount', ({ amount }) => amount)

export const MenuCell = ({ goal, onEditGoal, toggleDeleteModal }) => {
  const handleClick = (callback) => (evt: React.MouseEvent<HTMLDivElement>) => {
    evt.stopPropagation()
    callback(goal)
  }

  return (
    <DropdownButton
      className='text-primary plan-inputs__overflow'
      variant=''
      title={<i className='bi bi-three-dots'></i>}
      drop='down-centered'>
      <Dropdown.Item onClick={handleClick(onEditGoal)}>Edit</Dropdown.Item>
      <Dropdown.Item onClick={handleClick(() => toggleDeleteModal(goal))}>
        Delete
      </Dropdown.Item>
    </DropdownButton>
  )
}

type TableColumns = ReturnType<typeof getTableColumns>

// in mobile view, MobileNameCell and MenuCell will sum 35% (keep PlanInputTable and PendingUpdateTable mobile views the same)
const getTableColumns = (hidePriority: boolean) => [
  {
    Cell: NameCell,
    style: { width: '25%' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: MobileNameCell,
    style: { width: '30%' },
    className: 'plan-inputs-table__column--only-mobile'
  },
  {
    Cell: PeriodCell,
    style: { width: '17%' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: PriorityCell,
    style: { width: '13%', visibility: hidePriority ? 'hidden' : 'visible' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: AmountCell,
    style: { width: '10%' },
    className: 'plan-inputs-table__column--no-mobile'
  },
  {
    Cell: MenuCell,
    style: { width: '5%' },
    className: 'plan-inputs-table__column'
  }
]

const PlanInputTable = ({
  title = '',
  goals = [],
  hidePriority,
  onEditGoal,
  onDeleteGoal
}: PlanInputTableProps) => {
  const [deleteModalOpen, toggleModal] = useState(false)
  const [targetDeleteGoal, setDeleteGoal] = useState()
  if (!goals?.length) {
    return null
  }

  const toggleDeleteModal = (goal) => {
    setDeleteGoal(goal)
    toggleModal(!deleteModalOpen)
  }
  const id = title.replace(/\W/g, '-').toLowerCase()
  const data = goals.map((goal) => ({
    id: goal.id,
    goal,
    onEditGoal,
    toggleDeleteModal,
    setDeleteGoal
  }))

  const renderTableRows = (data: TTableData, columns: TableColumns) => {
    return data?.map((item, index) => (
      <tr key={item.id} className='plan-inputs-table__row'>
        {columns.map((column, colIndex) => {
          const CellComponent = column.Cell
          return (
            <td
              key={colIndex}
              style={column.style as any}
              className={column.className}>
              <CellComponent
                goal={item.goal}
                onEditGoal={item.onEditGoal}
                toggleDeleteModal={item.toggleDeleteModal}
              />
            </td>
          )
        })}
      </tr>
    ))
  }

  const CustomPlanInputTable = ({
    id,
    data,
    hidePriority
  }: {
    id: string
    data: TTableData
    hidePriority: boolean
  }) => {
    const columns = getTableColumns(hidePriority)

    return (
      <table id={id}>
        <tbody>{renderTableRows(data, columns)}</tbody>
      </table>
    )
  }

  return (
    <Card
      id={`goals-card-${id}`}
      className='bg-white border-0 p-0 rounded-0 plan-inputs-table'>
      <h3 className='plan-inputs-table__title'>{title}</h3>

      <CustomPlanInputTable
        id={`goals-table-${id}`}
        data={data}
        hidePriority={hidePriority}
      />

      <DeleteInputModal
        show={deleteModalOpen}
        goal={targetDeleteGoal}
        onDeleteGoal={onDeleteGoal}
        closeModal={() => toggleModal(false)}
      />
    </Card>
  )
}

export default PlanInputTable
