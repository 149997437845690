import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  ApolloClient,
  split,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { withRouter, RouteComponentProps } from 'react-router'

import { GlobalState } from './reducers'
import { AppDispatch } from './store'
import { setAdvisorImpersonation } from './actions/user.action'
import { setUser } from './analytics/googleTagManager'

import RouteIndex from './Routes'

import './assets/styles/master.scss'

export let GQLClient: ApolloClient<NormalizedCacheObject> = null

interface AppProps {
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
  userId: string
  userType: string
  userVersion: string
  disableDocumentVault: boolean
  termsOfServiceLoading: boolean
  termsOfServiceAccepted: boolean
  userContactId: string
  accessToken: string
  isAppBannerVisible: boolean
  dispatch: AppDispatch
}

interface AppState {
  entryPermitted: boolean
}

class App extends Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)
    this.state = {
      entryPermitted: props.termsOfServiceAccepted
    }
  }

  public componentDidMount() {
    const { location, dispatch } = this.props
    if (location?.search && location?.search.startsWith('?contact=')) {
      dispatch(setAdvisorImpersonation())
    }
  }

  public componentDidUpdate(prev: AppProps) {
    const {
      termsOfServiceAccepted,
      userContactId,
      userId,
      userType,
      accessToken
    } = this.props
    const entryPermitted =
      userContactId.length > 0 ? true : termsOfServiceAccepted
    if (!prev.userId && userId) {
      setUser(userId, userType)
    }
    if (entryPermitted !== this.state.entryPermitted) {
      this.setState({
        entryPermitted
      })
    }
    if (!GQLClient && accessToken) {
      this.initializeGQL(accessToken)
    }
  }

  private initializeGQL = (accessToken: string) => {
    const cache = new InMemoryCache()
    // Create an http link:
    const httpLink = new HttpLink({
      uri: `https://${window._env_.REACT_APP_ASYNC_URL}`,
      headers: {
        Authorization: `Bearer client:${accessToken}`
      }
    })
    const wsLink = new WebSocketLink({
      uri: `wss://${window._env_.REACT_APP_ASYNC_SUBSCRIPTION_URL}`,
      options: {
        timeout: 60 * 1000, // give it 60 seconds to get a keep-alive message before retrying
        reconnect: true,
        reconnectionAttempts: 3,
        lazy: true,
        connectionParams: {
          Authorization: `Bearer client:${accessToken}`
        }
      }
    })
    const link = split(
      // split based on operation type
      ({ query }) => {
        const queryDoc = getMainDefinition(query)
        return (
          queryDoc.kind === 'OperationDefinition' &&
          queryDoc.operation === 'subscription'
        )
      },
      wsLink,
      httpLink
    )
    GQLClient = new ApolloClient({ cache, link })
  }

  public render() {
    const { entryPermitted } = this.state
    const {
      termsOfServiceLoading,
      disableDocumentVault,
      history,
      accessToken,
      userId,
      isAppBannerVisible
    } = this.props

    return (
      <RouteIndex
        history={history}
        entryPermitted={entryPermitted}
        termsOfServiceLoading={termsOfServiceLoading}
        disableDocumentVault={disableDocumentVault}
        accessToken={accessToken}
        userId={userId}
        isAppBannerVisible={isAppBannerVisible}
        initializeGQL={this.initializeGQL}
      />
    )
  }
}

export default withRouter(
  connect((store: GlobalState, { location, history }: any) => {
    const { user, auth } = store
    return {
      history,
      location,
      userId: user.id,
      userType: user ? (user.isFinlifeInstitution ? 'Finlife' : 'PFM') : null,
      disableDocumentVault: user.disableDocumentVault,
      termsOfServiceLoading: user.termsOfServiceLoading,
      termsOfServiceAccepted: user.termsOfServiceAccepted,
      userContactId: auth.userContactId,
      accessToken: auth.accessToken
    }
  })(App)
)
