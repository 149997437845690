import React from 'react'
import Button from 'react-bootstrap/Button'
import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface DeleteModalProps {
  id: string
  type: string
  deleteModal(id: string, folderId?: string): void
  closeModal(id: string): void
}

class DeleteModal extends React.Component<DeleteModalProps> {
  public deleteFn = () => {
    const { id, deleteModal } = this.props
    deleteModal(id)
  }

  public closeFn = () => {
    this.props.closeModal('')
  }

  public render() {
    const isFolder = this.props.type === 'folder'
    return (
      <>
        <h1 className='vault-modal__title'>
          {isFolder ? 'Delete Folder' : 'Delete File'}
        </h1>
        <div className='vault-modal__content'>
          <p>
            {isFolder
              ? 'Are you sure you want to delete this folder?'
              : 'Deleting this file will move it to the trash section, from where it can be restored. Are you sure you want to delete this file?'}
          </p>
          <div className='vault-modal__buttons'>
            <Button
              id='delete-modal-save'
              variant='primary'
              size='lg'
              className='match-btn-width'
              onClick={this.deleteFn}>
              Delete
            </Button>
            <Button
              id='delete-modal-cancel'
              variant='link'
              onClick={this.closeFn}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default ControlledModalWrapper(DeleteModal)
