import React, { useState, useEffect } from 'react'
import { isEqual } from 'lodash-es'
import Rechart from './Rechart'
import ChartLabel from './ChartLabel'

export const INACTIVE_INDEX = -1

interface DefaultLabel {
  top?: string
  bottom?: string
}
interface DonutChartProps {
  data?: Array<any>
  hoverEffect?: boolean
  activeIndex?: number
  dataKey?: string
  nameKey?: string
  defaultLabel?: DefaultLabel
  noLabelOnHover?: boolean
  formatLabelValue?: (value: number | string) => string
  onActiveSector?: (index: number) => void
}

const emptyStateData = [
  { value: 1, fill: '#E6ECF3', stroke: 'transparent', fillOpacity: 0.2 }
]

const centerCoords = {
  y: '45%'
}

const DonutChart = ({
  data: propsData = [], // the data to display
  defaultLabel = {}, // label text to display when there is no data, empty renders no label
  nameKey = 'name', // used for top text in label when sector is active
  dataKey = 'value', // value key to use for bottom text of label when sector is active
  formatLabelValue = (value) => `${value}`, // format active sector value for label, e.g., (0.5) => 50%
  activeIndex = INACTIVE_INDEX, // set the active index externally
  hoverEffect = true, // enable hover effect for sectors
  noLabelOnHover = false, // hide the inner label on hover
  onActiveSector // event handler when a sector is hovered over
}: DonutChartProps) => {
  const [data, setData] = useState(propsData) // manipulated data we'll show in the chart
  const [active, setActive] = useState(activeIndex) // index of the active sector
  const hasData = data.length > 0

  // only want hover effect if chart has data and hover enabled
  const hoverEnabled = hasData && hoverEffect

  // on mouse enter, set active index and opacity of inactive sectors
  const handleMouseEnter = (_, index: number) => {
    if (!hoverEnabled) return
    const updated = propsData.map((row, i) => {
      if (i === index) {
        return row
      }
      return { ...row, fillOpacity: 0.5, stroke: 'transparent' }
    })
    setData(updated)
    setActive(index)
    onActiveSector && onActiveSector(index)
  }

  // on mouse leave, reset data and active index
  const handleMouseLeave = () => {
    if (!hoverEnabled) return
    setData(propsData)
    setActive(INACTIVE_INDEX)
    onActiveSector && onActiveSector(INACTIVE_INDEX)
  }

  const Label = () => {
    // render the default label if no sector is active
    // if no default label, render nothing
    if (active === INACTIVE_INDEX) {
      return defaultLabel ? (
        <ChartLabel
          centerText={{
            value: defaultLabel?.top,
            className: 'donut-chart__label donut-chart__label--default',
            ...centerCoords
          }}
          bottomText={{
            value: defaultLabel?.bottom,
            className: 'donut-chart__label donut-chart__label--default',
            dy: '24'
          }}
        />
      ) : null
    }

    // no label to show on hover
    if (noLabelOnHover) {
      return null
    }

    // get the active sector data and render it in label
    const sector = data[active]
    return (
      <ChartLabel
        centerText={{
          value: sector[nameKey],
          className: 'donut-chart__label',
          ...centerCoords
        }}
        bottomText={{
          value: formatLabelValue(sector[dataKey]),
          className: 'donut-chart__label donut-chart__label-value',
          dy: '24'
        }}
      />
    )
  }

  // only want this to fire if activeIndex prop has changed
  useEffect(() => {
    if (active !== activeIndex) {
      setActive(activeIndex)
    }
  }, [activeIndex, setActive])

  // update data in state if props data has changed
  useEffect(() => {
    if (!isEqual(propsData, data)) {
      setData(propsData)
    }
  }, [propsData, setData])

  return (
    <Rechart
      activeIndex={active}
      data={data}
      emptyStateData={emptyStateData}
      emptyStateGradient={false}
      height={400}
      Label={Label}
      growActiveShape={5}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseEnter}
      responsive={true}
      innerRadius='60%'
    />
  )
}

export default DonutChart
