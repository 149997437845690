import React, { Dispatch, SetStateAction } from 'react'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { Views } from './index'

export interface MainViewProps {
  setView: Dispatch<SetStateAction<Views>>
  hasYodlee: boolean
  disableManualAccount: boolean
  householdFetched: boolean
}

const Main = ({
  hasYodlee,
  disableManualAccount,
  householdFetched,
  setView
}: MainViewProps) => {
  return (
    <Card
      id='networth-add-account'
      className='border-0 rounded-0 bg-white p-x2'>
      <Row className='d-flex flex-row justify-content-start networth-interstitial'>
        <Col xs={12} sm={8} className='mb-4 pe-2 mb-sm-0'>
          <div className='pe-3 networth-interstitial__title'>
            <div className='m-0 p-0'>
              <h2 className='rfont--h2 mt-0 mb-3'>Add Account</h2>
              <div className='mt-2 networth-interstitial__sub-title'>
                Seeing a full picture of all your assets and liabilities is
                instrumental in helping your advisor develop an accurate
                financial plan. Choose the method that works best for each entry
                below.
              </div>
            </div>
          </div>
        </Col>
        {householdFetched && (
          <>
            {hasYodlee && (
              <Col xs={12} sm={6}>
                <Card
                  id='networth-instant-account'
                  className='p-3 p-md-x2 d-block rounded-0 networth-interstitial__option'>
                  <h2 className='rfont--h2 mt-0 mb-3'>Instantly Link</h2>
                  <p>
                    Enter basic account information to connect your accounts.
                    Any connected accounts will update automatically so your Net
                    Worth is up to date.
                  </p>
                  <Button
                    className='fw-bold match-btn-width'
                    size='lg'
                    onClick={() => setView(Views.instant)}>
                    Instantly Link
                  </Button>
                </Card>
              </Col>
            )}
            {!disableManualAccount && (
              <Col xs={12} sm={6}>
                <Card
                  id='networth-manual-account'
                  className='p-3 p-md-x2 d-block rounded-0 networth-interstitial__option-manual'>
                  <h2 className='rfont--h2 mt-0 mb-3'>Manually Link</h2>
                  <p>
                    If you are unable to enter an account or you want to add an
                    asset or liability, click here to enter basic information so
                    they are included on your net worth.
                  </p>
                  <Button
                    className='font-color--dark fw-bold match-btn-width'
                    variant='outline-primary'
                    size='lg'
                    onClick={() => setView(Views.manual)}>
                    Manually Link
                  </Button>
                </Card>
              </Col>
            )}
          </>
        )}
      </Row>
    </Card>
  )
}

export default Main
