import React from 'react'

interface NewDocumentNotificationProps {
  batchSize: number
  content: any
  firstName: string
  lastName: string
}

function NewDocumentNotification({
  batchSize,
  content,
  firstName,
  lastName
}: NewDocumentNotificationProps): JSX.Element {
  const displayName = content?.data?.fileName
  const displayFolderName = content?.data?.folderName

  return (
    <div className='header__notification-message'>
      {batchSize > 1 ? (
        <>
          <span className='header__notification-name'>
            {firstName} {lastName} uploaded {batchSize} new files to{' '}
            {displayFolderName}.
          </span>
        </>
      ) : (
        <>
          <span className='header__notification-name'>
            {firstName} {lastName} uploaded {displayName} to {displayFolderName}
            .
          </span>
        </>
      )}
    </div>
  )
}

export default NewDocumentNotification
