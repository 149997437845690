import { createDeepEqualSelector } from './utilz'
import { GlobalState } from '../reducers'
import { ContactObj, ContactsInterface } from '../objects/contact'

const getContacts = (store: GlobalState) => store.contacts
const getUserId = (store: GlobalState) => store.user.id

export const getSelfMoneyMind = createDeepEqualSelector(
  getContacts,
  getUserId,
  (contacts: ContactsInterface, userId: string) => {
    const self: ContactObj = Object.values(contacts).find(
      (x) => x?.id === userId
    )
    return {
      moneymind: self?.moneymind,
      moneymindDescription: self?.moneymindDescription
    }
  }
)

export const getPartnerMoneyMind = createDeepEqualSelector(
  getContacts,
  getUserId,
  (contacts: ContactsInterface, userId: string) => {
    const partner: ContactObj = Object.values(contacts).find(
      (x) => x?.id !== userId
    )
    return {
      contactName: partner?.firstName,
      moneymind: partner?.moneymind,
      moneymindDescription: partner?.moneymindDescription
    }
  }
)

export const getHouseholdPartner = createDeepEqualSelector(
  getContacts,
  getUserId,
  (contacts: ContactsInterface, userId: string) => {
    return Object.values(contacts).find((x) => x?.id !== userId)
  }
)
