import React from 'react'
import classnames from 'classnames'
import { iconList } from '../../../helpers/priorities'

interface PriorityCardProps {
  active: boolean
  title: string
  category: string
  primaryScore?: number
  secondaryScore?: number
  isPersonal?: boolean
  singleHH?: boolean
  onClick?: () => void
}

const PriorityCard = (props: PriorityCardProps): JSX.Element => {
  const {
    active,
    title,
    primaryScore,
    secondaryScore,
    category,
    isPersonal = false,
    singleHH = false
  } = props
  const className = classnames(
    `honestConversations-cards__card${active ? '' : '--inactive'}`,
    `honestConversations-cards__card${isPersonal ? '--dark' : ''}${
      active ? '' : '-inactive'
    }`
  )
  const underlineClassname = classnames(
    `honestConversations-cards__underline${active ? '' : '--inactive'}`,
    `honestConversations-cards__underline${isPersonal ? '--dark' : ''}`
  )
  return (
    <div className={className}>
      <div className='honestConversations-cards__text'>
        <span>{title}</span>
      </div>
      <div className='honestConversations-cards__score-container'>
        <div className='honestConversations-cards__scores'>
          {(primaryScore || secondaryScore) && (
            <span className='honestConversations-cards__score'>
              {primaryScore}
            </span>
          )}
          {!singleHH
            ? (primaryScore || secondaryScore) && (
                <span className='honestConversations-cards__score'>
                  {secondaryScore}
                </span>
              )
            : null}
        </div>
        <span className='honestConversations-cards__logo'>
          <img
            className='honestConversations-cards__logo'
            src={iconList[category]}
            alt={`hc_${category}`}
          />
        </span>
      </div>
      <div className={underlineClassname} />
    </div>
  )
}

export default PriorityCard
