import React from 'react'
import { Row, Col, Card, Button } from 'react-bootstrap'
import { history } from '../../store'

export const CompletedCard = () => {
  const navigateToCompletedTasks = () => {
    history.push('/tasks/completed')
  }
  return (
    <Card id='completed-tasks-card' className='completed-tasks-card'>
      <Row className='d-flex flex-column flex-sm-row justify-content-normal'>
        <Col xs={12} sm={8} md={9} className='mb-4 pe-2 mb-sm-0'>
          <h3>Tasks History</h3>
          <p>Review all the tasks that you have previously fulfilled</p>
        </Col>
        <Col xs={12} sm={4} md={3}>
          <Button
            className='font-color--dark w-100 d-flex justify-content-around align-items-center match-btn-width completed-tasks-card__btn'
            variant='outline-primary'
            size='lg'
            onClick={navigateToCompletedTasks}>
            View History
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
