import React from 'react'
import { sortHouseholdCards } from '../../../helpers/honestConversations'
import SlickCarousel from '../../../components/SlickCarousel'
import PriorityCard from './PriorityCard'
import {
  findScoreCard,
  generateContactCardScores
} from '../../../helpers/priorities'
import { useWindowSize } from '../../../hooks'

export const renderCards = (
  primaryContact,
  secondaryContact,
  honestConversation,
  selectCard,
  setSelectedCard,
  mobileMode,
  isSecondaryActive?: false,
  singleHH?: false
) => {
  const sortedHouseholdCards =
    honestConversation &&
    honestConversation.householdCards &&
    sortHouseholdCards(honestConversation.householdCards)

  const renderSlides = () =>
    sortedHouseholdCards.map((cardObject, index) => {
      const primaryCard = findScoreCard(
        primaryContact.primaryCardScores,
        cardObject
      )
      const secondaryCard = findScoreCard(
        secondaryContact.secondaryCardScores,
        cardObject
      )
      const { category, title } = cardObject && cardObject.card
      const active = !selectCard
        ? index === 0
        : selectCard === cardObject.card?.id
      return (
        <div
          key={index}
          className='honestConversations-cards__button'
          role='button'
          onClick={() => setSelectedCard(cardObject.card?.id)}>
          <PriorityCard
            active={active}
            title={title}
            primaryScore={
              isSecondaryActive
                ? secondaryCard && secondaryCard.score
                : primaryCard && primaryCard.score
            }
            secondaryScore={
              isSecondaryActive
                ? primaryCard && primaryCard.score
                : secondaryCard && secondaryCard.score
            }
            category={category}
            singleHH={singleHH}
          />
        </div>
      )
    })
  return (
    <div className='honestConversations-cards__container px-md-3'>
      <SlickCarousel
        containerStyle={{ width: '100%' }}
        dotColor='light'
        buttonProps={{
          variant: 'dark',
          size: 'sm'
        }}
        slides={renderSlides}
        slidesToShow={mobileMode ? 1 : 5}
      />
    </div>
  )
}

export const PriorityCards = (props) => {
  const {
    honestConversation,
    contacts,
    selectCard,
    setSelectedCard,
    isSecondaryActive,
    singleHH
  } = props
  const primaryContact = generateContactCardScores(
    contacts,
    honestConversation,
    true
  )
  const secondaryContact = generateContactCardScores(
    contacts,
    honestConversation,
    false
  )
  const { width } = useWindowSize()
  const isLargerThanSmScreen = width >= 640
  return (
    <div id='honest-conversation-score' className='honestConversations-cards'>
      {honestConversation &&
        honestConversation.householdCards &&
        renderCards(
          primaryContact,
          secondaryContact,
          honestConversation,
          selectCard,
          setSelectedCard,
          !isLargerThanSmScreen,
          isSecondaryActive,
          singleHH
        )}
    </div>
  )
}

export default PriorityCards
