import React, { useState } from 'react'
import logo from '../../assets/images/gc-logo-blue.svg'
import generateDeeplink, {
  shouldShowAndroidBanner
} from '../../helpers/deeplink'
import { Container, Col, Row, Image, Button } from 'react-bootstrap'

export default function AppBannerComponent() {
  const [showBanner, setShowBanner] = useState(true)
  const handleCloseBanner = () => setShowBanner(false)
  const isShown = shouldShowAndroidBanner()
  const deeplink = generateDeeplink()
  const imageProps = {
    src: logo,
    alt: 'Logo'
  }

  const linkProps = {
    href: deeplink,
    text: 'Use App'
  }

  const message = ['Using the GuideCenter', 'app is much easier.']

  return isShown && showBanner ? (
    <Container fluid className='app-banner'>
      <Row className='align-items-center'>
        <Col xs={8} className='d-flex align-items-center'>
          <Button
            className='align-items-center app-banner__transparent-button'
            onClick={handleCloseBanner}>
            <i className='bi bi-x app-banner__x-icon'></i>
          </Button>
          <Image src={imageProps.src} className='app-banner__gc-icon' />
          <div className='app-banner__message-container'>
            {message.map((msg, i) => (
              <p key={msg} className='app-banner__message'>
                {msg}
              </p>
            ))}
          </div>
        </Col>
        <Col xs={4} className='d-flex justify-content-end'>
          <Button
            href={linkProps.href}
            variant='light'
            className='app-banner__button'>
            {linkProps.text}
          </Button>
        </Col>
      </Row>
    </Container>
  ) : null
}
