import { UserObj } from './user'

import ameritrade from '../assets/images/logos/partnerLogos/ameritrade.png'
import blackDiamond from '../assets/images/logos/partnerLogos/black-diamond.png'
import charlesSchwab from '../assets/images/logos/partnerLogos/charles-schwab.png'
import emoney from '../assets/images/logos/partnerLogos/emoney.png'
import envestnetTamarac from '../assets/images/logos/partnerLogos/envestnet-tamarac.png'
import envestnet from '../assets/images/logos/partnerLogos/envestnet.png'
import fidelity from '../assets/images/logos/partnerLogos/fidelity.png'
import moneyguidepro from '../assets/images/logos/partnerLogos/moneyguidepro.png'
import orion from '../assets/images/logos/partnerLogos/orion.png'
import pershing from '../assets/images/logos/partnerLogos/pershing.png'
import folioClient from '../assets/images/logos/partnerLogos/folio-client.png'

export const allowedPartners = [
  'Fidelity',
  'Schwab',
  'TD Ameritrade',
  'Envestnet',
  'Tamarac',
  'Tamarac - DiNuzzo',
  'Tamarac - SWM',
  'Folio Client',
  'Pershing',
  'Black Diamond',
  'Orion'
]

export const investmentPartners = (onlinePortal: string, user: UserObj) => {
  switch (onlinePortal) {
    case 'Emoney':
      return {
        img: emoney,
        name: onlinePortal,
        link:
          window._env_.REACT_APP_EMONEY_ENTERPRISE_ENABLED &&
          !user.isFinlifeInstitution
            ? 'https://wealth.emaplan.com/ayco/sub/def/pfm/nonsso'
            : 'https://wealth.emaplan.com/ema/SignIn?ema'
      }
    case 'Money Guide Pro':
      return {
        img: moneyguidepro,
        name: onlinePortal,
        link: 'https://www.moneyguidepro.com/ifa/SignOn.aspx'
      }
    case 'Fidelity':
      return {
        img: fidelity,
        name: onlinePortal,
        link:
          'https://login.fidelity.com/ftgw/Fas/Fidelity/RtlCust/Login/Init?AuthRedUrl=https://oltx.fidelity.com/ftgw/fbc/ofsummary/defaultPage'
      }
    case 'Folio Client':
      return {
        img: folioClient,
        name: onlinePortal,
        link: 'https://folioidentity.com/identity/login'
      }
    case 'Pershing':
      return {
        img: pershing,
        name: onlinePortal,
        link: 'https://www.netxinvestor.com/web/netxinvestor/login'
      }
    case 'Envestnet':
      return {
        img: envestnet,
        name: onlinePortal,
        link:
          'https://portal.envestnet.com/secure/login/login.jsp?firm=united&show_in_parent_window=false'
      }
    case 'Schwab':
      return {
        img: charlesSchwab,
        name: onlinePortal,
        link:
          'https://client.schwab.com/Login/SignOn/CustomerCenterLogin.aspx?&kc=y&sim=y'
      }
    case 'TD Ameritrade':
      return {
        img: ameritrade,
        name: onlinePortal,
        link: 'https://advisorclient.com/advisorclient/p/gridLogin'
      }
    case 'Black Diamond':
      return {
        img: blackDiamond,
        name: onlinePortal,
        link: 'https://login.bdreporting.com/'
      }
    case 'Black Diamond - Advus':
      return {
        img: blackDiamond,
        name: onlinePortal,
        link: 'https://login.bdreporting.com/Auth/lamco/SignIn'
      }
    case 'Tamarac':
      return {
        img: envestnetTamarac,
        name: onlinePortal,
        link: 'https://keystone.portal.tamaracinc.com/Login.aspx'
      }
    case 'Tamarac - SWM':
      return {
        img: envestnetTamarac,
        name: onlinePortal,
        link: 'https://sendero.portal.tamaracinc.com/Login.aspx?ReturnUrl=%2f'
      }
    case 'Tamarac - DiNuzzo':
      return {
        img: envestnetTamarac,
        name: onlinePortal,
        link:
          'https://dinuzzoindex.portal.tamaracinc.com/Login.aspx?ReturnUrl=%2f'
      }
    case 'Orion':
      return {
        img: orion,
        name: onlinePortal,
        link: 'https://login.orionadvisor.com/login.html'
      }
    default:
      return null
  }
}
