import { useReducer, KeyboardEvent, MouseEvent } from 'react'
import Api from '../helpers/api'

export type ModalActions =
  | 'show_edit_folder'
  | 'show_edit_file'
  | 'show_delete_folder'
  | 'show_delete_file'
  | 'show_ios_error'
  | 'show_upload_file'
  | 'show_move_file'
  | 'close_modal'
  | 'show_restore_archived'
  | 'show_hard_delete_archived'
  | 'show_empty_archived'

export const initModalState = {
  showEditFolder: false,
  showEditFile: false,
  showDeleteFolder: false,
  showDeleteFile: false,
  showHardDeleteFile: false,
  showUploadFile: false,
  showMoveFile: false,
  showIosError: false,
  showRestoreArchived: false,
  showEmptyArchived: false
}

export const modalReducer = (state, action: ModalActions) => {
  switch (action) {
    case 'show_edit_folder':
      return { ...initModalState, showEditFolder: true }
    case 'show_edit_file':
      return { ...initModalState, showEditFile: true }
    case 'show_delete_folder':
      return { ...initModalState, showDeleteFolder: true }
    case 'show_delete_file':
      return { ...initModalState, showDeleteFile: true }
    case 'show_hard_delete_archived':
      return { ...initModalState, showHardDeleteFile: true }
    case 'show_ios_error':
      return { ...initModalState, showIosError: true }
    case 'show_upload_file':
      return { ...initModalState, showUploadFile: true }
    case 'show_move_file':
      return { ...initModalState, showMoveFile: true }
    case 'close_modal':
      return { ...initModalState }
    case 'show_restore_archived':
      return { ...initModalState, showRestoreArchived: true }
    case 'show_empty_archived':
      return { ...initModalState, showEmptyArchived: true }
    default:
      throw new Error('No proper action was passed')
  }
}

export const useModalReducer = () => {
  const [modalState, handleModal] = useReducer(modalReducer, initModalState)
  return { modalState, handleModal }
}

export const navigatorPlatformTypeEquals = (checkOS: string) => {
  if (window.navigator.platform === checkOS) {
    return true
  }
  return false
}

export function getFileNameWithExtensionInLowerCase(fileName: string) {
  const portions = fileName?.split('.') // This would split the array by .
  if (portions?.length > 1) {
    // making sure there is at least one .
    portions[portions.length - 1] = portions[portions.length - 1].toLowerCase()
    fileName = portions.join('.')
  }
  return fileName
}

export const downloadFile = async (
  folderId: string,
  fileId: string,
  name: string,
  onDownloadProgressCallback?: (progressEvent: ProgressEvent) => void
) => {
  const apiInstance = Api.getInstance()
  apiInstance
    .get(`/portaldocumentvault/v2/folders/${folderId}/files/${fileId}`, {
      onDownloadProgress: (progressEvent) => {
        if (typeof onDownloadProgressCallback === 'function') {
          onDownloadProgressCallback(progressEvent)
        }
      },
      timeout: 0,
      responseType: 'blob'
    })
    .then((response) => {
      if (response && response.data) {
        const navigator = window.navigator as any

        if (navigator?.msSaveOrOpenBlob) {
          return navigator.msSaveOrOpenBlob(response.data, name)
        }

        const url = window.URL.createObjectURL(response.data)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = url
        tempLink.setAttribute('download', name)

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank')
        }

        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(url)
        return null
      } else {
        return null
      }
    })
}

export const clickOrDisabledKey = (
  e: KeyboardEvent<HTMLInputElement> | MouseEvent<HTMLInputElement>
) => {
  if (e.type === 'keydown') {
    return ['Delete', 'ArrowRight'].includes((e as KeyboardEvent).key)
  }
  return e.type === 'click'
}

export const splitExtension = (name: string) => {
  const nameAr = name.split('.')
  if (nameAr.length < 2) return { name, ext: null }
  const ext = `.${nameAr.pop()}`
  return { name: nameAr.join('.'), ext }
}
