import React from 'react'
import { Card } from 'react-bootstrap'
import { dollarFormat } from '../../helpers'

import { getAccountRow } from './AccountRows'

import { ClientAccountObj } from '../../objects/clientAccount'
import { useWindowSize } from '../../hooks'

import { NetworthTable } from './NetworthTable'

interface AccountsTable {
  accounts: { sum: number; category: string; accounts: ClientAccountObj[] }
  toggleModal(): void
}

const getFormattedTotal = (amount) => {
  const sum = Number(amount)
  return sum < 0 ? dollarFormat(sum, 0, true) : dollarFormat(sum, 0)
}

const AccountsTable = (props: AccountsTable): JSX.Element => {
  const { width } = useWindowSize()
  const { accounts: accountsCollection, toggleModal } = props
  const { category, sum, accounts } = accountsCollection

  const sumText = getFormattedTotal(sum)

  const generateTableData = () => {
    return accounts.map((item) => ({
      id: item.id,
      cellComponentProps: {
        content: {
          ...item,
          toggleModal
        },
        account: item
      }
    }))
  }

  const tableData = generateTableData()
  const accountRows = getAccountRow(width)
  const emptyTable = tableData.length === 0

  return emptyTable ? null : (
    <Card
      className='border-0 bg-white mt-4'
      style={{ padding: '0px', margin: '0px' }}>
      <div className='account-category'>
        <div className='p-x2 account-category__header'>
          <h3>{category}</h3>
          <span className='account-category-sum'>{sumText}</span>
        </div>
        <div className='account-category__item account-table__row-w'>
          <NetworthTable
            id='file-vault-table'
            className='account-category__table'
            data={tableData}
            columns={accountRows}
          />
        </div>
      </div>
    </Card>
  )
}

export default AccountsTable
