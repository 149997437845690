import * as actions from '../actions/officeteam.action'
import * as householdActions from '../actions/household.action'
import { OfficeTeamObj } from '../objects/officeTeam'

const initState: OfficeTeamObj = {
  id: '',
  name: '',
  members: [],
  advisorId: ''
}

const mapOfficeTeam = (officeTeam: OfficeTeamObj) => {
  return {
    id: officeTeam.id,
    name: officeTeam.name,
    members: officeTeam.members
  }
}

const OfficeTeams = (state = initState, action: any): OfficeTeamObj => {
  switch (action.type) {
    case actions.FETCH_OFFICE_TEAM_FULFILLED:
      const officeTeam = mapOfficeTeam(action.payload)
      return { ...state, ...officeTeam }
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      return { ...state, advisorId: action.payload.advisorId }
    default:
      return state
  }
}

export default OfficeTeams
