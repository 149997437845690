import React, { CSSProperties } from 'react'
import classnames from 'classnames'

interface ButtonProps {
  children: React.ReactNode
  primary?: boolean
  clear?: boolean
  style?: CSSProperties
  href?: string
  disabled?: boolean
  target?: string
  rel?: string
  negative?: boolean
  onClick?(): void
  className?: string
}

export class Button extends React.Component<ButtonProps> {
  public onClick = () => {
    const { onClick, disabled } = this.props
    if (!disabled && onClick) {
      onClick()
    }
  }

  public render() {
    const {
      children,
      primary,
      clear,
      style,
      href,
      target,
      rel,
      negative,
      disabled,
      className
    } = this.props

    let buttonClass = classnames(className, 'legacy-btn', {
      'legacy-btn__prime': primary,
      'legacy-btn__clear': clear,
      'legacy-btn__negative': negative,
      'legacy-btn__default': !primary && !negative && !clear
    })

    buttonClass += disabled ? ` ${buttonClass}--disabled` : ''
    return (
      <a
        className={buttonClass}
        style={style}
        onClick={this.onClick}
        href={href}
        target={target}
        rel={rel}>
        {children}
      </a>
    )
  }
}
