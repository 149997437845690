import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { GlobalState } from '../../reducers'
import { Form, Button } from 'react-bootstrap'
import AccountFormDropdown from '../../components/FormDropdown/FormDropdown'
import {
  classificationOptionList,
  ClientAccountObj,
  ManualClientAccountObj
} from '../../objects/clientAccount'
import * as actions from '../../actions/clientAccounts.action'
import { getClassification } from '../../helpers/netWorth'
import { AppDispatch } from '../../store'

interface LinkedAccountFormProps {
  className?: string
  dispatch: AppDispatch
  account?: ClientAccountObj
  modalType: string
  closeOnError?: boolean
  onClose(): () => void
}
interface LinkedAccountFormState {
  loading?: boolean
  validated?: boolean
}

class LinkedAccountForm extends React.Component<
  LinkedAccountFormProps,
  LinkedAccountFormState
> {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      validated: false
    }
  }

  public createOrUpdateAccount = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const data = (Object as any).fromEntries((formData as any).entries())

    const {
      modalType,
      closeOnError = false,
      onClose,
      account = {
        id: '',
        registrationType: '',
        source: 'Guide Center - Yodlee',
        requirementErrors: [],
        accountNumberFull: '',
        balanceDate: '',
        totalValueManual: ''
      }
    } = this.props

    const id = account.id || ''
    const registrationType = account.registrationType || ''
    const source = account.source || ''
    const accountNumberFull = account.accountNumberFull || ''
    const balanceDate = account.balanceDate || ''
    const totalValueManual = Number(account.totalValueManual || '')
    const guidebookClassification = getClassification(
      data.guidebookClassification
    )

    const manualAccount: ManualClientAccountObj = {
      accountNickname: data.accountNickname,
      accountNumberFull,
      balanceDate,
      guidebookClassification,
      totalValueManual: Math.round(totalValueManual),
      registrationType: registrationType || '',
      source,
      strategyReporting: true
    }

    const isFormFieldsValid = [
      manualAccount.accountNickname,
      manualAccount.guidebookClassification
    ].every((item) => Boolean(item))

    this.setState({ validated: true })
    if (!isFormFieldsValid) return

    this.setState({ loading: true })

    if (modalType === 'add') {
      try {
        await this.props.dispatch(actions.saveManualAccount(manualAccount))
      } catch (error) {
        this.setState({ loading: false })
        closeOnError && onClose()
        return
      }
    } else if (modalType === 'edit') {
      try {
        await this.props.dispatch(
          actions.updateManualAccount(id, manualAccount)
        )
      } catch (error) {
        this.setState({ loading: false })
        closeOnError && onClose()
        return
      }
    }
    await this.props.dispatch(actions.getClientAccounts())
    onClose()
  }

  public render() {
    const {
      account = {} as ClientAccountObj,
      className = 'networth-add-account-form__container mx-4',
      onClose
    } = this.props
    const { loading, validated, ...formFields } = this.state
    const accountNickname = account?.accountNickname || ''
    const guidebookClassification = getClassification(
      account?.guidebookClassification
    )
    const disabled = Object.values(formFields).some((field) => !field)

    return (
      <div className={classnames('networth-add-account-form', className)}>
        <div className='networth-add-account-form__linked-form-title mb-4'>
          Edit Linked Account
        </div>
        <div className='networth-add-account-form__linked-form-sub-title mb-4'>
          Update the name or type of account below
        </div>
        {/* noValidate turns off native html validation and feedback
            (also makes onSubmit be called even if invalid)
            validated is for react-bootstrap adding the .was-valid class
        */}
        <Form
          validated={validated}
          noValidate
          onSubmit={this.createOrUpdateAccount}>
          <Form.Group>
            <Form.Label>Account Nickname</Form.Label>
            <Form.Control
              type='text'
              name='accountNickname'
              placeholder='Enter Account Nickname'
              defaultValue={accountNickname}
              required={true}
            />
          </Form.Group>
          <AccountFormDropdown
            label='Classification'
            name='guidebookClassification'
            options={classificationOptionList}
            placeholder='Select'
            defaultValue={guidebookClassification}
            validated={validated}
          />
          <div className='networth-add-account-form__btns'>
            <Button
              className={`networth-add-account-form__btns-save${
                disabled ? '--disabled' : ''
              }`}
              style={{ minWidth: '168px' }}
              type='submit'
              disabled={loading}>
              Save Changes
            </Button>
            <Button
              className='ms-4'
              variant='link'
              type='button'
              onClick={onClose}
              disabled={loading}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, ownProps) => {
  return { ...ownProps }
}
export default connect(mapStateToProps)(LinkedAccountForm)
