import React from 'react'
import ErrorAccountsTable from './ErrorAccountsTable'
import { YodleeObj } from '../../objects/yodlee'
import { ClientAccountObj } from '../../objects/clientAccount'
import { Accordion, Card } from 'react-bootstrap'

interface ErrorSectionProps {
  allAccounts: ClientAccountObj[]
  errorAccounts: ClientAccountObj[]
  toggleModal: () => void
  yodlee: YodleeObj
}

export const createExceptionAccountsList = (
  errorAccounts: ClientAccountObj[]
) => {
  const upgradeInstitutionList = []
  return {
    errors: errorAccounts.reduce((list, account) => {
      const { custodianText, connectedErrorCode } = account
      if (
        connectedErrorCode === '999' &&
        !upgradeInstitutionList.includes(custodianText)
      ) {
        upgradeInstitutionList.push(custodianText)
      }
      return custodianText &&
        !list.includes(custodianText) &&
        connectedErrorCode !== '999' &&
        !upgradeInstitutionList.includes(custodianText)
        ? [...list, custodianText]
        : list
    }, []),
    upgrades: upgradeInstitutionList
  }
}

export const renderTables = (props: ErrorSectionProps) => {
  const { allAccounts, errorAccounts, toggleModal, yodlee } = props
  const exceptionAccountList = createExceptionAccountsList(errorAccounts)
  const getExceptionAccountTable = (exceptionType, accountList) => {
    return exceptionAccountList?.[exceptionType].map((institution: string) => {
      const exceptionAccounts = []
      accountList.forEach((account) =>
        account.custodianText === institution
          ? exceptionAccounts.push(account)
          : null
      )
      return (
        <ErrorAccountsTable
          key={exceptionAccounts[0]?.id}
          accounts={{
            sum: null,
            category: `${institution} Accounts`,
            errMessage:
              exceptionType === 'errors'
                ? exceptionAccounts[0]?.connectedErrorCodeDescription
                : null,
            accounts: exceptionAccounts
          }}
          yodlee={yodlee}
          toggleModal={toggleModal}
          hideErrorCell={true}
          isUpgradeTile={exceptionType === 'upgrades'}
        />
      )
    })
  }
  const errorAccountTable = getExceptionAccountTable('errors', errorAccounts)
  const upgradeAccountsTable = getExceptionAccountTable('upgrades', allAccounts)
  return (
    <div>
      {upgradeAccountsTable}
      {errorAccountTable}
    </div>
  )
}

export const ErrorAccountSection = (props: ErrorSectionProps): JSX.Element => {
  const { errorAccounts } = props
  return (
    errorAccounts && (
      <Card
        id='error-account-section'
        className='rounded-0 border-0 bg-white account-error'>
        <Accordion className='account-error__accordion'>
          <Accordion.Item eventKey='0' className='border-0'>
            <Accordion.Header>
              <div className='account-error__header'>
                <div className='ms-3'>
                  <i className='bi bi-exclamation-circle fs-5 text-warning fw-bold'></i>
                </div>
                <div className='account-error__header--name fw-bold'>
                  Needs Attention
                </div>
              </div>
            </Accordion.Header>
            <Accordion.Body className='p-0'>
              <div>{renderTables(props)}</div>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Card>
    )
  )
}

export default ErrorAccountSection
