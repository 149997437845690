import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'

import { GoalsTiles } from '../Goals/GoalsTiles'
import { getSortedGoals } from '../../selectors/goals'
import { getGoalActions } from '../../actions/goalActions.action'
import { getHousehold } from '../../actions/household.action'
import { getCardGoals } from '../../helpers/priorities'

type HonestConversationsGoalActions = typeof mapDispatchToProps
type HonestConversationsGoalStore = ReturnType<typeof mapStateToProps>
export type HonestConversationsGoalProps = HonestConversationsGoalActions &
  HonestConversationsGoalStore

export const HonestConversationsGoal = (
  props: HonestConversationsGoalProps
) => {
  const {
    goalActions,
    householdFetched,
    getGoalActions,
    getHousehold,
    selectCard
  } = props

  useEffect(() => {
    getGoalActions()
    if (!householdFetched) {
      getHousehold()
    }
  }, [])

  const displayedGoals =
    householdFetched && goalActions && getCardGoals(selectCard, goalActions)
  return !displayedGoals || displayedGoals.length === 0 ? null : (
    <div>
      <h3 className='rfont--h3 text-dark fw-bold mb-3'>Goals</h3>
      <GoalsTiles goals={displayedGoals} />
    </div>
  )
}

const mapDispatchToProps = {
  getGoalActions,
  getHousehold
}
const mapStateToProps = (store: GlobalState, ownProps) => {
  const { goals } = getSortedGoals(store)
  return {
    goalActions: goals,
    householdFetched: store.user.householdFetched,
    ...ownProps
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HonestConversationsGoal)
