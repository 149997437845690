import React, { FormEvent } from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'

import { Form, Button } from 'react-bootstrap'
import FormDropdown from '../../components/FormDropdown/FormDropdown'
import DateInput from '../../components/DateInput/DateInput'

import {
  classificationOptionList,
  ClientAccountObj,
  ManualClientAccountObj
} from '../../objects/clientAccount'
import { addErrorToast, addSuccessToast } from '../../actions/toasts.action'
import * as actions from '../../actions/clientAccounts.action'
import { getClassification, titleList } from '../../helpers/netWorth'
import { dateFormatNet } from '../../helpers'
import { dateValidator } from '../../utils/validators'

export interface ManualAccountFormProps {
  className?: string
  dispatch: AppDispatch
  account?: ClientAccountObj
  modalType: 'add' | 'edit'
  closeOnError?: boolean
  onClose(): () => void
}

export interface ManualAccountFormState {
  loading?: boolean
  totalValueManual?: string | number
  validated?: boolean
}

export class ManualAccountForm extends React.Component<
  ManualAccountFormProps,
  ManualAccountFormState
> {
  constructor(props) {
    super(props)
    const formattedTotalValueManual =
      this.props.account?.totalValueManual === 0
        ? '0'
        : this.formatCurrencyWithCommas(
            this.props.account?.totalValueManual?.toString() || ''
          )
    // initialize state with formatted currency
    this.state = {
      loading: false,
      totalValueManual: formattedTotalValueManual,
      validated: false
    }
  }

  // reference DatInput component
  private dateInputRef = React.createRef<HTMLInputElement>()

  public formatCurrencyWithCommas = (value) => {
    // remove all non-digit characters except the first decimal
    const parts = value.replace(/[^\d.]/g, '').match(/(\d*)(\.?)(\d{0,2})/)
    const rawValue = parts ? parts[1] + parts[2] + parts[3] : ''

    if (!rawValue) {
      return ''
    }

    // insert commas every three digits
    const formattedValue = rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return `$${formattedValue}`
  }

  public handleCurrencyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target
    const formattedValue = this.formatCurrencyWithCommas(value)
    this.setState({ totalValueManual: formattedValue })
  }

  // remove commas for the raw value form submission
  public getRawValue = () => {
    const { totalValueManual } = this.state
    const rawCurrencyVal =
      totalValueManual === 0 ? '0' : totalValueManual.toString() || ''
    return rawCurrencyVal.replace(/[,$]/g, '')
  }

  public createOrUpdateAccount = async (e: FormEvent) => {
    e.preventDefault()
    const formData = new FormData(e.target as HTMLFormElement)
    const data = (Object as any).fromEntries((formData as any).entries())

    const {
      modalType,
      onClose,
      account = {
        id: '',
        registrationType: '',
        source: 'Guide Center - Manual',
        requirementErrors: []
      }
    } = this.props

    const id = account.id || ''
    const registrationType = account.registrationType || ''
    const source = account.source || ''
    const guidebookClassification = getClassification(
      data.guidebookClassification
    )

    // remove commas when submitting
    const cleanTotalValueManual = this.getRawValue()

    const manualAccount: ManualClientAccountObj = {
      accountNickname: data.accountNickname,
      accountNumberFull: data.accountNumber,
      balanceDate: data.balanceDate,
      guidebookClassification,
      totalValueManual: Math.round(Number(cleanTotalValueManual)),
      registrationType: registrationType || '',
      source,
      strategyReporting: true
    }
    let error = false

    // validate these form fields
    const isFormFieldsValid = [
      manualAccount.accountNickname,
      manualAccount.accountNumberFull,
      dateValidator('MM/DD/YYYY').isValidSync(manualAccount.balanceDate),
      manualAccount.guidebookClassification,
      manualAccount.totalValueManual
    ].every((item) => Boolean(item))

    this.setState({ validated: true })

    // if any fields are invalid, return
    if (!isFormFieldsValid) return

    this.setState({ loading: true })

    if (modalType === 'add') {
      error = await this.createAccount(manualAccount)
    } else if (modalType === 'edit') {
      error = await this.updateAccount(id, manualAccount)
    } else return

    if (error) return

    await this.props.dispatch(actions.getClientAccounts())
    this.props.dispatch(
      addSuccessToast({
        message:
          modalType === 'add'
            ? 'Investment account added'
            : modalType === 'edit'
            ? 'Investment account information updated'
            : ''
      })
    )
    onClose()
  }

  public createAccount = async (account: ManualClientAccountObj) => {
    try {
      await this.props.dispatch(actions.saveManualAccount(account))
    } catch (error) {
      return this.onError('Unable to add investment account. Please try again')
    }
    return false
  }

  public updateAccount = async (
    id: string,
    account: ManualClientAccountObj
  ) => {
    try {
      await this.props.dispatch(actions.updateManualAccount(id, account))
    } catch (error) {
      return this.onError(
        'Unable to update investment account. Please try again'
      )
    }
    return false
  }

  public onError = (message: string) => {
    const { closeOnError, onClose } = this.props
    this.props.dispatch(addErrorToast({ message }))

    this.setState({ loading: false })
    closeOnError && onClose()
    return true
  }

  public render() {
    const {
      modalType,
      account = {} as ClientAccountObj,
      className = 'networth-add-account-form__container mx-4',
      onClose
    } = this.props
    const { loading, validated } = this.state
    const accountNickname = account?.accountNickname || ''
    const accountNumber = account?.accountNumber || ''
    const balanceDate = account?.balanceDate || ''
    const guidebookClassification = getClassification(
      account?.guidebookClassification
    )
    const { header, subheader } = titleList[`show_${modalType}_account`]

    return (
      <div className={classnames('networth-add-account-form', className)}>
        <div className='h1 mb-4 p-0'>{header}</div>
        <div className='b2 text--regular mb-4'>{subheader}</div>
        {/* noValidate turns off native html validation and feedback
            (also makes onSubmit be called even if invalid)
            validated is for react-bootstrap adding the .was-valid class
        */}
        <Form
          validated={validated}
          noValidate
          onSubmit={this.createOrUpdateAccount}>
          <Form.Group>
            <Form.Label>Account Nickname</Form.Label>
            <Form.Control
              type='text'
              name='accountNickname'
              placeholder='Enter Account Nickname'
              defaultValue={accountNickname}
              required={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Account Number (Last 4 Digits)</Form.Label>
            <Form.Control
              type='text'
              name='accountNumber'
              placeholder='Last 4 Digits of Your Account No.'
              defaultValue={accountNumber}
              maxLength={4}
              required={true}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Balance</Form.Label>
            <Form.Control
              type='text'
              name='totalValueManual'
              placeholder='$ Enter Balance'
              value={this.state.totalValueManual}
              onChange={this.handleCurrencyChange}
              required={true}
            />
          </Form.Group>
          <DateInput
            ref={this.dateInputRef}
            label='Balance Date'
            name='balanceDate'
            placeholder='MM/DD/YYYY'
            defaultValue={dateFormatNet(balanceDate)}
            validated={validated}
          />
          <FormDropdown
            label='Classification'
            name='guidebookClassification'
            options={classificationOptionList}
            placeholder='Select'
            defaultValue={guidebookClassification}
            validated={validated}
          />
          <div className='networth-add-account-form__btns'>
            <Button
              style={{ minWidth: '168px' }}
              type='submit'
              disabled={loading}
              className='px-x2 py-3 fs-6'>
              {modalType === 'edit' ? 'Save Changes' : 'Add Account'}
            </Button>
            <Button
              className='ms-4 fs-6'
              variant='link'
              type='button'
              onClick={onClose}
              disabled={loading}>
              Cancel
            </Button>
          </div>
        </Form>
      </div>
    )
  }
}

const mapStateToProps = (store: GlobalState, ownProps) => {
  return { ...ownProps }
}
export default connect(mapStateToProps)(ManualAccountForm)
