import { ApolloClient, split, HttpLink, InMemoryCache } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'

/**
 *
 * @param accessToken
 * @returns ApolloClient
 *
 */
export const newApolloClient = (accessToken: string) => {
  // Create an http link:
  const httpLink = new HttpLink({
    uri: `https://${window._env_.REACT_APP_ASYNC_URL}`,
    headers: {
      Authorization: `Bearer client:${accessToken}`
    }
  })

  const wsLink = new WebSocketLink({
    uri: `wss://${window._env_.REACT_APP_ASYNC_SUBSCRIPTION_URL}`,
    options: {
      timeout: 60 * 1000, // give it 60 seconds to get a kA message before retrying
      reconnect: true,
      reconnectionAttempts: 3,
      lazy: true,
      connectionParams: {
        Authorization: `Bearer client:${accessToken}`
      }
    }
  })

  const link = split(
    // split based on operation type
    ({ query }) => {
      const querDoc = getMainDefinition(query)
      return (
        querDoc.kind === 'OperationDefinition' &&
        querDoc.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )

  const cache = new InMemoryCache()
  return new ApolloClient({ cache, link })
}
