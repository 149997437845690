import * as yup from 'yup'
import dayjs from 'dayjs'

const maxVaultFileSize = window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE
export const INPUT_ERROR_MESSAGES = {
  specialChars: 'Special characters are not allowed.',
  fileChars: 'Special characters are not allowed in file names.',
  folderChars: 'Special characters are not allowed in folder names.'
}
export const VAULT_ERROR_MESSAGES = {
  folderMaxLength: 'Folder name too long.',
  folderExists: 'Folder with this name already exists',
  fileMaxLength: 'File name too long.',
  fileSizeLimit: `File size cannot exceed ${maxVaultFileSize} MB.`,
  fileExtension:
    'File name is missing a valid extension (i.e. pdf, doc, xls, jpg).',
  fileUploadExtension: 'File format is not correct.',
  fileExists: 'File with this name already exists.'
}

const ALLOWED_FOLDER_PATTERN = /^[a-zA-Z0-9\s_]+$/i
// Maybe allow spaces(\s) and -_ characters
const ALLOWED_UPLOAD_FILE_PATTERN = /^[a-zA-Z0-9\s_]+\.[^.]+$/i
const ALLOWED_FILE_EXTENSIONS_PATTERN = /(.pdf|.docx|.doc|.txt|.csv|.xls|.xlsx|.jpe?g|.png|.bmp|.gif|.tif|.ppt|.pptx|.zip)$/i
const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9\s]+$/g
const MAX_LENGTH = 76

const isAlphaNumeric = (value) => value.match(ALPHA_NUMERIC_REGEX)
const isProperFolderName = (value: string) =>
  value.match(ALLOWED_FOLDER_PATTERN) ? true : false
const isProperFileName = (value: string) =>
  value.match(ALLOWED_UPLOAD_FILE_PATTERN) ? true : false
const isProperFileExtension = (value: string) =>
  value.match(ALLOWED_FILE_EXTENSIONS_PATTERN) ? true : false

/**
 * 30MB = 31457280(binary) / 1024 / 1024
 * 30000000 = decimal
 * 31457280 = binary
 * 75mb = 78643200(binary) / 1024 / 1024
 * 75000000 = decimal
 * 78643200 = binary
 * 200mb = 209715200(binary) / 1024 / 1024
 * 200000000 = decimal
 * 209715200 = binary
 */
export const FILE_SIZE_LIMIT =
  window?._env_?.REACT_APP_DOC_VAULT_MAX_FILE_SIZE * 1024 * 1024

export const ALLOWED_FILE_TYPES = [
  '.pdf',
  '.docx',
  '.doc',
  '.txt',
  '.csv',
  '.xls',
  '.xlsx',
  '.jpg',
  '.jpeg',
  '.png',
  '.bmp',
  '.gif',
  '.tif',
  '.ppt',
  '.pptx',
  '.zip'
]

export interface File {
  lastModified: number
  lastModifiedDate: Date
  name: string
  size: number
  type: string
  webkitRelativePath: string
}

export const alphaNumericSchema = yup
  .string()
  .required()
  .test('specialChars', INPUT_ERROR_MESSAGES.specialChars, (value) =>
    isAlphaNumeric(value)
  )
  .test(
    'maxLength',
    VAULT_ERROR_MESSAGES.folderMaxLength,
    (value) => value.length <= MAX_LENGTH
  )

export const fileRenameSchema = yup
  .object()
  .shape({
    value: yup.string().required(),
    currentName: yup.string().required(),
    names: yup.array()
  })
  .test(
    'fileExtension',
    VAULT_ERROR_MESSAGES.fileExtension,
    ({ value }) =>
      isProperFileExtension(value) &&
      ALLOWED_FILE_TYPES.includes(`.${value.split('.')[1]}`)
  )
  .test('fileName', INPUT_ERROR_MESSAGES.fileChars, ({ value }) =>
    isProperFileName(value)
  )
  .test(
    'maxLength',
    VAULT_ERROR_MESSAGES.fileMaxLength,
    ({ value }) => value.length <= MAX_LENGTH
  )

export const folderRenameSchema = yup
  .object()
  .shape({
    value: yup.string().required()
  })
  .test('folderRename', INPUT_ERROR_MESSAGES.folderChars, ({ value }) =>
    isProperFolderName(value)
  )
  .test('maxLength', VAULT_ERROR_MESSAGES.folderMaxLength, ({ value }) => {
    return value.length <= MAX_LENGTH
  })

/**
 * File upload
 */
export const fileUploadSchema = yup
  .object()
  .shape({
    name: yup.string().required(),
    size: yup
      .number()
      .required()
      .positive()
      .integer()
  })
  .test('specialChars', INPUT_ERROR_MESSAGES.fileChars, ({ name }) =>
    isProperFileName(name)
  )
  .test('fileExtension', VAULT_ERROR_MESSAGES.fileUploadExtension, ({ name }) =>
    isProperFileExtension(name)
  )
  .test(
    'maxLength',
    VAULT_ERROR_MESSAGES.fileMaxLength,
    ({ name }) => name.length <= MAX_LENGTH
  )
  .test('fileSize', VAULT_ERROR_MESSAGES.fileSizeLimit, ({ size }) => {
    return size <= FILE_SIZE_LIMIT
  })

/**
 * Provides validation for one or more files
 * @param files
 * @returns string[]
 */
export const uploadValidator = async (files: File[]) => {
  const validate = (file: File) => {
    const { name, size } = file
    return new Promise((resolve) => {
      fileUploadSchema
        .validate({ name, size }, { abortEarly: false, strict: false })
        .then((value) => {
          resolve({
            data: []
          })
        })
        .catch((error) => {
          const { errors }: any = JSON.parse(JSON.stringify(error))
          resolve({ data: errors })
        })
    })
  }

  const validations = files.map(async (file) => {
    return validate(file).then((result: any) => {
      const { data } = result
      return data
    })
  })
  // concat all messages
  return Promise.all(validations).then((results) => {
    return [].concat.apply([], results)
  })
}

export const dateValidator = (format: string, msg?: string) => {
  return yup
    .string()
    .test(
      'date',
      msg || `Must be a valid date in format ${format}`,
      (input) => {
        return dayjs(input, format, true).isValid()
      }
    )
}
