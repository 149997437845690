import * as actions from '../actions/goalActions.action'
import { simpleDateFormat } from '../helpers'
import {
  GoalResponse,
  CardObj,
  CardResponse,
  GoalActionObj,
  GoalActionSortByTypes
} from '../objects/goals'
import * as householdActions from '../actions/household.action'

export interface GoalActionState {
  goals: GoalActionObj[]
  preferenceSortBy: GoalActionSortByTypes
  sessionSortBy: GoalActionSortByTypes
}

const initState: GoalActionState = {
  goals: null,
  preferenceSortBy: null,
  sessionSortBy: null
}

const mapGoalCards = (cards: CardResponse[]): CardObj[] => {
  return (
    cards &&
    cards.map(
      (card: CardResponse): CardObj => {
        return {
          id: (card && card.id) || '',
          title: (card && card.title) || '',
          category: (card && card.category) || null,
          ranking: null,
          showCard: card && card.showCard
        }
      }
    )
  )
}

const mapGoals = (goalActions: GoalResponse[]): GoalActionObj[] => {
  return (
    Array.isArray(goalActions) &&
    goalActions
      .map(
        (goal: GoalResponse): GoalActionObj => {
          return {
            id: goal.id,
            assigneePrimaryId: goal.assigneePrimaryId,
            assigneeSecondaryId: goal.assigneeSecondaryId,
            isLifeAction: goal.type === 'life',
            type: goal.type,
            name: goal.name,
            note: goal.note,
            status: goal.status,
            dueDate: simpleDateFormat(goal.dueDate),
            isNew: false,
            client: null,
            cards: mapGoalCards(goal.cards),
            archived: goal.archived,
            customGoal: goal.customGoal
          }
        }
      )
      .filter((goal: GoalActionObj): boolean => goal.archived !== true)
  )
}

const GoalActions = (state = initState, action): GoalActionState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case householdActions.FETCH_HOUSEHOLD_FULFILLED:
      const { preferences = [] } = action.payload
      const sortBy = preferences[0]?.goalActionsSortBy || 'Due Date'
      return { ...state, preferenceSortBy: sortBy }
    case `${actions.FETCH_GOAL_ACTIONS}_FULFILLED`:
      return { ...newState, goals: mapGoals(action.payload.data) }
    case actions.SET_SESSION_SORT:
      return { ...newState, sessionSortBy: action.payload }
    default:
      return state
  }
}

export default GoalActions
