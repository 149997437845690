import React from 'react'
import Button from 'react-bootstrap/Button'
import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface EmptyTrashModalProps {
  emptyTrashFn(): void
  closeModal(): void
}

class EmptyTrashModal extends React.Component<EmptyTrashModalProps> {
  public render() {
    const { emptyTrashFn, closeModal } = this.props
    return (
      <>
        <div className='vault-modal text-start'>
          <h1 className='vault-modal__title'>Empty Trash</h1>
        </div>
        <div className='vault-modal__body-text text-start'>
          <p>
            Emptying the trash will delete all files from the trash, and you
            will no longer be able to access these files. Are you sure you want
            to empty the trash?
          </p>
          <div className='vault-modal__buttons'>
            <Button
              id='delete-modal-save'
              variant='primary'
              size='lg'
              className='match-btn-width'
              onClick={emptyTrashFn}>
              Empty
            </Button>
            <Button
              id='delete-modal-cancel'
              variant='link'
              onClick={closeModal}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default ControlledModalWrapper(EmptyTrashModal)
