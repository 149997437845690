import { configureStore, AnyAction } from '@reduxjs/toolkit'

// browser history
import { createBrowserHistory } from 'history'
import { useDispatch } from 'react-redux'
import { routerMiddleware } from 'connected-react-router'
import PromiseMiddleware from 'redux-promise-middleware'
import Thunk, { ThunkAction } from 'redux-thunk'
import NavigationScroll from './middlewares/navigationScroll'
import SessionTimeout from './middlewares/sessionTimeout'
import ContactParam from './middlewares/contactParam'
// reducers
import reducers, { GlobalState, RootState } from './reducers'
import { forceAuth } from './middlewares/authentication'

const history = createBrowserHistory()
const router = routerMiddleware(history)

// production
const store = configureStore({
  reducer: reducers(history),
  middleware: [
    PromiseMiddleware(),
    Thunk,
    forceAuth,
    router,
    SessionTimeout,
    ContactParam,
    NavigationScroll
  ],
  devTools: process.env.NODE_ENV !== 'production'
})

// Setup store for tests
export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: reducers(history),
    middleware: [
      PromiseMiddleware(),
      Thunk,
      forceAuth,
      router,
      SessionTimeout,
      ContactParam,
      NavigationScroll
    ],
    preloadedState
  })
}

export type AppStore = ReturnType<typeof setupStore>
export default store

export type AppDispatch = typeof store.dispatch | any
export const useAppDispatch = () => useDispatch<AppDispatch>()

export type AppThunkAction = ThunkAction<void, GlobalState, unknown, AnyAction>

export { history }
