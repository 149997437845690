import React, { useEffect } from 'react'
import { GlobalState } from '../../reducers'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import Button from 'react-bootstrap/Button'

import * as actions from '../../actions/tasks.action'

import store, { history } from '../../store'

import TasksTable from './TasksTable'
import { TasksHeader } from './TasksHeader'
import NullTasks from './NullTasks'
import { completedTasksSelector } from '../../selectors/tasks'
import { taskRefetch } from '../../helpers/tasks'

type CompletedTasksStore = ReturnType<typeof mapStateToProps>
type CompletedTasksDispatch = typeof mapDispatchToProps
type CompletedTasksProps = CompletedTasksStore & CompletedTasksDispatch

const navigateToTasks = () => {
  history.push('/tasks')
}

const CompletedTasksPage = (props: CompletedTasksProps) => {
  const {
    getTasks,
    completedTasks,
    hasTasks,
    filtered,
    setFiltered,
    resetSortFilter
  } = props

  useEffect(() => {
    if (!hasTasks) {
      window._env_.REACT_APP_CONNECT_TO_TASKS_V2 === 'true' &&
      store.getState().user?.isFinlifeInstitution === false &&
      store.getState().user?.enableCRMTasks === true
        ? taskRefetch()
        : getTasks()
    }
    return () => {
      resetSortFilter()
    }
  }, [])

  if (hasTasks && !filtered && !completedTasks.length)
    return <Redirect to='/tasks' />

  return (
    <div>
      <Button
        type='button'
        variant=''
        onClick={navigateToTasks}
        className='completed-tasks-page__btn--back'>
        <i className='bi bi-chevron-left me-2' />
        Back
      </Button>
      <TasksHeader
        filtered={filtered}
        onFilter={() => setFiltered(!filtered)}
        completed={true}
      />
      {!completedTasks.length ? (
        <NullTasks completed={true} />
      ) : (
        <TasksTable tasks={completedTasks} className='mb-4 mb-sm-8' />
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getTasks: actions.getTasks,
  setFiltered: actions.filterTasks,
  resetSortFilter: actions.resetSortFilter
}

const mapStateToProps = (store: GlobalState) => {
  const { completedTasks, hasTasks, filtered } = completedTasksSelector(store)
  return {
    completedTasks,
    hasTasks,
    filtered
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletedTasksPage)
