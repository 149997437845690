import React from 'react'
import { Query } from '@apollo/client/react/components'
import { QueryResult } from '@apollo/client'
import dayjs from 'dayjs'
import { MessageBodyQuery, GetClientNotifications } from '../../helpers/queries'
import { dateFormat, timeFormat } from '../../helpers'

const StatusCell = ({
  householdFinId,
  threadId,
  userId
}: {
  householdFinId: string
  threadId: string
  userId: string
}) => {
  return (
    <Query
      query={MessageBodyQuery}
      variables={{ householdFinId, threadId }}
      skip={!householdFinId}>
      {({ data: messages }: QueryResult) => {
        if (userId) {
          return (
            <Query
              query={GetClientNotifications}
              variables={{ userId }}
              skip={!householdFinId}>
              {({ data: notifications }: QueryResult) => {
                const filterMessageNotifications = notifications?.allClientToNotifications?.nodes?.filter(
                  (node) =>
                    node?.notificationByNotification.subject === 'MESSAGE' &&
                    !node.read
                )
                const messageList = messages?.allMessages?.nodes
                const isUnread = filterMessageNotifications?.some(
                  (notification) =>
                    messageList?.some((message) => {
                      const content = JSON.parse(
                        notification?.notificationByNotification
                          ?.content as string
                      )
                      return message?.id === content?.data?.messageId
                    })
                )
                return isUnread ? <div className='messages-table__dot' /> : null
              }}
            </Query>
          )
        } else return null
      }}
    </Query>
  )
}

const NameCell = ({
  householdFinId,
  threadId,
  subject
}: {
  householdFinId: string
  threadId: string
  subject: string
}) => {
  return (
    <Query
      query={MessageBodyQuery}
      variables={{ householdFinId, threadId }}
      skip={!householdFinId}>
      {({ data }: QueryResult) => {
        const latestMessage =
          data?.allMessages?.nodes[data?.allMessages?.nodes?.length - 1]
        return (
          <div className='messages-table__cell-name-text'>{`${subject}${
            latestMessage?.message ? ` - ${latestMessage?.message}` : ''
          }`}</div>
        )
      }}
    </Query>
  )
}

const VideoCell = ({
  householdFinId,
  threadId
}: {
  householdFinId: string
  threadId: string
}) => {
  return (
    <Query
      query={MessageBodyQuery}
      variables={{ householdFinId, threadId }}
      skip={!householdFinId}>
      {({ data }: QueryResult) =>
        data?.allMessages?.nodes.some((message) =>
          Boolean(message?.videoId)
        ) ? (
          <i className='bi bi-play-btn fs-5' />
        ) : null
      }
    </Query>
  )
}

const DateCell = ({ date }: { date: string }) => {
  const today = dayjs()
  const threadDate = today.isSame(date, 'day')
    ? timeFormat(date)
    : dateFormat(date)
  return <div>{threadDate}</div>
}

const MobileDateCell = ({
  householdFinId,
  threadId,
  date
}: {
  householdFinId: string
  threadId: string
  date: string
}) => {
  return (
    <Query
      query={MessageBodyQuery}
      variables={{ householdFinId, threadId }}
      skip={!householdFinId}>
      {({ data }: QueryResult) => {
        const today = dayjs()
        const threadDate = today.isSame(date, 'day')
          ? timeFormat(date)
          : dateFormat(date)
        const videoIcon = data?.allMessages?.nodes.some((message) =>
          Boolean(message?.videoId)
        ) ? (
          <i className='bi bi-play-btn fs-5' />
        ) : null
        return (
          <div className='messages-table__cell-date-mobile-w'>
            <div>{threadDate}</div>
            <div>{videoIcon}</div>
          </div>
        )
      }}
    </Query>
  )
}

export const getMessageThreadRow = () => {
  return [
    {
      Cell: StatusCell,
      className: 'pe-3 messages-table__cell-status',
      id: 'StatusCell'
    },
    {
      Cell: NameCell,
      className: 'messages-table__cell-name',
      id: 'NameCell'
    },
    {
      Cell: VideoCell,
      className: 'messages-table__cell-video',
      id: 'VideoCell'
    },
    {
      Cell: DateCell,
      className:
        'pe-3 messages-table__cell-date messages-table__cell-date--no-mobile',
      id: 'DateCell'
    },
    {
      Cell: MobileDateCell,
      className:
        'pe-3 messages-table__cell-date messages-table__cell-date--mobile',
      id: 'MobileDateCell'
    }
  ]
}
