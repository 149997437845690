import { AllocationObj } from './allocation'
import { DiscreteAllocationObj } from './discreteAllocation'
import { ClientAccountStrategyObj } from './strategy'

export interface ClientAccountObj {
  accountName?: string
  accountNickname: string
  name: string
  accountNumber: string
  accountNumberFull?: string
  accountReporting?: boolean
  accountType: string
  allocationOverriden: boolean
  allocations: AllocationObj
  balanceDate: string
  date: string
  category: string
  connectedAccountId?: string
  connectedErrorCode: string
  connectedErrorCodeDescription: string
  connectedProviderAccountId?: string
  costBasis?: number
  createdDate?: string
  description: string
  discreteAllocation: DiscreteAllocationObj
  guidebookClassification?: string
  household?: string
  id: string
  includeInGuidebook?: boolean
  isDeletedConnected?: boolean
  isSleeve?: boolean
  isUma?: boolean
  liquid?: boolean
  managed: boolean
  ownerIds?: string[]
  portalExternalId?: string
  positions?: ClientAccountPositionsInterface
  productName?: string
  pssSelected: boolean
  providerAccountId: string
  qualified?: boolean
  registrationType: string
  status?: string
  subType: string
  sendToPlanningSoftware?: boolean
  sfId?: string
  source: string
  strategy?: ClientAccountStrategyObj
  strategyReporting?: boolean
  type: string
  taxStatus?: string
  custodianText?: string
  totalValue: number
  totalValueManual: number
  yield?: number
}
export interface ClientAccountObjState {
  [key: string]: ClientAccountObj
}
export interface ClientAccountInterface {
  clientAccounts: ClientAccountObjState
  manual: {
    accountType: string
    source: string
    name: string
    balance: string
  }
  errorMessage: string
  saved: boolean
  successMessage: string
}

export interface ManualClientAccountObj {
  balanceDate: string
  accountNickname: string
  accountNumberFull: string
  guidebookClassification: string
  totalValueManual: number
  source: string
  registrationType?: string
  strategyReporting: boolean
}

export interface NetworthPreferences {
  cashGroupBy: string
  cashSortBy: string
  cashSortingOrder: string
  investmentsGroupBy: string
  investmentsSortBy: string
  investmentsSortingOrder: string
  otherAssetsGroupBy: string
  otherAssetsSortBy: string
  otherAssetsSortingOrder: string
  liabilitiesGroupBy: string
  liabilitiesSortBy: string
  liabilitiesSortingOrder: string
  unclassifiedGroupBy: string
  unclassifiedSortBy: string
  unclassifiedSortingOrder: string
  goalActionsSortBy?: string
}

export const classificationOptionList = [
  { value: 'Cash & Equivalents', label: 'Cash & Equivalents' },
  { value: 'Taxable Investments', label: 'Taxable Investments' },
  { value: 'Tax-Deferred Investments', label: 'Tax-Deferred Investments' },
  { value: 'Tax-Exempt Investments', label: 'Tax-Exempt Investments' },
  { value: 'Personal Assets', label: 'Personal Assets' },
  { value: 'Real Estate', label: 'Real Estate' },
  { value: 'Business Assets', label: 'Business Assets' },
  { value: 'Short-Term Liabilities', label: 'Short-Term Liabilities' },
  { value: 'Long-Term Liabilities', label: 'Long-Term Liabilities' }
]

export const clientAccountRequirements = [
  'accountNickname',
  'totalValueManual',
  'guidebookClassification',
  'accountNumber'
]

export interface ClientAccountPositionsInterface {
  [key: string]: ClientAccountPositionsObj
}

export interface ClientAccountPositionsObj {
  clientAccount: string
  createdDate: string
  asOfDate: string
  id: string
  isSleeve: boolean
  name: string
  price: number
  quantity: number
  rawValue: number
  totalCost: number
  value: number
  altPercent: number
  cashPercent: number
  equityPercent: number
  fixedPercent: number
  unclassifiedPercent: number
  securityDescription: string
  securityId: string
  securityStyle: string
  securityType: string
  tickerSymbol: string
  symbol: string
  allocationOverridden: boolean
}

export interface PositionAllocationObj {
  equityPercent: number
  fixedPercent: number
  altPercent: number
  cashPercent: number
  unclassifiedPercent: number
}
