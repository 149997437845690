import Api from '../helpers/api'
import { ManualClientAccountObj } from '../objects/clientAccount'

export const FETCH_CLIENT_ACCOUNTS: string = 'FETCH_CLIENT_ACCOUNTS'
export const FETCH_CLIENT_ACCOUNTS_PENDING: string =
  'FETCH_CLIENT_ACCOUNTS_PENDING'
export const FETCH_CLIENT_ACCOUNTS_FULFILLED: string =
  'FETCH_CLIENT_ACCOUNTS_FULFILLED'
export const FETCH_CLIENT_ACCOUNTS_REJECTED: string =
  'FETCH_CLIENT_ACCOUNTS_REJECTED'
export const SAVE_MANUAL_ACCOUNT: string = 'SAVE_MANUAL_ACCOUNT'
export const SAVE_MANUAL_ACCOUNT_PENDING: string = 'SAVE_MANUAL_ACCOUNT_PENDING'
export const SAVE_MANUAL_ACCOUNT_FULFILLED: string =
  'SAVE_MANUAL_ACCOUNT_FULFILLED'
export const SAVE_MANUAL_ACCOUNT_REJECTED: string =
  'SAVE_MANUAL_ACCOUNT_REJECTED'
export const FETCH_INVESTMENT_ACCOUNTS = 'FETCH_INVESTMENT_ACCOUNTS'
export const FETCH_INVESTMENT_ACCOUNTS_PENDING =
  'FETCH_INVESTMENT_ACCOUNTS_PENDING'
export const FETCH_INVESTMENT_ACCOUNTS_FULFILLED =
  'FETCH_INVESTMENT_ACCOUNTS_FULFILLED'
export const FETCH_INVESTMENT_ACCOUNTS_REJECTED =
  'FETCH_INVESTMENT_ACCOUNTS_REJECTED'
export const REMOVE_ACCOUNT: string = 'REMOVE_ACCOUNT'
export const REMOVE_ACCOUNT_PENDING: string = 'REMOVE_ACCOUNT_PENDING'
export const REMOVE_ACCOUNT_FULFILLED: string = 'REMOVE_ACCOUNT_FULFILLED'
export const REMOVE_ACCOUNT_REJECTED: string = 'REMOVE_ACCOUNT_REJECTED'
export const UPDATE_MANUAL_ACCOUNT: string = 'UPDATE_MANUAL_ACCOUNT'
export const UPDATE_MANUAL_ACCOUNT_PENDING: string =
  'UPDATE_MANUAL_ACCOUNT_PENDING'
export const UPDATE_MANUAL_ACCOUNT_FULFILLED: string =
  'UPDATE_MANUAL_ACCOUNT_FULFILLED'
export const UPDATE_MANUAL_ACCOUNT_REJECTED: string =
  'UPDATE_MANUAL_ACCOUNT_REJECTED'
export const RESET: string = 'RESET'

export interface ManualClientRequestObj {
  balanceDate: string
  totalValueManual: number
  accountNickname: string
  accountNumberFull: string
  type: string
  guidebookClassification: string
  source: string
  strategyReporting: boolean
  allocationOverridden?: boolean
  bondsManual?: number
  stocksManual?: number
  cashManual?: number
  altManual?: number
  unclassifiedManual?: number
}

const mapManualAccountObj = (
  request: ManualClientAccountObj
): ManualClientRequestObj => {
  return {
    balanceDate: `${request.balanceDate}`,
    totalValueManual: request.totalValueManual,
    accountNickname: `${request.accountNickname}`,
    accountNumberFull: `${request.accountNumberFull}`,
    type: `${request.registrationType}`,
    guidebookClassification: `${request.guidebookClassification}`,
    source:
      request.source === null || request.source === undefined
        ? 'Guide Center - Manual'
        : `${request.source}`,
    strategyReporting:
      request.strategyReporting === null ||
      request.strategyReporting === undefined
        ? true
        : request.strategyReporting
  }
}

export const getClientAccounts = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_CLIENT_ACCOUNTS,
    payload: apiInstance.get('/clientAccounts').then((response) => {
      return response.data
    })
  }
}

export const getInvestmentAccounts = (): {
  type: string
  payload: Promise<string>
} => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_INVESTMENT_ACCOUNTS,
    payload: apiInstance.get('/investments').then((response) => {
      return response.data
    })
  }
}

export const saveManualAccount = (
  request: ManualClientAccountObj
): { type: string; payload: Promise<string> } => {
  const apiInstance = Api.getInstance()
  const manualObj = mapManualAccountObj(request)
  manualObj.allocationOverridden = true
  manualObj.bondsManual = 0
  manualObj.stocksManual = 0
  manualObj.cashManual = 0
  manualObj.altManual = 0
  manualObj.unclassifiedManual = 100
  return {
    type: SAVE_MANUAL_ACCOUNT,
    payload: apiInstance.post('/clientAccounts', manualObj).then((response) => {
      return response.data
    })
  }
}

export const removeAccount = (id) => {
  const apiInstance = Api.getInstance()
  return {
    type: REMOVE_ACCOUNT,
    payload: apiInstance.delete(`/clientAccounts/${id}`).then((response) => {
      return response.data
    })
  }
}

export const updateManualAccount = (id, request: ManualClientAccountObj) => {
  const apiInstance = Api.getInstance()
  return {
    type: UPDATE_MANUAL_ACCOUNT,
    payload: apiInstance
      .put(`/clientAccounts/${id}`, mapManualAccountObj(request))
      .then((response) => {
        return response.data
      })
  }
}

export const resetAccount = () => {
  return {
    type: RESET
  }
}
