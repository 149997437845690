import React from 'react'

const NetWorthDisclosureText = (props: any) => {
  const { extraDisclosure } = props
  return (
    <div>
      <h2 className='disclosures__section-title'>Accounts</h2>
      <p className='disclosures__text'>
        The information contained in this material is for general informational
        purposes only and does not constitute an offer, solicitation, invitation
        or recommendation to buy or sell any financial instruments or to provide
        any investment advice or service of any kind. This report is not to be
        used as an official books and records statement. Please contact the
        relevant product sponsor if you have any questions about the statements.
        Any information and balances included herein have been obtained from
        sources believed to be reliable but its accuracy and completeness are
        not guaranteed and have not been independently verified by United
        Capital Financial Advisors, LLC (“United Capital”) or your financial
        advisor. In the event of a discrepancy, the sponsor's valuation shall
        prevail. This information is not meant to supersede your custodial
        account statement which should be reviewed regularly. If you have any
        questions about the accuracy of your investment holdings, investment
        allocation or the management fee displayed on this statement, please
        contact your advisor immediately. Data reflected within this report may
        reflect data held at various custodians and may not be covered under
        SIPC. SIPC coverage only applies to those assets held at a SIPC member
        firm. You should contact your financial representative, or the other
        entity, or refer to the other entity's statement, regarding SIPC
        coverage. Assets reflected on this table that are not managed by your
        investment advisor are not part of an official books and records
        statement. For fee-based accounts only: The data may or may not reflect
        the deduction of investment advisory fees. If the investment is being
        managed through a fee-based account or agreement, the returns may be
        reduced by those applicable advisory fees.
      </p>
      <p className='disclosures__text'>
        PLEASE REFER TO YOUR ACCOUNT CUSTODIAN CONFIRMATIONS AND STATEMENTS FOR
        THE MOST ACCURATE INFORMATION ABOUT YOUR ACCOUNT(S).
      </p>
      <div
        className='disclosures__text'
        dangerouslySetInnerHTML={{ __html: extraDisclosure || '' }}
      />
    </div>
  )
}

export default NetWorthDisclosureText
