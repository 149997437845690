import React from 'react'

const GeneralDisclosures = () => {
  const currentYear = new Date().getFullYear()
  return (
    <p className='footer__links-container'>
      ©{currentYear} | United Capital Financial Advisors, LLC (“United
      Capital”). All Rights Reserved. | FinLife Partners is a d/b/a of United
      Capital Financial Advisors, LLC
    </p>
  )
}

export default GeneralDisclosures
