/**
 * data to track
 * add optional tracking properties
 * page : page name
 * feature: feature name
 * custom: custom tracking
 */

import sha256 from 'crypto-js/sha256'

export interface FeatureTracking {
  page?: string
  id: string
  userType: 'Finlife' | 'PFM'
  feature?: string
  custom?: {}
}

export interface TrackingProps {
  user: { householdId: string; type: 'Finlife' | 'PFM' }
  location: { pathname: string }
}

export const scramble = (string: string): string => sha256(string).toString()

// extract page name given the predefined page routes.
const extractPageName = (pathName: string, hhid: string): string => {
  if (!pathName) return ''
  if (pathName === '/') return 'GuideCenter'

  const pathArray = pathName.split('/')
  let pageName: string = ''
  pathArray[1].split('-').forEach((item) => {
    pageName = pageName + ' ' + item.charAt(0).toUpperCase() + item.slice(1)
  })

  return pageName.replace(/^\s+|\s+$/g, '')
}

// handle tracking click thrus
export const googleTagManagerOnClick = (
  propsParams: TrackingProps,
  customParams: {}
): FeatureTracking | boolean => {
  if (!propsParams) return false
  const { householdId, type } = propsParams.user
  const pathName = propsParams.location && propsParams.location.pathname
  return {
    ...customParams,
    id: householdId,
    userType: type,
    page: extractPageName(pathName, householdId)
  }
}

/**
 * build the gtm data layer
 * should whitelist props keys for tracking
 */
export const googleTagManagerBuilder = (
  propsParams: TrackingProps
): FeatureTracking | boolean => {
  if (!propsParams) return false
  const { householdId, type } = propsParams.user
  const trackingObject = {
    id: householdId,
    userType: type,
    page: extractPageName(propsParams.location.pathname, householdId)
  }

  return !householdId ? false : trackingObject
}

/**
 * Push the analytics data to the dataLayer
 * @param trackingData - analytics data
 */
export const googleTagManager = (
  trackingData: object | FeatureTracking | boolean
) => {
  if (
    trackingData === false ||
    (trackingData && !Object.keys(trackingData)?.length)
  ) {
    return false
  }
  if ((window as any) && (window as any).dataLayer) {
    ;(window as any).dataLayer.push(trackingData)
  }

  return true
}

/**
 * Log unique event only | will push the analytics data in the layer only if the event not exist
 * @param trackingData - analytics data
 * @param forceUpdate - With forceUpdate function will update the params only
 */
export const logUniqueEvent = (
  trackingData: any,
  forceUpdate: boolean = false
) => {
  if ((window as any)?.dataLayer) {
    if (
      forceUpdate === false &&
      Object.values((window as any)?.dataLayer)?.filter(
        (layer: any) => layer?.event === trackingData?.event
      ).length === 0
    ) {
      googleTagManager(trackingData)
    } else if (forceUpdate) {
      updateEventParams(trackingData)
    }
  }
}

/**
 * Update params for existing event and data in the dataLayer
 * @param trackingData - analytics data with required event property
 */
export const updateEventParams = (trackingData: any) => {
  if (trackingData?.event) {
    const keys = Object.keys(trackingData).filter((key) => key !== 'event')
    if (keys.length) {
      if ((window as any) && (window as any).dataLayer) {
        ;(window as any).dataLayer.filter((data: any) => {
          if (data.event === trackingData?.event) {
            keys.forEach((key) => {
              data[key] = trackingData[key]
            })
          }
          return data
        })
      }
    }
  }
}

/**
 * Set user id with optional forceUpdate
 * @param userId  - login id of the user
 * @param userType - login user's type: either 'PFM' or 'Finlife'
 * @param forceUpdate - force update params for the event
 */
export const setUser = (
  userId: string,
  userType: string,
  forceUpdate: boolean = false
) => {
  logUniqueEvent(
    {
      event: 'login',
      userId: userId !== null ? scramble(userId) : userId,
      userType: userType !== null ? userType : 'No user type found.'
    },
    forceUpdate
  )
}

export const emoneyClickThru = (planningSoftware: string, userId: string) => {
  const planningSoftwareList: string[] = ['emoney', 'emoney_enterprise']

  planningSoftwareList.includes(planningSoftware) &&
    logUniqueEvent(
      {
        event: 'emoney-login',
        userId: scramble(userId),
        type: planningSoftware
      },
      false
    )
}
