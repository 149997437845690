import React, { ReactNode } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import classnames from 'classnames'

export type DashTileLink = {
  label: string
  route: string
  openExternalLink?: boolean
}

export interface DashTileProps {
  className?: string
  title: string
  imageRight?: ReactNode
  children?: ReactNode
  links?: DashTileLink[]
  nullComponent?: ReactNode
  showNull?: boolean
  rowTileType?: boolean
  loading?: boolean
  error?: boolean
}

const Header = ({
  title,
  imageRight,
  styleType
}: {
  title: string
  imageRight: ReactNode
  styleType?: string
}) => (
  <Row className={`${styleType}__header justify-content-between`}>
    <Col>
      <h4 className={`${styleType}__title`}>{title}</h4>
    </Col>
    {imageRight && (
      <Col>
        <div className='d-flex flex-row-reverse'>{imageRight}</div>
      </Col>
    )}
  </Row>
)

const DashTile = ({
  className,
  title,
  children,
  links = [],
  nullComponent,
  showNull = false,
  imageRight,
  rowTileType,
  loading = false,
  error = false
}: DashTileProps) => {
  const id = `dashboard-tile-${title.replace(/\s/g, '-').toLowerCase()}`
  const styleType = rowTileType ? 'dashboard-row-tile' : 'dashboard-tile'
  const noContent = loading || error

  return (
    <div className={classnames('d-flex', 'dashboard-tile', className)}>
      <Card
        id={id}
        className={`border-0 rounded-0 flex-grow-1 bg-white dashboard-tile__card${
          noContent ? '--no-content' : ''
        }`}>
        {loading ? (
          <>
            <Header title={title} imageRight={imageRight} />
            <div className='dashboard-tile__content'>Loading...</div>
          </>
        ) : nullComponent && showNull ? (
          nullComponent
        ) : (
          <>
            <Header
              title={title}
              imageRight={imageRight}
              styleType={styleType}
            />
            <div className={`${styleType}__content`}>{children}</div>
            {links.length > 0 && (
              <div className={`${styleType}__links`}>
                {links.map((link, i) => (
                  <Link
                    key={`${id}-link-${i}`}
                    className={`${styleType}__links-link d-inline-flex`}
                    to={link.openExternalLink ? null : link.route}
                    onClick={() =>
                      link.openExternalLink ? window.open(link.route) : null
                    }>
                    <span className='dashboard-tile__links-link-text'>
                      {link.label}
                    </span>
                    <i className='bi bi-chevron-right text-dark dashboard-tile__icon'></i>
                  </Link>
                ))}
              </div>
            )}
          </>
        )}
      </Card>
    </div>
  )
}

export default DashTile
