import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import {
  Form,
  Dropdown,
  Button,
  Card,
  Row,
  Col,
  Container
} from 'react-bootstrap'
import { connect, ConnectedProps } from 'react-redux'
import { getHousehold } from '../../../actions/household.action'
import { getGoals } from '../../../actions/goals.action'
import { GlobalState } from '../../../reducers'
import {
  createGoalRequest,
  getGoalRequests,
  updateGoalRequest
} from '../../../actions/goalRequests.action'
import { addErrorToast, addSuccessToast } from '../../../actions/toasts.action'
import { GoalRequestObj, FinancialGoalObj } from '../../../objects/plan'
import { sanitizeCurrency } from '../../../helpers'
import { closeForm, currencyOptions, typeOptions } from '../../../helpers/plan'

export type AddEditFormProps = ConnectedProps<typeof connector>

export const AddEditForm = (props: AddEditFormProps) => {
  const {
    user,
    getGoalRequests,
    goal,
    request,
    createGoalRequest,
    updateGoalRequest,
    getGoals,
    addSuccessToast,
    addErrorToast
  } = props
  const householdFetched = user?.householdFetched

  const goalState = {
    name: goal?.name || '',
    type: goal?.type || '',
    owner: goal?.owner || '',
    startDate: goal?.startDate || '',
    endDate: goal?.endDate || '',
    amount: goal?.amount?.replace(/[$%]/g, '') || '',
    amountType: goal?.amountType || 'Dollar',
    annualIncrease: goal?.annualIncrease ? String(goal.annualIncrease) : '',
    comment: ''
  }

  const requestState = {
    name: request?.proposedName,
    type: request?.proposedGoalType,
    owner: request?.proposedGoalOwnerId,
    startDate: request?.proposedStartOfGoal,
    endDate: request?.proposedEndOfGoal,
    amount: request?.proposedAmount ? String(request?.proposedAmount) : '',
    amountType: request?.proposedAmountType,
    annualIncrease: request?.proposedAnnualIncrease
      ? String(request?.proposedAnnualIncrease)
      : '',
    comment: request?.comment
  }

  const [validForm, setValidation] = useState(false)
  const [stateObj, setNewState] = useState(request ? requestState : goalState)
  const [isValidProposedGoalType, setIsValidProposedGoalType] = useState(true)

  useEffect(() => {
    getGoals()
    getGoalRequests()
  }, [])

  const onChange = (name, val) => {
    setNewState((prevState) => ({
      ...prevState,
      [name]: val
    }))
  }

  const handleSelect = (eventKey, event, field) => {
    event.stopPropagation()
    setNewState((prevState) => ({
      ...prevState,
      [field]: eventKey
    }))

    if (field === 'type') {
      setIsValidProposedGoalType(Boolean(eventKey))
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const requestBody: GoalRequestObj = {
      comment: stateObj?.comment,
      requestType: goal?.id || request?.id ? 'EDIT' : 'CREATE',
      proposedName: stateObj?.name,
      proposedGoalType: stateObj?.type,
      proposedAmount: sanitizeCurrency(String(stateObj?.amount)),
      proposedAmountType: stateObj?.amountType,
      proposedAnnualIncrease: sanitizeCurrency(stateObj?.annualIncrease),
      proposedGoalOwnerId: stateObj?.owner,
      proposedStartOfGoal: stateObj?.startDate,
      proposedEndOfGoal: stateObj?.endDate
    }
    if (request?.id) {
      requestBody.id = request?.id
    } else {
      requestBody.status = 'PENDING'
    }
    if (goal?.id) {
      requestBody.existingId = goal?.id
    }
    if (request?.existingId) {
      requestBody.existingId = request?.existingId
    }

    setValidation(true)
    // will return if the following fields are invalid
    let isRequiredFieldsValid = true

    if (!requestBody.proposedName) {
      setValidation(false)
      isRequiredFieldsValid = false
    }

    if (!requestBody.proposedGoalType) {
      setValidation(false)
      setIsValidProposedGoalType(false)
      isRequiredFieldsValid = false
    }

    if (!isRequiredFieldsValid) {
      return
    }

    if (request?.id) {
      try {
        await updateGoalRequest(request.id, requestBody)
        closeForm()
        goal && getGoals()
        request && getGoalRequests()
        addSuccessToast({
          message: 'Plan Input change request completed.'
        })
      } catch (error) {
        setValidation(false)
        addErrorToast({
          message: 'Unable to change plan input.\nPlease try again'
        })
      }
    } else {
      try {
        await createGoalRequest(requestBody)
        closeForm()
        goal && getGoals()
        request && getGoalRequests()
        addSuccessToast({
          message: 'New Plan Input sent successfully to your advisor!'
        })
      } catch (error) {
        setValidation(false)
        addErrorToast({
          message: 'Unable to create new plan input.\nPlease try again'
        })
      }
    }
  }

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [householdFetched])

  return (
    <Container fluid>
      <Row className='d-flex flex-row justify-content-center plan-input-forms'>
        <Card
          id='networth-add-account'
          className='rounded-0 bg-white plan-input-forms__card'>
          <Row className='d-flex flex-row justify-content-start'>
            <Col xs={12}>
              <h2>{goal || request ? 'Edit Input' : 'New Update Proposal'}</h2>
              <div className='plan-input-forms__subheading'>
                Saving this will not immediately affect your plan. Your advisor
                will review your request and contact you to discuss the impact.
              </div>
            </Col>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>Input Name</Form.Label>
                <Form.Control
                  type='text'
                  tabIndex={1}
                  name='name'
                  placeholder='E.g. Vacation Home'
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={stateObj.name || ''}
                />
              </Form.Group>
              <label className='plan-input-forms__label-dropdown'>Type</label>
              <div
                className={`${
                  isValidProposedGoalType
                    ? ''
                    : 'plan-input-forms__dropdown-is-invalid'
                }`}>
                <Dropdown
                  onSelect={(eventKey, event) =>
                    handleSelect(eventKey, event, 'type')
                  }>
                  <Dropdown.Toggle
                    variant=''
                    className='d-flex text-start w-100'
                    tabIndex={2}>
                    {!isValidProposedGoalType && (
                      <i className='bi bi-arrow-right-circle fs-5'></i>
                    )}
                    {stateObj.type || 'Select Type'}
                    <i className='bi bi-chevron-down ms-auto fs-5'></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {typeOptions.map((option, i) => (
                      <Dropdown.Item
                        eventKey={option.value}
                        key={option.value}
                        tabIndex={2}
                        className='plan-input-forms__dropdown-item'>
                        {option.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {!isValidProposedGoalType && (
                  <div className='d-inline-flex mb-4'>
                    <i className='bi bi-exclamation-circle me-2 messages-error__icon'></i>
                    <div className='fs-8 fw-bold'>
                      This is a required field.
                    </div>
                  </div>
                )}
              </div>

              <Form.Group>
                <Form.Label>Owner</Form.Label>
                <Form.Control
                  type='text'
                  tabIndex={3}
                  name='owner'
                  placeholder='E.g. Jonathan Doe'
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={stateObj.owner || ''}
                />
              </Form.Group>
              <Row className='d-flex flex-row justify-content-between'>
                <Col className='plan-input-forms__input-half' xs={12}>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control
                      type='text'
                      tabIndex={4}
                      name='startDate'
                      placeholder='MM/DD/YYYY'
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={stateObj.startDate || ''}
                    />
                  </Form.Group>
                </Col>
                <Col className='plan-input-forms__input-half' xs={12}>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control
                      type='text'
                      tabIndex={5}
                      name='endDate'
                      placeholder='MM/DD/YYYY'
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={stateObj.endDate || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='d-flex flex-row justify-content-between'>
                <Col className='plan-input-forms__input-half' xs={12}>
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      type='text'
                      tabIndex={6}
                      name='amount'
                      placeholder='E.g. 100,000'
                      onChange={(e) => onChange(e.target.name, e.target.value)}
                      value={stateObj.amount || ''}
                    />
                  </Form.Group>
                </Col>
                <Col className='plan-input-forms__input-half' xs={12}>
                  <label className='plan-input-forms__label-dropdown'>
                    Currency
                  </label>
                  <div>
                    <Dropdown
                      onSelect={(eventKey, event) =>
                        handleSelect(eventKey, event, 'amountType')
                      }>
                      <Dropdown.Toggle
                        variant=''
                        className='d-flex justify-content-between text-start w-100'
                        tabIndex={7}>
                        {stateObj.amountType === 'Dollar' ? '$' : '%'}
                        <i className='bi bi-chevron-down fs-5'></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {currencyOptions.map((option, i) => (
                          <Dropdown.Item
                            eventKey={option.value}
                            key={option.value}
                            tabIndex={7}
                            className='plan-input-forms__dropdown-item'>
                            {option.label}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Annual Increase</Form.Label>
                <Form.Control
                  type='text'
                  tabIndex={8}
                  name='annualIncrease'
                  placeholder='E.g. 2%'
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={stateObj.annualIncrease || ''}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Comment</Form.Label>
                <Form.Control
                  type='text'
                  tabIndex={9}
                  name='comment'
                  placeholder='Add a comment regarding this change'
                  onChange={(e) => onChange(e.target.name, e.target.value)}
                  value={stateObj.comment || ''}
                />
              </Form.Group>
              <Button
                className='rounded-1 plan-input-forms__button match-btn-width'
                type='submit'
                disabled={validForm}>
                Send Request
              </Button>
              <Button
                variant='link'
                className='rounded-1 plan-input-forms__button-cancel match-btn-width'
                type='button'
                onClick={closeForm}>
                Cancel
              </Button>
            </Form>
          </Row>
        </Card>
      </Row>
    </Container>
  )
}

const mapStateToProps = (store: GlobalState, { match }: any) => {
  const paramId = match?.params?.goalId
  const goals = store.financialGoals.goalsById
  let goal: FinancialGoalObj
  if (goals && paramId) {
    goal = Object.values(goals)?.find((acc) => {
      return acc.id === paramId
    })
  }
  const requests = store.goalRequests.requests
  let request: GoalRequestObj
  if (requests && paramId) {
    request = Object.values(requests)?.find((acc) => {
      return acc.id === paramId
    })
  }
  return {
    goal,
    request,
    user: store.user
  }
}

const mapDispatchToProps = {
  getHousehold,
  createGoalRequest,
  updateGoalRequest,
  getGoalRequests,
  getGoals,
  addSuccessToast,
  addErrorToast
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default withRouter(connector(AddEditForm))
