import React, { useEffect, useState } from 'react'
import { ClientAccountPositionsObj } from '../../../objects/clientAccount'
import { HoldingHeaderCell, HoldingRow, ESortOrder } from './HoldingRow'
import {
  sortByAllocations,
  sortByNumber,
  sortByString
} from '../../../helpers/investments'

interface IHoldingsTable {
  positions: ClientAccountPositionsObj[]
}

enum ESortStr {
  symbol = 'tickerSymbol',
  description = 'securityDescription',
  allocation = 'allocation',
  qty = 'quantity',
  price = 'price',
  value = 'value'
}

const sortableHeaderLabels = {
  [ESortStr.symbol]: 'Symbol',
  [ESortStr.description]: 'Description',
  [ESortStr.allocation]: 'Allocation',
  [ESortStr.qty]: 'QTY',
  [ESortStr.price]: 'Price',
  [ESortStr.value]: 'Value'
}

const HoldingsTable = (props: IHoldingsTable): JSX.Element => {
  const { positions } = props
  const [localPositions, setLocalPositions] = useState([])
  const [sortBy, setSortBy] = useState(ESortStr.value)
  const [sortOrder, setSortOrder] = useState(ESortOrder.ASC)

  useEffect(() => {
    const newPositions = [...positions]
    switch (sortBy) {
      // strings
      case ESortStr.symbol:
      case ESortStr.description: {
        newPositions.sort((v1, v2) => sortByString(v1, v2, sortBy) * sortOrder)
        break
      }
      // numbers
      case ESortStr.value:
      case ESortStr.qty:
      case ESortStr.price: {
        newPositions.sort((v1, v2) => sortByNumber(v1, v2, sortBy) * sortOrder)
        break
      }
      // allocation special case
      case ESortStr.allocation: {
        newPositions.sort((v1, v2) => {
          const result = sortByAllocations(v1, v2) * sortOrder
          return result === 0
            ? sortByNumber(v1, v2, 'value') * sortOrder
            : result
        })
        break
      }
    }
    setLocalPositions(newPositions)
  }, [sortBy, sortOrder, JSON.stringify(positions)])

  const handleSort = (sortString) => {
    if (sortBy === sortString) {
      return sortOrder === ESortOrder.ASC
        ? setSortOrder(ESortOrder.DESC)
        : setSortOrder(ESortOrder.ASC)
    }
    setSortBy(sortString)
    setSortOrder(ESortOrder.ASC)
  }

  return (
    <div className='holding-table__container'>
      <div className='position-relative' id='holding_title'>
        <div className='holding-table__title'>Holdings</div>
      </div>
      <div id='holding-table' className='position-relative bg-white mt-3'>
        <div className='holding-table__row-w'>
          <table id='holding_table' className='holding-table'>
            <thead>
              <tr className='holding-table__row'>
                <th className='holding-table__header holding-table__header--first' />
                {Object.entries(sortableHeaderLabels).map(
                  ([property, label]) => (
                    <HoldingHeaderCell
                      key={property}
                      label={label}
                      property={property}
                      sortOrder={property === sortBy && sortOrder}
                      sortCallback={handleSort}
                    />
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {localPositions.map((tableRow) => (
                <HoldingRow key={tableRow.id} rowData={tableRow} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default HoldingsTable
