import React, { useEffect, useState, useCallback } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'

import { getHonestConversation } from '../../../actions/honestConversations.action'
import { getHousehold } from '../../../actions/household.action'
import { getContacts } from '../../../actions/contacts.action'
import { getUser } from '../../../actions/user.action'

import backgroundLogo from '../../../assets/images/backgroundlogo.png'

import { getHouseholdPartner } from '../../../selectors/moneymind'

import {
  getPartnerPriorityCards,
  getUserPriorityCards
} from '../../../selectors/priorities'

import { CardCarousel } from './CardCarousel'

const NULL_TEXT = 'There is no comment for this card.'

type PersonalPrioritiesStore = ReturnType<typeof mapStateToProps>
type PersonalPrioritiesActions = typeof mapDispatchToProps
export type PersonalPrioritiesProps = PersonalPrioritiesActions &
  PersonalPrioritiesStore

const PersonalPriorities = (props: PersonalPrioritiesProps) => {
  const {
    partner,
    partnerCards,
    userCards,
    honestConversation,
    getHonestConversation,
    getContacts,
    getHousehold,
    getUser
  } = props

  const [partnerCardComment, setPartnerCardComment] = useState(null)
  const [selectedPartnerCard, setPartnerSelectedCard] = useState(null)

  const [userCardComment, setUserCardComment] = useState(null)
  const [selectedUserCard, setUserSelectedCard] = useState(null)

  const [hcLoaded, setHcLoaded] = useState(false)
  const [householdLoaded, setHouseHold] = useState(false)

  const updateComments = useCallback(async () => {
    if (partnerCards) {
      setPartnerCardComment(partnerCards?.[0].comment ?? NULL_TEXT)
    }
    if (userCards) {
      setUserCardComment(userCards?.[0].comment ?? NULL_TEXT)
    }
  }, [partnerCards, userCards])

  const doHcActions = useCallback(async () => {
    if (householdLoaded && !hcLoaded && !honestConversation) {
      await getHonestConversation()
      setHcLoaded(true)
    }
    updateComments()
  }, [householdLoaded, hcLoaded, honestConversation])

  const doActions = useCallback(async () => {
    if (!householdLoaded) {
      await getHousehold()
      await getUser()
      await getContacts()
    }
    setHouseHold(true)
  }, [householdLoaded])

  useEffect(() => {
    doActions()
  }, [])

  useEffect(() => {
    if (householdLoaded) {
      doHcActions()
    }
  }, [householdLoaded, honestConversation])

  const userCardSetter = (id: string) => {
    setUserSelectedCard(id)
    userCards.forEach((card) => {
      if (card.cardId === id) {
        setUserCardComment(card.comment)
      }
    })
  }

  const partnerCardSetter = (id: string) => {
    setPartnerSelectedCard(id)
    partnerCards.forEach((card) => {
      if (card.cardId === id) {
        setPartnerCardComment(card.comment)
      }
    })
  }

  return (
    <div>
      <img
        alt='ins-bg'
        className='personal-priorities__bg-image'
        src={backgroundLogo}
      />

      <Link
        to='/honest-conversations'
        className='d-block text-dark fw-bold ms-4 ms-md-0 py-3'>
        <i className='bi bi-chevron-left fs-6 me-2' />
        Back
      </Link>

      <h2 className='rfont--h2 fw-bold text-dark ms-4 ms-md-0 mb-lg-5'>
        Personal Priorities
      </h2>
      {userCards?.length > 0 && (
        <div>
          <div className='personal-priorities__sub-title mb-3'>
            Your Priorities
          </div>
          <div className='position-relative bg-white p-3 p-md-x2'>
            <CardCarousel
              cards={userCards}
              selectCard={selectedUserCard}
              setSelectedCard={userCardSetter}
            />
            <h4 className='fs-6 text-dark fw-bold'>
              Why did you choose this card?
            </h4>
            <div className='fs-7'>{userCardComment}</div>
          </div>
        </div>
      )}

      {partner && partnerCards?.length > 0 && (
        <div className='mt-5'>
          <div className='personal-priorities__sub-title mb-3'>{`${partner.firstName} Priorities`}</div>
          <div className='position-relative bg-white p-3 p-md-x2'>
            <CardCarousel
              cards={partnerCards}
              selectCard={selectedPartnerCard}
              setSelectedCard={partnerCardSetter}
            />

            <h4 className='fs-6 text-dark fw-bold'>
              {`Why did ${partner.firstName} choose this card?`}
            </h4>
            <div className='fs-7'>{partnerCardComment}</div>
          </div>
        </div>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  getUser,
  getHousehold,
  getContacts,
  getHonestConversation
}
const mapStateToProps = (store: GlobalState) => {
  const partner = getHouseholdPartner(store)
  const partnerCards = getPartnerPriorityCards(store)
  const userCards = getUserPriorityCards(store)

  return {
    partner,
    partnerCards,
    userCards,
    honestConversation:
      store.honestConversations[store.honestConversations.hcExerciseId]
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PersonalPriorities)
