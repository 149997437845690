import React, { FunctionComponent, CSSProperties, SVGProps } from 'react'
import Tile from './Tile'
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg'

interface ModalProps {
  iconPng?: string
  style?: CSSProperties
  customCss?: string
  icon?: FunctionComponent<SVGProps<SVGSVGElement>>
  title: string | React.ReactNode[] | React.ReactNode
  children: React.ReactNode
  size?: 'S' | 'M' | 'L'
  largeHeader?: boolean
  closeModal?(): void
}

export class Modal extends React.Component<ModalProps> {
  public closeButton = (): JSX.Element => {
    const { closeModal } = this.props
    const closeButton = (
      <CloseIcon className='legacy-modal__icon' onClick={closeModal} />
    )
    return (
      <div className='legacy-modal__btn--close'>
        {closeModal !== null ? closeButton : null}
      </div>
    )
  }
  public getWidth = () => {
    const { size } = this.props
    switch (size) {
      case 'S':
        return 430
      case 'M':
        return 600
      case 'L':
        return 820
      default:
        return 600
    }
  }
  public render() {
    const { title, children, style, customCss, largeHeader } = this.props
    const modalCss = customCss
      ? 'legacy-modal__overlay ' + customCss
      : 'legacy-modal__overlay'
    return (
      <div className={modalCss}>
        <div className='legacy-modal__box-w' style={{ width: this.getWidth() }}>
          <Tile
            largeHeader={largeHeader}
            leftHeader={title}
            style={style}
            rightHeader={this.closeButton()}>
            {children}
          </Tile>
        </div>
      </div>
    )
  }
}
