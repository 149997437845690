import React from 'react'

type TTableData = {
  id: string
  cellComponentProps: any
}[]

type TTableColumns = {
  Cell: React.ElementType
  style?: any
  className?: string
}[]

const renderRows = (
  data: TTableData,
  columns: TTableColumns,
  rowClassname?: string
) => {
  return data?.map((item, _index) => (
    <tr key={item.id} className={rowClassname}>
      {columns.map((column, colIndex) => {
        const CellComponent = column.Cell
        return (
          <td
            key={colIndex}
            style={column.style as any}
            className={`${column.className}`}>
            <CellComponent {...item.cellComponentProps} />
          </td>
        )
      })}
    </tr>
  ))
}

export const NetworthTable = ({
  id,
  data,
  columns,
  rowClassname,
  className
}: {
  id: string
  data: TTableData
  columns: TTableColumns
  rowClassname?: string
  className?: string
}) => {
  return (
    <table id={id} className={`${className} networth-table`}>
      <tbody>{renderRows(data, columns, rowClassname)}</tbody>
    </table>
  )
}
