import React from 'react'
import { connect } from 'react-redux'
import { YodleeObj } from '../../objects/yodlee'
import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'

interface YodleeIframeProps {
  dispatch: AppDispatch
  yodleeParams: YodleeObj
}

class YodleeIframe extends React.Component<YodleeIframeProps> {
  public componentDidMount() {
    const { yodleeParams } = this.props
    if (yodleeParams.token !== null && yodleeParams.rsession !== null) {
      ;(document.getElementById('yodlee-form') as HTMLFormElement).submit()
    }
  }

  public componentDidUpdate(prevProps) {
    const { yodleeParams } = this.props
    if (yodleeParams !== prevProps.yodleeParams) {
      if (yodleeParams.token !== null && yodleeParams.rsession !== null) {
        ;(document.getElementById('yodlee-form') as HTMLFormElement).submit()
      }
    }
  }
  public render() {
    const { yodleeParams } = this.props
    return (
      <div style={{ WebkitOverflowScrolling: 'touch', maxHeight: 600 }}>
        {this.yodleeForm()}
        {yodleeParams.token !== null && yodleeParams.rsession !== null ? (
          <iframe
            src=''
            name='account-iframe'
            id='account-iframe'
            title='account-iframe'
            height='334px'
            width='100%'
            frameBorder='0'
            scrolling='yes'
          />
        ) : (
          <div style={{ flexGrow: 1 }} />
        )}
      </div>
    )
  }
  private yodleeForm = () => {
    const url = window._env_.REACT_APP_YODLEE_FASTLINK_URL
    const { yodleeParams } = this.props
    return (
      <form
        action={url}
        method='post'
        target='account-iframe'
        id='yodlee-form'
        style={{ display: 'none' }}>
        {Object.keys(yodleeParams).map((key) => (
          <input
            hidden={true}
            key={key}
            name={key}
            value={yodleeParams[key] ? yodleeParams[key] : ''}
            readOnly={true}
          />
        ))}
      </form>
    )
  }
}

export default connect((store: GlobalState, dispatch: AppDispatch) => {
  return {
    dispatch,
    yodleeParams: store.yodlee
  }
})(YodleeIframe)
