import React from 'react'
import { connect } from 'react-redux'
import InProgress from './InProgress'
import { GlobalState } from '../../../reducers'
import { getClientCards } from '../../../selectors/priorities'
import bgLower from '../../../assets/images/background/hc/lower.png'

export const noLinkDescription =
  'Your advisor needs to send you the link to the HonestConversations exercise before you can start.'

export const NoLinkSent = () => (
  <div className='hc-null'>
    <img className='hc-null__bg-image--lower' src={bgLower} alt='background' />
    <div id='hc-null-title' className='hc-null__main-title'>
      <h4 className='hc-null__title'>Your HonestConversations®</h4>
      <h1 className='hc-null__subtitle'>Focus On What You Care About</h1>
    </div>
    <div className='hc-null__descriptions'>
      <div className='hc-null__description mb-4'>
        Our HonestConversations® exercise helps focus you on the intersection
        between what matters to you and what you can control. It also gives you
        the opportunity to get on the same page with your loved ones about your
        financial goals and intentions.
      </div>
      <div className='hc-null__description'>{noLinkDescription}</div>
    </div>
  </div>
)

export type HonestConversationsNullProps = ReturnType<typeof mapStateToProps>

export const NullState = (props: HonestConversationsNullProps) => {
  const { user } = props

  if (!user.emailSent) {
    return <NoLinkSent />
  }

  return <InProgress {...props} />
}

const mapStateToProps = (store: GlobalState) => {
  const {
    user,
    partner,
    isSingleHH,
    rankedAnyCards,
    rankedTopCards
  } = getClientCards(store)
  return {
    user,
    partner,
    isSingleHH,
    rankedAnyCards,
    rankedTopCards
  }
}

export default connect(mapStateToProps)(NullState)
