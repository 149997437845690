import React from 'react'
import { connect } from 'react-redux'
import { GlobalState } from '../../reducers'

import { investmentPartners, allowedPartners } from '../../objects/partners'

export type PartnerLoginTileProps = ReturnType<typeof mapStateToProps>

export const PartnerLoginTile = (props: PartnerLoginTileProps) => {
  const { partners, user } = props

  const onlinePortalOptions = () => {
    const partnerDisplay = (onlinePortal) => (
      <a
        href={onlinePortal.link}
        target='_blank'
        rel='noopener noreferrer'
        key={onlinePortal.name}>
        <div className='inv-partners-login__item'>
          <img src={onlinePortal.img} alt={onlinePortal.name} />
        </div>
      </a>
    )

    return allowedPartners.reduce((displayablePartners, partner) => {
      if (partners.includes(partner)) {
        const partnerObj = investmentPartners(partner, user)
        partnerObj && displayablePartners.push(partnerDisplay(partnerObj))
      }
      return displayablePartners
    }, [])
  }

  return (
    <div className='mt-4 mt-sm-x2 ms-1'>
      <h3 className='inv-partners-login__title'>Partner Login</h3>
      <p className='inv-partners-login__title inv-partners-login__title--sub'>
        For a deeper look at your investment performance, log in below.
      </p>
      <div className='inv-partners-login__list'>{onlinePortalOptions()}</div>
    </div>
  )
}

const mapStateToProps = (store: GlobalState) => {
  const { user, investments } = store
  const { partners } = investments
  return {
    partners,
    user
  }
}

export default connect(mapStateToProps)(PartnerLoginTile)
