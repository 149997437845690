import React, { RefObject, Fragment, useState } from 'react'
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import { connect } from 'react-redux'
import { GlobalState } from '../../../reducers'
import { createGraphData, createGraphLayout } from './GraphData'
import LineGraphModal from './LineGraphModal'
import {
  filteredFundedScores,
  getPlanningSoftware,
  annotateYRange
} from '../../../selectors/plan'

const canvasRef: RefObject<HTMLCanvasElement> = React.createRef()

const generateGraph = (props) => {
  const { fundedScores, planningSoftware, rangeAnnotation } = props

  const roundedScores = fundedScores.map((fundedScore) => ({
    ...fundedScore,
    score: Math.round(fundedScore.score)
  }))

  return {
    graphDataSet: createGraphData(
      planningSoftware,
      roundedScores,
      rangeAnnotation
    ),
    graphOptions: createGraphLayout(rangeAnnotation)
  }
}

type LineGraphStore = ReturnType<typeof mapStateToProps>
export type LineGraphProps = LineGraphStore

export const LineGraph = (props: LineGraphProps) => {
  const [displayDetailsModal, toggleDetailModal] = useState(false)
  const [currentScore, setScore] = useState(null)

  const showDetailsModal = (evt) => {
    if (evt.length) {
      toggleDetailModal(true)
      setScore(props.fundedScores[evt[0]._index])
    }
  }

  const closeDetailsModal = () => {
    const tooltipEl = document.getElementById('chartjs-tooltip')
    tooltipEl.style.opacity = '0'
    toggleDetailModal(false)
    setScore(null)
  }

  return (
    <Fragment>
      <div className='funded-score__graph'>
        <Line
          ref={canvasRef}
          id='latest-funded-score'
          data={generateGraph(props).graphDataSet}
          options={generateGraph(props).graphOptions}
          getElementAtEvent={showDetailsModal}
        />
      </div>
      {displayDetailsModal ? (
        <LineGraphModal
          show={displayDetailsModal}
          closeModal={closeDetailsModal}
          currentScore={currentScore}
        />
      ) : null}
    </Fragment>
  )
}

const mapStateToProps = (store: GlobalState) => ({
  fundedScores: filteredFundedScores(store),
  planningSoftware: getPlanningSoftware(store),
  rangeAnnotation: annotateYRange(store)
})

export default connect(mapStateToProps, {})(LineGraph)
