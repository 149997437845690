import React, { useState, useEffect } from 'react'
import {
  Button,
  Card,
  Form,
  FormGroup,
  FormControl,
  FormLabel
} from 'react-bootstrap'
import { connect } from 'react-redux'
import { GQLClient } from '../../app'
import {
  CreateMessageThreadQuery,
  GetMessageThreadQuery
} from '../../helpers/queries'
import { history } from '../../store'
import { GlobalState } from '../../reducers'
import { getHousehold } from '../../actions/household.action'

export interface NewMessageProps {
  householdFinId: string
  householdFetched: boolean
  userId: string
  getHousehold(): void
}

export const NewMessage = (props: NewMessageProps) => {
  const { getHousehold, householdFetched, householdFinId, userId } = props
  const [messageObj, updateMessageObj] = useState({ subject: '', message: '' })
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!householdFetched) {
      getHousehold()
    }
  }, [])

  const onChange = (name: string, val: string): any => {
    const tempState = { ...messageObj }
    tempState[name] = val
    updateMessageObj(tempState)
  }

  const isSubjectEmpty = messageObj?.subject?.trim().length === 0

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitted(true)
    if (isSubjectEmpty) {
      return
    }

    const { message, subject } = messageObj
    GQLClient.mutate({
      mutation: CreateMessageThreadQuery,
      variables: { message, householdFinId, subject, userId },
      refetchQueries: [
        {
          query: GetMessageThreadQuery,
          variables: { householdFinId }
        }
      ]
    }).then((res) => {
      history.push('/messages')
    })
  }

  return (
    <div className='d-flex flex-row justify-content-center add-message'>
      <Card
        id='add-message'
        className='p-x2 border-0 rounded-0 bg-white add-message__card'>
        <div className='d-flex flex-column justify-content-start'>
          <h2 className='add-message__title-heading'>New message</h2>
          <div className='add-message__title-subheading'>
            Communicate securely and conveniently
          </div>
          <Form onSubmit={handleSubmit} className='pt-3'>
            <FormGroup>
              <FormLabel>Subject</FormLabel>
              <FormControl
                tabIndex={1}
                name='subject'
                placeholder='Enter subject'
                onChange={(e) => onChange('subject', e.target.value)}
                isInvalid={submitted && isSubjectEmpty}
                className='add-message__input'
              />
            </FormGroup>

            <FormGroup className='pt-4'>
              <FormLabel>Message</FormLabel>
              <FormControl
                as='textarea'
                tabIndex={2}
                name='message'
                onChange={(e) => onChange('message', e.target.value)}
                placeholder='Add a message'
                rows={6}
                required={false}
                className='add-message__textarea'
              />
            </FormGroup>

            <Button
              type='submit'
              className='add-message__button fs-6 match-btn-width'
              size='lg'>
              Send
            </Button>
          </Form>
          <div className='add-message__disclaimer'>
            Thank you for contacting us. All interactions are subject to
            recordkeeping and monitoring. We are unable to accept any trading or
            transaction requests, nor do we provide any legal, tax or investment
            advice through this tool.
          </div>
        </div>
      </Card>
    </div>
  )
}

const mapDispatchToProps = { getHousehold }

const mapStateToProps = (store: GlobalState) => {
  const { user } = store
  return {
    householdFinId: user.householdId,
    householdFetched: user.householdFetched,
    userId: user.id
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewMessage)
