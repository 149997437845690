import Api from '../helpers/api'

export const FETCH_YODLEE_PARAMS: string = 'FETCH_YODLEE_PARAMS'
export const FETCH_YODLEE_PARAMS_FULFILLED: string =
  'FETCH_YODLEE_PARAMS_FULFILLED'
export const FETCH_YODLEE_PARAMS_PENDING: string = 'FETCH_YODLEE_PARAMS_PENDING'
export const FETCH_YODLEE_PARAMS_REJECTED: string =
  'FETCH_YODLEE_PARAMS_REJECTED'
export const SET_YODLEE_PARAMS_ACCOUNT: string = 'SET_YODLEE_PARAMS_ACCOUNT'
export const FETCH_YODLEE_CLIENT_ACCOUNTS: string =
  'FETCH_YODLEE_CLIENT_ACCOUNTS'
export const FETCH_YODLEE_CLIENT_ACCOUNTS_PENDING: string =
  'FETCH_YODLEE_CLIENT_ACCOUNTS_PENDING'
export const FETCH_YODLEE_CLIENT_ACCOUNTS_FULFILLED: string =
  'FETCH_YODLEE_CLIENT_ACCOUNTS_FULFILLED'
export const FETCH_YODLEE_CLIENT_ACCOUNTS_REJECTED: string =
  'FETCH_YODLEE_CLIENT_ACCOUNTS_REJECTED'
export const FETCH_YODLEE_POSITIONS: string = 'FETCH_YODLEE_POSITIONS'
export const FETCH_YODLEE_POSITIONS_PENDING: string =
  'FETCH_YODLEE_POSITIONS_PENDING'
export const FETCH_YODLEE_POSITIONS_FULFILLED: string =
  'FETCH_YODLEE_POSITIONS_FULFILLED'
export const FETCH_YODLEE_POSITIONS_REJECTED: string =
  'FETCH_YODLEE_POSITIONS_REJECTED'

export const FETCH_FASTLINK: string = 'FETCH_FASTLINK'
export const FETCH_FASTLINK_FULFILLED: string = 'FETCH_FASTLINK_FULFILLED'
export const FETCH_FASTLINK_PENDING: string = 'FETCH_FASTLINK_PENDING'
export const FETCH_FASTLINK_REJECTED: string = 'FETCH_FASTLINK_REJECTED'

export const getYodleeParams = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_YODLEE_PARAMS,
    payload: apiInstance.get('/yodlee/accessParams').then((response) => {
      if (!response.data || typeof response.data === 'string') return {}
      const { data } = response

      return {
        token: data.accessTokens[0].value,
        rsession: data.accessTokens[0].userSession,
        app: data.accessTokens[0].appId,
        redirectReq: true
      }
    })
  }
}

export const getFastlinkUrl = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_FASTLINK,
    payload: apiInstance.get('/yodlee/fastlink').then((response) => {
      return response.data
    })
  }
}

export const setYodleeParamsAccount = (accountId) => {
  return {
    type: SET_YODLEE_PARAMS_ACCOUNT,
    payload: accountId
  }
}

export const getYodleeAccounts = () => {
  const apiInstance = Api.getInstance()
  return {
    type: FETCH_YODLEE_CLIENT_ACCOUNTS,
    payload: apiInstance.post('/yodlee/syncClientAccounts').then((response) => {
      return response.data
    })
  }
}
