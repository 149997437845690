import React from 'react'
import { connect } from 'react-redux'
import * as authActions from '../../actions/auth.action'
import { GlobalState } from '../../reducers'
import { AppDispatch } from '../../store'
import { Button } from '../Buttons'
import { Modal } from '../Layout'

interface TimeoutModalProps {
  sessionTimeout: number
  sessionTimeoutToggle: boolean
  dispatch: AppDispatch
}

interface TimeoutModalState {
  display: boolean
  sessionTimer: number
  modalTimer: number
}

class TimeoutModal extends React.Component<
  TimeoutModalProps,
  TimeoutModalState
> {
  private timerID: number

  constructor(props) {
    super(props)
    this.state = {
      display: false,
      sessionTimer: null,
      modalTimer: null
    }
  }

  public logout = async () => {
    await this.props.dispatch(authActions.revokeAuthenticationToken())
    window.location.replace(window._env_.REACT_APP_LOGOUT_URL)
  }

  public resetTimeout = () => {
    window.clearInterval(this.timerID)
    this.props.dispatch(authActions.resetTimeout())
    this.setState({ display: false, modalTimer: null })
  }

  public setDisplay = () => {
    this.setState({
      display: true,
      modalTimer: parseInt(window._env_.REACT_APP_SESSION_WARNING_SECONDS, 10)
    })
    this.timerID = window.setInterval(this.tick, 1000)
  }

  public tick = () => {
    const { modalTimer } = this.state
    if (modalTimer === 1) {
      window.clearInterval(this.timerID)
      this.logout()
    }
    this.setState({ modalTimer: modalTimer - 1 })
  }

  public componentWillReceiveProps(nextProps) {
    // Reset Timeout
    clearTimeout(this.state.sessionTimer)
    // Theres two conflicting typescript definitions that say that setTimeout should be a number and a NodeJS.Timer
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/21310
    const sessionTimer = setTimeout(
      this.setDisplay,
      this.props.sessionTimeout * 1000
    ) as any
    this.setState({ sessionTimer })
  }

  public componentWillUnmount() {
    clearTimeout(this.state.sessionTimer)
  }

  public renderTimeoutModal = () => {
    return (
      <Modal title='Session is Idle' closeModal={this.resetTimeout}>
        <div className='legacy-timeout-modal'>
          <p className='legacy-timeout-modal__message-1'>
            Due to inactivity you will be automatically signed out in{' '}
            {this.state.modalTimer} seconds.
          </p>
          <p className='legacy-timeout-modal__message-2'>
            To continue your session click on the "Stay Signed In" button. If
            you want to end your session now, click on the "Sign Out" button.
          </p>
          <div className='legacy-timeout-modal__btn-w'>
            <Button clear={true} onClick={this.logout}>
              Sign Out
            </Button>
            <Button primary={true} onClick={this.resetTimeout}>
              Stay Signed In
            </Button>
          </div>
        </div>
      </Modal>
    )
  }

  public render() {
    const { display } = this.state
    return display ? this.renderTimeoutModal() : null
  }
}

export default connect((store: GlobalState, dispatch: AppDispatch) => {
  return {
    sessionTimeout: store.auth.sessionTimeout,
    sessionTimeoutToggle: store.auth.sessionTimeoutToggle,
    dispatch
  }
})(TimeoutModal)
