import * as actions from '../actions/lifeActions.action'
import {
  LifeActionsInterface,
  LifeActionObj,
  AssigneesObj
} from '../objects/lifeActions'

export interface LifeActionsState {
  [household: string]: LifeActionsInterface
}

const initState: LifeActionsState = {}

const mapTasks = (lifeActionTasks: any) => {
  const taskList: any = {}
  lifeActionTasks.map((lifeActionTask) => {
    if (
      lifeActionTask.task &&
      lifeActionTask.task.assigneeType &&
      lifeActionTask.task.assigneeType.toLowerCase() === 'client'
    ) {
      taskList[lifeActionTask.task.id] = {
        isDeleted: lifeActionTask.isDeleted,
        lifeActionTaskId: lifeActionTask.id,
        id: lifeActionTask.task.id,
        subject: lifeActionTask.task.subject,
        description: lifeActionTask.task.description,
        dueDate: lifeActionTask.task.dueDate,
        createdDate: lifeActionTask.task.createdDate,
        completedDate: lifeActionTask.task.completedDate,
        status: lifeActionTask.task.status,
        assigneeType: lifeActionTask.task.assigneeType,
        assigneeNames: lifeActionTask.task.assigneeNames,
        includeInGuidebook: lifeActionTask.task.includeInGuidebook
      }
    }
  })
  return taskList
}

const mapAssignees = (assignees: AssigneesObj[]) => {
  const assigneeList: any = {}
  assignees.map((assignee) => {
    if (assignee.id) {
      assigneeList[assignee.id] = {
        firstName: assignee.firstName,
        lastName: assignee.lastName,
        id: assignee.id
      }
    }
  })
  return assigneeList
}

const mapHcCards = (hcCards: any[]) => {
  const lifeActionCardList: any = {}
  hcCards.map((hcCard) => {
    lifeActionCardList[hcCard.id] = {
      hcCardId: hcCard.hcCardId,
      actionId: hcCard.actionId
    }
  })
  return lifeActionCardList
}

const mapLifeActions = (lifeActions: LifeActionObj[]) => {
  const lifeActionList: LifeActionsInterface = {}
  lifeActions.map((lifeAction) => {
    lifeActionList[lifeAction.id] = {
      id: lifeAction.id,
      name: lifeAction.name,
      comment: lifeAction.comment,
      status: lifeAction.status,
      householdId: lifeAction.householdId,
      dueDate: lifeAction.dueDate,
      assignees: mapAssignees(lifeAction.assignees),
      assigneePrimaryId: lifeAction.assigneePrimaryId,
      assigneeSecondaryId: lifeAction.assigneeSecondaryId,
      tasks: mapTasks(lifeAction.tasks),
      cards: mapHcCards(lifeAction.cards)
    }
  })

  return lifeActionList
}

const LifeActions = (state = initState, action: any): LifeActionsState => {
  const newState = JSON.parse(JSON.stringify(state))
  switch (action.type) {
    case `${actions.FETCH_LIFE_ACTIONS}_FULFILLED`:
      const lifeActions = mapLifeActions(action.payload.data.data)
      return { ...newState, ...lifeActions }
    default:
      return state
  }
}

export default LifeActions
