import React from 'react'
import Button from 'react-bootstrap/Button'

import ControlledModalWrapper from '../../components/ControlledModalWrapper'

export interface RestoreModalProps {
  id: string
  type: string
  restoreModal(id: string, folderId?: string): void
  closeModal(id: string): void
}

interface RestoreModalState {
  disableButton: boolean
}

class RestoreModal extends React.Component<
  RestoreModalProps,
  RestoreModalState
> {
  constructor(props: RestoreModalProps) {
    super(props)
    this.state = {
      disableButton: false
    }
  }
  public restoreFn = () => {
    const { id, restoreModal } = this.props
    this.setState({ disableButton: true })
    restoreModal(id)
  }

  public closeFn = () => {
    this.props.closeModal('')
  }

  public render() {
    const { disableButton } = this.state
    return (
      <>
        <h1 className='vault-modal__title'>Restore File</h1>
        <div className='vault-modal__content'>
          <p>Are you sure you want to restore this file?</p>
          <div className='vault-modal__buttons'>
            <Button
              id='delete-modal-save'
              variant='primary'
              size='lg'
              className='match-btn-width'
              onClick={this.restoreFn}
              disabled={disableButton}>
              Restore
            </Button>
            <Button
              id='delete-modal-cancel'
              variant='link'
              onClick={this.closeFn}
              className='ms-4 match-btn-width'>
              Cancel
            </Button>
          </div>
        </div>
      </>
    )
  }
}

export default ControlledModalWrapper(RestoreModal)
