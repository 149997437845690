import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { Query } from '@apollo/client/react/components'
import { ApolloProvider } from '@apollo/client'

import { GetClientNotifications } from '../helpers/queries'

import { GQLClient } from '../app'
import RestrictedRoutes from './RestrictedRoutes'
import UnrestrictedRoutes from './UnrestrictedRoutes'

interface RouteIndexProps {
  history: RouteComponentProps['history']
  entryPermitted: boolean
  termsOfServiceLoading: boolean
  disableDocumentVault: boolean
  accessToken: string
  userId: string
  isAppBannerVisible: boolean
  initializeGQL(accessToken: string): void
}

const RouteIndex: FC<RouteIndexProps> = ({
  userId,
  accessToken,
  disableDocumentVault,
  isAppBannerVisible,
  history,
  termsOfServiceLoading,
  entryPermitted,
  initializeGQL
}) => {
  if (accessToken && entryPermitted) {
    if (!GQLClient) {
      initializeGQL(accessToken)
    }
    return (
      <ApolloProvider client={GQLClient}>
        <Query
          query={GetClientNotifications}
          variables={{ userId }}
          skip={!userId}>
          {({ data, subscribeToMore }) => {
            return (
              <RestrictedRoutes
                isAppBannerVisible={isAppBannerVisible}
                disableDocumentVault={disableDocumentVault}
                history={history}
                data={data}
                subscribeToMore={subscribeToMore}
              />
            )
          }}
        </Query>
      </ApolloProvider>
    )
  } else
    return <UnrestrictedRoutes termsOfServiceLoading={termsOfServiceLoading} />
}

export default RouteIndex
