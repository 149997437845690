import React from 'react'
import MessagesError from '../../../components/Messages/MessagesError'

interface MessageNullProps {
  error?: boolean
}

const MessageNull = ({ error }: MessageNullProps) => {
  return (
    <div className='message-null-w'>
      {error ? (
        <MessagesError />
      ) : (
        <div className='message-null-container'>
          <p className='message-null-text__blue'>No messages here</p>
          <p className='message-null-text'>
            Click the Back link to go back to the messages list
          </p>
        </div>
      )}
    </div>
  )
}

export default MessageNull
