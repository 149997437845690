import { FundedScoreObj, PlanningSoftwareType } from '../../../objects/plan'
import { pointColor } from '../../../helpers/plan'
import { dateFormat } from '../../../helpers'

const onHoverPoint = <E, T>(event: any, chartElement: T[]) => {
  event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
}

export const createGraphLayout = (rangeAnnotation: {
  rangeLow: number
  rangeHigh: number
}): {} => {
  return {
    events: ['mousemove', 'click'],
    scaleShowVerticalLines: false,
    pointDotRadius: 5,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 5,
        right: 5,
        top: 40,
        bottom: 0
      }
    },
    legend: {
      display: false
    },
    border: 0,
    scales: {
      xAxes: [
        {
          offset: true,
          position: 'bottom',
          gridLines: {
            display: false
          },
          scaleLabel: {
            display: false
          },
          ticks: {
            reverse: false,
            padding: 10,
            fontFamily: 'Basis Grotesque Pro',
            fontColor: '#676879',
            fontSize: 12
          }
        }
      ],
      yAxes: [
        {
          position: 'left',
          gridLines: {
            display: true,
            borderDash: [2, 1],
            color: '#AEB6BE',
            drawBorder: false,
            tickMarkLength: 1
          },
          scaleLabel: {
            display: false
          },
          ticks: {
            padding: 20,
            reverse: false,
            suggestedMax: 100,
            suggestedMin: 40,
            stepSize: 20,
            fontFamily: 'Basis Grotesque Pro',
            fontColor: '#22263F',
            fontSize: 12
          }
        }
      ]
    },
    annotation: {
      annotations: [
        {
          id: 'line-chart-annotation',
          drawTime: 'afterDraw',
          type: 'box',
          xScaleID: 'x-axis-0',
          yScaleID: 'y-axis-0',
          yMin: rangeAnnotation.rangeLow,
          yMax: rangeAnnotation.rangeHigh,
          borderColor: 'rgba(162,209,52,0)',
          backgroundColor: 'rgba(6, 134, 103, .07)',
          borderWidth: 0,
          borderDash: [2, 2]
        }
      ]
    },
    animation: {
      onComplete: 'scoreDate'
    },
    tooltips: {
      enabled: false,
      custom: createPointToolTip()
    },
    hover: {
      onHover: onHoverPoint
    }
  }
}

export const createGraphData = (
  planningSoftware: PlanningSoftwareType,
  fundedScores: FundedScoreObj[],
  rangeAnnotation: { rangeLow: number; rangeHigh: number }
): {} => {
  const chartLabels: string[] = []
  const chartData: number[] = []
  const chartPointColors: string[] = []
  const yRanges = rangeAnnotation
  const findPointColor = (chartData: number): string => {
    if (!planningSoftware) return pointColor.other
    if (chartData < yRanges.rangeLow) return pointColor.rangeLow
    if (chartData > yRanges.rangeHigh) return pointColor.rangeHigh
    if (yRanges.rangeLow <= chartData && yRanges.rangeHigh >= chartData)
      return pointColor.optimal
    else return pointColor.other
  }

  fundedScores.forEach((score) => {
    chartLabels.push(dateFormat(score['scoreDate']))
    chartData.push(score['score'])
    chartPointColors.push(findPointColor(score['score']))
  })

  return {
    labels: chartLabels,
    datasets: [
      {
        lineTension: 0,
        fill: false,
        borderColor: '#AEB6BE',
        borderWidth: 2,
        spanGaps: 5,
        pointStyle: generatePointStyles(chartPointColors, fundedScores),
        data: chartData,
        backgroundColor: chartPointColors
      }
    ]
  }
}

const createPointToolTip = () =>
  function(this: any, tooltipModel) {
    let tooltipEl = document.getElementById('chartjs-tooltip')
    const position = this._chart.canvas.getBoundingClientRect()

    if (!tooltipEl) {
      tooltipEl = document.createElement('div')
      tooltipEl.id = 'chartjs-tooltip'
      document.body.appendChild(tooltipEl)
    }
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = '0'
      return
    }
    if (tooltipModel.body) {
      tooltipEl.innerText = tooltipModel.body[0].lines[0] || ''
    }

    tooltipEl.style.opacity = '1'
    tooltipEl.style.position = 'absolute'
    tooltipEl.style.left =
      position.left + window.pageXOffset + tooltipModel.caretX + -14 + 'px'
    tooltipEl.style.top =
      position.top + window.pageYOffset + tooltipModel.caretY + -14 + 'px'
    tooltipEl.style.width = '24px'
    tooltipEl.style.height = '24px'
    tooltipEl.style.fontFamily = 'Basis Grotesque Pro'
    tooltipEl.style.fontSize = '12px'
    tooltipEl.style.color = 'white'
    tooltipEl.style.fontWeight = '400'
    tooltipEl.style.textAlign = 'center'
    tooltipEl.style.verticalAlign = 'middle'
    tooltipEl.style.lineHeight = '26px'
    tooltipEl.style.pointerEvents = 'none'
    tooltipEl.style.borderRadius = '50%'
    tooltipEl.style.border = 'solid 1px #fff'
    tooltipEl.style.backgroundColor =
      tooltipModel.labelColors[0].backgroundColor
  }

const generatePointStyles = (
  chartPointColors: string[],
  listOfFundedScores: FundedScoreObj[]
) => {
  const pointsDisplay = []
  const assignStyles = (
    id: string,
    circleStroke: string | '#8a8888'
  ): string => {
    const dimension = 20
    const startRad = 5
    const canvas: HTMLCanvasElement = document.createElement('canvas')
    canvas.id = id
    canvas.width = dimension
    canvas.height = dimension
    canvas.style.visibility = 'hidden'
    document.body.appendChild(canvas)

    let context = canvas.getContext('2d')
    context.beginPath()
    context.arc(dimension / 2, dimension / 2, startRad, 0, 2 * Math.PI, false)
    context.fillStyle = circleStroke
    context.fill()
    context.lineWidth = 2
    context.strokeStyle = '#fff'
    context.stroke()
    context = canvas.getContext('2d')
    context.fillStyle = '#000000'
    const imageFactoryCanvas = document.getElementById(id) as HTMLCanvasElement
    const base64Png: string = imageFactoryCanvas.toDataURL()
    document.getElementById(id).remove()
    return base64Png
  }

  listOfFundedScores.forEach((e, i: number) => {
    const pointImg = new Image()
    pointImg.src =
      assignStyles('funded-score-chart-point-' + i, chartPointColors[i]) ||
      'circle'
    pointsDisplay.push(pointImg)
  })

  return pointsDisplay
}
