import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { map } from 'lodash-es'
import { Link } from 'react-router-dom'
import { ClientAccountObj } from '../../objects/clientAccount'
import HorizontalBarChart from '../../components/HorizontalBarChart'
import { currentAllocMap, formatInvAmount } from '../../helpers/investments'
import { roundToTwo } from '../../helpers'
import { history } from '../../store'

interface AccountRowProps {
  account: ClientAccountObj
}

const AccountRow = ({ account }: AccountRowProps) => {
  const allocation = map(account.discreteAllocation, (rawPercent, key) => {
    const { name, fill } = currentAllocMap[key]
    const percent = roundToTwo(rawPercent)
    return { name, percent, color: fill }
  })

  const hasHoldings = Boolean(account.positions?.length)
  const holdingsLink = `/holdings/${account.id}`

  return (
    <Row className='investment-accounts-table__row g-0'>
      {hasHoldings && (
        <div
          className='investment-accounts-table__row--clickable'
          title='Go to holdings'
          onClick={() => history.push(holdingsLink)}
        />
      )}
      <Col
        xs={6}
        sm={{ span: 6, order: '1' }}
        md={4}
        lg={5}
        className='pb-0 pb-sm-3 pb-md-0'>
        <div
          className='investment-accounts-table__cell-title'
          style={{ paddingRight: '6px' }}>
          {account.accountNickname}
        </div>
        <div className='investment-accounts-table__cell-subtitle'>
          {account.category}
        </div>
      </Col>
      <Col
        className='d-none d-sm-block'
        sm={{ span: 6, order: '3' }}
        md={{ span: 4, order: '2' }}
        lg={3}>
        <HorizontalBarChart
          className='investment-accounts-table__cell-chart'
          itemList={allocation}
          tooltipOn={true}
        />
        <div className='investment-accounts-table__cell-subtitle'>
          Current Allocation
        </div>
      </Col>
      <Col
        className='d-none d-sm-flex align-items-center justify-content-end investment-accounts-table__cell-link'
        sm={{ span: 6, order: '4' }}
        md={{ span: 2, order: '3' }}>
        {hasHoldings && <Link to={holdingsLink}>See Holdings</Link>}
      </Col>
      <Col
        xs={6}
        sm={{ span: 6, order: '2' }}
        md={{ span: 2, order: '4' }}
        className='pb-0 pb-sm-3 pb-md-0'>
        <div className='investment-accounts-table__cell-title text-end'>
          {formatInvAmount(account)}
        </div>
        <div className='investment-accounts-table__cell-subtitle text-end'>
          Balance
        </div>
      </Col>
    </Row>
  )
}

export default AccountRow
