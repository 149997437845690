import React from 'react'
import { Card, Button } from 'react-bootstrap'
import { history } from '../../store'

export const newUpdateAccount = () => history.push('/financial-plan/add')

const PlanUpdate = () => {
  return (
    <Card
      id='plan-update'
      className='rounded-0 border-0 bg-white card-margin plan-update__container'>
      <div className='d-flex direction-row justify-content-between align-items-start plan-update__row'>
        <div className='d-flex direction-column justify-content-start align-items-start plan-update__column'>
          <div className='plan-update__cell-title'>Update my plan</div>
          <div className='plan-update__cell-subtitle card-margin--update'>
            Notify your advisor of any changes or additions you would like to
            make to your plan.
          </div>
        </div>
        <Button
          size='lg'
          className='card-margin--update match-btn-width'
          onClick={newUpdateAccount}>
          Propose Update
        </Button>
      </div>
    </Card>
  )
}

export default PlanUpdate
